import React from "react";
import moment from "moment";

import {
	PDFViewer,
	Page,
	Document,
	StyleSheet,
	Text,
	View,
	Image,
} from "@react-pdf/renderer";

import { Lotto } from "../../../pianificazione/pianificazioneSlice";
import { getColore } from "../../../pianificazione/utility/lottoUtility";
const logoScarso = require("../../../../../../static/images/logo/logo-Scarso_nuovo_solo.png");

// Create styles
const styles = StyleSheet.create({
	page: {
		backgroundColor: "#fff",
		paddingTop: 20,
	},
	section: {
		marginTop: 10,
		marginLeft: 10,
		marginRight: 10,
		padding: 10,
	},
	tableContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		margin: 5,
	},

	sectionIntestazione: {
		// width: "100%",
		flexDirection: "row",
		fontSize: 12,
		justifyContent: "flex-start",

		// marginTop: 5,
		// marginLeft: 10,
		// marginRight: 10,
		padding: 2,
		paddingTop: 2,
		paddingBottom: 7,
		backgroundColor: "#777777",
		color: "#ffffff",
	},
	sectionRighe: {
		// width: "100%",
		flexDirection: "row",
		justifyContent: "flex-start",

		fontSize: 8,
		// marginTop: 5,
		// marginLeft: 10,
		// marginRight: 10,
		padding: 2,
		paddingTop: 1,
		marginBottom: 1,
		paddingBottom: 5,
		marginTop: 6,

		borderBottom: " 0.5px solid #777777",
	},

	row: {
		flexDirection: "row",
		// alignItems: "center",
		justifyContent: "flex-start",

		fontSize: 10,
	},
	larghezza_ordinamento_lotto: {
		width: "3%",
	},
	larghezza_committente: {
		width: "22%",
	},
	larghezza_codice_rintrac: {
		width: "11%",
	},
	larghezza_allevatore: {
		width: "18%",
	},
	larghezza_capi_input: {
		width: "7%",
		height: "28px",
		border: " 1px solid #777777",
	},

	digiuno: {
		width: "16px",
		height: "16px",
		border: " 1px solid #777777",
		// marginLeft: "2%",
		// marginRight: "2%",
	},
	textIntestazioni: {
		paddingRight: 3,
		marginRight: 6,
		// marginVertical: 20,
		// textTransform: "uppercase",
	},

	textRighe: {
		paddingRight: 3,
		marginRight: 6,
		// marginVertical: 20,
		// textTransform: "uppercase",
	},
	text: {
		fontSize: 15,
		fontStyle: "medium",
		// marginVertical: 20,
		// textTransform: "uppercase",
	},
	sectionTable: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginLeft: 0,
		borderBottomWidth: 1,
	},
	textSeq: {
		// flexDirection: "row",
		paddingHorizontal: 3,
		paddingVertical: 2,
		fontSize: 14,
		fontWeight: "bold",
		borderWidth: 1,
	},
});

interface SchedaMacellazionePdfProps {
	lotti: Lotto[];
}

// const calcoloTotaleCapi = (lotti: Lotto[]) => {
// 	const totaleCapi = lotti.reduce(function (previousValue, currentValue) {
// 		return previousValue + (currentValue.numero_capi || 0);
// 	}, 0);
// 	return totaleCapi;
// };

const SchedaMacellazionePdf = ({ lotti }: SchedaMacellazionePdfProps) => {
	const totaleCapi = lotti.reduce(function (previousValue, currentValue) {
		return previousValue + (currentValue.numero_capi || 0);
	}, 0);
	return (
		<PDFViewer width={"100%"} height={"100%"}>
			<Document>
				<Page
					size="A4"
					style={styles.page}
					orientation="landscape"
					key={"scheda_macellazione"}
				>
					<View
						key={"scheda_macellazione_intestazione"}
						style={{
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<Text style={[styles.text, { fontSize: 20, paddingRight: 50 }]}>
							Scheda di macellazione{" "}
							<Text style={{ fontSize: 30, paddingRight: 50 }}>
								{moment(lotti[0]?.data_lavorazione).format("dddd DD MMM YYYY")}
							</Text>
						</Text>
						<Text
							style={[
								styles.text,
								{
									fontWeight: "bold",
									marginBottom: 1,
									paddingBottom: 0,
									fontSize: 20,
								},
							]}
						>
							<Image
								src={logoScarso}
								style={{
									width: 30,
									height: 30,
									// margin: 0,
									padding: 0,
									paddingLeft: "10px",
									marginLeft: "3px",
								}}
							/>
						</Text>
					</View>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<View style={styles.tableContainer}>
							<View
								key={"scheda_macellazione_lotto_intestazione"}
								style={styles.sectionIntestazione}
							>
								<Text
									style={[
										styles.textIntestazioni,
										styles.larghezza_ordinamento_lotto,
									]}
								>
									Seq.
								</Text>
								<Text
									style={[
										styles.textIntestazioni,
										styles.larghezza_committente,
									]}
								>
									Lotto
								</Text>
								<Text
									style={[
										styles.textIntestazioni,
										styles.larghezza_codice_rintrac,
									]}
								>
									Cod. Rintrac.
								</Text>
								<Text
									style={[
										styles.textIntestazioni,
										styles.larghezza_allevatore,
										{ fontSize: 8 },
									]}
								>
									allevatore{"\n"}
									<Text
										style={[
											styles.textIntestazioni,
											styles.larghezza_allevatore,
											{ fontFamily: "Times-Italic", fontSize: 10 },
										]}
									>
										(produttore)
									</Text>
								</Text>
								<Text
									style={[styles.textIntestazioni, styles.larghezza_capi_input]}
								>
									Digiuno
								</Text>
								<Text
									style={[styles.textIntestazioni, styles.larghezza_capi_input]}
								>
									Inizio macellazione
								</Text>
								<Text
									style={[styles.textIntestazioni, styles.larghezza_capi_input]}
								>
									Fine macellazione
								</Text>
								<Text
									style={[styles.textIntestazioni, styles.larghezza_capi_input]}
								>
									Capi morti
								</Text>
								<Text
									style={[styles.textIntestazioni, styles.larghezza_capi_input]}
								>
									Scarto 1
								</Text>
								<Text
									style={[styles.textIntestazioni, styles.larghezza_capi_input]}
								>
									Scarto 2
								</Text>
							</View>
							{lotti.map((lotto) => {
								return (
									<View
										key={"scheda_macellazione_lotto_" + lotto.id}
										style={styles.sectionRighe}
									>
										<Text
											style={[
												styles.textIntestazioni,
												styles.larghezza_ordinamento_lotto,
												{ fontSize: 14 },
											]}
										>
											{lotto.ordinamento_lotto}
										</Text>
										<View
											style={[
												styles.larghezza_committente,
												{ flexDirection: "column" },
											]}
										>
											<Text style={[{ fontSize: 10, fontStyle: "medium" }]}>
												{lotto.committente_desc}
											</Text>
											<Text style={[{ fontSize: 10, fontStyle: "medium" }]}>
												{lotto.numero_capi +
													" " +
													lotto.tipo_capo_desc +
													(lotto.colore ? " " + getColore(lotto) : "")}{" "}
												(
												{Intl.NumberFormat("it-IT").format(
													lotto.peso_medio || 0
												)}
												{" kg"})
											</Text>
										</View>

										<View
											style={[
												styles.larghezza_codice_rintrac,
												{ flexDirection: "column", marginTop: "5px" },
											]}
										>
											<Text style={[styles.textIntestazioni, { fontSize: 9 }]}>
												{lotto.codice_tracciabilita}
											</Text>
											{lotto.aggiustamento_resa_bst ? (
												<>
													<Text
														style={[styles.textIntestazioni, { fontSize: 9 }]}
													>
														{"TZ eviscer.: " +
															(lotto.num_tz_eviscerati_pianificati_lotto != 0
																? Intl.NumberFormat("it-IT").format(
																		lotto.num_tz_eviscerati_pianificati_lotto ||
																			0
																  )
																: "___")}
													</Text>
													<Text
														style={[styles.textIntestazioni, { fontSize: 9 }]}
													>
														{"TZ: " +
															(lotto.num_tz_pianificati_lotto != 0
																? Intl.NumberFormat("it-IT").format(
																		lotto.num_tz_pianificati_lotto || 0
																  )
																: "___")}
													</Text>
												</>
											) : (
												<Text
													style={[styles.textIntestazioni, { fontSize: 9 }]}
												>
													{"BST: " +
														(lotto.num_busti_pianificati_lotto != 0
															? Intl.NumberFormat("it-IT").format(
																	lotto.num_busti_pianificati_lotto || 0
															  )
															: "___")}
												</Text>
											)}
										</View>
										<View
											key={"scheda_macellazione_allevatore_" + lotto.id}
											style={[
												styles.larghezza_allevatore,
												{ flexDirection: "column" },
											]}
										>
											<Text style={[styles.textIntestazioni]}>
												{lotto.allevamento_pack?.nome}
											</Text>
											<Text style={[styles.textIntestazioni]}>
												({lotto.allevamento_pack?.codice_asl})
												{lotto.allevamento_pack?.comune}
											</Text>
											<Text
												style={[
													styles.textIntestazioni,
													{ fontFamily: "Times-Italic", fontSize: 10 },
												]}
											>
												({lotto.produttore_pack?.nome})
											</Text>
										</View>
										<Text
											style={[
												styles.textIntestazioni,
												{ width: "16px", height: "16px" },
												// styles.larghezza_capi_input,
											]}
										>
											{"\u2B1C"}
										</Text>
										<Text
											style={[
												styles.textIntestazioni,
												styles.digiuno,
												// styles.larghezza_capi_input,
											]}
										>
											{"\u2B1C"}
										</Text>
										<Text
											style={[
												styles.textIntestazioni,
												{ width: "16px", height: "16px" },
												// styles.larghezza_capi_input,
											]}
										>
											{"\u2B1C"}
										</Text>

										<Text
											style={[
												styles.textIntestazioni,
												styles.larghezza_capi_input,
											]}
										>
											{"\u2B1C"}
										</Text>
										<Text
											style={[
												styles.textIntestazioni,
												styles.larghezza_capi_input,
											]}
										>
											{"\u2B1C"}
										</Text>
										<Text
											style={[
												styles.textIntestazioni,
												styles.larghezza_capi_input,
											]}
										>
											{"\u2B1C"}
										</Text>
										<Text
											style={[
												styles.textIntestazioni,
												styles.larghezza_capi_input,
											]}
										>
											{"\u2B1C"}
										</Text>
										<Text
											style={[
												styles.textIntestazioni,
												styles.larghezza_capi_input,
											]}
										>
											{"\u2B1C"}
										</Text>
									</View>
								);
							})}
							<View style={styles.sectionIntestazione}>
								<Text
									style={[
										styles.textIntestazioni,
										{ width: "100%", color: "#f4f624" },
									]}
								>
									{"Tot. capi: "}
									{Intl.NumberFormat("it-IT").format(totaleCapi)}
								</Text>
							</View>
						</View>
					</View>
				</Page>
			</Document>
		</PDFViewer>
	);
};

export default SchedaMacellazionePdf;
