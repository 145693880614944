import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import { theme } from "../../theme";

interface ConfermaAnnullaProps {
	handleAnnulla: () => void;
	handleConferma: () => void;
	testoConferma?: string;
	handleAzioneAlternativa?: () => void;
	testoAzioneAlternativa?: string;
	domanda: string;
	open: boolean;
}

const ConfermaAnnulla = ({
	handleAnnulla,
	handleConferma,
	testoConferma,
	handleAzioneAlternativa,
	testoAzioneAlternativa,
	domanda,
	open = false,
	...props
}: ConfermaAnnullaProps) => {
	return (
		<Modal
			open={open}
			onClose={handleAnnulla}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Alert
				elevation={6}
				variant="outlined"
				severity="warning"
				sx={{
					bgcolor: "background.paper",
					position: "absolute" as "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					maxWidth: 400,
					boxShadow: 24,
					py: 6,
					px: 2,
					textAlign: "center",
				}}
			>
				<AlertTitle sx={{ color: theme.palette.text.primary }}>
					{domanda}?
				</AlertTitle>

				<Stack direction="row" spacing={2}>
					<Button onClick={handleConferma} variant="outlined">
						{testoConferma ? testoConferma : "Conferma"}
					</Button>
					{handleAzioneAlternativa && (
						<Button onClick={handleAzioneAlternativa} variant="outlined">
							{testoAzioneAlternativa
								? testoAzioneAlternativa
								: "altrimenti..."}
						</Button>
					)}
					<Button onClick={handleAnnulla} variant="outlined">
						Annulla
					</Button>
				</Stack>
			</Alert>
		</Modal>
	);
};

export default ConfermaAnnulla;
