import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

import { Lotto } from "../../pianificazione/pianificazioneSlice";
import { SchedaCarico } from "../schedeCaricoSlice";
import { getColore } from "../../pianificazione/utility/lottoUtility";

// Create styles
const styles = StyleSheet.create({
	page: {
		backgroundColor: "#fff",
		paddingTop: 20,
	},
	section: {
		marginTop: 10,
		marginLeft: 10,
		marginRight: 10,
		padding: 10,
	},
	sectionIntestazione: {
		// width: "100%",
		marginTop: 5,
		marginLeft: 10,
		marginRight: 10,
		padding: 10,
		paddingTop: 10,
		paddingBottom: 7,
		backgroundColor: "#777777",
		color: "#ffffff",
	},
	text: {
		fontSize: 20,
		fontStyle: "medium",
		marginVertical: 20,
		textTransform: "uppercase",
	},
	sectionTable: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginLeft: 0,
		borderBottomWidth: 1,
		width: 550,
	},
	sectionFrame: {
		flexDirection: "row",
		justifyContent: "flex-start",
		textAlign: "center",
		width: 400,
		// paddingHorizontal: 30,
		paddingVertical: 20,
		fontSize: 260,
		fontStyle: "medium",
		borderWidth: 1,
		marginRight: 5,
	},
});

interface EtichetteGabbiePdfProps {
	lotto: Lotto;
	schedaCarico: SchedaCarico;
}

const EtichetteGabbiePdf = ({
	lotto,
	schedaCarico,
}: EtichetteGabbiePdfProps) => (
	<View
		key={"etichetta_schedaCarico_" + schedaCarico.id}
		style={{
			flexDirection: "column",
			justifyContent: "center",
		}}
	>
		<View
			style={{
				flexDirection: "column",
				alignItems: "center",
				height: 400,
				marginTop: 25,
			}}
		>
			<Text
				style={[styles.text, { fontSize: 32, marginBottom: 10, marginLeft: 0 }]}
			>
				PARTITA
			</Text>
			<Text style={[styles.sectionFrame]}>{lotto.ordinamento_lotto}</Text>
		</View>

		<View
			style={{
				flexDirection: "column",
				alignItems: "center",
				marginLeft: 10,
				marginRight: 10,
				height: 350,
				marginTop: 20,
			}}
		>
			<View style={styles.sectionTable}>
				<Text style={[styles.text, { fontSize: 32, paddingRight: 10 }]}>
					{schedaCarico.camion_pack?.nome}
				</Text>
				<View style={{ flexDirection: "row" }}>
					<Text
						style={[styles.text, { fontSize: 32, textTransform: "lowercase" }]}
					>
						tg.{" "}
					</Text>
					<Text style={[styles.text, { fontSize: 32 }]}>
						{schedaCarico.camion_pack?.targa}
					</Text>
				</View>
			</View>

			<View style={styles.sectionTable}>
				<Text style={[styles.text, { paddingRight: 5 }]}>AUTISTA</Text>
				<View>
					{schedaCarico.autisti_pack.map((autista) => {
						return (
							<Text
								style={[styles.text, { marginBottom: 5 }]}
								key={"autista_" + autista.id}
							>
								{autista.cognome + " " + autista.nome}
							</Text>
						);
					})}
				</View>
			</View>
			<View style={styles.sectionTable}>
				<Text style={[styles.text, { paddingRight: 0, width: 80 }]}>
					ALLEV.
				</Text>
				<View>
					<Text
						style={[
							styles.text,
							{
								textAlign: "right",
								width: 400,
							},
						]}
					>
						{lotto.allevamento_pack?.nome}
					</Text>
				</View>
			</View>
			<View style={styles.sectionTable}>
				<Text style={[styles.text, { paddingRight: 0, width: 220 }]}>
					{lotto.tipo_capo_desc} {getColore(lotto)}
				</Text>
				{/* </View>
			<View style={styles.sectionTable}> */}
				<View>
					<Text style={[styles.text, { textAlign: "right", width: 330 }]}>
						{lotto.committente_desc}
					</Text>
				</View>
			</View>
			<View style={styles.sectionTable}>
				<Text break />
			</View>
		</View>
	</View>
);

export default EtichetteGabbiePdf;
