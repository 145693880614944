import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../redux/store";
import { Link, useLocation } from "react-router-dom";

import {
	fetchLottiProduttore,
	LottiCommittenteProduttore,
} from "../../ospitiSlice";

import { Utente } from "../../../../authentication/authenticationSlice";
import { PermissionTypes } from "../../../../authentication/permissionsGroups";

import {
	Committente,
	Committenti,
	Produttori,
	Produttore,
} from "../../../anagrafiche/anagraficheSlice";
import { SelectCommittente } from "../../dashboard/DashboardCommittentiToolbarOptions";

import NavigazioneGiorniToolbar from "../../../../common/dateUtils/NavigazioneGiorniToolbar";

import moment from "moment";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import LottiProduttorePageBreadcrumbs from "./LottiProduttorePageBreadcrumbs";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import DateToday from "../../../../common/dateUtils/DateToday";
import PaperMui from "@mui/material/Paper";
import LottiProduttoreBox from "./LottiProduttoreBox";

import { styled, Box } from "@mui/system";

const Paper = styled(PaperMui)(({ theme }) => ({
	color: theme.palette.primary.contrastText,
	backgroundColor: "#b0b0b0",
	padding: theme.spacing(1),
	borderRadius: theme.shape.borderRadius,
}));

const LottiProduttorePage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	const utente = useSelector<RootState, Utente>(
		(state) => state.authentication.utenteLogged
	);

	const committenti: Committenti = useSelector<RootState, Committenti>(
		(state) => state.anagrafiche.committenti
	);

	const produttori = useSelector<RootState, Produttori>(
		(state) => state.anagrafiche.produttori
	);

	const [produttoreCorrente, setProduttoreCorrente] =
		React.useState<Produttore | null>(null);

	useEffect(() => {
		if (utente.gruppi?.includes(PermissionTypes.PIANIFICATORI)) {
			!produttoreCorrente && setProduttoreCorrente(produttori.results[0]);
		} else {
			!!utente.produttore &&
				setProduttoreCorrente(
					produttori.results.find(
						(produttore) => produttore.id == utente.produttore
					) || null
				);
		}
	}, [produttori.results]);

	const handlerChangeProduttore = (event: SelectChangeEvent) => {
		const newProduttore = produttori.results.find(
			(produttore) => produttore.id.toString() == event.target.value
		);
		setProduttoreCorrente(newProduttore || null);
	};

	const location = useLocation();

	const [refresh, setRefresh] = React.useState<boolean>(false);

	useEffect(() => {
		!!produttoreCorrente?.id &&
			dispatch(fetchLottiProduttore({ produttore_id: produttoreCorrente.id }));
		setRefresh(false);
	}, [refresh]);

	return (
		<Box>
			<LottiProduttorePageBreadcrumbs />
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					committenti.errorsStack.status == ErrorStatusTypes.PENDING
					// schedeCarico.errorsStack.status == ErrorStatusTypes.PENDING ||
					// consuntiviVivo.errorsStack.status == ErrorStatusTypes.PENDING ||
					// anagraficheErrorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<NavigazioneGiorniToolbar
				titolo={"Lotti prodotti da " + produttoreCorrente?.nome}
				dataPresente={false}
				setData={(data: Date) => {}}
				refresh={() => {
					setRefresh(true);
				}}
				Toolbar={() => {
					return (
						<Stack
							direction="row"
							justifyContent="flex-end"
							alignItems="center"
							sx={{ ml: 2 }}
							spacing={1}
						>
							{utente.gruppi?.includes(PermissionTypes.PIANIFICATORI) &&
								produttori.results && (
									<Select
										key="selectCommittenteCorrente"
										required={true}
										id="selectCommittenteCorrente"
										label="Committente"
										// disabled={disabled}
										// notched={"true"}
										// defaultValue={produttoreCorrente?.id||0}
										value={produttoreCorrente?.id.toString() || "0"}
										variant="outlined"
										sx={{ width: "100%" }}
										onChange={handlerChangeProduttore}
									>
										{produttori.results.map((option) => {
											return (
												<MenuItem key={option.id} value={option.id}>
													{option.nome}
												</MenuItem>
											);
										})}
									</Select>
								)}
						</Stack>
					);
				}}
			/>
			{produttoreCorrente && (
				<LottiProduttoreBox
					produttoreCorrente={produttoreCorrente}
					refresh={refresh}
					setRefresh={setRefresh}
				/>
			)}
		</Box>
	);
};

export default LottiProduttorePage;
