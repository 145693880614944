import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { listenerMiddleware } from "../../redux/listenerMiddleware";
import { authenticationSlice } from "../authentication/authenticationSlice";

export interface OrdinamentoLista {
	asc: boolean;
	campo: string;
}

export interface PreferenzeLista {
	numeroRecord?: number;
	page?: number;
	statoId?: string;
	ordinamento?: OrdinamentoLista;
}

export interface PreferenzeListaEventiManutenzione extends PreferenzeLista {
	camionId?: number;
}

export interface PreferenzeUtente {
	drawer_aperto: boolean;
	dark_mode: boolean;
}

export interface UserStored {
	username?: string;
	email?: string;
	access?: string;
	refresh?: string;
	timeRefresh?: string;
}

export interface PreferenzeUtenteState {
	userStored?: UserStored;
	preferenzeUtente: PreferenzeUtente;
	preferenzeListaEventiManutenzione: PreferenzeListaEventiManutenzione;
}

const initialState: PreferenzeUtenteState = {
	preferenzeUtente: { drawer_aperto: true, dark_mode: false },
	preferenzeListaEventiManutenzione: { numeroRecord: 10, page: 1 },
};

export const userPreferenceSlice = createSlice({
	name: "userPreference",
	initialState,
	reducers: {
		setDrawerAperto: (state, action: PayloadAction<boolean>) => {
			state.preferenzeUtente.drawer_aperto = action.payload;
		},
		setPreferenzeListaEventiManutenzione: (
			state,
			action: PayloadAction<PreferenzeListaEventiManutenzione>
		) => {
			state.preferenzeListaEventiManutenzione = action.payload;
		},

		setUserStored: (state, action: PayloadAction<UserStored>) => {
			state.userStored = action.payload;
		},
		tokenRefresh: (
			state,
			action: PayloadAction<{
				access?: string;
				refresh?: string;
			}>
		) => {
			state.userStored = { ...state.userStored, ...action.payload };
		},
		resetUserStored: (state) => {
			state.userStored = undefined;
		},
	},
	extraReducers: (builder) => {},
});

// Action creators are generated for each case reducer function
export const {
	setDrawerAperto,
	setPreferenzeListaEventiManutenzione,
	setUserStored,
	tokenRefresh,
	resetUserStored,
} = userPreferenceSlice.actions;

listenerMiddleware.startListening({
	actionCreator: authenticationSlice.actions.resetUtente,
	effect: (action, listenerApi) => {
		listenerApi.dispatch(userPreferenceSlice.actions.resetUserStored());
	},
});

export const userPreferenceReducer = userPreferenceSlice.reducer;
