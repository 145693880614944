import React from "react";
import { Link } from "react-router-dom";
import { Routes_path } from "../../../../routerApp";

import { Lotto } from "../../../pianificazione/pianificazioneSlice";
import { Parametri } from "../../../anagrafiche/anagraficheSlice";
import { LottoCommittente } from "../../ospitiSlice";

import Avatar from "@mui/material/Avatar";
import { theme } from "../../../../../theme";
import moment from "moment";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { getColore } from "../../../pianificazione/utility/lottoUtility";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

import Chip from "@mui/material/Chip";
interface LottiProduttoreCardProps {
	lotto: LottoCommittente;
}

const LottiProduttoreCard = ({ lotto }: LottiProduttoreCardProps) => {
	return (
		<>
			<ListItem key={"Box_lottiCommittente_page_" + lotto.id} disablePadding>
				{/* <ListItemButton component={Link} to={"/ospiti/lotto/" + lotto.id}> */}
				<ListItemButton
					component={Link}
					to={Routes_path.LOTTO_PRODUTTORE + lotto.id}
				>
					<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }}>
						<Grid item xs={12} sm={12} md={12} lg={7} container>
							<Grid item xs={12} sm={3} md={3}>
								<Stack
									direction="row"
									justifyContent="flex-start"
									alignItems="flex-start"
									spacing={2}
									sx={{ mb: 1 }}
								>
									<Chip
										label={lotto.codice_tracciabilita || "________"}
										color={"primary"}
										variant="outlined"
										sx={{
											backgroundColor: lotto.resa_confermata
												? "rgba(88, 237, 92, 0.2)"
												: "background.paper",
											borderColor: lotto.in_dubbio
												? "rgba(250, 250, 250, 0)"
												: lotto.stato_lotto.stato == 4
												? "red"
												: lotto.stato_lotto.stato == 3
												? "lightgreen"
												: lotto.stato_lotto.stato == 2
												? "rgba(91, 188, 247, 1)"
												: lotto.stato_lotto.stato == 1
												? "#ffdc10"
												: "#999",
										}}
									/>
									<Typography variant="body1" component="div">
										{moment(lotto.data_lavorazione).format("ddd DD MMM YYYY")}
									</Typography>
								</Stack>
							</Grid>
							<Grid item xs={5} sm={2} md={2}>
								<Stack
									direction="row"
									justifyContent="flex-start"
									alignItems="flex-start"
									spacing={2}
									sx={{ mb: 1 }}
								>
									<Typography variant="h6" component="div">
										{
											lotto.numero_capi + " " + lotto.tipo_capo_desc
											// +" " + getColore(lotto)
										}
									</Typography>
								</Stack>
							</Grid>
							<Grid item xs={4} sm={4} md={3}>
								<Typography variant="body1" component="div">
									{lotto.committente_desc}
								</Typography>
							</Grid>
							<Grid item xs={3} sm={3} md={3}>
								<Typography variant="body1" component="div">
									{lotto.allevamento_pack?.nome} (
									{lotto.allevamento_pack?.comune})
								</Typography>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={5}
							container
							justifyContent="flex-end"
						>
							<Grid item xs={3} sm={3} md={3} justifyContent="flex-end">
								<Typography variant="body1" component="div" align="right">
									{Intl.NumberFormat("it-IT", {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}).format(lotto.peso_medio || 0) + " kg"}
								</Typography>
							</Grid>
							<Grid item xs={3} sm={3} md={3}>
								<Typography variant="body1" component="div" align="right">
									{Intl.NumberFormat("it-IT").format(lotto.capi_ritirati || 0)}
								</Typography>
							</Grid>
							<Grid item xs={3} sm={3} md={3} justifyContent="flex-end">
								<Typography variant="body1" component="div" align="right">
									{Intl.NumberFormat("it-IT", {
										maximumFractionDigits: 0,
									}).format(
										(lotto.peso_casse_busto_lotto || 0) +
											(lotto.peso_casse_tz_lotto || 0)
									) + " kg"}
								</Typography>
							</Grid>
							<Grid item xs={3} sm={3} md={3} justifyContent="flex-end">
								<Typography variant="body1" component="div" align="right">
									{Intl.NumberFormat("it-IT").format(
										(lotto.num_busti_lotto || 0) +
											(lotto.num_tz_lotto || 0) +
											(lotto.numero_scarti_2 || 0)
									)}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</ListItemButton>
			</ListItem>
			<Divider />
		</>
	);
};

export default LottiProduttoreCard;
