import React from "react";
import { IndicatoriTotaliCommittenteTipiCapo } from "../../reportistica/grafici/graficiKpiSlice";
import moment from "moment";

import {
	RadialBarChart,
	BarChart,
	Bar,
	RadialBar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	PolarAngleAxis,
	ResponsiveContainer,
} from "recharts";

import { stringToColour } from "../../../common/stringToColour";
const generaData = (
	indicatoriTotaliCommittenteTipiCapo: IndicatoriTotaliCommittenteTipiCapo[]
) => {
	const data = indicatoriTotaliCommittenteTipiCapo
		.filter((tipo) => tipo.resa_media > 0)
		.map((tipo) => ({
			...tipo,
			name: tipo.tipo_capo,
			fill: stringToColour(tipo.tipo_capo),
		}));

	return { data: data };
};

interface CommittenteResaMediaTipiCapoGraficoProps {
	indicatoriTotaliCommittenteTipiCapo: IndicatoriTotaliCommittenteTipiCapo[];
}
const CommittenteResaMediaTipiCapoGrafico = ({
	indicatoriTotaliCommittenteTipiCapo,
}: CommittenteResaMediaTipiCapoGraficoProps) => {
	return (
		<ResponsiveContainer width="100%" height={600}>
			<RadialBarChart
				// width={600}
				// height={400}
				cx="50%"
				cy="34%"
				innerRadius={
					90 -
					Math.pow(
						generaData(indicatoriTotaliCommittenteTipiCapo).data.length,
						2.3
					)
				}
				// outerRadius="140"
				startAngle={180}
				endAngle={0}
				barSize={
					30 - generaData(indicatoriTotaliCommittenteTipiCapo).data.length / 2
				}
				data={generaData(indicatoriTotaliCommittenteTipiCapo).data}
			>
				{/* <Tooltip
					formatter={(value: number) =>
						new Intl.NumberFormat("it-IT", {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})
							.format(value * 100)
							.toString() + "%"
					}
				/> */}
				<Legend
					// iconSize={10}
					// width={120}
					// height={120}
					// layout="vertical"
					// verticalAlign="top"
					// align="right"
					wrapperStyle={{ fontSize: 13, position: "sticky", bottom: "18%" }}
				/>

				<PolarAngleAxis
					type="number"
					domain={[0, 1]}
					angleAxisId={0}
					tick={false}
				/>

				<RadialBar
					dataKey={"resa_media"}
					name={"resa media"}
					background
					isAnimationActive={false}
					label={{
						fill: "#FFFFFF",
						fontSize: "14",
						fontWeight: "800",
						position: "insideStart",
						formatter: (value: number) =>
							new Intl.NumberFormat("it-IT", {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})
								.format(value * 100)
								.toString() + "%",
					}}
				/>
			</RadialBarChart>
		</ResponsiveContainer>
	);
};
export default CommittenteResaMediaTipiCapoGrafico;
