import React from "react";
import { CostoLavorazione } from "../bolleFattureSlice";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

interface LottoCorrispettiviLavorazioniCardProps {
	costi_di_lavorazione_list: CostoLavorazione[];
}

const LottoCorrispettiviLavorazioniCard = ({
	costi_di_lavorazione_list,
}: LottoCorrispettiviLavorazioniCardProps) => {
	return (
		<Box>
			<Typography variant="h6" gutterBottom>
				Costi Lavorazioni
			</Typography>
			<Box sx={{ mt: 3 }}>
				<Grid container spacing={2}>
					<Grid item md={2}>
						<Typography variant="body2">Codice cliente</Typography>
					</Grid>

					<Grid item md={4}>
						<Typography variant="body2">descrizione</Typography>
					</Grid>
					<Grid item md={2}>
						<Typography variant="body2" align="right">
							n° casse
						</Typography>
					</Grid>
					<Grid item md={2}>
						<Typography variant="body2" align="right">
							peso
						</Typography>
					</Grid>
					<Grid item md={2}>
						<Typography variant="body2" align="right">
							costo
						</Typography>
					</Grid>
				</Grid>
				<Divider />
			</Box>

			{costi_di_lavorazione_list.map((costoLavorazione) => {
				return (
					<Grid container spacing={2} key={costoLavorazione.lavorazione_id}>
						<Grid item md={2}>
							<Typography variant="subtitle2" gutterBottom>
								{costoLavorazione.codice_articolo_committente}
							</Typography>
						</Grid>

						<Grid item md={4}>
							<Typography variant="subtitle2" gutterBottom>
								{costoLavorazione.descrizione}
							</Typography>
						</Grid>
						<Grid item md={2}>
							<Typography variant="subtitle2" gutterBottom align="right">
								{Intl.NumberFormat("it-IT", {
									maximumFractionDigits: 0,
								}).format(costoLavorazione.numero_cassette_prodotte || 0)}
							</Typography>
						</Grid>
						<Grid item md={2}>
							<Typography variant="subtitle2" gutterBottom align="right">
								{Intl.NumberFormat("it-IT", {
									maximumFractionDigits: 0,
								}).format(costoLavorazione.peso_cassette_prodotte || 0)}{" "}
								kg
							</Typography>
						</Grid>
						<Grid item md={2}>
							<Typography variant="subtitle2" gutterBottom align="right">
								{Intl.NumberFormat("it-IT", {
									maximumFractionDigits: 0,
								}).format(costoLavorazione.costo_lavorazione || 0)}{" "}
								€
							</Typography>
						</Grid>
					</Grid>
				);
			})}
		</Box>
	);
};

export default LottoCorrispettiviLavorazioniCard;
