import React, { useState, useEffect } from "react";
import { Lotto } from "../pianificazione/pianificazioneSlice";

import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

interface SchedaMacelloInfoLottoProps {
	lotto: Lotto;
}
const SchedaMacelloInfoLotto = ({ lotto }: SchedaMacelloInfoLottoProps) => {
	return (
		<Grid container spacing={4} justifyContent="space-around">
			<Grid item>
				<Typography sx={{ mb: 1.5 }} color="text.secondary">
					Allevatore
				</Typography>

				<Typography variant="body1">{lotto.allevamento_pack?.nome}</Typography>
				<Typography variant="body2">
					{"cod. asl. " + lotto.allevamento_pack?.codice_asl}
				</Typography>
			</Grid>
			<Grid item>
				<Typography sx={{ mb: 1.5 }} color="text.secondary">
					Trasporto
				</Typography>
				{lotto.schede_carico.map((schedaCarico) => (
					<Box key={schedaCarico.id}>
						<Typography variant="body1" display="inline">
							{schedaCarico.camion_pack?.nome + " "}
						</Typography>
						<Typography
							variant="caption"
							display="inline"
							sx={{ fontStyle: "italic" }}
						>
							{schedaCarico.autisti_pack
								.map((autista) => autista.cognome)
								.join(", ")}
						</Typography>
					</Box>
				))}
			</Grid>

			<Grid item>
				<Typography sx={{ mb: 1.5 }} color="text.secondary">
					N° Capi
				</Typography>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
				>
					<Typography variant="body1">Pianificati:</Typography>
					<Typography variant="body1">
						{Intl.NumberFormat("it-IT").format(lotto.numero_capi || 0)}
					</Typography>
				</Stack>

				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
				>
					<Typography variant="body1">Ritirati:</Typography>
					<Typography variant="body1">
						{Intl.NumberFormat("it-IT").format(lotto.capi_ritirati || 0)}
					</Typography>
				</Stack>
			</Grid>
			<Grid item>
				<Typography sx={{ mb: 1.5 }} color="text.secondary">
					Peso
				</Typography>

				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
				>
					<Typography variant="body1">Partenza:</Typography>
					<Typography variant="body1">
						{Intl.NumberFormat("it-IT").format(lotto.peso_lotto_partenza || 0)}
					</Typography>
				</Stack>

				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
				>
					<Typography variant="body1">Arrivo:</Typography>
					<Typography variant="body1">
						{Intl.NumberFormat("it-IT").format(lotto.peso_lotto_arrivo || 0)}
					</Typography>
				</Stack>
			</Grid>
			<Grid item>
				<Typography sx={{ mb: 1.5 }} color="text.secondary">
					Peso medio
				</Typography>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
				>
					<Typography variant="body1">Pianificato:</Typography>
					<Typography variant="body1">
						{Intl.NumberFormat("it-IT").format(lotto.peso_medio || 0)}
					</Typography>
				</Stack>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
				>
					<Typography variant="body1">Arrivo:</Typography>
					<Typography variant="body1">
						{Intl.NumberFormat("it-IT").format(
							lotto.peso_medio_lotto_arrivo || 0
						)}
					</Typography>
				</Stack>
			</Grid>
		</Grid>
	);
};

export default SchedaMacelloInfoLotto;
