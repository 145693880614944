import React, { useEffect } from "react";
import { useAppDispatch, RootState } from "./../../../redux/store";
import { useSelector } from "react-redux";

import { LavorazioneCommittente } from "./lavorazioniSlice";
import {
	Articolo,
	Etichetta,
	CodiceAI,
	fetchEtichette,
	fetchCodiciAI,
} from "../anagrafiche/anagraficheSlice";
import { Lotto } from "../pianificazione/pianificazioneSlice";
import LavorazioneCommittenteForm from "./LavorazioneCommittenteForm";
import { recuperaPesature } from "./lavorazioniConsuntivazione/confezionamentiSlice";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TransitionsModal from "../../common/TransitionsModal";

import Collapse from "@mui/material/Collapse";

interface LavorazioneCommittenteFooterProps {
	lotto: Lotto;
	lavorazioneCommittenteCorrente: LavorazioneCommittente;
	setLavorazioneCommittenteCorrente: (
		lavorazioneCommittente_id: number
	) => void;
	resetLavorazioneCommittenteCorrente: () => void;
	articoli: Articolo[];
	saveLavorazioneCommittente: (
		lottoId: number,
		lavorazioneCommittenteToSave: LavorazioneCommittente
	) => void;
	deleteLavorazioneCommittente: (
		lottoId: number,
		lavorazioneCommittenteToDelete: LavorazioneCommittente
	) => void;
}

const LavorazioneCommittenteFooter = ({
	lotto,
	lavorazioneCommittenteCorrente,
	setLavorazioneCommittenteCorrente,
	resetLavorazioneCommittenteCorrente,
	articoli,
	saveLavorazioneCommittente,
	deleteLavorazioneCommittente,
}: LavorazioneCommittenteFooterProps) => {
	const dispatch = useAppDispatch();

	const [formAttivo, setFormAttivo] = React.useState(false);

	useEffect(() => {
		if (lavorazioneCommittenteCorrente.id) {
			setFormAttivo(true);
		}
		if (
			lavorazioneCommittenteCorrente.errorsStack?.status ==
			ErrorStatusTypes.SUCCESS
		) {
			setFormAttivo(false);
		}
	}, [lavorazioneCommittenteCorrente]);

	useEffect(() => {
		dispatch(fetchEtichette());
		dispatch(fetchCodiciAI());
	}, []);

	const etichette: Etichetta[] = useSelector<RootState, Etichetta[]>(
		(state) => state.anagrafiche.etichette.results
	);
	const codiciAI: CodiceAI[] = useSelector<RootState, CodiceAI[]>(
		(state) => state.anagrafiche.codiciAI.results
	);

	const handleOpenFormToAdd = () => {
		setLavorazioneCommittenteCorrente(0);
		setFormAttivo(true);
	};
	const handleClosehForm = () => {
		resetLavorazioneCommittenteCorrente();
		setFormAttivo(false);
	};
	const handleRecuperaPesature = () => {
		dispatch(recuperaPesature(lotto));
	};
	return (
		<Box>
			<Collapse in={!formAttivo}>
				<Stack
					direction="row"
					justifyContent="flex-end"
					alignItems="center"
					sx={{ ml: 2 }}
					spacing={1}
				>
					<Box sx={{ padding: "10px" }}>
						<Button variant="outlined" onClick={handleOpenFormToAdd}>
							aggiungi lavorazione
						</Button>
					</Box>
					<Button
						onClick={handleRecuperaPesature}
						variant="outlined"
						// disabled={true}
					>
						Recupera Pesature
					</Button>
				</Stack>
			</Collapse>
			<TransitionsModal open={formAttivo} handleClose={handleClosehForm}>
				<Paper elevation={3} sx={{ my: 2, padding: "10px" }}>
					{formAttivo && (
						<LavorazioneCommittenteForm
							lavorazioneCommittente={lavorazioneCommittenteCorrente}
							annulla={handleClosehForm}
							lotto_id={lotto.id || 0}
							// articoli={articoli.filter((articolo) =>
							// 	articolo.tipi_capo.includes(lotto.tipo_capo || 0)
							// )}
							articoli={articoli}
							etichette={etichette}
							codiciAI={codiciAI}
							saveLavorazioneCommittente={saveLavorazioneCommittente}
							deleteLavorazioneCommittente={deleteLavorazioneCommittente}
						/>
					)}
				</Paper>
			</TransitionsModal>
		</Box>
	);
};
export default LavorazioneCommittenteFooter;
