import React from "react";
import { Link } from "react-router-dom";

import { styled, experimental_sx as sx } from "@mui/material/styles";
import under_construction from "../../../static/images/common/UNDER_CONSTRUCITON.webp";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";

// const Box = styled(MuiBox)(({ theme }) =>
// 	sx({
// 		display: "flex",
// 		flexWrap: "wrap",
// 		"& > :not(style)": {
// 			// m: 1,
// 			marginTop: 5,
// 			maxWidth: "90%",
// 			height: "auto",
// 		},
// 		justifyContent: "center",
// 		border: 0,
// 		// elevation: 0,
// 	})
// );

const UnderConstruction = () => {
	return (
		<Stack
			direction="row"
			justifyContent="center"
			alignItems="center"
			spacing={2}
			sx={{ mt: 5 }}
		>
			{/* <Box sx={{ height: 20 }}>
				<img src={under_construction} alt="under construction" />
			</Box> */}
			<Card sx={{ width: "40%" }}>
				<CardHeader title="In Costruzione" />
				<CardMedia
					sx={{ height: 400 }}
					component="img"
					height="194"
					image={under_construction}
					alt="In costruzione"
				/>
				<CardContent>
					<Button
						sx={{
							backgroundColor: "#f8d300",
							"&:hover": {
								backgroundColor: "#a1887f",
							},
							color: "#222222",
							elevation: 2,
							align: "center",
						}}
						variant="contained"
						component={Link}
						to="/"
					>
						{"Back to Homepage"}
					</Button>
				</CardContent>
			</Card>
		</Stack>
	);
};

export default UnderConstruction;
