import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import { getConsuntivoVivo } from "../schedeCarico/consuntiviVivo/consuntiviVivoSlice";
import * as allegatiApi from "../../../api/allegati/allegatiApi";
import moment from "moment";
import { toast } from "react-toastify";
// import { useDispatch } from "react-redux";

moment.locale("it");

export interface TipoDocumento {
	id: number;
	nome: string;
	descrizione?: string;
}
export interface TipiDocumento {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: TipoDocumento[];
}

export interface Allegato {
	id: number | null;
	tipo_documento: number | null;
	tipo_documento_des?: TipoDocumento;
	descrizione: string;
	scheda_carico?: number | null;
	evento_manutenzione?: number | null;
	autore: string;
	file: string;
}

export interface Allegati {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Allegato[];
	errorsStack: ErrorsStack;
}

export interface AllegatiState {
	allegato: Allegato;
	errorsStack: ErrorsStack;
}

export interface AllegatiStrutturaState {
	tipiDocumento: TipiDocumento;
	allegati: Allegati;
	allegatoCorrente: AllegatiState;
}

const initialState: AllegatiStrutturaState = {
	tipiDocumento: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
	},
	allegati: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	allegatoCorrente: {
		allegato: {
			id: null,
			tipo_documento: null,
			descrizione: "",
			scheda_carico: null,
			autore: "",
			file: "",
		},
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchTipiDocumento = createAsyncThunk(
	"allegato/fetchTipiDocumento",
	async () => {
		return await allegatiApi.fetchTipiDocumento();
		//   return user;
	}
);
export const fetchAllegati = createAsyncThunk(
	"allegato/fetchAllegati",
	async (filtriAllegati: {
		schedaCarico_id?: number | null;
		eventoManutenzione_id?: number | null;
		tipo_documento_id?: number;
	}) => {
		return await allegatiApi.fetchAllegati(
			filtriAllegati.schedaCarico_id,
			filtriAllegati.eventoManutenzione_id,
			filtriAllegati.tipo_documento_id
		);
		//   return user;
	}
);

export const uploadAllegato = createAsyncThunk(
	"allegato/uploadAllegato",
	async (
		parametriAllegato: {
			allegato: Allegato;
			file: File;
		},
		thunkApi
	) => {
		const schedaCarico_id = parametriAllegato.allegato.scheda_carico;
		return await allegatiApi
			.uploadAllegato(parametriAllegato.allegato, parametriAllegato.file)
			.then((response) => {
				thunkApi.dispatch(fetchAllegati({ schedaCarico_id }));
				schedaCarico_id &&
					thunkApi.dispatch(getConsuntivoVivo(schedaCarico_id));
				return response;
			});
	}
);

export const mandaDocumentiLotto = createAsyncThunk(
	"allegato/mandaDocumentiLotto",
	async (
		parametriMail: {
			lotto_id: number;
			contatto_id: number;
		},
		thunkApi
	) => {
		return await allegatiApi.mandaDocumentiLotto(
			parametriMail.lotto_id,
			parametriMail.contatto_id
		);
	}
);

export const avvertiUtenteDocumentiLotto = createAsyncThunk(
	"allegato/avvertiUtenteDocumentiLotto",
	async (
		parametriAvvertimento: {
			lotto_id: number;
			utenteEsterno_id: number;
		},
		thunkApi
	) => {
		return await allegatiApi.avvertiUtenteDocumentiLotto(
			parametriAvvertimento.lotto_id,
			parametriAvvertimento.utenteEsterno_id
		);
	}
);

export const deleteAllegato = createAsyncThunk(
	"schedaCarico/deleteAllegato",
	async (allegatoToDelete: Allegato, thunkApi) => {
		const schedaCarico_id = allegatoToDelete.scheda_carico;
		return await allegatiApi
			.deleteAllegato(allegatoToDelete)
			.then((response) => {
				schedaCarico_id &&
					thunkApi.dispatch(getConsuntivoVivo(schedaCarico_id));
				return response;
			});
	}
);

export const allegatiSlice = createSlice({
	name: "allegatiSlice",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch tipiDocumento
		builder.addCase(fetchTipiDocumento.pending, (state, action) => {
			// state.tipiDocumento.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchTipiDocumento.fulfilled, (state, action) => {
			state.tipiDocumento = action.payload;
			// state.tipiDocumento.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchTipiDocumento.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			// state.tipiDocumento.errorsStack = {
			//   status: ErrorStatusTypes.ERROR,
			//   fieldsErrors: JSON.parse(action?.error?.message || ""),
			// };
		});
		// fetch Allegati
		builder.addCase(fetchAllegati.pending, (state, action) => {
			state.allegati.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchAllegati.fulfilled, (state, action) => {
			state.allegati.results = [
				...state.allegati.results.filter(
					(allegato) =>
						allegato.scheda_carico != action.meta.arg.schedaCarico_id
				),
				...action.payload.results,
			];

			state.allegati.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchAllegati.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.allegati.errorsStack = parseErrorMessage(action.error);
		});
		// fetch Allegati
		builder.addCase(deleteAllegato.pending, (state, action) => {
			state.allegati.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(deleteAllegato.fulfilled, (state, action) => {
			state.allegati.results = state.allegati.results.filter(
				(allegato) => allegato.id != action.meta.arg.id
			);
			toast.success(action.payload.message || "Allegato cancellato.");
			state.allegati.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(deleteAllegato.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.allegati.errorsStack = parseErrorMessage(action.error);
		});
		// carica Documento
		builder.addCase(uploadAllegato.pending, (state, action) => {
			state.allegatoCorrente.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(uploadAllegato.fulfilled, (state, action) => {
			state.allegatoCorrente.errorsStack = { status: ErrorStatusTypes.OK };
			toast.success("Allegato inserito.");
		});
		builder.addCase(uploadAllegato.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.allegatoCorrente.errorsStack = parseErrorMessage(action.error);
		});
		// invio mail Documenti
		builder.addCase(mandaDocumentiLotto.pending, (state, action) => {
			state.allegatoCorrente.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(mandaDocumentiLotto.fulfilled, (state, action) => {
			state.allegatoCorrente.errorsStack = { status: ErrorStatusTypes.OK };
			toast.success("Allegati inviati.");
		});
		builder.addCase(mandaDocumentiLotto.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.allegatoCorrente.errorsStack = parseErrorMessage(action.error);
		});
		// avverto di Documenti un utente interno
		builder.addCase(avvertiUtenteDocumentiLotto.pending, (state, action) => {
			state.allegatoCorrente.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(avvertiUtenteDocumentiLotto.fulfilled, (state, action) => {
			state.allegatoCorrente.errorsStack = { status: ErrorStatusTypes.OK };
			toast.success("Utente avvertito.");
		});
		builder.addCase(avvertiUtenteDocumentiLotto.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.allegatoCorrente.errorsStack = parseErrorMessage(action.error);
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = allegatiSlice.actions;

export const allegatiSliceReducer = allegatiSlice.reducer;
