import React from "react";
import { RootState, useAppDispatch } from "../redux/store";
import AppComposer from "./AppComposer";
import {
	utenteTokenRefresh,
	UtenteLogged,
} from "./authentication/authenticationSlice";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "./common/errorsDeclarations";

import { useSelector } from "react-redux";
// import { fetchComuni } from "../components/anagrafiche/comuni/comuniSlice";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

const AppTokenMng = () => {
	const dispatch = useAppDispatch();
	const minutiRefresh = useSelector<RootState, number>(
		(state) => state.authentication.minutiRefresh
	);

	const minutiRefreshAnagrafiche = useSelector<RootState, number>(
		(state) => state.authentication.minutiRefreshAnagrafiche
	);

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const tokenStatus = useSelector<RootState, ErrorStatusTypes>(
		(state) => state.authentication.token.errorsStack.status
	);

	const access_token = useSelector<RootState, string | undefined>(
		(state) => state.userPreferenceState?.userStored?.access
	);

	// const comuniUltimoAggiornamento = useSelector<RootState, string>(
	// 	(state) => state.comuniState.comuni.ultimo_aggiornamento
	// );

	React.useEffect(() => {
		const timeRefresh = minutiRefresh * 60 * 1000;
		access_token != undefined && dispatch(utenteTokenRefresh());
		const interval = setInterval(() => {
			access_token !== undefined && dispatch(utenteTokenRefresh());
		}, timeRefresh);
		return () => clearInterval(interval);
	}, [utente.id]);

	React.useEffect(() => {
		// utente.isLogged && dispatch(fetchTipiArticoli());
	}, [dispatch, utente.isLogged]);

	React.useEffect(() => {
		// utente.isLogged && dispatch(fetchTipiArticoli());
		// utente.isLogged &&
		// 	dispatch(fetchComuni({ ultimoAggiornamento: comuniUltimoAggiornamento }));

		const timeRefresh = minutiRefreshAnagrafiche * 60 * 1000;

		const intervalloAggiornamentoAnagrafiche = setInterval(() => {
			// utente.isLogged && dispatch(fetchTipiArticoli());
			// utente.isLogged &&
			// 	dispatch(
			// 		fetchComuni({ ultimoAggiornamento: comuniUltimoAggiornamento })
			// 	);
		}, timeRefresh);

		// setIntervalId(id);

		return () => clearInterval(intervalloAggiornamentoAnagrafiche);
	}, [dispatch, utente.isLogged]);

	return (
		<>
			{!utente.isLogged &&
			(tokenStatus == ErrorStatusTypes.PENDING ||
				utente.errorsStack.status == ErrorStatusTypes.PENDING) ? (
				<Box sx={{ width: "100%" }}>
					<Stack
						sx={{ height: 400 }}
						justifyContent="center"
						alignItems="center"
						// direction={{ xs: "column", sm: "row" }}
						spacing={{ xs: 1, sm: 2, md: 4 }}
					>
						<Card variant="outlined" sx={{ width: "50%" }}>
							<CardContent>
								<Typography variant="h3" gutterBottom>
									Verifica autenticazione in corso
								</Typography>
								<LinearProgress />
							</CardContent>
						</Card>
					</Stack>
				</Box>
			) : (
				<AppComposer />
			)}
		</>
	);
};

export default AppTokenMng;
