import React from "react";
import { FC, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import type { XYCoord, Identifier } from "dnd-core";
import { ItemTypes } from "./ItemTypes";

import Box from "@mui/material/Box";

const style = {
	border: "1px dashed gray",
	padding: "0.5rem 1rem",
	marginBottom: ".5rem",
	backgroundColor: "white",
	cursor: "move",
};

export interface DnDBoxProps {
	id: number;
	index: number;
	typeBox: string;
	accept: string;
	children?: React.ReactNode;
	moveDnDBox: (dragIndex: number, hoverIndex: number) => void;
	dragDnDBox: (dragId: number, hoverValue: any) => void;
	disabled?: boolean;
}

interface DragItem {
	index: number;
	id: number;
	type: string;
}
interface DropResult {
	value: any;
}

const DnDBox: FC<DnDBoxProps> = ({
	id,
	index,
	typeBox,
	accept,
	moveDnDBox,
	dragDnDBox,
	disabled = false,
	...props
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const [{ handlerId }, drop] = useDrop<
		DragItem,
		void,
		{ handlerId: Identifier | null }
	>({
		accept,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item: DragItem, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			const dragId = item.id;
			const hoverId = id;

			// Don't replace items with themselves
			if (dragId === hoverId) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			// Time to actually perform the action
			moveDnDBox(dragId, hoverId);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		},
	});

	const [{ isDragging }, drag] = useDrag({
		type: typeBox,
		item: () => {
			return { id, index };
		},
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging(),
		}),
		end: (item, monitor) => {
			const dropResult = monitor.getDropResult<DropResult>();
			if (item) {
				dragDnDBox(item.id, dropResult?.value);
			}
		},
	});

	const opacity = isDragging ? 0.1 : 1;
	if (!disabled) {
		drag(drop(ref));
	}
	return (
		<Box
			key={disabled ? "undroppable_" + id : "droppable_" + id}
			ref={ref}
			sx={{ opacity }}
			data-handler-id={handlerId}
			children={props.children}
		/>
	);
};

export default DnDBox;
