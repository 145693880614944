import React from "react";

import { NavLink } from "react-router-dom";

import ButtonGroup from "@mui/material/ButtonGroup";
import ProfileMenu from "./ProfileMenu";
import { userIsLogged } from "../UserIsLogged";

import IconButton from "@mui/material/IconButton";
import LoginIcon from "@mui/icons-material/Login";
import HomeIcon from "@mui/icons-material/Home";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";

const MenuItemStyle = {
	display: { sm: "inline-flex", xs: "flex" },
	padding: 0,
	ml: 1,
};

export enum Orientation {
	Horizontal = "horizontal",
	Vertical = "vertical",
}

interface HeaderMenuProps {
	// TODO gestire il tipo utente
	utente: any;
	resetUtente: () => void;
	orientation: Orientation;
	activeStyle: React.CSSProperties;
}

const HeaderMenu = ({
	utente,
	resetUtente,
	orientation,
	activeStyle,
}: HeaderMenuProps) => {
	return (
		<>
			<Button
				aria-controls="simple-menu"
				component={NavLink}
				size="small"
				// activeStyle={activeStyle}
				// exact
				color="primary"
				to="/"
				sx={{ ml: 1 }}
			>
				Homepage
				<IconButton aria-label="show 4 new mails" size="large" color="primary">
					<Badge badgeContent={0}>
						<HomeIcon />
					</Badge>
				</IconButton>
			</Button>

			{userIsLogged(utente) ? (
				<Box sx={MenuItemStyle}>
					<ProfileMenu
						utente={utente}
						resetUtente={resetUtente}
						activeStyle={activeStyle}
					/>
				</Box>
			) : (
				<Button
					aria-controls="simple-menu"
					component={NavLink}
					size="small"
					// activeStyle={activeStyle}
					// exact
					color="inherit"
					to="/signin/"
				>
					accedi
					<IconButton
						aria-label="account of current user"
						aria-controls="primary-search-account-menu"
						aria-haspopup="true"
						color="inherit"
						size="large"
					>
						<LoginIcon />
					</IconButton>
				</Button>
			)}
		</>
	);
};

export default HeaderMenu;
