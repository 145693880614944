import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";

import {
	fetchLottiCommittente,
	LottiCommittenteProduttore,
} from "../ospitiSlice";
import Pagination from "@mui/material/Pagination";
import { Committente } from "../../anagrafiche/anagraficheSlice";

import LottiCommittenteCard from "./LottiCommittenteCard";

import moment from "moment";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import PaperMui from "@mui/material/Paper";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../common/errorsDeclarations";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled, Box } from "@mui/system";

const Paper = styled(PaperMui)(({ theme }) => ({
	color: theme.palette.primary.contrastText,
	backgroundColor: "#b0b0b0",
	padding: theme.spacing(1),
	borderRadius: theme.shape.borderRadius,
}));

interface LottiCommittenteBoxProps {
	committenteCorrente: Committente;
	numeroRecord?: number;
}

const LottiCommittenteBox = ({
	committenteCorrente,
	numeroRecord,
}: LottiCommittenteBoxProps) => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	const lotti: LottiCommittenteProduttore = useSelector<
		RootState,
		LottiCommittenteProduttore
	>((state) => state.ospitiState.lottiCommittenteProduttore);

	const [page, setPage] = React.useState<number>(1);

	const handlerPager = (event: React.ChangeEvent<unknown>, page: number) => {
		setPage(page);
	};

	useEffect(() => {
		setPage(1);
	}, [committenteCorrente]);

	useEffect(() => {
		committenteCorrente?.id &&
			dispatch(
				fetchLottiCommittente({
					committente_id: committenteCorrente?.id,
					numeroRecord: numeroRecord,
					page: page,
				})
			);
	}, [committenteCorrente, page, numeroRecord]);

	return (
		<Box sx={{ m: 1, bgcolor: "background.paper" }}>
			{lotti.errorsStack.status == ErrorStatusTypes.PENDING ? (
				<Typography align="center" sx={{ p: "15vh" }}>
					<CircularProgress color="inherit" />
				</Typography>
			) : (
				<List>
					<ListItem
						key={"Box_lottiCommittente_page_0"}
						disablePadding
						sx={{ backgroundColor: "#aaa" }}
					>
						<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }}>
							<Grid item xs={12} sm={12} md={12} lg={7} container>
								<Grid item xs={5} sm={5} md={5}></Grid>
								<Grid item xs={4} sm={4} md={3}>
									<Typography variant="h6" component="div" gutterBottom>
										Produttore
									</Typography>
								</Grid>
								<Grid item xs={3} sm={3} md={3}>
									<Typography variant="h6" component="div" gutterBottom>
										Allevamento
									</Typography>
								</Grid>
							</Grid>

							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={5}
								container
								justifyContent="flex-end"
							>
								<Grid item xs={3} sm={3} md={3} justifyContent="flex-end">
									<Typography
										variant="h6"
										component="div"
										gutterBottom
										align="right"
									>
										Peso medio dich.
									</Typography>
								</Grid>
								<Grid item xs={3} sm={3} md={3} justifyContent="flex-end">
									<Typography
										variant="h6"
										component="div"
										gutterBottom
										align="right"
									>
										N. capi ritirati
									</Typography>
								</Grid>
								<Grid item xs={3} sm={3} md={3} justifyContent="flex-end">
									<Typography
										variant="h6"
										component="div"
										gutterBottom
										align="right"
									>
										Peso Macellato
									</Typography>
								</Grid>
								<Grid item xs={3} sm={3} md={3} justifyContent="flex-end">
									<Typography
										variant="h6"
										component="div"
										gutterBottom
										align="right"
										sx={{ mr: 1 }}
									>
										N. capi lavorati
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</ListItem>

					{lotti.results.map((lotto) => (
						<LottiCommittenteCard
							key={"consuntivi_lotto_" + lotto.id}
							lotto={lotto}
						/>
					))}
					{!numeroRecord && (
						<ListItem sx={{ alignContent: "right" }}>
							<Pagination
								count={lotti.num_pages}
								onChange={handlerPager}
								page={page}
							/>
						</ListItem>
					)}
				</List>
			)}
		</Box>
	);
};

export default LottiCommittenteBox;
