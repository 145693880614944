import React from "react";
import moment from "moment";

import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";

import { Resa } from "../../../reportistica/rese/reseSlice";
import ResaIntestazionePDF from "./ResaIntestazionePDF";

import { getColore } from "../../../pianificazione/utility/lottoUtility";
import { style } from "@mui/system";
const logoScarso = require("../../../../../../static/images/logo/logo-Scarso_nuovo_solo.png");

interface ResaPdfProps {
	resa: Resa;
}

const ResaPdf = ({ resa }: ResaPdfProps) => (
	<>
		<View
			key={"scheda_macellazione_intestazione"}
			style={{
				paddingLeft: 23,
				paddingRight: 23,
				flexDirection: "row",
				justifyContent: "space-between",
			}}
		>
			<Text style={{ fontSize: 18, paddingRight: 50, color: "#1c325c" }}>
				{resa.committente_desc} - Resa macellazione{" "}
				<Text>{moment(resa.data_lavorazione).format("ddd DD MMM YYYY")}</Text>
				<Text>
					{resa.ordine_cliente && " ord. consegna " + resa.ordine_cliente}
				</Text>
			</Text>
			<Text
				style={{
					fontWeight: "bold",
					marginBottom: 1,
					paddingBottom: 0,
					fontSize: 20,
				}}
			>
				<Image
					src={logoScarso}
					style={{
						width: 30,
						height: 30,
						// margin: 0,
						padding: 0,
						paddingLeft: "10px",
						marginLeft: "3px",
					}}
				/>
			</Text>
		</View>
		<ResaIntestazionePDF resa={resa} />
		{/* <View
			style={{
				flexDirection: "row",
				justifyContent: "center",
			}}
		></View> */}
	</>
);

export default ResaPdf;
