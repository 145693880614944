import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";

import { Lotto, saveLotto } from "../../pianificazione/pianificazioneSlice";
import { Allevamento } from "../../anagrafiche/anagraficheSlice";

import { useForm, SubmitHandler, Controller } from "react-hook-form";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import Alert from "../../../common/Alert";

interface SchedeCaricoAllevamentoSelectProps {
	lotto: Lotto;
	annulla: () => void;
}

const SchedeCaricoAllevamentoSelect = ({
	lotto,
	annulla,
}: SchedeCaricoAllevamentoSelectProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
		setValue,
		watch,
		getValues,
		control,
		setError,
		reset,
	} = useForm<Lotto>();

	// const committenti = useSelector<RootState, Committente[]>(
	//   (state) => state.anagrafiche.committenti.results
	// );

	const allevamenti = useSelector<RootState, Allevamento[]>(
		(state) => state.anagrafiche.allevamenti.results
	);

	const dispatch = useAppDispatch();

	const handlerSaveLotto = (lotto: Lotto) => {
		dispatch(saveLotto(lotto));
	};

	const listaCampi = Object.keys(lotto) as Array<keyof Lotto>;

	const [allevamentoCorrente, setInitAllevamentoCorrente] = React.useState<
		Allevamento | undefined
	>(undefined);

	useEffect(() => {
		setInitAllevamentoCorrente(
			allevamenti.find(
				(allevamentoScelto) => allevamentoScelto.id == getValues("allevamento")
			)
		);
	}, [watch("allevamento")]);
	// #############################################

	useEffect(
		() => {
			listaCampi.map((field) => {
				setValue(field, lotto[field]);
			});
		},
		[
			// listaCampi.forEach((field) => {
			// 	lotto[field];
			// }),
			// lotto,
		]
	);

	useEffect(() => {
		listaCampi.forEach((field) => {
			lotto?.errorsStack?.fieldsErrors &&
				lotto.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: lotto.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [lotto?.errorsStack?.fieldsErrors]);

	// useEffect(() => {
	// 	handlerSaveLotto(lotto);
	// }, [watch("allevamento")]);

	const onSubmit: SubmitHandler<Lotto> = (lotto) => {
		handlerSaveLotto(lotto);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{lotto?.errorsStack?.fieldsErrors?.non_field_errors && (
				<Alert severity="error">
					{lotto?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
				</Alert>
			)}
			<Grid container spacing={2} justifyContent="flex-start">
				<Grid item xs={12} md={6} lg={4}>
					<Autocomplete
						key={allevamentoCorrente?.codice_asl}
						// disabled={getValues("confermato")}
						value={allevamentoCorrente}
						isOptionEqualToValue={(option, value) => option.id === value.id}
						onChange={(event: any, newValue: any) => {
							setValue("allevamento", newValue ? newValue.id : null, {
								shouldDirty: true,
							});
							handlerSaveLotto({
								...lotto,
								allevamento: getValues("allevamento"),
							});
						}}
						id="allevamento"
						options={allevamenti}
						getOptionLabel={(option) => option.codice_asl + " " + option.nome}
						renderOption={(props, option) => (
							<Box component="li" {...props}>
								{option.codice_asl + " " + option.nome}
							</Box>
						)}
						renderInput={(params: any) => (
							<TextField
								variant="outlined"
								{...params}
								label="Allevamento"
								error={!!errors?.allevamento}
								helperText={errors?.allevamento?.message}
							/>
						)}
					/>
				</Grid>

				<Grid item xs={12} md={5} lg={3}>
					<Stack
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						spacing={2}
					>
						<Button variant="outlined" onClick={annulla} sx={{ widht: "100%" }}>
							Annulla
						</Button>
						<Button
							disabled={!isDirty}
							variant="outlined"
							type="submit"
							sx={{ widht: "100%" }}
						>
							Salva
						</Button>
					</Stack>
				</Grid>
			</Grid>
		</form>
	);
};

export default SchedeCaricoAllevamentoSelect;
