import React, { useEffect, useState } from "react";

import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { Lotto } from "../../pianificazione/pianificazioneSlice";
import LavorazioniLottoManage from "./LavorazioniLottoManage";
import { getColore } from "../../pianificazione/utility/lottoUtility";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface LavorazioniLottoCardProps {
	lotto: Lotto;
	cosuntivazione?: boolean;
}

const LavorazioniLottoCard = ({
	lotto,
	cosuntivazione,
}: LavorazioniLottoCardProps) => {
	return (
		<>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={lotto?.errorsStack?.status == ErrorStatusTypes.PENDING}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<LavorazioniLottoManage lotto={lotto} cosuntivazione={cosuntivazione} />
		</>
	);
};

export default LavorazioniLottoCard;
