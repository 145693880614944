import { handleResponse, handleError, headerset } from "../apiUtils";
import moment from "moment";
import { ConsuntivoVivo } from "../../components/main/schedeCarico/consuntiviVivo/consuntiviVivoSlice";

const baseUrl = (consuntivi_vivo_id?: number | null) => {
	if (consuntivi_vivo_id) {
		return (
			process.env.API_URL +
			"/api/consuntivi_vivo/consuntivi_vivo/" +
			consuntivi_vivo_id +
			"/"
		);
	} else {
		return process.env.API_URL + "/api/consuntivi_vivo/consuntivi_vivo/";
	}
};

export function fetchConsuntiviVivo(data_da?: Date, data_a?: Date) {
	let url = baseUrl();
	// moment(data_da).format("YYYY-MM-DD");
	if (data_da && data_a) {
		url += `?data_da=${moment(data_da).format("YYYY-MM-DD")}&data_a=${moment(
			data_a
		).format("YYYY-MM-DD")}`;
	} else if (data_da) {
		url += `?data_da=${moment(data_da).format("YYYY-MM-DD")}`;
	} else if (data_a) {
		url += `?data_a=${moment(data_a).format("YYYY-MM-DD")}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getConsuntivoVivo(consuntivoVivoId: number) {
	let url = baseUrl(consuntivoVivoId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveConsuntivoVivo(consuntivoVivoToSave: ConsuntivoVivo) {
	const consuntivoVivo = {
		...consuntivoVivoToSave,
		data_ora_partenza_sede: moment(
			consuntivoVivoToSave.data_ora_partenza_sede
		).toISOString(),
		data_ora_inizio_carico: moment(
			consuntivoVivoToSave.data_ora_inizio_carico
		).toISOString(),
		data_ora_fine_carico: moment(
			consuntivoVivoToSave.data_ora_fine_carico
		).toISOString(),
		data_ora_fine_turno: moment(
			consuntivoVivoToSave.data_ora_fine_turno
		).toISOString(),
	};

	return fetch(baseUrl(consuntivoVivoToSave.id), {
		method: consuntivoVivoToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...consuntivoVivo,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

// export function deleteSchedaCarico(schedaCaricoToDelete: SchedaCarico) {
//   return fetch(baseUrl(schedaCaricoToDelete.id), {
//     method: "DELETE",
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: headerset().Authorization,
//     },
//   })
//     .then(handleResponse)
//     .catch(handleError);
// }

// export function generaSchedeCarico(lotto_id: number) {
//   let url =
//     process.env.API_URL +
//     "/api/schede_carico/genera_schede_carico/" +
//     lotto_id +
//     "/";
//   return fetch(url, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: headerset().Authorization,
//     },
//     body: JSON.stringify({}),
//   })
//     .then(handleResponse)
//     .catch(handleError);
// }

// export function mandaM4(lotto_id: number, contatto_id: number) {
//   let url =
//     process.env.API_URL +
//     "/api/schede_carico/schede_carico/" +
//     lotto_id +
//     "/" +
//     contatto_id +
//     "/manda_m4/";
//   return fetch(url, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//       Authorization: headerset().Authorization,
//     },
//     body: JSON.stringify({}),
//   })
//     .then(handleResponse)
//     .catch(handleError);
// }
