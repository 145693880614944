import * as React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import moment from "moment";

type CustomPickerDayProps = PickersDayProps<Date> & {
	dayIsBetween: boolean;
	isFirstDay: boolean;
	isLastDay: boolean;
};

const CustomPickersDay = styled(PickersDay, {
	shouldForwardProp: (prop) =>
		prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
	...(dayIsBetween && {
		borderRadius: 0,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		"&:hover, &:focus": {
			backgroundColor: theme.palette.primary.dark,
		},
	}),
	...(isFirstDay && {
		borderTopLeftRadius: "50%",
		borderBottomLeftRadius: "50%",
	}),
	...(isLastDay && {
		borderTopRightRadius: "50%",
		borderBottomRightRadius: "50%",
	}),
})) as React.ComponentType<CustomPickerDayProps>;

interface weekPickerProps {
	data: Date | undefined;
	setData: (newValue: Date) => void;
}

export default function WeekPicker({ data, setData }: weekPickerProps) {
	const [value, setValue] = React.useState<Date | undefined>(data);

	const changeData = (newValue: Date) => {
		setValue(newValue);
		setData(newValue);
	};

	const renderWeekPickerDay = (
		date: Date,
		selectedDates: Array<Date | null>,
		pickersDayProps: PickersDayProps<Date>
	) => {
		if (!data) {
			return <PickersDay {...pickersDayProps} />;
		}

		const start = moment(data).startOf("isoWeek");
		const end = moment(data).endOf("isoWeek");

		const dayIsBetween = moment(date).isBetween(start, end, undefined, "[)");
		const isFirstDay = moment(date).isSame(start);
		const isLastDay = moment(date).isSame(end);

		return (
			<CustomPickersDay
				{...pickersDayProps}
				disableMargin
				dayIsBetween={dayIsBetween}
				isFirstDay={isFirstDay}
				isLastDay={isLastDay}
			/>
		);
	};

	return (
		<>
			<StaticDatePicker
				displayStaticWrapperAs="desktop"
				label="Week picker"
				value={data}
				onChange={(newValue) => {
					newValue && changeData(new Date(newValue));
				}}
				renderDay={renderWeekPickerDay}
				renderInput={(params) => <TextField {...params} />}
				inputFormat="'Week of' MMM d"
			/>
		</>
	);
}
