import { handleResponse, handleError, headerset } from "../apiUtils";
import moment from "moment";
import { EventoManutenzione } from "../../components/main/manutenzione_mezzi/manutenzioneMezziSlice";
import EventiManutenzionePage from "components/main/manutenzione_mezzi/EventiManutenzionePage";

const baseUrl = (evento_manutenzione_id?: number | null) => {
	if (evento_manutenzione_id) {
		return (
			process.env.API_URL +
			"/api/manutenzione_mezzi/eventi_manutenzione/" +
			evento_manutenzione_id +
			"/"
		);
	} else {
		return process.env.API_URL + "/api/manutenzione_mezzi/eventi_manutenzione/";
	}
};

export function fetchEventiManutenzione(
	stato?: string,
	camion_id?: number,
	data_da?: Date,
	data_a?: Date,
	numeroRecord?: number,
	page?: number,
	search?: string
) {
	let url = baseUrl();
	if (
		stato != undefined ||
		camion_id != undefined ||
		camion_id != undefined ||
		data_da != undefined ||
		data_a != undefined ||
		numeroRecord != undefined ||
		search != undefined ||
		page != undefined
	) {
		url += "?";
	}

	if (stato != undefined) {
		url += `stato=${stato}&`;
	}
	if (camion_id != undefined) {
		url += `azienda_id=${camion_id}&`;
	}
	if (data_da != undefined) {
		url += `data_da=${moment(data_da).format("YYYY-MM-DD")}&data_a=${moment(
			data_da
		).format("YYYY-MM-DD")}&`;
	}
	if (data_a != undefined) {
		url += `data_a=${moment(data_a).format("YYYY-MM-DD")}&data_a=${moment(
			data_a
		).format("YYYY-MM-DD")}&`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getEventoManutenzione(eventoManutenzioneId: number) {
	let url = baseUrl(eventoManutenzioneId);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveEventoManutenzione(
	eventoManutenzioneToSave: EventoManutenzione
) {
	const eventoManutenzione = {
		...eventoManutenzioneToSave,
		data_inizio_pianificata: eventoManutenzioneToSave.data_inizio_pianificata
			? moment(eventoManutenzioneToSave.data_inizio_pianificata).format(
					"YYYY-MM-DD"
			  )
			: null,
		data_fine_pianificata: eventoManutenzioneToSave.data_fine_pianificata
			? moment(eventoManutenzioneToSave.data_fine_pianificata).format(
					"YYYY-MM-DD"
			  )
			: null,
		data_inizio_manutenzione: eventoManutenzioneToSave.data_inizio_manutenzione
			? moment(eventoManutenzioneToSave.data_inizio_manutenzione).format(
					"YYYY-MM-DD"
			  )
			: null,
		data_fine_manutenzione: eventoManutenzioneToSave.data_fine_manutenzione
			? moment(eventoManutenzioneToSave.data_fine_manutenzione).format(
					"YYYY-MM-DD"
			  )
			: null,
	};

	return fetch(baseUrl(eventoManutenzioneToSave.id), {
		method: eventoManutenzioneToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...eventoManutenzione,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteEventoManutenzione(
	eventoManutenzioneToDelete: EventoManutenzione
) {
	return fetch(baseUrl(eventoManutenzioneToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
