import React, { useEffect } from "react";

import { ReportCamionsIolepec } from "../reportAutistiCamionSlice";

import { Box } from "@mui/system";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

interface IolepecCamionMeseTableProps {
	reportCamionsIolepec: ReportCamionsIolepec[];
}

const calcoloCostoTotaleCamions = (costiCamions: ReportCamionsIolepec[]) => {
	const costoTotaleCamions = costiCamions?.reduce(
		(previousValue, currentValue: ReportCamionsIolepec) => {
			return previousValue + currentValue.costo_totale;
		},
		0
	);
	return costoTotaleCamions;
};

const IolepecCamionMeseTable = ({
	reportCamionsIolepec,
}: IolepecCamionMeseTableProps) => {
	const [costoTotaleCamions, setCostoTotaleCamions] = React.useState<number>(0);

	useEffect(() => {
		setCostoTotaleCamions(calcoloCostoTotaleCamions(reportCamionsIolepec));
	}, [reportCamionsIolepec]);

	return (
		<React.Fragment>
			<TableContainer component={Paper} sx={{ width: "100%" }}>
				<Box>
					<Table size="small" aria-label="report mensile autisti">
						<TableHead>
							<TableRow>
								<TableCell
									align="center"
									colSpan={5}
									sx={{
										paddingTop: 3,
										paddingBottom: 3,
										fontWeight: "bold",
										fontSize: 18,
										textTransform: "uppercase",
									}}
								>
									Costo totale camion mese: €{" "}
									{Intl.NumberFormat("it-IT", {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}).format(costoTotaleCamions || 0)}
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell sx={{ fontWeight: "bold" }}>Nome camion</TableCell>
								<TableCell sx={{ fontWeight: "bold" }}>Targa</TableCell>
								<TableCell sx={{ fontWeight: "bold" }} align="right">
									Km percorsi
								</TableCell>
								<TableCell sx={{ fontWeight: "bold" }} align="right">
									Costo per km
								</TableCell>
								<TableCell sx={{ fontWeight: "bold" }} align="right">
									Costo totale camion
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{reportCamionsIolepec.map((camion) => (
								<TableRow key={camion.id}>
									<TableCell>{camion.nome}</TableCell>
									<TableCell>{camion.targa}</TableCell>
									<TableCell align="right">
										{Intl.NumberFormat("it-IT").format(camion.km_totali)}
									</TableCell>
									<TableCell align="right">
										€{" "}
										{Intl.NumberFormat("it-IT", {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										}).format(camion.costo_km || 0)}
									</TableCell>
									<TableCell
										align="right"
										sx={{
											color: camion.costo_totale == 0 ? "#FF0000" : "inherit",
										}}
									>
										{"€ "}
										{Intl.NumberFormat("it-IT", {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										}).format(camion.costo_totale || 0)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Box>
			</TableContainer>
		</React.Fragment>
	);
};

export default IolepecCamionMeseTable;
