import React from "react";
import { Link } from "react-router-dom";

import { styled, experimental_sx as sx } from "@mui/material/styles";
import notPermit from "../../../static/images/common/403.png";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// const Box = styled(MuiBox)(({ theme }) =>
// 	sx({
// 		display: "flex",
// 		flexWrap: "wrap",
// 		"& > :not(style)": {
// 			// m: 1,
// 			marginTop: 5,
// 			maxWidth: "90%",
// 			height: "auto",
// 		},
// 		justifyContent: "center",
// 		border: 0,
// 		elevation: 0,
// 	})
// );

const NotPermittedPage = () => {
	return (
		<>
			<Box>
				<Typography variant="h4">403 - Accesso negato</Typography>
			</Box>
			<Box>
				<img src={notPermit} alt="403 - Accesso negato" />
			</Box>
			<Box>
				<Button
					sx={{
						backgroundColor: "#f8d300",
						"&:hover": {
							backgroundColor: "#a1887f",
						},
						color: "#222222",
						elevation: 2,
						align: "center",
					}}
					variant="contained"
					component={Link}
					to="/"
				>
					{"Back to Homepage"}
				</Button>
			</Box>
		</>
	);
};

export default NotPermittedPage;
