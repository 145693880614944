import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";

import AllegatiList from "./AllegatiList";
import { Lotto } from "../pianificazione/pianificazioneSlice";
import {
	DocumentoDiTrasporto,
	getDocumentoDiTrasporto,
	resetDocumentiDiTrasporto,
} from "../bolle_fatture/bolleFattureSlice";
import {
	Allegato,
	fetchAllegati,
	fetchTipiDocumento,
	deleteAllegato,
} from "./allegatiSlice";

import InviaAllegatiBottoni from "./InviaAllegatiBottoni";
import Box from "@mui/material/Box";

interface AllegatiLottoPageProps {
	lotto?: Lotto;
}

const listaIdSchedeCarico = (lotto: Lotto): number[] => {
	return lotto.schede_carico.map((a) => a.id || 0);
};

const AllegatiLottoPage = ({ lotto }: AllegatiLottoPageProps) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(fetchTipiDocumento());
		lotto?.schede_carico &&
			lotto?.schede_carico.map(
				(schedaCarico) =>
					schedaCarico.id &&
					dispatch(fetchAllegati({ schedaCarico_id: schedaCarico.id }))
			);
		dispatch(resetDocumentiDiTrasporto());
		lotto?.documenti_trasporto &&
			lotto?.documenti_trasporto.map((ddt) =>
				dispatch(getDocumentoDiTrasporto(ddt))
			);
	}, []);

	const allegati: Allegato[] | undefined = useSelector<
		RootState,
		Allegato[] | undefined
	>((state) =>
		state.allegati.allegati.results.filter(
			(allegato) =>
				typeof allegato.scheda_carico === "number" &&
				lotto &&
				listaIdSchedeCarico(lotto).includes(allegato.scheda_carico)
		)
	);

	const documentiDiTrasporto: DocumentoDiTrasporto[] | undefined = useSelector<
		RootState,
		DocumentoDiTrasporto[]
	>((state) =>
		state.bolleFattureState.documentiDiTrasporto.results.filter(
			(ddt) => ddt.destinatario == lotto?.committente
		)
	);

	const handleDelete = (allegato: Allegato) => {
		dispatch(deleteAllegato(allegato));
	};

	return (
		<Box>
			<Box
				sx={{
					border: 1,
					borderRadius: 0.4,
					borderColor: "#094e8a",
					margin: 2,
					marginTop: 0,

					padding: 2,
					paddingTop: 0,
				}}
			>
				{allegati && (
					<AllegatiList
						lotto={lotto}
						allegati={allegati}
						documentiDiTrasporto={documentiDiTrasporto}
						handleDelete={handleDelete}
					/>
				)}
			</Box>
			<InviaAllegatiBottoni lotto={lotto} />
		</Box>
	);
};

export default AllegatiLottoPage;
