import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import {
	Camion,
	Autista,
	fetchCamions,
	fetchAutisti,
} from "../anagrafiche/anagraficheSlice";
import { Allegato } from "../allegati/allegatiSlice";
import {
	Lotto,
	getLotto,
	PianificazioneStrutturaState,
} from "../pianificazione/pianificazioneSlice";
import * as schedeCaricoApi from "../../../api/schedeCarico/schedeCaricoApi";
import moment from "moment";
import { toast } from "react-toastify";
// import { useDispatch } from "react-redux";

moment.locale("it");

export interface StatoSchedulazione {
	stato: number;
	descrizione: string;
	messaggio: string;
}
export interface SchedaCarico {
	id: number | null;
	numero_ordine_giorno: number | null;
	ordinamento_scheda_carico: number;
	numero_capi_pianificati?: number | null;
	lotto: number;
	viaggio_lungo: boolean;
	stato_schedulazione: StatoSchedulazione;
	numero_fori?: number | null;
	numero_capi_foro?: number | null;
	camion?: number | null;
	camion_pack?: Camion;
	rimorchio: boolean;
	autisti: number[];
	autisti_pack: Autista[];
	data_ora_carico_pianificata?: Date | string;
	note?: string;
	allegati: Allegato[];
	errorsStack?: ErrorsStack;
}
export interface SchedeCaricoState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: SchedaCarico[];

	ultimo_aggiornamento: string;
	errorsStack: ErrorsStack;
}

type LottoPack = Omit<
	Lotto,
	| "stato_lotto"
	| "committente"
	// | "committente_desc"
	| "produttore"
	// | "produttore_pack"
	| "m4_inviato"
	| "m4_inviati"
	| "in_dubbio"
	| "note_dubbio"
	| "ordine_cliente"
	| "calcolo_codice_tracciabilita_automatico"
	// | "tipo_capo"
	| "peso_medio"
	// | "colore"
	| "peso_medio"
	| "numero_capi"
	| "capi_schedulati"
	| "capi_ritirati"
	| "numero_capi_foro"
	| "numero_fori"
	| "fori_schedulati"
	| "peso_lotto_partenza"
	| "peso_lotto_arrivo"
	| "peso_medio_lotto_arrivo"
	| "allevamento"
	// | "allevamento_pack"
	| "resa_confermata"
	| "note"
	| "schede_carico"
	| "consuntivi_vivo"
	| "lavorazioni_lotto"
	| "documenti_trasporto"
	| "aggiustamento_resa_bst"
	| "num_tz_pianificati_lotto"
	| "num_tz_eviscerati_pianificati_lotto"
	| "num_busti_pianificati_lotto"
	| "scheda_macello"
>;

export interface PianiViaggio {
	id: number | null;
	numero_ordine_giorno: number | null;
	ordinamento_scheda_carico: number;
	numero_capi_pianificati?: number | null;
	lotto_pack: LottoPack;
	numero_fori?: number | null;
	numero_capi_foro?: number | null;
	camion?: number | null;
	camion_pack?: Camion;
	rimorchio: boolean;
	autisti: number[];
	autisti_pack: Autista[];
	data_ora_carico_pianificata?: Date | string;
	note?: string;
	errorsStack?: ErrorsStack;
}
export interface PianiViaggioState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: PianiViaggio[];
	errorsStack: ErrorsStack;
}

// export interface LottoCorrente extends Lotto {
//   errorsStack: ErrorsStack;
// }

export interface SchedaCaricoStrutturaState {
	schedeCarico: SchedeCaricoState;
	pianiViaggio: PianiViaggioState;
}

const initialState: SchedaCaricoStrutturaState = {
	schedeCarico: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		ultimo_aggiornamento: "",
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	pianiViaggio: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchschedeCarico = createAsyncThunk(
	"schedaCarico/fetchschedeCarico",
	async (dataRange?: {
		data_da?: Date;
		data_a?: Date;
		ultimoAggiornamento?: string;
	}) => {
		return await schedeCaricoApi.fetchschedeCarico(
			dataRange?.data_da,
			dataRange?.data_a,
			dataRange?.ultimoAggiornamento
		);
	}
);

export const getSchedaCarico = createAsyncThunk(
	"schedaCarico/getSchedaCarico",
	async (schedaCaricoId: number) => {
		return await schedeCaricoApi.getSchedaCarico(schedaCaricoId);
	}
);

export const saveSchedaCarico = createAsyncThunk(
	"schedaCarico/saveSchedaCarico",
	async (schedaCaricoToSave: SchedaCarico, thunkApi) => {
		const pianificazioneState = thunkApi.getState() as {
			pianificazione: PianificazioneStrutturaState;
		};
		const lotto = pianificazioneState.pianificazione.lotti.results.find(
			(lotto) => lotto.id == schedaCaricoToSave.lotto
		);

		return await schedeCaricoApi
			.saveSchedaCarico(schedaCaricoToSave)
			.then((response) => {
				thunkApi.dispatch(fetchCamions(lotto?.data_lavorazione));
				thunkApi.dispatch(fetchAutisti(lotto?.data_lavorazione));
				thunkApi.dispatch(getLotto(lotto?.id || 0));
				return response;
			});
	}
);

export const deleteSchedaCarico = createAsyncThunk(
	"schedaCarico/deleteSchedaCarico",
	async (schedaCaricoToDelete: SchedaCarico, thunkApi) => {
		return await schedeCaricoApi
			.deleteSchedaCarico(schedaCaricoToDelete)
			.then((response) => {
				thunkApi.dispatch(getLotto(schedaCaricoToDelete.lotto));
				return response;
			});
	}
);

export const generaSchedeCarico = createAsyncThunk(
	"schedaCarico/generaSchedeCarico",
	async (parametri: { lotto: Lotto; viaggioLungo: boolean }, thunkApi) => {
		return await schedeCaricoApi
			.generaSchedeCarico(parametri.lotto.id || 0, parametri.viaggioLungo)
			.then((response) => {
				thunkApi.dispatch(
					fetchschedeCarico({
						data_da: new Date(parametri.lotto.data_lavorazione),
						data_a: new Date(parametri.lotto.data_lavorazione),
					})
				);
				thunkApi.dispatch(getLotto(parametri.lotto.id || 0));
				return response;
			});
	}
);

export const mandaM4 = createAsyncThunk(
	"schedaCarico/mandaM4",
	async (parametriM4: { lotto: Lotto; contatto_id: number }, thunkApi) => {
		return await schedeCaricoApi
			.mandaM4(parametriM4.lotto.id || 0, parametriM4.contatto_id)
			.then((response) => {
				thunkApi.dispatch(getLotto(parametriM4.lotto?.id || 0));
				return response;
			});
	}
);

export const mandaWhatsAppAutisti = createAsyncThunk(
	"schedaCarico/mandaWhatsAppAutisti",
	async (schedaCarico_id: number, thunkApi) => {
		return await schedeCaricoApi.mandaWhatsAppAutisti(schedaCarico_id);
	}
);

export const mandaSmsAppAutisti = createAsyncThunk(
	"schedaCarico/mandaSmsAppAutisti",
	async (schedaCarico_id: number, thunkApi) => {
		return await schedeCaricoApi.mandaSmsAppAutisti(schedaCarico_id);
	}
);

export const fetchPianiViaggio = createAsyncThunk(
	"schedaCarico/fetchPianiViaggio",
	async (dataRange?: { data_da?: Date; data_a?: Date }) => {
		return await schedeCaricoApi.fetchPianiViaggio(
			dataRange?.data_da,
			dataRange?.data_a
		);
	}
);

export const assegnaCamion = createAsyncThunk(
	"schedaCarico/assegnaCamion",
	async (
		parametri: {
			camion_id: number;
			schedaCarico_id: number;
			lotto_id: number;
			data_lavorazione: Date | string;
		},
		thunkApi
	) => {
		return await schedeCaricoApi
			.assegnaCamion(parametri.camion_id, parametri.schedaCarico_id)
			.then((response) => {
				thunkApi.dispatch(getLotto(parametri.lotto_id));
				thunkApi.dispatch(fetchCamions(parametri.data_lavorazione));

				return response;
			});
	}
);
export const assegnaAutista = createAsyncThunk(
	"schedaCarico/assegnaAutista",
	async (
		parametri: {
			autista_id: number;
			schedaCarico_id: number;
			lotto_id: number;
			data_lavorazione: Date | string;
		},
		thunkApi
	) => {
		return await schedeCaricoApi
			.assegnaAutista(parametri.autista_id, parametri.schedaCarico_id)
			.then((response) => {
				thunkApi.dispatch(getLotto(parametri.lotto_id));
				thunkApi.dispatch(fetchAutisti(parametri.data_lavorazione));

				return response;
			});
	}
);

export const schedeCaricoSlice = createSlice({
	name: "schedeCaricoState",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch Schede Carico
		builder.addCase(fetchschedeCarico.pending, (state, action) => {
			if (action.meta.arg?.ultimoAggiornamento == undefined) {
				state.schedeCarico.errorsStack.status = ErrorStatusTypes.PENDING;
			}
		});
		builder.addCase(fetchschedeCarico.fulfilled, (state, action) => {
			// state.schedeCarico.results = [
			// 	...action.payload.results,
			// 	...state.schedeCarico.results.filter((schedaCarico) => {
			// 		if (
			// 			action.payload.results.find(
			// 				(schedaCaricoNew: SchedaCarico) =>
			// 					schedaCaricoNew.id == schedaCarico.id
			// 			)
			// 		) {
			// 			return false;
			// 		} else {
			// 			return true;
			// 		}
			// 	}),
			// ];

			state.schedeCarico.results = action.payload.results;

			state.schedeCarico.ultimo_aggiornamento =
				action.payload.ultimo_aggiornamento;

			state.schedeCarico.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchschedeCarico.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.schedeCarico.errorsStack = parseErrorMessage(action.error);
		});

		// get Scheda Carico
		builder.addCase(getSchedaCarico.pending, (state, action) => {
			state.schedeCarico.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(getSchedaCarico.fulfilled, (state, action) => {
			state.schedeCarico.results = state.schedeCarico.results.map(
				(schedaCarico) => {
					if (schedaCarico.id == action.payload.id) {
						return action.payload;
					} else {
						return schedaCarico;
					}
				}
			);
			state.schedeCarico.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getSchedaCarico.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.schedeCarico.errorsStack = parseErrorMessage(action.error);
		});

		// save save SchedaCarico
		builder.addCase(saveSchedaCarico.pending, (state, action) => {
			state.schedeCarico.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(saveSchedaCarico.fulfilled, (state, action) => {
			state.schedeCarico.results = state.schedeCarico.results.map(
				(schedaCarico) => {
					if (schedaCarico.id == action.payload.id) {
						return action.payload;
					} else {
						return schedaCarico;
					}
				}
			);
			state.schedeCarico.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Scheda carico salvata.");
		});
		builder.addCase(saveSchedaCarico.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.schedeCarico.results = state.schedeCarico.results.map(
				(schedaCarico) => {
					if (schedaCarico.id == action.meta.arg.id) {
						return {
							...schedaCarico,
							errorsStack: {
								status: ErrorStatusTypes.ERROR,
								fieldsErrors: JSON.parse(action?.error?.message || ""),
							},
						};
					} else {
						return schedaCarico;
					}
				}
			);
			state.schedeCarico.errorsStack = parseErrorMessage(action.error);
		});
		// cancella SchedaCarico
		builder.addCase(deleteSchedaCarico.pending, (state, action) => {
			state.schedeCarico.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(deleteSchedaCarico.fulfilled, (state, action) => {
			state.schedeCarico.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			state.schedeCarico.results = state.schedeCarico.results.filter(
				(schedaCarico) => schedaCarico.id != action.meta.arg.id
			);
			toast.success(action.payload.message || "Scheda carico  xx cancellata.");
		});
		builder.addCase(deleteSchedaCarico.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.schedeCarico.errorsStack = parseErrorMessage(action.error);
		});

		// save save generaSchedeCarico
		builder.addCase(generaSchedeCarico.pending, (state, action) => {
			state.schedeCarico.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(generaSchedeCarico.fulfilled, (state, action) => {
			state.schedeCarico.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Schede carico Create.");
		});
		builder.addCase(generaSchedeCarico.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.schedeCarico.errorsStack = parseErrorMessage(action.error);
		});

		// mandaM4
		builder.addCase(mandaM4.pending, (state, action) => {
			state.schedeCarico.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(mandaM4.fulfilled, (state, action) => {
			state.schedeCarico.errorsStack = { status: ErrorStatusTypes.OK };
			toast.success("M4 inviato.");
		});
		builder.addCase(mandaM4.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.schedeCarico.errorsStack = parseErrorMessage(action.error);
		});

		// mandaWhatsAppAutisti
		builder.addCase(mandaWhatsAppAutisti.pending, (state, action) => {
			state.schedeCarico.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(mandaWhatsAppAutisti.fulfilled, (state, action) => {
			state.schedeCarico.errorsStack = { status: ErrorStatusTypes.OK };
			toast.success("Massaggio WhatApp Inviato.");
		});
		builder.addCase(mandaWhatsAppAutisti.rejected, (state, action) => {
			state.schedeCarico.errorsStack = parseErrorMessage(action.error);

			toast.error("Errore:" + action?.error?.message || "");
			// state.schedeCarico.errorsStack = {
			// 	status: ErrorStatusTypes.ERROR,
			// 	fieldsErrors: JSON.parse(action?.error?.message || ""),
			// };
		});

		// mandaSmsAppAutisti
		builder.addCase(mandaSmsAppAutisti.pending, (state, action) => {
			state.schedeCarico.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(mandaSmsAppAutisti.fulfilled, (state, action) => {
			state.schedeCarico.errorsStack = { status: ErrorStatusTypes.OK };
			toast.success("Massaggio SMS Inviato.");
		});
		builder.addCase(mandaSmsAppAutisti.rejected, (state, action) => {
			state.schedeCarico.errorsStack = parseErrorMessage(action.error);

			toast.error("Errore:" + action?.error?.message || "");
			// state.schedeCarico.errorsStack = {
			// 	status: ErrorStatusTypes.ERROR,
			// 	fieldsErrors: JSON.parse(action?.error?.message || ""),
			// };
		});

		// fetch Piani di viaggio
		builder.addCase(fetchPianiViaggio.pending, (state, action) => {
			state.pianiViaggio.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(fetchPianiViaggio.fulfilled, (state, action) => {
			state.pianiViaggio = action.payload;

			state.pianiViaggio.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchPianiViaggio.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.pianiViaggio.errorsStack = parseErrorMessage(action.error);
		});

		// put Assegna Camion
		builder.addCase(assegnaCamion.pending, (state, action) => {
			state.schedeCarico.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(assegnaCamion.fulfilled, (state, action) => {
			state.schedeCarico.results = state.schedeCarico.results.map(
				(schedaCarico) => {
					if (schedaCarico.id == action.payload.id) {
						return action.payload;
					} else {
						return schedaCarico;
					}
				}
			);
			state.schedeCarico.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(assegnaCamion.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.schedeCarico.errorsStack = parseErrorMessage(action.error);
		});

		// put Assegna Autista
		builder.addCase(assegnaAutista.pending, (state, action) => {
			state.schedeCarico.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(assegnaAutista.fulfilled, (state, action) => {
			state.schedeCarico.results = state.schedeCarico.results.map(
				(schedaCarico) => {
					if (schedaCarico.id == action.payload.id) {
						return action.payload;
					} else {
						return schedaCarico;
					}
				}
			);
			state.schedeCarico.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(assegnaAutista.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.schedeCarico.errorsStack = parseErrorMessage(action.error);
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = schedeCaricoSlice.actions;

export const schedeCaricoSliceReducer = schedeCaricoSlice.reducer;
