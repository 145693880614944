import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";

import {
	LottiState,
	fetchLotti,
	resetLotti,
} from "../../pianificazione/pianificazioneSlice";
import { SchedeCaricoState, fetchschedeCarico } from "../schedeCaricoSlice";

import {
	fetchCamions,
	fetchAutisti,
	Parametri,
} from "../../anagrafiche/anagraficheSlice";

import OrdiniCaricoPdf from "./OrdiniCaricoPdf";
import NavigazioneGiorniToolbar from "../../../common/dateUtils/NavigazioneGiorniToolbar";

import moment from "moment";
import { PDFViewer, Page, Document, StyleSheet } from "@react-pdf/renderer";

import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import DateToday from "../../../common/dateUtils/DateToday";
import { Box } from "@mui/system";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Routes_path } from "../../../routerApp";
import ProgrammaMacellazionePdf from "./ProgrammaMacellazionePdf";

import stampaConNome from "../../../common/pdf/stampaConNome";

interface OrdiniCaricoPageProps {}

const OrdiniCaricoPage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();
	const lotti: LottiState = useSelector<RootState, LottiState>(
		(state) => state.pianificazione.lotti
	);
	const schedeCarico: SchedeCaricoState = useSelector<
		RootState,
		SchedeCaricoState
	>((state) => state.schedeCaricoState.schedeCarico);

	const anagraficheErrorsStack: ErrorsStack = useSelector<
		RootState,
		ErrorsStack
	>((state) => state.anagrafiche.errorsStack);

	// const parametri: Parametri = useSelector<RootState, Parametri>(
	// 	(state) => state.anagrafiche.parametri
	// );

	type LocationState = {
		giornoSettimana: Date;
	};
	const location = useLocation();

	const { giornoSettimana } = location.state
		? (location.state as LocationState)
		: { giornoSettimana: DateToday() };

	const initData = giornoSettimana;
	const [data, setData] = React.useState<Date | undefined>(initData);

	// const parametri = useSelector<RootState, Parametri | undefined>((state) =>
	// 	state.anagrafiche.storicoParametri.results.find(
	// 		(parametri) => new Date(parametri.data_validta_da) < new Date(data || "")
	// 	)
	// );

	const parametri = useSelector<RootState, Parametri | undefined>((state) =>
		state.anagrafiche.storicoParametri.results.find(
			(p) =>
				new Date(p.data_validta_da) <=
					new Date(new Date(data || "").getTime() + 6 * 60 * 60 * 1000) &&
				new Date(new Date(data || "").getTime() - 6 * 60 * 60 * 1000) <=
					new Date(p.data_validta_a)
		)
	);
	const [refresh, setRefresh] = React.useState<boolean>(false);

	const nomeProgramma = (data: string | Date) => {
		return moment(data).format("YY_MM_DD") + "_programma_macellazione";
	};

	useEffect(() => {
		const data_da = data;
		const data_a = data;
		dispatch(resetLotti());
		dispatch(fetchLotti({ data_da, data_a }));
		dispatch(fetchschedeCarico({ data_da, data_a }));
		data && dispatch(fetchCamions(data));
		data && dispatch(fetchAutisti(data));
		setRefresh(false);
	}, [data, refresh]);

	return (
		<Box>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					lotti.errorsStack.status == ErrorStatusTypes.PENDING ||
					schedeCarico.errorsStack.status == ErrorStatusTypes.PENDING ||
					anagraficheErrorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<NavigazioneGiorniToolbar
				titolo={"Ordini di carico"}
				setData={setData}
				data={data}
				refresh={() => {
					setRefresh(true);
				}}
			/>
			<Box sx={{ backgroundColor: "#999", height: "80vh" }}>
				{parametri && (
					<OrdiniCaricoPdf
						lotti={lotti.results.filter(
							(lotto) => lotto.stato_lotto.stato == 3
						)}
						parametri={parametri}
					/>
				)}
			</Box>

			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="flex-start"
				mt={2}
				spacing={1}
			>
				<Button
					variant="contained"
					component={Link}
					to={{
						pathname: Routes_path.SCHEDE_CARICO,
					}}
					endIcon={<KeyboardReturnIcon />}
					// color="success"
				>
					Torna alle schede carico
				</Button>
				<Button
					variant="contained"
					endIcon={<SaveAltIcon />}
					onClick={() => {
						data &&
							lotti.results &&
							stampaConNome({
								Documento: (
									<Document>
										<Page
											size="A4"
											style={{
												backgroundColor: "#fff",
												paddingTop: 20,
											}}
											orientation="landscape"
											key={"scheda_macellazione"}
										>
											<ProgrammaMacellazionePdf lotti={lotti.results} />
										</Page>
									</Document>
								),
								nome: nomeProgramma(data),
							});
					}}
				>
					Salva Programma
				</Button>
			</Stack>
		</Box>
	);
};

export default OrdiniCaricoPage;
