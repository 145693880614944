import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";

import { Utente, saveUtente, UtenteLogged } from "../authenticationSlice";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import ProfileForm from "./ProfileForm";
// nodejs library that concatenates classes
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";

const ProfilePage = ({ ...props }) => {
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const dispatch = useAppDispatch();
	const [editing, setEditing] = useState(false);

	const switchEdit = (switchOn?: boolean) => {
		if (switchOn != undefined) {
			setEditing(switchOn);
		} else {
			setEditing(!editing);
		}
	};

	return (
		<Box>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={utente.errorsStack.status == ErrorStatusTypes.PENDING}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<Grid container justifyContent="center">
				<Grid item xs={12} sm={12} md={6}>
					<Card raised sx={{ mt: 10 }}>
						<CardContent sx={{ mb: 2 }}>
							<div>
								{/* <img src={profileImg} /> */}
								<div>
									<h3>
										{utente.first_name} {utente.last_name}
										{!editing && (
											<IconButton
												aria-label="edit"
												size="small"
												onClick={() => {
													switchEdit();
												}}
											>
												<EditIcon fontSize="inherit" />
											</IconButton>
										)}
									</h3>

									<ProfileForm
										utenteLogged={utente}
										saveUtente={(utente: Utente) =>
											dispatch(saveUtente(utente))
										}
										editing={editing}
										switchEdit={switchEdit}
									/>

									{/* <IconButton link size="large">
                <TwitterIcon />
              </IconButton>
              <IconButton justIcon size="large">|
                <InstagramIcon />
              </IconButton>
              <IconButton justIcon link size="large">
                <FacebookIcon />
              </IconButton> */}
								</div>
							</div>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
};

export default ProfilePage;
