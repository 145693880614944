import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../../common/errorsDeclarations";

import {
	Lotto,
	getLotto,
	PianificazioneStrutturaState,
} from "../../pianificazione/pianificazioneSlice";

import {
	TipoCapo,
	Committente,
	fetchTipiCapo,
	fetchCommittenti,
} from "../../anagrafiche/anagraficheSlice";

import * as graficiKpiApi from "../../../../api/reportistica/reportisticaApi";
import moment from "moment";
import { toast } from "react-toastify";

moment.locale("it");
// export interface StatoConsuntivoVivo {
// 	stato: number;
// 	descrizione: string;
// 	messaggio: string;
// }

export interface GraficoTipiCapoCommittenti {
	id: number;
	nome: string;
	committente: string;
	id_committente: number;
	kg_totali: number;
	// resa_media: number;
}
export interface GraficoCommittenteTipiCapoMese {
	mese: string;
	tipo_capo: string;
	kg: number;
}

export interface GraficoCommittenteTipiCapoPesoMedioSettimana {
	settimana: number;
	tipo_capo: string;
	peso_medio_resa: number;
	numero_capi: number;
	numero_lotti: number;
}
export interface GraficoCommittenteReseSettimane {
	settimana: string;
	tipo_capo: string;
	resa: number;
}

export interface IndicatoriTotaliCommittenteTipiCapo {
	tipo_capo: string;
	kg_totali: number;
	numero_capi: number;
	numero_lotti: number;
	lavorazioni_medie_lotto: number;
	resa_media: number;
}

export interface IndicatoriTotaliCommittente {
	committente: string;
	kg_totali: number;
	calo_percentuale_medio: number;
	minuti_attesa_media_presso_allevamento: number;
	numero_capi: number;
	numero_lotti: number;
	lavorazioni_medie_lotto: number;
	resa_media: number;
}

export interface GraficiKpiStrutturaState {
	graficoTipiCapoCommittenti: GraficoTipiCapoCommittenti[];
	graficoCommittenteTipiCapoMese: GraficoCommittenteTipiCapoMese[];
	graficoCommittenteTipiCapoPesoMedioSettimana: GraficoCommittenteTipiCapoPesoMedioSettimana[];
	graficoCommittenteReseSettimane: GraficoCommittenteReseSettimane[];
	indicatoriTotaliCommittenteTipiCapo: IndicatoriTotaliCommittenteTipiCapo[];
	indicatoriTotaliCommittenti: IndicatoriTotaliCommittente[];
	errorsStack: ErrorsStack;
}

const initialState: GraficiKpiStrutturaState = {
	graficoTipiCapoCommittenti: [],
	graficoCommittenteTipiCapoMese: [],
	graficoCommittenteTipiCapoPesoMedioSettimana: [],
	graficoCommittenteReseSettimane: [],
	indicatoriTotaliCommittenteTipiCapo: [],
	indicatoriTotaliCommittenti: [],
	errorsStack: { status: ErrorStatusTypes.OK },
};

export const fetchGraficoTipiCapoCommittenti = createAsyncThunk(
	"grafici/fetchGraficoTipiCapoCommittenti",
	async (dataRange?: { data_da?: Date; data_a?: Date }) => {
		return await graficiKpiApi.fetchGraficoTipiCapoCommittenti(
			dataRange?.data_da,
			dataRange?.data_a
		);
	}
);

export const fetchGraficoCommittenteTipiCapoMese = createAsyncThunk(
	"grafici/fetchGraficoCommittenteTipiCapoMese",
	async (parametri: { committente_id: number; periodo: string }) => {
		return await graficiKpiApi.fetchGraficoCommittenteTipiCapoMese(
			parametri.committente_id,
			parametri.periodo
		);
	}
);

export const fetchGraficoCommittenteTipiCapoPesoMedioSettimana =
	createAsyncThunk(
		"grafici/fetchGraficoCommittenteTipiCapoPesoMedioSettimana",
		async (parametri: { committente_id: number; periodo: string }) => {
			return await graficiKpiApi.fetchGraficoCommittenteTipiCapoPesoMedioSettimana(
				parametri.committente_id,
				parametri.periodo
			);
		}
	);

export const fetchGraficoCommittenteReseSettimane = createAsyncThunk(
	"grafici/fetchGraficoCommittenteReseSettimane",
	async (parametri: { committente_id: number; periodo: string }) => {
		return await graficiKpiApi.fetchGraficoCommittenteReseSettimane(
			parametri.committente_id,
			parametri.periodo
		);
	}
);

export const fetchIndicatoriTotaliCommittenteTipiCapo = createAsyncThunk(
	"grafici/fetchIndicatoriTotaliCommittenteTipiCapo",
	async (parametri: { committente_id: number; periodo: string }) => {
		return await graficiKpiApi.fetchIndicatoriTotaliCommittenteTipiCapo(
			parametri.committente_id,
			parametri.periodo
		);
	}
);

export const fetchIndicatoriTotaliCommittenti = createAsyncThunk(
	"grafici/fetchIndicatoriTotaliCommittenti",
	async (periodo: string) => {
		return await graficiKpiApi.fetchIndicatoriTotaliCommittenti(periodo);
	}
);

export const graficiKpiSlice = createSlice({
	name: "graficiKpiState",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch dati per il grafico tipi capo - committenti
		builder.addCase(
			fetchGraficoTipiCapoCommittenti.pending,
			(state, action) => {
				state.errorsStack = {
					status: ErrorStatusTypes.PENDING,
				};
			}
		);
		builder.addCase(
			fetchGraficoTipiCapoCommittenti.fulfilled,
			(state, action) => {
				state.graficoTipiCapoCommittenti = action.payload;
				state.errorsStack = { status: ErrorStatusTypes.OK };
			}
		);
		builder.addCase(
			fetchGraficoTipiCapoCommittenti.rejected,
			(state, action) => {
				// toast.error(
				// 	"Errore grafico tipi capo - committenti:" + action?.error?.message ||
				// 		""
				// );
				state.errorsStack = parseErrorMessage(action.error);
			}
		);

		// fetch dati per il grafico tipi capo - mese x committente
		builder.addCase(
			fetchGraficoCommittenteTipiCapoMese.pending,
			(state, action) => {
				state.errorsStack = {
					status: ErrorStatusTypes.PENDING,
				};
			}
		);
		builder.addCase(
			fetchGraficoCommittenteTipiCapoMese.fulfilled,
			(state, action) => {
				state.graficoCommittenteTipiCapoMese = action.payload;
				state.errorsStack = { status: ErrorStatusTypes.OK };
			}
		);
		builder.addCase(
			fetchGraficoCommittenteTipiCapoMese.rejected,
			(state, action) => {
				// toast.error(
				// 	"Errore grafico tipi capo mese x committente:" +
				// 		action?.error?.message || ""
				// );
				state.errorsStack = parseErrorMessage(action.error);
			}
		);

		// fetch dati per il grafico tipi capo - settimana x committente
		builder.addCase(
			fetchGraficoCommittenteTipiCapoPesoMedioSettimana.pending,
			(state, action) => {
				state.errorsStack = {
					status: ErrorStatusTypes.PENDING,
				};
			}
		);
		builder.addCase(
			fetchGraficoCommittenteTipiCapoPesoMedioSettimana.fulfilled,
			(state, action) => {
				state.graficoCommittenteTipiCapoPesoMedioSettimana = action.payload;
				state.errorsStack = { status: ErrorStatusTypes.OK };
			}
		);
		builder.addCase(
			fetchGraficoCommittenteTipiCapoPesoMedioSettimana.rejected,
			(state, action) => {
				// toast.error(
				// 	"Errore grafico tipi capo mese x committente:" +
				// 		action?.error?.message || ""
				// );
				state.errorsStack = parseErrorMessage(action.error);
			}
		);

		// fetch dati per il grafico tipi capo - rese - settimane x committente
		builder.addCase(
			fetchGraficoCommittenteReseSettimane.pending,
			(state, action) => {
				state.errorsStack = {
					status: ErrorStatusTypes.PENDING,
				};
			}
		);
		builder.addCase(
			fetchGraficoCommittenteReseSettimane.fulfilled,
			(state, action) => {
				state.graficoCommittenteReseSettimane = action.payload;
				state.errorsStack = { status: ErrorStatusTypes.OK };
			}
		);
		builder.addCase(
			fetchGraficoCommittenteReseSettimane.rejected,
			(state, action) => {
				// toast.error(
				// 	"Errore grafico rese settimane x committente:" +
				// 		action?.error?.message || ""
				// );
				state.errorsStack = parseErrorMessage(action.error);
			}
		);

		// fetch dati per il grafico indicatori totali tipi capo x committente
		builder.addCase(
			fetchIndicatoriTotaliCommittenteTipiCapo.pending,
			(state, action) => {
				state.errorsStack = {
					status: ErrorStatusTypes.PENDING,
				};
			}
		);
		builder.addCase(
			fetchIndicatoriTotaliCommittenteTipiCapo.fulfilled,
			(state, action) => {
				state.indicatoriTotaliCommittenteTipiCapo = action.payload;
				state.errorsStack = { status: ErrorStatusTypes.OK };
			}
		);
		builder.addCase(
			fetchIndicatoriTotaliCommittenteTipiCapo.rejected,
			(state, action) => {
				toast.error(
					"Errore indicatori tipi capo x committente:" +
						action?.error?.message || ""
				);
				state.errorsStack = parseErrorMessage(action.error);
			}
		);

		// fetch dati per i grafici x committenti
		builder.addCase(
			fetchIndicatoriTotaliCommittenti.pending,
			(state, action) => {
				state.errorsStack = {
					status: ErrorStatusTypes.PENDING,
				};
			}
		);
		builder.addCase(
			fetchIndicatoriTotaliCommittenti.fulfilled,
			(state, action) => {
				state.indicatoriTotaliCommittenti = action.payload;
				state.errorsStack = { status: ErrorStatusTypes.OK };
			}
		);
		builder.addCase(
			fetchIndicatoriTotaliCommittenti.rejected,
			(state, action) => {
				// toast.error(
				// 	"Errore indicatori tipi capo x committente:" +
				// 		action?.error?.message || ""
				// );
				state.errorsStack = parseErrorMessage(action.error);
			}
		);
	},
});

// Action creators are generated for each case reducer function
export const {} = graficiKpiSlice.actions;

export const graficiKpiSliceReducer = graficiKpiSlice.reducer;
