import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";

import Alert from "@mui/material/Alert";
import { Lotto } from "../pianificazione/pianificazioneSlice";
import {
	Committente,
	Produttore,
	TipoContattoTypes,
} from "../anagrafiche/anagraficheSlice";
import {
	mandaDocumentiLotto,
	avvertiUtenteDocumentiLotto,
} from "./allegatiSlice";
import SendIcon from "@mui/icons-material/Send";

import Box from "@mui/material/Box";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneIcon from "@mui/icons-material/Phone";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ComputerIcon from "@mui/icons-material/Computer";

interface InviaAllegatiBottoniProps {
	lotto?: Lotto;
}

const InviaAllegatiBottoni = ({ lotto }: InviaAllegatiBottoniProps) => {
	const dispatch = useAppDispatch();

	const handlerMandaDocumentiLotto = async (contatto_id: number) => {
		lotto?.id &&
			dispatch(
				mandaDocumentiLotto({
					lotto_id: lotto.id,
					contatto_id: contatto_id,
				})
			);
	};
	const handlerAvvertiUtenteDocumentiLotto = async (
		utenteEsterno_id: number
	) => {
		lotto?.id &&
			dispatch(
				avvertiUtenteDocumentiLotto({
					lotto_id: lotto.id,
					utenteEsterno_id: utenteEsterno_id,
				})
			);
	};

	const committente = useSelector<RootState, Committente | undefined>((state) =>
		state.anagrafiche.committenti.results.find(
			(commitente) => commitente.id == lotto?.committente
		)
	);
	const produttore = useSelector<RootState, Produttore | undefined>((state) =>
		state.anagrafiche.produttori.results.find(
			(produttore) => produttore.id == lotto?.produttore
		)
	);

	const [openContattiCommittente, setOpenContattiCommittente] = useState(false);
	const handleOpenContattiCommittente = () => {
		setOpenContattiCommittente(true);
	};
	const handleCloseContattiCommittente = () => {
		setOpenContattiCommittente(false);
	};

	const [openContattiProduttore, setOpenContattiProduttore] = useState(false);
	const handleOpenContattiProduttore = () => {
		setOpenContattiProduttore(true);
	};
	const handleCloseContattiProduttore = () => {
		setOpenContattiProduttore(false);
	};

	return (
		<Box>
			<Stack direction="row" spacing={2} sx={{ mt: 2 }}>
				<Button
					variant="outlined"
					onClick={handleOpenContattiCommittente}
					endIcon={<SendIcon />}
				>
					Contatti Committente
				</Button>
				<Button
					variant="outlined"
					onClick={handleOpenContattiProduttore}
					endIcon={<SendIcon />}
				>
					Contatti Produttore
				</Button>
			</Stack>
			<Dialog
				open={openContattiCommittente}
				onClose={handleCloseContattiCommittente}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Contatti di " + lotto?.committente_desc}
				</DialogTitle>
				<DialogContent>
					<List sx={{ pt: 0 }}>
						{committente?.utenti_esterni.map((utenteEsterno) => (
							<ListItem key={"utenteEsterno_" + utenteEsterno.id}>
								<ListItemAvatar>
									<Avatar sx={{ backgroundColor: "#32CD32" }}>
										<AccountCircleIcon />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={
										utenteEsterno.user?.first_name +
										" " +
										utenteEsterno.user?.last_name
									}
									secondary="utente applicativo"
								/>
								<ListItemAvatar>
									<Button
										variant="contained"
										color="info"
										onClick={() =>
											handlerAvvertiUtenteDocumentiLotto(utenteEsterno.id || 0)
										}
										sx={{ ml: 2 }}
									>
										avverti documenti disponibili
										<ComputerIcon sx={{ ml: 1 }} />
									</Button>
								</ListItemAvatar>
							</ListItem>
						))}
						<Alert severity="error">
							Attenzione - al momento la mail spedisce solo i documenti
							scansionati!
						</Alert>
						{committente?.contatti.map((contatto) => (
							<ListItem key={"contatto_" + contatto.id}>
								<ListItemAvatar>
									<Avatar>
										{contatto.tipo_contatto == TipoContattoTypes.M && (
											<AlternateEmailIcon />
										)}
										{contatto.tipo_contatto == TipoContattoTypes.T && (
											<PhoneIcon />
										)}
										{contatto.tipo_contatto == TipoContattoTypes.C && (
											<PhoneIphoneIcon />
										)}
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={contatto.riferimento}
									secondary={contatto.valore}
								/>
								{contatto.tipo_contatto == TipoContattoTypes.M && (
									<ListItemAvatar>
										<Button
											variant="contained"
											onClick={() => handlerMandaDocumentiLotto(contatto.id)}
											sx={{ ml: 2 }}
										>
											invia documenti
											<AlternateEmailIcon />
										</Button>
									</ListItemAvatar>
								)}
							</ListItem>
						))}
					</List>
				</DialogContent>
			</Dialog>
			<Dialog
				open={openContattiProduttore}
				onClose={handleCloseContattiProduttore}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Contatti di " + produttore?.nome}
				</DialogTitle>
				<DialogContent>
					<Alert severity="error">
						Attenzione - al momento la mail spedisce solo i documenti
						scansionati!
					</Alert>
					<List sx={{ pt: 0 }}>
						{produttore?.contatti.map((contatto) => (
							<ListItem key={contatto.id}>
								<ListItemAvatar>
									<Avatar>
										{contatto.tipo_contatto == TipoContattoTypes.M && (
											<AlternateEmailIcon />
										)}
										{contatto.tipo_contatto == TipoContattoTypes.T && (
											<PhoneIcon />
										)}
										{contatto.tipo_contatto == TipoContattoTypes.C && (
											<PhoneIphoneIcon />
										)}
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={contatto.riferimento}
									secondary={contatto.valore}
								/>
								{contatto.tipo_contatto == TipoContattoTypes.M && (
									<ListItemAvatar>
										<Button
											variant="contained"
											onClick={() => handlerMandaDocumentiLotto(contatto.id)}
											sx={{ ml: 2 }}
										>
											invia documenti
											<AlternateEmailIcon />
										</Button>
									</ListItemAvatar>
								)}
							</ListItem>
						))}
					</List>
				</DialogContent>
			</Dialog>
		</Box>
	);
};

export default InviaAllegatiBottoni;
