import { Location } from "react-router-dom";
import DateToday from "./DateToday";
import { DATA_CORRENTE, DATA_CORRENTE_DATA } from "./../../../api/apiUtils";
import moment from "moment";

type LocationState = {
	giornoSettimana: Date;
};

export const RecuperaDataCorrente = (location?: Location) => {
	if (location && location.state) {
		const { giornoSettimana } = location.state as LocationState;
		if (giornoSettimana) {
			return giornoSettimana;
		}
	}
	const dataCorrente = localStorage.getItem(DATA_CORRENTE);
	const dataCorrenteData = localStorage.getItem(DATA_CORRENTE_DATA);
	if (
		!!dataCorrente &&
		dataCorrenteData == moment(new Date()).format("YYYY-MM-DD")
	) {
		return new Date(dataCorrente);
	}
	return DateToday();
};

export const FissaDataCorrente = (data: Date) => {
	localStorage.setItem(DATA_CORRENTE, moment(data).format("YYYY-MM-DD"));
	localStorage.setItem(
		DATA_CORRENTE_DATA,
		moment(new Date()).format("YYYY-MM-DD")
	);
};
