import React, { useState, useEffect } from "react";
import { Confezionamento } from "./confezionamentiSlice";
import { theme } from "../../../../theme";
import moment from "moment";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";

import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import ClockIcon from "@mui/icons-material/AccessTime";
import AlarmIcon from "@mui/icons-material/Alarm";
import ShutterSpeedIcon from "@mui/icons-material/ShutterSpeed";
import SnoozeIcon from "@mui/icons-material/Snooze";
import { Button, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

interface ConfezionamentoFormProps {
	confezionamento: Confezionamento;
	saveConfezionamento: (confezionamento: Confezionamento) => void;
}

const ConfezionamentoForm = ({
	confezionamento,
	saveConfezionamento,
}: ConfezionamentoFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Confezionamento>();

	const listaCampi = Object.keys(confezionamento) as Array<
		keyof Confezionamento
	>;

	useEffect(() => {
		listaCampi.map((field) => {
			setValue(field, confezionamento[field]);
		});
	}, [
		listaCampi.forEach((field) => {
			confezionamento[field];
		}),
	]);

	// useEffect(() => {
	// 	reset({}, { keepValues: true });
	// }, [confezionamento, reset]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			confezionamento?.errorsStack?.fieldsErrors &&
				confezionamento.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: confezionamento.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [confezionamento?.errorsStack?.fieldsErrors]);

	const onSubmit: SubmitHandler<Confezionamento> = (confezionamento) => {
		saveConfezionamento(confezionamento);
	};

	// #############################################
	// Controllo e gestioni errori
	// #############################################

	React.useEffect(() => {
		clearErrors("data_ora_inizio_confezionamento");
		clearErrors("data_ora_fine_confezionamento");
		const data_ora_inizio_confezionamento: Date | null | undefined = getValues(
			"data_ora_inizio_confezionamento"
		);
		const data_ora_fine_confezionamento: Date | null | undefined = getValues(
			"data_ora_fine_confezionamento"
		);
		if (data_ora_inizio_confezionamento && data_ora_fine_confezionamento) {
			if (
				new Date(data_ora_inizio_confezionamento).toLocaleTimeString("it-IT") >
				new Date(data_ora_fine_confezionamento).toLocaleTimeString("it-IT")
			) {
				setError("data_ora_inizio_confezionamento", {
					type: "custom",
					message: "Inizio confezionamento successivo a fine confezionamento",
				});
				setError("data_ora_fine_confezionamento", {
					type: "custom",
					message: "Fine confezionamento anteriore a inizio confezionamento",
				});
			}
		}
	}, [
		watch("data_ora_inizio_confezionamento"),
		watch("data_ora_fine_confezionamento"),
	]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{/* {schedaCarico?.errorsStack?.fieldsErrors?.non_field_errors && (
        <Alert severity="error">
          {schedaCarico?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
        </Alert>
      )} */}

			<Grid
				container
				// sx={{ flexGrow: 1, mb: 1 }}
				rowSpacing={1}
				columnSpacing={{ xs: 1 }}
				// justifyContent="center"
				justifyContent="center"
				alignItems="center"
			>
				{/* <Grid
					sx={{
						display: { xs: "none", sm: "block", md: "block", lg: "none" },
					}}
					item
					xs={0}
					sm={1}
					md={1}
					lg={0}
				></Grid> */}
				<Grid item xs={12} sm={5} md={4} lg={4}>
					{/* <Stack direction="row" justifyContent="center"> */}
					{/* <Grid item xs={6} sm={2} md={2}> */}
					<TimePicker
						components={{
							OpenPickerIcon: ClockIcon,
						}}
						inputFormat="HH:mm"
						mask={"__:__"}
						label="inizio confezionamento"
						value={
							watch("data_ora_inizio_confezionamento") ||
							confezionamento?.data_ora_inizio_confezionamento
						}
						renderInput={(params) => (
							<TextField
								{...params}
								error={!!errors?.data_ora_inizio_confezionamento}
								helperText={errors?.data_ora_inizio_confezionamento?.message}
								sx={{ width: "100%" }}
								autoComplete={"off"}
								size="small"
							/>
						)}
						onChange={(newValue: any) => {
							setValue(
								"data_ora_inizio_confezionamento",
								newValue ? new Date(newValue) : null,
								{
									shouldDirty: true,
								}
							);
							clearErrors("data_ora_inizio_confezionamento");
						}}
					/>
				</Grid>

				<Grid item xs={12} sm={5} md={4} lg={4}>
					<TimePicker
						components={{
							OpenPickerIcon: ClockIcon,
						}}
						inputFormat="HH:mm"
						mask={"__:__"}
						label="fine confezionamento"
						value={
							watch("data_ora_fine_confezionamento") ||
							confezionamento?.data_ora_fine_confezionamento
						}
						renderInput={(params) => (
							<TextField
								{...params}
								error={!!errors?.data_ora_fine_confezionamento}
								helperText={errors?.data_ora_fine_confezionamento?.message}
								sx={{ width: "100%" }}
								autoComplete={"off"}
								size="small"
							/>
						)}
						onChange={(newValue: any) => {
							setValue(
								"data_ora_fine_confezionamento",
								newValue ? new Date(newValue) : null,
								{
									shouldDirty: true,
								}
							);
							clearErrors("data_ora_fine_confezionamento");
						}}
					/>
				</Grid>
				{/* </Stack> */}
				{/* </Grid> */}

				<Grid item xs={12} sm={2} md={2} lg={2}>
					<Button disabled={!isDirty} variant="contained" type="submit">
						Salva
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default ConfezionamentoForm;
