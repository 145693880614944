import React from "react";
import { useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../redux/store";
import { Link } from "react-router-dom";

import { Lotto } from "../../../pianificazione/pianificazioneSlice";
import { SchedaCarico } from "../../../schedeCarico/schedeCaricoSlice";
import { Veterinario } from "../veterinarioSlice";
import { Routes_path } from "../../../../routerApp";
import { Parametri } from "../../../anagrafiche/anagraficheSlice";
import { PDFViewer, Page, Document, StyleSheet } from "@react-pdf/renderer";

import ModuloVeterinarioPdf from "./ModuloVeterinarioPdf";

import moment from "moment";

import { ErrorsStack } from "../../../../common/errorsDeclarations";

import { Box } from "@mui/system";
import Button from "@mui/material/Button";

// Create styles
const styles = StyleSheet.create({
	page: {
		backgroundColor: "#fff",
		paddingTop: 20,
	},
	section: {
		marginTop: 10,
		marginLeft: 10,
		marginRight: 10,
		padding: 10,
	},
	sectionIntestazione: {
		marginTop: 5,
		marginLeft: 10,
		marginRight: 10,
		padding: 10,
		paddingTop: 10,
		paddingBottom: 7,
		backgroundColor: "#777777",
		color: "#ffffff",
	},
	text: {
		fontSize: 12,
		fontStyle: "normal",
	},
});

const ModuloVeterinarioPage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	// const schedeCarico: SchedeCaricoState = useSelector<
	//   RootState,
	//   SchedeCaricoState
	// >((state) => state.schedeCaricoState.schedeCarico);

	const anagraficheErrorsStack: ErrorsStack = useSelector<
		RootState,
		ErrorsStack
	>((state) => state.anagrafiche.errorsStack);

	type LocationState = {
		lottiGiornoScelto: Veterinario[];
		parametroAggiustamentoCalc: number;
		totalePesoCalc: number;
		totaleCapiCalc: number;
		orarioFineCalc: Date;
	};
	const location = useLocation();

	const {
		lottiGiornoScelto,
		parametroAggiustamentoCalc,
		totalePesoCalc,
		totaleCapiCalc,
		orarioFineCalc,
	} = location.state
		? (location.state as LocationState)
		: {
				lottiGiornoScelto: undefined,
				parametroAggiustamentoCalc: 1,
				totalePesoCalc: 0,
				totaleCapiCalc: 0,
				orarioFineCalc: new Date(0),
		  };

	const [lottiGiorno, setLottiGiorno] = React.useState<
		Veterinario[] | undefined
	>(lottiGiornoScelto);

	const [parametroAggiustamento, setParametroAggiustamento] = React.useState<
		number | undefined
	>(parametroAggiustamentoCalc);

	const [totalePeso, setTotalePeso] = React.useState<number | undefined>(
		totalePesoCalc
	);

	const [totaleCapi, setTotaleCapi] = React.useState<number | undefined>(
		totaleCapiCalc
	);

	const [orarioFine, setOrarioFine] = React.useState<Date | undefined>(
		orarioFineCalc
	);

	return (
		<Box>
			{/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          lotti.errorsStack.status == ErrorStatusTypes.PENDING ||
          schedeCarico.errorsStack.status == ErrorStatusTypes.PENDING ||
          anagraficheErrorsStack.status == ErrorStatusTypes.PENDING
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}

			<Box sx={{ backgroundColor: "#999", height: "80vh" }}>
				{lottiGiorno && (
					<PDFViewer width={"100%"} height={"100%"}>
						<Document>
							<Page
								size="A4"
								style={styles.page}
								orientation="landscape"
								// key={"scheda_carico_" + schedaCarico?.id}
							>
								<ModuloVeterinarioPdf
									lottiGiorno={lottiGiorno}
									parametroAggiustamento={parametroAggiustamento}
									totalePeso={totalePeso}
									totaleCapi={totaleCapi}
									orarioFine={orarioFine}
								/>
							</Page>
						</Document>
					</PDFViewer>
				)}
			</Box>
			<Button
				variant="outlined"
				component={Link}
				to={{
					pathname: Routes_path.REPORT_VETERINARIO,
				}}
				// color="success"
			>
				Torna al Report Sanitario
			</Button>
		</Box>
	);
};

export default ModuloVeterinarioPage;
