import React from "react";
import { Autista } from "../../anagrafiche/anagraficheSlice";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

interface AutistaSelectProps {
	autistaCorrente: Autista | null;
	autisti: Autista[];
	setAutistaCorrente: any;
}
export const AutistaSelect = ({
	autistaCorrente,
	autisti,
	setAutistaCorrente,
}: AutistaSelectProps) => {
	const handlerCahnge = (event: SelectChangeEvent) => {
		const newAutista = autisti.find(
			(autista) => autista.id.toString() == event.target.value
		);
		setAutistaCorrente(newAutista);
	};
	return (
		<Select
			key="selectAutistaCorrente"
			required={true}
			id="selectAutistaCorrente"
			label="Autista"
			// disabled={disabled}
			// notched={"true"}
			// defaultValue={autistaCorrente?.id||0}
			value={autistaCorrente?.id.toString() || "0"}
			variant="outlined"
			sx={{ width: "100%" }}
			onChange={handlerCahnge}
		>
			{autisti.map((option) => {
				return (
					<MenuItem key={option.id} value={option.id}>
						{option.cognome + " " + option.nome}
					</MenuItem>
				);
			})}
		</Select>
	);
};
