import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";

import {
	Committenti,
	Committente,
	Produttore,
	Autista,
	fetchCommittenti,
	fetchAllevamenti,
	fetchAutisti,
} from "../../anagrafiche/anagraficheSlice";
import UtentiEsterniList from "./UtentiEsterniList";

import { Routes_path } from "../../../routerApp";

import {
	UtentiEsterni,
	fetchUtentiEsterni,
	OspitiStrutturaState,
} from "../ospitiSlice";

import Stack from "@mui/material/Stack";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import NavigazioneGiorniToolbar from "../../../common/dateUtils/NavigazioneGiorniToolbar";
import { DATA_SCHEDULAZIONE_CORRENTE } from "../../../../api/apiUtils";

import moment from "moment";

import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import DateToday from "../../../common/dateUtils/DateToday";
import PaperMui from "@mui/material/Paper";

import Grid from "@mui/material/Grid";
import { styled, Box } from "@mui/system";
import Button from "@mui/material/Button";
import PrintIcon from "@mui/icons-material/Print";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));

const Paper = styled(PaperMui)(({ theme }) => ({
	color: theme.palette.primary.contrastText,
	backgroundColor: "#b0b0b0",
	padding: theme.spacing(1),
	borderRadius: theme.shape.borderRadius,
}));

interface UtentiEsterniPageProps {}

const UtentiEsterniPage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();
	const [currentTab, setCurrentTab] = useState("1");

	const handleTabChange = (event: any, newValue: string) => {
		setCurrentTab(newValue);
	};

	const utentiEsterni: UtentiEsterni = useSelector<RootState, UtentiEsterni>(
		(state) => state.ospitiState.utentiEsterni
	);

	const committenti: Committente[] = useSelector<RootState, Committente[]>(
		(state) => state.anagrafiche.committenti.results
	);

	const produttori: Produttore[] = useSelector<RootState, Produttore[]>(
		(state) => state.anagrafiche.produttori.results
	);

	const autisti = useSelector<RootState, Autista[]>(
		(state) => state.anagrafiche.autisti.results
	);

	const [refresh, setRefresh] = React.useState<boolean>(false);

	useEffect(() => {
		dispatch(fetchUtentiEsterni());
		dispatch(fetchCommittenti());
		dispatch(fetchAutisti());
		setRefresh(false);
	}, [refresh]);

	return (
		<Box>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={utentiEsterni.errorsStack.status == ErrorStatusTypes.PENDING}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<NavigazioneGiorniToolbar
				titolo={"Utenti Esterni"}
				dataPresente={false}
				setData={(data: Date) => {}}
				refresh={() => {
					setRefresh(true);
				}}
				Toolbar={() => {
					return (
						<Stack
							direction="row"
							justifyContent="flex-end"
							alignItems="center"
							sx={{ ml: 2 }}
							spacing={1}
						>
							<Button
								component={Link}
								variant="contained"
								to={{
									pathname: Routes_path.UTENTE_ESTERNO,
								}}
								state={{
									utente_id: null,
								}}
							>
								crea Utente
							</Button>
						</Stack>
					);
				}}
			/>

			<Box
				sx={{
					p: 1,
					// elevation: 0,
					// margin: 1,
					// marginY: 2,
					// marginBottom: 5,
					padding: 5,
					backgroundColor: "#f5f5f5",
					border: 0,
					// borderStyle: "solid",
					borderRadius: 4,
					boxShadow: 2,
				}}
			>
				<UtentiEsterniList
					committenti={committenti}
					produttori={produttori}
					autisti={autisti}
					utentiEsterni={utentiEsterni}
				/>
			</Box>
		</Box>
	);
};

export default UtentiEsterniPage;
