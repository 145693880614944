import React, { FC, useEffect, ReactElement } from "react";

import moment from "moment";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";
import WeekPicker from "../../common/dateUtils/WeekPicker";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CardMui from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { styled, Box } from "@mui/system";
import Button from "@mui/material/Button";

const Card = styled(CardMui)(({ theme }) => ({
	// display: "flex:1",
	// "& > *": {
	margin: theme.spacing(1),
	align: "center",
	marginBottom: 1,
	// },
}));

interface NavigazioneSettimaneToolbarProps {
	titolo?: string;
	data?: Date;
	setData: (data: Date) => void;
	refresh: () => void;
	Toolbar: FC;
}

const NavigazioneSettimaneToolbar = ({
	titolo,
	data,
	setData,
	refresh,
	Toolbar,
	...props
}: NavigazioneSettimaneToolbarProps) => {
	interface Settimana {
		numeroSettimana: String;
		dateSettimana: Date[];
	}

	const caricaSettimana = (data?: Date) => {
		const dateSettimana: Date[] = [...Array(6)].map((_, i) =>
			moment(data).startOf("isoWeek").add(i, "d").toDate()
		);
		return dateSettimana;
	};

	const cambiaSettimana = (avanti: Boolean) => {
		data &&
			(avanti
				? setData(new Date(data.setDate(data.getDate() + 7)))
				: setData(new Date(data.setDate(data.getDate() - 7))));
	};

	const [settimana, setSettimana] = React.useState<Settimana>({
		numeroSettimana: moment(data).format("W"),
		dateSettimana: caricaSettimana(data),
	});
	useEffect(() => {
		const newSettimana = {
			numeroSettimana: moment(data).format("W"),
			dateSettimana: caricaSettimana(data),
		};
		setSettimana(newSettimana);
	}, [data]);
	const [weekPickerView, setWeekPickerView] = React.useState<Boolean>(false);

	return (
		// <Grid container spacing={1}>
		// 	<Grid item xs={12}>
		<Card>
			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				sx={{ ml: 2 }}
				spacing={2}
			>
				<Typography variant="h5" component="div">
					{titolo}
				</Typography>
				<Typography variant="h5" component="div">
					<IconButton
						aria-label="SettimanaPrecedente"
						onClick={() => {
							cambiaSettimana(false);
						}}
					>
						<ArrowBackIosIcon />
					</IconButton>
					{"Settimana "}
					{settimana.numeroSettimana}
					{" da "}
					{moment(settimana.dateSettimana.at(0)).format("dddd DD MMM")} {" a "}
					{moment(settimana.dateSettimana.at(-1)).format("dddd DD MMM")}
					<Button
						sx={{ p: 2, ml: 2, mr: 2 }}
						onClick={() => {
							setWeekPickerView(!weekPickerView);
						}}
					>
						<DateRangeIcon />
					</Button>
					<IconButton
						color="primary"
						aria-label="upload picture"
						component="span"
						onClick={refresh}
					>
						<ReplayIcon />
					</IconButton>
					<IconButton
						aria-label="SettimanaSuccessiva"
						onClick={() => {
							cambiaSettimana(true);
						}}
					>
						<ArrowForwardIosIcon />
					</IconButton>
				</Typography>
				{weekPickerView && <WeekPicker data={data} setData={setData} />}
				<>
					<Toolbar />
				</>
			</Stack>
		</Card>
		// 	{/* </Grid>
		// </Grid> */}
	);
};

export default NavigazioneSettimaneToolbar;
