import React from "react";
import { Committente } from "../../anagrafiche/anagraficheSlice";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

interface SelectCommittenteProps {
	committenteCorrente: Committente | null;
	committenti: Committente[];
	setCommittenteCorrente: any;
}
export const SelectCommittente = ({
	committenteCorrente,
	committenti,
	setCommittenteCorrente,
}: SelectCommittenteProps) => {
	const handlerCahnge = (event: SelectChangeEvent) => {
		const newCommittente = committenti.find(
			(committente) => committente.id.toString() == event.target.value
		);
		setCommittenteCorrente(newCommittente);
	};
	return (
		<Select
			key="selectCommittenteCorrente"
			required={true}
			id="selectCommittenteCorrente"
			label="Committente"
			// disabled={disabled}
			// notched={"true"}
			// defaultValue={committenteCorrente?.id||0}
			value={committenteCorrente?.id.toString() || "0"}
			variant="outlined"
			sx={{ width: "100%" }}
			onChange={handlerCahnge}
		>
			{committenti.map((option) => {
				return (
					<MenuItem key={option.id} value={option.id}>
						{option.nome}
					</MenuItem>
				);
			})}
		</Select>
	);
};

interface SelectPeriodoProps {
	periodo: string;
	setPeriodo: any;
	periodi: { valore: string; periodo: string }[];
}
export const SelectPeriodo = ({
	periodo,
	setPeriodo,
	periodi,
}: SelectPeriodoProps) => {
	const handlerCahnge = (event: SelectChangeEvent) => {
		setPeriodo(event.target.value);
	};

	return (
		<Select
			key="periodo"
			required={true}
			id="periodo"
			label="periodo"
			// disabled={disabled}
			// notched={"true"}
			// defaultValue={committenteCorrente?.id||0}
			value={periodo}
			variant="outlined"
			sx={{ width: "100%" }}
			onChange={handlerCahnge}
		>
			{periodi.map((periodo) => (
				<MenuItem key={periodo.valore} value={periodo.valore}>
					{periodo.periodo}
				</MenuItem>
			))}
		</Select>
	);
};
