import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";
import { Link, useLocation } from "react-router-dom";

import {
	LottiState,
	fetchLotti,
	resetLotti,
} from "../../pianificazione/pianificazioneSlice";

import {
	ConsuntiviVivoState,
	fetchConsuntiviVivo,
} from "./consuntiviVivoSlice";
import { Parametri } from "../../anagrafiche/anagraficheSlice";

import NavigazioneGiorniToolbar from "../../../common/dateUtils/NavigazioneGiorniToolbar";
import ConsuntiviVivoLottoCard from "./ConsuntiviVivoLottoCard";
// import { DATA_CONSUNTIVAZIONE_CORRENTE } from "../../../../api/apiUtils";

import moment from "moment";

import {
	RecuperaDataCorrente,
	FissaDataCorrente,
} from "../../../common/dateUtils/RecuperaDataCorrente";

import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// import DateToday from "../../../common/dateUtils/DateToday";
import PaperMui from "@mui/material/Paper";

import Grid from "@mui/material/Grid";
import { styled, Box } from "@mui/system";

const Paper = styled(PaperMui)(({ theme }) => ({
	color: theme.palette.primary.contrastText,
	backgroundColor: "#b0b0b0",
	padding: theme.spacing(1),
	borderRadius: theme.shape.borderRadius,
}));

const ConsuntiviVivoPage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();
	const lotti: LottiState = useSelector<RootState, LottiState>(
		(state) => state.pianificazione.lotti
	);

	const consuntiviVivo: ConsuntiviVivoState = useSelector<
		RootState,
		ConsuntiviVivoState
	>((state) => state.consuntiviVivoState.consuntiviVivo);

	// const schedeCarico: SchedeCaricoState = useSelector<
	// 	RootState,
	// 	SchedeCaricoState
	// >((state) => state.schedeCaricoState.schedeCarico);

	// const allevamenti: Allevamento[] = useSelector<RootState, Allevamento[]>(
	// 	(state) => state.anagrafiche.allevamenti.results
	// );
	// const camions: Camion[] = useSelector<RootState, Camion[]>(
	// 	(state) => state.anagrafiche.camions.results
	// );
	// const autisti: Autista[] = useSelector<RootState, Autista[]>(
	// 	(state) => state.anagrafiche.autisti.results
	// );

	const anagraficheErrorsStack: ErrorsStack = useSelector<
		RootState,
		ErrorsStack
	>((state) => state.anagrafiche.errorsStack);

	// const parametri: Parametri = useSelector<RootState, Parametri>(
	// 	(state) => state.anagrafiche.parametri
	// );

	type LocationState = {
		giornoSettimana: any;
	};
	const location = useLocation();
	const recupera_data_corrente = RecuperaDataCorrente(location);

	// const recupera_data_corrente = () => {
	// 	if (location.state) {
	// 		const { giornoSettimana } = location.state as LocationState;
	// 		if (giornoSettimana) {
	// 			return giornoSettimana;
	// 		}
	// 	}
	// 	const dataConsuntivazioneCorrente = localStorage.getItem(
	// 		DATA_CONSUNTIVAZIONE_CORRENTE
	// 	);
	// 	if (!!dataConsuntivazioneCorrente) {
	// 		return new Date(dataConsuntivazioneCorrente);
	// 	}
	// 	return DateToday();
	// };

	const [data, setData] = React.useState<Date | undefined>(
		recupera_data_corrente
	);

	// const parametri = useSelector<RootState, Parametri | undefined>((state) =>
	// 	state.anagrafiche.storicoParametri.results.find(
	// 		(parametri) => new Date(parametri.data_validta_da) < new Date(data || "")
	// 	)
	// );

	const parametri = useSelector<RootState, Parametri | undefined>((state) =>
		state.anagrafiche.storicoParametri.results.find(
			(p) =>
				new Date(p.data_validta_da) <=
					new Date(new Date(data || "").getTime() + 6 * 60 * 60 * 1000) &&
				new Date(new Date(data || "").getTime() - 6 * 60 * 60 * 1000) <=
					new Date(p.data_validta_a)
		)
	);

	const [refresh, setRefresh] = React.useState<boolean>(false);

	useEffect(() => {
		data && FissaDataCorrente(data);

		// localStorage.setItem(
		// 	DATA_CONSUNTIVAZIONE_CORRENTE,
		// 	moment(data).format("YYYY-MM-DD")
		// );
		const data_da = data;
		const data_a = data;
		dispatch(resetLotti());
		dispatch(fetchLotti({ data_da, data_a }));
		dispatch(fetchConsuntiviVivo({ data_da, data_a }));
		// dispatch(fetchschedeCarico({ data_da, data_a }));
		// dispatch(fetchCamions(data));
		// dispatch(fetchAutisti(data));
		setRefresh(false);
	}, [data, refresh]);

	return (
		<Box>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					lotti.errorsStack.status == ErrorStatusTypes.PENDING ||
					// schedeCarico.errorsStack.status == ErrorStatusTypes.PENDING ||
					consuntiviVivo.errorsStack.status == ErrorStatusTypes.PENDING ||
					anagraficheErrorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<NavigazioneGiorniToolbar
				titolo={"Consuntivi Vivo"}
				setData={setData}
				data={data}
				refresh={() => {
					setRefresh(true);
				}}
			/>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12} md={12}>
					{parametri &&
						lotti.results.map((lotto) => (
							<ConsuntiviVivoLottoCard
								key={"consuntivi_lotto_" + lotto.id}
								lotto={lotto}
								parametri={parametri}
							/>
						))}
				</Grid>
			</Grid>
		</Box>
	);
};

export default ConsuntiviVivoPage;
