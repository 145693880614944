import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";
import {
	LottiState,
	Lotto,
	fetchLotti,
	moveLotto,
	resetLottoCorrente,
	resetLotti,
	setLottoCorrente,
	deleteLotto,
	cambiaGiornoLotto,
	consolidaOrdinamento,
	creaLottiStandard,
	getLotto,
} from "./pianificazioneSlice";
import {
	aggiornaTutteAnagrafiche,
	TipoCapo,
	Parametri,
} from "../anagrafiche/anagraficheSlice";
import LottoPage from "./LottoPage";
import GiornoColumn from "./GiornoColumn";
import { DATA_PIANIFICAZIONE_CORRENTE } from "../../../api/apiUtils";
import moment from "moment";

import {
	RecuperaDataCorrente,
	FissaDataCorrente,
} from "../../common/dateUtils/RecuperaDataCorrente";

// import { DndProvider } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
import NavigazioneSettimaneToolbar from "../../common/dateUtils/NavigazioneSettimaneToolbar";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { styled, Box } from "@mui/system";
import CardMui from "@mui/material/Card";
import TransitionsModal from "../../common/TransitionsModal";

import DateToday from "../../common/dateUtils/DateToday";
import Grid from "@mui/material/Grid";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

const Card = styled(CardMui)(({ theme }) => ({
	// display: "flex:1",
	// "& > *": {
	margin: theme.spacing(1),
	align: "center",
	marginBottom: 1,
	// },
}));
const lotti_semplificati: boolean = true;

const PianificazionePage = ({}) => {
	moment.locale("it");
	const dispatch = useAppDispatch();
	const lotti: LottiState = useSelector<RootState, LottiState>(
		(state) => state.pianificazione.lotti
	);

	const tipiCapo = useSelector<RootState, TipoCapo[]>(
		(state) => state.anagrafiche.tipiCapo.results
	);

	const recupera_data_corrente = RecuperaDataCorrente();

	const minutiRefreshLotti = useSelector<RootState, number>(
		(state) => state.authentication.minutiRefreshLotti
	);
	// const recupera_data_corrente = () => {
	// 	// localStorage.setItem(ACCESS_TOKEN, token.access);
	// 	const dataPianificazioneCorrente = localStorage.getItem(
	// 		DATA_PIANIFICAZIONE_CORRENTE
	// 	);
	// 	if (!!dataPianificazioneCorrente) {
	// 		return new Date(dataPianificazioneCorrente);
	// 	}
	// 	return DateToday();
	// };

	const [data, setData] = React.useState<Date | undefined>(
		recupera_data_corrente
	);

	interface Settimana {
		numeroSettimana: String;
		dateSettimana: Date[];
	}

	const caricaSettimana = (data?: Date) => {
		const dateSettimana: Date[] = [...Array(6)].map((_, i) =>
			moment(data).startOf("isoWeek").add(i, "d").toDate()
		);
		return dateSettimana;
	};

	const [settimana, setSettimana] = React.useState<Settimana>({
		numeroSettimana: moment(data).format("W"),
		dateSettimana: caricaSettimana(data),
	});

	const lottoCorrente_id = useSelector<RootState, number | null>(
		(state) => state.pianificazione.lottoCorrente_id
	);

	const [openLottoForm, setOpenLottoForm] = React.useState(false);

	useEffect(() => {
		data && FissaDataCorrente(data);
		// localStorage.setItem(
		// 	DATA_PIANIFICAZIONE_CORRENTE,
		// 	moment(data).format("YYYY-MM-DD")
		// );

		let data_da = moment(data).startOf("isoWeek").toDate();
		let data_a = moment(data).startOf("isoWeek").toDate();
		dispatch(resetLotti());
		dispatch(fetchLotti({ data_da, data_a, lotti_semplificati }));
		data_da = moment(data_da).add(1, "d").toDate();
		data_a = moment(data_da).add(1, "d").toDate();
		dispatch(fetchLotti({ data_da, data_a, lotti_semplificati }));
		data_da = moment(data_da).add(1, "d").toDate();
		data_a = moment(data_da).add(1, "d").toDate();
		dispatch(fetchLotti({ data_da, data_a, lotti_semplificati }));
		data_da = moment(data_da).add(1, "d").toDate();
		data_a = moment(data_da).add(1, "d").toDate();
		dispatch(fetchLotti({ data_da, data_a, lotti_semplificati }));
		data_da = moment(data_da).add(1, "d").toDate();
		data_a = moment(data_da).add(1, "d").toDate();
		dispatch(fetchLotti({ data_da, data_a, lotti_semplificati }));

		// const data_da = moment(data).startOf("isoWeek").toDate();
		// const data_a = moment(data).endOf("isoWeek").toDate();
		// dispatch(fetchLotti({ data_da, data_a, lotti_semplificati }));

		const newSettimana = {
			numeroSettimana: moment(data).format("W"),
			dateSettimana: caricaSettimana(data),
		};
		setSettimana(newSettimana);
	}, [data]);

	useEffect(() => {
		const timeRefresh = minutiRefreshLotti * 60 * 1000;

		const intervalloRefreshLotti = setInterval(() => {
			const ultimoAggiornamento = lotti.ultimo_aggiornamento;
			const data_da = moment(data).startOf("isoWeek").toDate();
			const data_a = moment(data).endOf("isoWeek").toDate();

			dispatch(
				fetchLotti({ data_da, data_a, lotti_semplificati, ultimoAggiornamento })
			);
		}, timeRefresh);
		return () => clearInterval(intervalloRefreshLotti);
	}, [data, lotti.ultimo_aggiornamento]);

	const refresh = () => {
		const data_da = moment(data).startOf("isoWeek").toDate();
		const data_a = moment(data).endOf("isoWeek").toDate();
		dispatch(resetLotti());
		dispatch(fetchLotti({ data_da, data_a, lotti_semplificati }));
		dispatch(aggiornaTutteAnagrafiche());
	};

	const [openStandard, setOpenStandard] = React.useState(false);

	const [lottoCorrenteId, setlottoCorrenteId] = React.useState<
		number | null | undefined
	>(null);

	const [dataCorrente, setDataCorrente] = React.useState<Date | null>(null);

	const [contaLottiDubbi, setContaLottiDubbi] = React.useState(true);
	const handleChangeLottiDubbi = () => {
		setContaLottiDubbi(!contaLottiDubbi);
	};

	const lottiFiltroDubbio = !contaLottiDubbi
		? lotti.results.filter((lotto) => lotto.in_dubbio == false)
		: lotti.results;

	const totaleCapiSettimana = Intl.NumberFormat("it-IT").format(
		lottiFiltroDubbio.reduce(
			(valoreCalcolato, lottoCorrente) =>
				valoreCalcolato +
				(lottoCorrente.numero_capi == undefined
					? 0
					: lottoCorrente.numero_capi),
			0
		)
	);

	const handleOpen =
		(lottoId: number | null, giornoSettimana: Date | null) =>
		(event: React.MouseEvent) => {
			event.preventDefault();
			dispatch(setLottoCorrente({ lottoCorrente_id: lottoId }));
			setDataCorrente(giornoSettimana);
			setOpenLottoForm(true);
		};

	const handleClose = () => {
		lottoCorrente_id && dispatch(getLotto(lottoCorrente_id));
		dispatch(resetLottoCorrente());
		setOpenLottoForm(false);
	};

	const handleOpenStandard = () => {
		setOpenStandard(true);
	};
	const handleCloseStandard = () => {
		setOpenStandard(false);
	};

	const style = {
		position: "absolute" as "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		maxWidth: 400,
		width: "95%",
		bgcolor: "background.paper",
		border: "2px solid #000",
		boxShadow: 24,
		p: 4,
		pb: 1,
		textAlign: "center",
	};

	const handlerMoveLotto = (dragId: number, hoverId: number) => {
		dispatch(moveLotto({ dragId, hoverId }));
	};

	const handlerDeleteLotto = (lottoToDelete: Lotto) => {
		dispatch(deleteLotto(lottoToDelete));
	};

	// const handlerFetchStandard = (data: Date) =>{
	//   dispatch(fecthStandard(data));
	// }
	const handlerCreaLottiStandard = (data: Date | undefined) => {
		data != undefined && dispatch(creaLottiStandard(data));
		handleCloseStandard();
	};

	const handlerConsolidaOrdinamento = (dragId: number) => {
		dispatch(consolidaOrdinamento({ dragId }));
	};
	const handlerCambiaGiornoLotto = (dragId: number, hoverValue: any) => {
		dispatch(cambiaGiornoLotto({ dragId, hoverValue }));
	};

	return (
		<>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={lotti.errorsStack.status == ErrorStatusTypes.PENDING}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<TransitionsModal open={openLottoForm} handleClose={handleClose}>
				<LottoPage
					lotto_id={lottoCorrenteId}
					dataCorrente={dataCorrente}
					handleClose={handleClose}
				/>
			</TransitionsModal>
			<NavigazioneSettimaneToolbar
				titolo={"Pianificazione"}
				setData={setData}
				data={data}
				refresh={refresh}
				Toolbar={() => {
					return (
						<Stack
							direction="row"
							justifyContent="center"
							alignItems="center"
							sx={{ ml: 2 }}
							spacing={1}
						>
							<Typography variant="h6" component="div">
								{"Tot. capi settimana: "}
								{totaleCapiSettimana}
							</Typography>

							<FormControlLabel
								label={
									<Typography>
										{contaLottiDubbi ? "compresi dubbi" : "esclusi dubbi"}{" "}
									</Typography>
								}
								control={
									<Switch
										id="switch_lotti_dubbi"
										checked={contaLottiDubbi}
										onChange={handleChangeLottiDubbi}
										color="primary"
										size="small"
									/>
								}
							/>
							{/* il pulsante per generare i lotti standard è stato commentato perchè non utilizzato e per evitare che venga cliccato per errore*/}
							{/* <Tooltip title="Aggiungi lotti standard">
								<Button
									size="small"
									variant="outlined"
									onClick={handleOpenStandard}
								>
									<DashboardCustomizeIcon />
								</Button>
							</Tooltip> */}
						</Stack>
					);
				}}
			/>
			<Grid container spacing={1} sx={{ marginTop: 1 }}>
				{/* Modal collegato al pulsante per aggiungere i lotti standard */}
				{/* <Modal open={openStandard} onClose={handleCloseStandard}>
					<Box sx={style}>
						<Typography id="modal-modal-title" variant="h6" component="h2">
							Sicuro di voler inserire i lotti standard?
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								py: 1,
								mt: 3,
								bgcolor: "background.paper",
								borderRadius: 1,
							}}
						>
							<Button onClick={handleCloseStandard} sx={{ pl: 0 }}>
								annulla
							</Button>
							<Button
								onClick={() => {
									handlerCreaLottiStandard(data);
								}}
								sx={{ pr: 0 }}
							>
								Aggiungi Lotti Standard
							</Button>
						</Box>
					</Box>
				</Modal> */}
				{/* <DndProvider backend={HTML5Backend}> */}
				{settimana.dateSettimana.map((giornoSettimana, i) => (
					<GiornoColumn
						key={i}
						giornoSettimana={giornoSettimana}
						lotti={lottiFiltroDubbio}
						tipiCapo={tipiCapo}
						// parametri={parametri}
						handleOpen={handleOpen}
						moveLotto={handlerMoveLotto}
						deleteLotto={handlerDeleteLotto}
						cambiaGiornoLotto={handlerCambiaGiornoLotto}
						consolidaOrdinamento={handlerConsolidaOrdinamento}
					/>
				))}
				{/* </DndProvider> */}
			</Grid>
		</>
	);
};

export default PianificazionePage;
