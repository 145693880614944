import React, {
	useState,
	useEffect,
	EventHandler,
	FocusEventHandler,
} from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "./../../../../redux/store";

import { Lotto } from "../../pianificazione/pianificazioneSlice";

import {
	Confezionamento,
	ConfezionamentiState,
	fetchConfezionamenti,
	saveConfezionamento,
} from "./confezionamentiSlice";
import ConfezionamentoForm from "./ConfezionamentoForm";
import { Fragment } from "react";
import moment from "moment";
import { on } from "process";

interface ConfezionamentoHeaderProps {
	lotto: Lotto;
	data: Date;
}
const ConfezionamentoHeader = ({ lotto, data }: ConfezionamentoHeaderProps) => {
	const dispatch = useAppDispatch();

	const confezionamentoLotto: Confezionamento | undefined = useSelector<
		RootState,
		Confezionamento | undefined
	>((state) =>
		state.confezionamentiState.confezionamenti.results.find(
			(confezionamento) => confezionamento.lotto == lotto.id
		)
	);

	useEffect(() => {
		const data_da = data;
		const data_a = data;
		dispatch(fetchConfezionamenti({ data_da, data_a }));
	}, [data]);

	const handlerSaveConfezionamento = (
		confezionamentoToSave: Confezionamento
	) => {
		const data_confezionamento_toSave: Date | string | undefined = data;
		const data_ora_inizio_confezionamento_toSave: Date | string = new Date(
			moment(data_confezionamento_toSave).format("YYYY-MM-DD") +
				"T" +
				moment(confezionamentoToSave.data_ora_inizio_confezionamento).format(
					"HH:mm:ss"
				)
		);
		const data_ora_fine_confezionamento_toSave: Date | string = new Date(
			moment(data_confezionamento_toSave).format("YYYY-MM-DD") +
				"T" +
				moment(confezionamentoToSave.data_ora_fine_confezionamento).format(
					"HH:mm:ss"
				)
		);
		dispatch(
			saveConfezionamento({
				...confezionamentoToSave,
				data_ora_inizio_confezionamento: data_ora_inizio_confezionamento_toSave,
				data_ora_fine_confezionamento: data_ora_fine_confezionamento_toSave,
			})
		);
	};

	function onEdit(e: any) {
		if (e.currentTarget !== e.target) {
			e.stopPropagation();
		}
	}

	return (
		<div onClick={onEdit}>
			{confezionamentoLotto && (
				<ConfezionamentoForm
					confezionamento={confezionamentoLotto}
					saveConfezionamento={handlerSaveConfezionamento}
				/>
			)}
		</div>
	);
};

export default ConfezionamentoHeader;
