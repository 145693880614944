import React from "react";

import { Utente } from "../../authentication/authenticationSlice";

import { PermissionTypes } from "../../authentication/permissionsGroups";

import { styled, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import Tooltip from "@mui/material/Tooltip";

const PREFIX = "SideMenu";

const classes = {
	iconSelected: `${PREFIX}-iconSelected`,
};

const StyledList = styled(List)(() => ({
	[`& .${classes.iconSelected}`]: {
		color: "#1b75b1",
	},
}));
const styleListItem = { justifyContent: "center" };

interface ElementoMenuControllatoProps {
	utente: Utente;
	path: string;
	label: string;
	gruppiAutorizzati: PermissionTypes[];
	open: boolean;
	selected: boolean;
	Icon: any;
	handleDrawerClose: () => void;
}
const ElementoMenuControllato = ({
	utente,
	path,
	label,
	open,
	gruppiAutorizzati,
	selected,
	Icon,
	handleDrawerClose,
}: ElementoMenuControllatoProps) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("md"));
	// const matches = useMediaQuery("min-width:900px)");
	const mobileHandleDrawerClose = () => {
		matches && handleDrawerClose();
	};

	return (
		<>
			{utenteAutorizzato(utente, gruppiAutorizzati) && (
				<ListItem
					component={Link}
					to={path}
					button
					key={path}
					sx={styleListItem}
				>
					<ListItemIcon style={{ minWidth: "auto" }}>
						<Tooltip title={label}>
							<Icon
								className={selected ? classes.iconSelected : ""}
								onClick={mobileHandleDrawerClose}
							/>
						</Tooltip>
					</ListItemIcon>
					{open && (
						<ListItemText
							style={{ marginLeft: 12 }}
							primary={label}
							className={selected ? classes.iconSelected : ""}
							onClick={mobileHandleDrawerClose}
						/>
					)}
				</ListItem>
			)}
		</>
	);
};

export default ElementoMenuControllato;
