import React from "react";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	ComposedChart,
	Line,
} from "recharts";

import { TipoCapo } from "../../anagrafiche/anagraficheSlice";

// const generaData = (
// 	kgCommittentiMese: KgCommittenti[],
// 	committenti: Committente[]
// ) => {
// 	const data: {}[] = [];

// 	// const mesi: string[] = [];
// 	// indicatoriTotaliCommittenti.map((mese_tipo_kg) => {
// 	// 	if (!mesi.includes(mese_tipo_kg.mese)) {
// 	// 		mesi.push(mese_tipo_kg.mese);
// 	// 	}
// 	// });

// 	committenti.map((committente) => {
// 		const record = {
// 			committente: committente.nome,
// 			peso_lotto_partenza: kgCommittentiMese
// 				.filter(
// 					(meseCommittente) => meseCommittente.committente == committente.id
// 				)
// 				.reduce(
// 					(previousValue, currentValue) =>
// 						previousValue + (currentValue.peso_lotto_partenza || 0),
// 					0
// 				),
// 			peso_lotto_arrivo: kgCommittentiMese
// 				.filter(
// 					(meseCommittente) => meseCommittente.committente == committente.id
// 				)
// 				.reduce(
// 					(previousValue, currentValue) =>
// 						previousValue + (currentValue.peso_lotto_arrivo || 0),
// 					0
// 				),
// 		};
// 		if (record.peso_lotto_arrivo > 0) {
// 			data.push(record);
// 		}
// 	});

// 	return { data: data };
// };

interface TipoCapoVelocitaProcessamentoGraficoProps {
	tipiCapo: TipoCapo[];
}
const TipoCapoVelocitaProcessamentoGrafico = ({
	tipiCapo,
}: TipoCapoVelocitaProcessamentoGraficoProps) => {
	return (
		<ResponsiveContainer>
			<ComposedChart
				// width={400}
				// height={200}
				// data={generaData(indicatoriTotaliCommittenti).data}
				data={tipiCapo}
				margin={{
					top: 8,
					right: 5,
					left: 25,
					bottom: 5,
				}}
				layout="vertical"
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis
					type="number"
					tickFormatter={(value) =>
						new Intl.NumberFormat("it-IT").format(value)
					}
					fontSize={13}
				/>
				<YAxis dataKey="nome" type="category" />
				<Tooltip
					formatter={(value) => {
						if (typeof value == "number") {
							return Intl.NumberFormat("it-IT").format(value).toString();
						} else {
							return value;
						}
					}}
					wrapperStyle={{ fontSize: 13 }}
				/>

				<Legend wrapperStyle={{ fontSize: 13 }} />
				<Bar key="key_kg" dataKey="velocita_processamento" fill={"#39a5f0"} />
				<Bar dataKey="calcolo_velocita_processamento" fill="#ff7300" />
				{/* <Bar dataKey="calcolo_velocita_processamento_pesata" fill="#a7a7e1" /> */}
			</ComposedChart>
		</ResponsiveContainer>
	);
};
export default TipoCapoVelocitaProcessamentoGrafico;
