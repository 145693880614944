import { DateTime } from "luxon";

/**
 * Converte un input di tipo string, Date, DateTime, null o undefined in un oggetto DateTime di Luxon,
 * impostando la locale su italiano. Restituisce null se l'input non è valido o non specificato.
 * @param input La data come stringa, Date, DateTime, null o undefined.
 * @returns Un oggetto DateTime configurato in italiano o null se l'input non è valido o non specificato.
 */
export function convertToDateTime(
	input?: string | Date | DateTime | null | undefined
): DateTime | null {
	if (input === undefined || input === null) {
		return null;
	} else if (input instanceof DateTime) {
		// Se l'input è già un DateTime, imposta solo la locale su "it"
		return input.setLocale("it");
	} else if (input instanceof Date) {
		// Crea DateTime da un oggetto Date e imposta la locale su "it"
		return DateTime.fromJSDate(input).setLocale("it");
	} else if (typeof input === "string") {
		// Tentativo di analisi della stringa assumendo formato ISO e imposta la locale su "it"
		const parsedDate = DateTime.fromISO(input).setLocale("it");
		return parsedDate.isValid ? parsedDate : null;
	}

	return null;
}
