import React from "react";
import { useEffect } from "react";

import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";
import moment from "moment";

import AllegatiLottoCommittenteBox from "./AllegatiLottoCommittenteBox";
import Grid from "@mui/material/Grid";

import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

import { UtenteLogged } from "../../../authentication/authenticationSlice";
import LottoProduttorePageBreadcrumbs from "./LottoCommittentePageBreadcrumbs";
import {
	Resa,
	fetchLavorazioniLotto,
	getResa,
} from "../../reportistica/rese/reseSlice";
import { Lavorazione } from "../../lavorazioni/lavorazioniSlice";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LottoCommittenteIntestazione from "./LottoCommittenteIntestazione";
import LottoCommittenteLavorazioniTable from "./LottoCommittenteLavorazioniTable";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
const logoScarso = require("../../../../../static/images/logo/logo-Scarso_nuovo_solo.png");

const LottoCommittentePage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	const { lotto_id } = useParams();
	const lottoId: number = parseInt(lotto_id || "");

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);

	const lottoCommittente = useSelector<RootState, Resa | undefined>((state) =>
		state.reseState.rese.results.find((resa) => resa.id == lottoId)
	);
	const lavorazioni = useSelector<RootState, Lavorazione[] | undefined>(
		(state) => state.reseState.lavorazioniLotto.results
	);

	useEffect(() => {
		dispatch(getResa(lottoId));
		dispatch(fetchLavorazioniLotto(lottoId));
	}, [lottoId]);

	return (
		<Box>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={
					false
					// lottoCommittente.errorsStack.status == ErrorStatusTypes.PENDING ||
					// errorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<LottoProduttorePageBreadcrumbs />
			{lottoCommittente && (
				<Card raised sx={{ mt: 2 }}>
					<Box sx={{ backgroundColor: "#2368a1" }}>
						<CardContent sx={{ mb: 2 }}>
							<Stack
								direction="row"
								justifyContent="flex-start"
								alignItems="flex-start"
								spacing={4}
								sx={{ mb: 1 }}
							>
								<Avatar
									sx={{
										width: "3rem",
										height: "3rem",
									}}
									aria-label="recipe"
								>
									<img
										src={logoScarso}
										style={{
											width: "80%",
											padding: 0,
										}}
									/>
								</Avatar>
								<Typography
									component="h3"
									variant="h5"
									sx={{ color: "#e6f4ff" }}
								>
									{lottoCommittente.committente_desc} - Resa macellazione
								</Typography>

								<Typography
									component="h3"
									variant="h5"
									sx={{ color: "#e6f4ff" }}
								>
									{moment(lottoCommittente.data_lavorazione).format(
										"dddd DD MMMM YYYYY"
									)}
								</Typography>

								<Typography
									component="h3"
									variant="h5"
									sx={{ color: "#e6f4ff" }}
								>
									{lottoCommittente.ordine_cliente &&
										" ord. consegna " + lottoCommittente.ordine_cliente}
								</Typography>
							</Stack>
						</CardContent>
					</Box>
					<CardContent sx={{ mb: 2 }}>
						{lottoCommittente && (
							<LottoCommittenteIntestazione
								resa={lottoCommittente}
								utente={utente}
							/>
						)}
					</CardContent>
					<CardContent sx={{ mb: 2 }}>
						<Grid container spacing={4}>
							{lavorazioni && (
								<Grid item md={12} lg={7}>
									<LottoCommittenteLavorazioniTable lavorazioni={lavorazioni} />
								</Grid>
							)}

							{lottoCommittente && (
								<Grid item md={12} lg={5}>
									<AllegatiLottoCommittenteBox lotto={lottoCommittente} />
								</Grid>
							)}
						</Grid>
					</CardContent>
				</Card>
			)}
		</Box>
	);
};

export default LottoCommittentePage;
