import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../redux/store";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {
	LottiState,
	fetchLotti,
	resetLotti,
	Lotto,
} from "../../../pianificazione/pianificazioneSlice";
import { DATA_PROGRAMMA_DI_INCASSETTAMENTO_CORRENTE } from "../../../../../api/apiUtils";
import NavigazioneGiorniToolbar from "../../../../common/dateUtils/NavigazioneGiorniToolbar";
// import DateToday from "../../../../common/dateUtils/DateToday";

import {
	RecuperaDataCorrente,
	FissaDataCorrente,
} from "../../../../common/dateUtils/RecuperaDataCorrente";

import LottoLavorazioniPdf from "./LottoLavorazioniPdf";
import { ErrorStatusTypes } from "../../../../common/errorsDeclarations";
import { PDFViewer, Page, Document, pdf } from "@react-pdf/renderer";

import moment from "moment";

import { Box } from "@mui/system";

interface ProgammaIncassettamentoProps {
	lotti: Lotto[];
}

const ProgammaIncassettamento = ({ lotti }: ProgammaIncassettamentoProps) => {
	return (
		<Document>
			{lotti.map((lotto) => {
				return (
					<Page
						size="A4"
						orientation="landscape"
						style={{ backgroundColor: "#fff", paddingTop: 20 }}
						key={"ProgammaIncassettamento_lotto_" + lotto.id}
					>
						<LottoLavorazioniPdf lotto={lotto} />
					</Page>
				);
			})}
		</Document>
	);
};

const ProgammaIncassettamentoStampaPage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	const lotti: LottiState = useSelector<RootState, LottiState>(
		(state) => state.pianificazione.lotti
	);

	type LocationState = {
		giornoSettimana: Date;
	};
	const location = useLocation();
	const recupera_data_corrente = RecuperaDataCorrente(location);

	// const recupera_data_corrente = () => {
	// 	if (location.state) {
	// 		const { giornoSettimana } = location.state as LocationState;
	// 		if (giornoSettimana) {
	// 			return giornoSettimana;
	// 		}
	// 	}
	// 	const dataLavorazioniCorrente = localStorage.getItem(
	// 		DATA_PROGRAMMA_DI_INCASSETTAMENTO_CORRENTE
	// 	);
	// 	if (!!dataLavorazioniCorrente) {
	// 		return new Date(dataLavorazioniCorrente);
	// 	}
	// 	return DateToday();
	// };

	const [data, setData] = useState<Date | undefined>(recupera_data_corrente);

	const [refresh, setRefresh] = useState<boolean>(false);

	useEffect(() => {
		data && FissaDataCorrente(data);

		// localStorage.setItem(
		// 	DATA_PROGRAMMA_DI_INCASSETTAMENTO_CORRENTE,
		// 	moment(data).format("YYYY-MM-DD")
		// );
		const data_da = data;
		const data_a = data;
		dispatch(resetLotti());
		dispatch(fetchLotti({ data_da, data_a }));
		setRefresh(false);
	}, [data, refresh]);

	return (
		<Box>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={lotti.errorsStack.status == ErrorStatusTypes.PENDING}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<NavigazioneGiorniToolbar
				titolo={"Programma Incassettamento"}
				setData={setData}
				data={data}
				refresh={() => {
					setRefresh(true);
				}}
			/>
			<Box sx={{ backgroundColor: "#999", height: "80vh" }}>
				<PDFViewer width={"100%"} height={"100%"}>
					<ProgammaIncassettamento lotti={lotti.results} />
				</PDFViewer>
			</Box>
		</Box>
	);
};

export default ProgammaIncassettamentoStampaPage;
