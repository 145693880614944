import { handleResponse, handleError, headerset } from "./apiUtils";
import {
	User,
	Utente,
	ChangePasswordProps,
	ResetPasswordProps,
} from "../components/authentication/authenticationSlice";

const baseUrl = process.env.API_URL + "/api/";

export function userCreate() {
	// return fetch(baseUrl).then(handleResponse).catch(handleError);
}

export function tokenObtain(user: User) {
	return fetch(baseUrl + "token/obtain/", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
		body: JSON.stringify({
			username: user.username,
			password: user.password,
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

import store from "../redux/store";

function getAccessToken() {
	const state = store.getState(); // Ottieni lo stato corrente
	return state.userPreferenceState.userStored?.access;
}

function getRefresh_token() {
	const state = store.getState(); // Ottieni lo stato corrente
	return state.userPreferenceState.userStored?.refresh;
}

export function tokenRefresh() {
	return fetch(baseUrl + "token/refresh/", {
		method: "POST",
		headers: {
			Authorization: "JWT " + getAccessToken(),
			"Content-Type": "application/json",
			Accept: "application/json",
		},
		body: JSON.stringify({
			refresh: getRefresh_token(),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getUtente() {
	return fetch(baseUrl + "me/", {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveUser(utente: Utente) {
	return fetch(baseUrl + "me/", {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...utente,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function changePassword(passwordStack: ChangePasswordProps) {
	return fetch(baseUrl + "me/change_password", {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...passwordStack,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function requestPasswordResetEmail(email: string) {
	return fetch(baseUrl + "request-password-reset/", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			// Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			email: email,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function passwordReset({
	uidb64,
	token,
	new_password,
	new_password2,
}: ResetPasswordProps) {
	return fetch(baseUrl + "password-reset/" + uidb64 + "/" + token + "/", {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			// Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			new_password: new_password,
			new_password2: new_password2,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function passwordResetCheck({
	uidb64,
	token,
	new_password,
	new_password2,
}: ResetPasswordProps) {
	return fetch(baseUrl + "password-reset/" + uidb64 + "/" + token + "/", {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			// Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
