import {
	Lotto,
	ColoreTypes,
	LottoConAttrezzaggio,
} from "../pianificazioneSlice";
import { Parametri, TipoCapo } from "../../anagrafiche/anagraficheSlice";
import { SchedaCarico } from "components/main/schedeCarico/schedeCaricoSlice";

export const lottoForiPerCamion = (lotto: Lotto): number => {
	if (lotto.allevamento && lotto.allevamento_pack?.consente_rimorchio) {
		return 160;
	}
	if (lotto.allevamento && !lotto.allevamento_pack?.consente_rimorchio) {
		return 80;
	}
	return 160; //default considero camion con rimorchio - Silvia comm
};

export const foriImpegnati = (lotto: Lotto): number => {
	// lotto.numero_fori== undefined || lotto.numero_fori== null && return 80;
	if (lotto.numero_fori && lotto.numero_fori > 80 && lotto.numero_fori <= 96) {
		return 96;
	}
	if (lotto.numero_fori) {
		return Math.ceil(lotto.numero_fori / 80) * 80;
	}
	return 80;
};

export const percentualeSchedulazione = (lotto: Lotto): number => {
	if (lotto.fori_schedulati && lotto.numero_fori) {
		return Math.floor((lotto.fori_schedulati * 100) / lotto.numero_fori);
	} else {
		return 0;
	}
};

export const camionNecessari = (lotto: Lotto): number => {
	return Math.ceil((lotto.numero_fori || 0) / lottoForiPerCamion(lotto));
};

export const schedeCaricoVereOPresunte = (
	lotto: Lotto
): { statoScheda: number }[] => {
	const schedeCaricoVereOPresunte: { statoScheda: number }[] = [];
	lotto.schede_carico.length > 0
		? lotto.schede_carico.map((scheda) => {
				schedeCaricoVereOPresunte.push({
					// statoScheda: 0,
					statoScheda: scheda.stato_schedulazione.stato,
				});
		  })
		: Array(camionNecessari(lotto))
				.fill({
					statoScheda: 0,
				})
				.map((schedaPresunta) =>
					schedeCaricoVereOPresunte.push(schedaPresunta)
				);
	return schedeCaricoVereOPresunte;
};

export const autistiNecessari = (
	lotto: Lotto,
	parametri: Parametri
): number => {
	if (
		(lotto.allevamento_pack?.distanza || 0) < parametri.massimo_km_autista_unico
	) {
		return 1;
	} else {
		return 2;
	}
};

export const getColore = (lotto: Lotto) => {
	const coloreType = Object.entries(ColoreTypes).find(
		([key, value]) => key == lotto.colore
	);
	if (coloreType != undefined) {
		return coloreType[1];
	} else {
		return "";
	}
};

export const lottiConAttrezzaggio = (
	lotti: Lotto[],
	tipiCapo: TipoCapo[],
	parametri?: Parametri
): LottoConAttrezzaggio[] => {
	const lottiRiordinati = [...lotti];
	lottiRiordinati.sort((a, b) => {
		if (
			new Date(a.scheda_macello?.data_ora_inizio_macello || "") <
			new Date(b.scheda_macello?.data_ora_inizio_macello || "")
		) {
			return -1;
		}
		return 1;
	});

	const lottiConAttrezzaggio: LottoConAttrezzaggio[] = lottiRiordinati.map(
		(lotto, index) => {
			let tempo_attrezzaggio: number = parametri?.attrezzaggio_standard || 4;
			try {
				const tipoCapoPrecedente_id = lottiRiordinati[index - 1].tipo_capo;
				const tipoCapoPrecedente = tipiCapo.find(
					(tipoCapo) => tipoCapo.id == tipoCapoPrecedente_id
				);
				const tipoCapoCorrente = tipiCapo.find(
					(tipoCapo) => tipoCapo.id == lotto.tipo_capo
				);
				if (tipoCapoPrecedente == tipoCapoCorrente) {
					tempo_attrezzaggio = parametri?.attrezzaggio_standard || 4;
				} else if (
					(tipoCapoPrecedente?.minuti_attrezzaggio || 0) >
					(tipoCapoCorrente?.minuti_attrezzaggio || 0)
				) {
					tempo_attrezzaggio = tipoCapoPrecedente?.minuti_attrezzaggio || 0;
				} else {
					tempo_attrezzaggio = tipoCapoCorrente?.minuti_attrezzaggio || 0;
				}
			} catch (err) {
				tempo_attrezzaggio = 0;
			}

			return { ...lotto, tempo_attrezzaggio: tempo_attrezzaggio };
		}
	);

	return lottiConAttrezzaggio;
};
