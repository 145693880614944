import { handleResponse, handleError, headerset } from "../apiUtils";

const baseUrl = process.env.API_URL + "/api/anagrafiche/parametri/";

export function fetchParametri() {
  return fetch(baseUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: headerset().Authorization,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}
