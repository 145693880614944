import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../redux/store";

import {
	LottiState,
	fetchLotti,
	resetLotti,
} from "../../../pianificazione/pianificazioneSlice";
// import { DATA_PIANIFICAZIONE_LAVORAZIONI_CORRENTE } from "../../../../../api/apiUtils";

import SchedaMacellazionePdf from "./SchedaMacellazionePdf";
import NavigazioneGiorniToolbar from "../../../../common/dateUtils/NavigazioneGiorniToolbar";

import moment from "moment";

import {
	RecuperaDataCorrente,
	FissaDataCorrente,
} from "../../../../common/dateUtils/RecuperaDataCorrente";

import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// import DateToday from "../../../../common/dateUtils/DateToday";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

interface SchedaMacellazioneStampaPageProps {}

const SchedaMacellazioneStampaPage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();
	const lotti: LottiState = useSelector<RootState, LottiState>(
		(state) => state.pianificazione.lotti
	);

	const anagraficheErrorsStack: ErrorsStack = useSelector<
		RootState,
		ErrorsStack
	>((state) => state.anagrafiche.errorsStack);

	type LocationState = {
		giornoSettimana: Date;
	};
	const location = useLocation();
	const recupera_data_corrente = RecuperaDataCorrente(location);

	// const recupera_data_corrente = () => {
	// 	if (location.state) {
	// 		const { giornoSettimana } = location.state as LocationState;
	// 		if (giornoSettimana) {
	// 			return giornoSettimana;
	// 		}
	// 	}
	// 	const dataLavorazioniCorrente = localStorage.getItem(
	// 		DATA_PIANIFICAZIONE_LAVORAZIONI_CORRENTE
	// 	);
	// 	if (!!dataLavorazioniCorrente) {
	// 		return new Date(dataLavorazioniCorrente);
	// 	}
	// 	return DateToday();
	// };

	const [data, setData] = useState<Date | undefined>(recupera_data_corrente);

	const [refresh, setRefresh] = useState<boolean>(false);

	useEffect(() => {
		data && FissaDataCorrente(data);

		// localStorage.setItem(
		// 	DATA_PIANIFICAZIONE_LAVORAZIONI_CORRENTE,
		// 	moment(data).format("YYYY-MM-DD")
		// );
		const data_da = data;
		const data_a = data;
		dispatch(resetLotti());
		dispatch(fetchLotti({ data_da, data_a }));
		// dispatch(fetchCamions(data));
		// dispatch(fetchAutisti(data));
		setRefresh(false);
	}, [data, refresh]);

	return (
		<Box>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					lotti.errorsStack.status == ErrorStatusTypes.PENDING ||
					anagraficheErrorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<NavigazioneGiorniToolbar
				titolo={"Scheda Macellazione"}
				setData={setData}
				data={data}
				refresh={() => {
					setRefresh(true);
				}}
			/>
			<Box sx={{ backgroundColor: "#999", height: "80vh" }}>
				{lotti.results.length > 0 ? (
					<SchedaMacellazionePdf lotti={lotti.results} />
				) : (
					<Typography variant="h4" gutterBottom sx={{ p: 30 }} align="center">
						Nessuna lavorazione prevista per {moment(data).format("dddd LL")} .
					</Typography>
				)}
			</Box>
		</Box>
	);
};

export default SchedaMacellazioneStampaPage;
