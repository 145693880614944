import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as utenteApi from "../../api/utenteApi";
import { toast } from "react-toastify";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../common/errorsDeclarations";
import { PermissionTypes } from "./permissionsGroups";
import {
	setUserStored,
	tokenRefresh,
	resetUserStored,
	UserStored,
} from "../userPreference/userPreferenceSlice";

export interface User {
	username: string;
	password: string;
}
export interface Token {
	errorsStack: ErrorsStack;
}

export interface Utente {
	id?: string;
	username?: string;
	first_name?: string;
	last_name?: string;
	email?: string;
	gruppi?: PermissionTypes[];
	committente?: number;
	produttore?: number;
	isLogged?: boolean;
	is_staff: boolean;
	is_superuser: boolean;
	autista?: number;
}

export interface UtenteLogged extends Utente {
	errorsStack: ErrorsStack;
}
export interface AuthenticationState {
	minutiRefresh: number;
	minutiRefreshAnagrafiche: number;
	token: Token;
	minutiRefreshLotti: number;
	utenteLogged: UtenteLogged;
}

export interface ChangePasswordProps {
	old_password: string;
	new_password: string;
	new_password2: string;
}

export interface ResetPasswordProps {
	uidb64: string;
	token: string;
	new_password: string;
	new_password2: string;
}

const initialState: AuthenticationState = {
	minutiRefresh: 6,
	minutiRefreshAnagrafiche: 15,
	minutiRefreshLotti: 0.2,
	token: {
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	utenteLogged: {
		id: undefined,
		username: "anonimo",
		first_name: undefined,
		last_name: undefined,
		email: undefined,
		gruppi: undefined,
		isLogged: false,
		is_staff: false,
		is_superuser: false,
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const utenteTokenObtain = createAsyncThunk(
	"utente/utenteTokenObtain",
	async (user: User, thunkApi) => {
		return await utenteApi
			.tokenObtain(user)
			.then((token) => {
				const userStored: UserStored = {
					username: user.username,
					access: token.access,
					refresh: token.refresh,
				};
				thunkApi.dispatch(setUserStored(userStored));
				toast.success("Successful Sign-in.");
				thunkApi.dispatch(getUtente());
			})
			.catch((error) => {
				thunkApi.dispatch(resetUserStored());
				throw error;
			});
	}
);

export const utenteTokenRefresh = createAsyncThunk(
	"utente/utenteTokenRefresh",
	async (_, thunkApi) => {
		return await utenteApi
			.tokenRefresh()
			.then((response) => {
				thunkApi.dispatch(tokenRefresh(response));
				thunkApi.dispatch(getUtente());
				return response;
			})
			.catch((error) => {
				thunkApi.dispatch(resetUtente());
				const message = error.toString();
				toast.error(message || "Login Error", { autoClose: 7000 });
				thunkApi.dispatch(resetUserStored());

				throw error;
			});
		//   return user;
	}
);

export const getUtente = createAsyncThunk(
	"utente/getUtente",
	async (_, thunkApi) => {
		return await utenteApi
			.getUtente()
			.then((response) => {
				return response;
			})
			.catch((error) => {
				thunkApi.dispatch(resetUtente());
				const message = error.toString();
				toast.error(message || "Login Error", { autoClose: 7000 });
				throw error;
			});
		//   return user;
	}
);

export const saveUtente = createAsyncThunk(
	"utente/saveUtente",
	async (utente: Utente) => {
		return await utenteApi.saveUser(utente);
		//   return user;
	}
);

export const changePassword = createAsyncThunk(
	"utente/changePassword",
	async (passwordStack: ChangePasswordProps) => {
		return await utenteApi.changePassword(passwordStack);
		//   return user;
	}
);

export const requestPasswordResetEmail = createAsyncThunk(
	"utente/requestPasswordResetEmail",
	async (email: string) => {
		return await utenteApi.requestPasswordResetEmail(email);
		//   return user;
	}
);

export const passwordResetCheck = createAsyncThunk(
	"utente/passwordResetCheck",
	async (resetPassword: ResetPasswordProps) => {
		return await utenteApi.passwordResetCheck(resetPassword);
		//   return user;
	}
);
export const passwordReset = createAsyncThunk(
	"utente/passwordReset",
	async (resetPassword: ResetPasswordProps) => {
		return await utenteApi.passwordReset(resetPassword);
		//   return user;
	}
);

export const authenticationSlice = createSlice({
	name: "authentication",
	initialState,
	reducers: {
		resetUtente: (state) => {
			state.utenteLogged = initialState.utenteLogged;
			resetUserStored();
			toast.warning("Successful Sign-out.");
		},
		resetErrorStatus: (state) => {
			state.utenteLogged = initialState.utenteLogged;
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.OK };
		},
	},
	extraReducers: (builder) => {
		// get Token
		builder.addCase(utenteTokenObtain.pending, (state, action) => {
			state.token.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(utenteTokenObtain.fulfilled, (state, action) => {
			state.token.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(utenteTokenObtain.rejected, (state, action) => {
			const errorsStack = parseErrorMessage(action.error);
			state.token.errorsStack = errorsStack;
			if (errorsStack.fieldsErrors) {
				const messaggioErrore: string =
					errorsStack.fieldsErrors?.["detail"]?.toString() ??
					"Problemi di autenticazione";
				toast.error(messaggioErrore, {
					autoClose: 7000,
				});
			}
		});

		// refresh Token
		builder.addCase(utenteTokenRefresh.pending, (state, action) => {
			state.token.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(utenteTokenRefresh.fulfilled, (state, action) => {
			state.token.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(utenteTokenRefresh.rejected, (state, action) => {
			// state = initialState;

			state.token.errorsStack = parseErrorMessage(action.error);
		});

		// get utente
		builder.addCase(getUtente.pending, (state, action) => {
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(getUtente.fulfilled, (state, action) => {
			state.utenteLogged = { ...action.payload, isLogged: true };
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getUtente.rejected, (state, action) => {
			state = initialState;

			toast.error("Errore....." + action.error);
			state.utenteLogged.errorsStack = parseErrorMessage(action.error);
		});

		/////////////////////////////////////////
		// save utente
		builder.addCase(saveUtente.pending, (state, action) => {
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(saveUtente.fulfilled, (state, action) => {
			state.utenteLogged = {
				...action.payload,
				isLogged: state.utenteLogged.isLogged,
			};
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.OK };
			toast.success("Profilo aggiornato.");
		});
		builder.addCase(saveUtente.rejected, (state, action) => {
			state.utenteLogged.errorsStack = parseErrorMessage(action.error);
		});
		/////////////////////////////////////////
		// change password utente
		builder.addCase(changePassword.pending, (state, action) => {
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(changePassword.fulfilled, (state, action) => {
			// state.utenteLogged = {
			//   ...action.payload,
			//   isLogged: state.utenteLogged.isLogged,
			// };
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Password aggiornata.");
		});
		builder.addCase(changePassword.rejected, (state, action) => {
			state.utenteLogged.errorsStack = parseErrorMessage(action.error);
			toast.error("Errore: " + JSON.stringify(action?.error?.message || ""));
		});
		/////////////////////////////////////////
		// change password utente
		builder.addCase(requestPasswordResetEmail.pending, (state, action) => {
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(requestPasswordResetEmail.fulfilled, (state, action) => {
			// state.utenteLogged = {
			// 	...action.payload,
			// 	//   isLogged: state.utenteLogged.isLogged,
			// };
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Richiesta inviata.");
		});
		builder.addCase(requestPasswordResetEmail.rejected, (state, action) => {
			state.utenteLogged.errorsStack = parseErrorMessage(action.error);
		});
		/////////////////////////////////////////
		// change password utente
		builder.addCase(passwordResetCheck.pending, (state, action) => {
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(passwordResetCheck.fulfilled, (state, action) => {
			state.utenteLogged = {
				...action.payload,
				//   isLogged: state.utenteLogged.isLogged,
			};
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(passwordResetCheck.rejected, (state, action) => {
			state.utenteLogged.errorsStack = parseErrorMessage(action.error);
			toast.error("Errore: " + JSON.stringify(action?.error?.message || ""));
		});
		/////////////////////////////////////////
		// change password utente
		builder.addCase(passwordReset.pending, (state, action) => {
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(passwordReset.fulfilled, (state, action) => {
			state.utenteLogged.errorsStack = { status: ErrorStatusTypes.SUCCESS };
			toast.success("Pasword modificata");
		});
		builder.addCase(passwordReset.rejected, (state, action) => {
			state.utenteLogged.errorsStack = parseErrorMessage(action.error);
		});
	},
});

// Action creators are generated for each case reducer function
export const { resetUtente, resetErrorStatus } = authenticationSlice.actions;

export const authenticationReducer = authenticationSlice.reducer;
