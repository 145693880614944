import React, { useState, useEffect } from "react";
import { Lotto } from "./pianificazioneSlice";

import Avatar from "@mui/material/Avatar";
import CardMui from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { styled, Box } from "@mui/system";
import { theme } from "../../../theme";

import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import {
	foriImpegnati,
	schedeCaricoVereOPresunte,
} from "./utility/lottoUtility";
import { getColore } from "../pianificazione/utility/lottoUtility";

import Button from "@mui/material/Button";
import ConfermaAnnulla from "../../common/ConfermaAnnulla";

import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { Typography } from "@mui/material";

const Card = styled(CardMui)(({ theme }) => ({
	display: "flex:1",
	// "& > *": {
	// margin: theme.spacing(1),
	align: "center",
	marginBottom: theme.spacing(0.7),
	// },
}));

interface LottoCardProps {
	lotto: Lotto;
	deleteLotto: (lottoToDelete: Lotto) => void;
	handleOpen: any;
}
const LottoCard = ({ lotto, deleteLotto, handleOpen }: LottoCardProps) => {
	//  ######################################################
	//  ##### Gestione conferma cancellazione  #############
	//  ######################################################
	const handlerDeleteLotto = () => {
		deleteLotto(lotto);
		handleConfermaClose();
	};
	const [openConferma, setOpenConferma] = React.useState(false);
	const handleModalopenConfermaOpen = () => setOpenConferma(true);
	const handleConfermaClose = () => setOpenConferma(false);
	//  ######################################################

	// const loopIconeCamion = [...Array(schedeCaricoVereOPresunte(lotto))];
	const subheader = (
		<div>
			{lotto.codice_tracciabilita}
			<br />
			<em>{lotto.produttore_pack?.nome}</em>
		</div>
	);

	return (
		<Card
			sx={{
				border: "4px",
				borderStyle: "solid",
				backgroundColor: lotto.in_dubbio
					? "rgba(250, 250, 250, 0.6)"
					: "background.paper",
				borderColor: lotto.in_dubbio
					? "rgba(250, 250, 250, 0)"
					: lotto.stato_lotto.stato == 4
					? "red"
					: lotto.stato_lotto.stato == 3
					? "lightgreen"
					: lotto.stato_lotto.stato == 2
					? "rgba(91, 188, 247, 1)"
					: lotto.stato_lotto.stato == 1
					? "#ffdc10"
					: "background.paper",
			}}
		>
			<CardHeader
				sx={{ pb: 1, pt: 1 }}
				avatar={
					<IconButton
						sx={{ ml: -1 }}
						onClick={handleOpen(lotto.id, null)}
						aria-label="delete"
					>
						<Avatar
							sx={{
								width: "1.5rem",
								height: "1.5rem",
								bgcolor: lotto.resa_confermata
									? theme.palette.success.main
									: theme.palette.primary.main,
								color: "white",
							}}
							aria-label="recipe"
						>
							{lotto.ordinamento_lotto}
						</Avatar>
					</IconButton>
				}
				action={
					<IconButton
						aria-label="settings"
						onClick={handleModalopenConfermaOpen}
					>
						<DeleteForeverIcon />
					</IconButton>
				}
				title={lotto.committente_desc}
				// subheader={lotto.codice_tracciabilita}
				subheader={subheader}
			/>
			<CardContent
				sx={{
					py: 0,
					"&:last-child": { pb: 1 },
				}}
			>
				{Intl.NumberFormat("it-IT").format(lotto.numero_capi || 0) +
					" - " +
					lotto.tipo_capo_desc +
					" " +
					(lotto.colore ? " " + getColore(lotto) : "")}
				<br />
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
				>
					<Box>{"fori: " + lotto.numero_fori + "/" + foriImpegnati(lotto)}</Box>
					<Box>
						{schedeCaricoVereOPresunte(lotto).map((scheda, i) => (
							<LocalShippingOutlinedIcon
								key={"icona_scheda_carico" + i}
								sx={{
									mb: "-0.25rem",
									ml: "0.2rem",
									color: lotto.viaggio_lungo
										? "#800080"
										: scheda.statoScheda == 1
										? "#ffdc10"
										: scheda.statoScheda == 2
										? "green"
										: scheda.statoScheda == 3
										? "red"
										: "#00000055",
								}}
							/>
						))}
					</Box>
					{lotto.in_dubbio && (
						<Box>
							<Tooltip
								title={lotto.note_dubbio ? lotto.note_dubbio : ""}
								sx={{ opacity: lotto.in_dubbio ? 1 : 0 }}
								placement="left-start"
								disableFocusListener
								enterTouchDelay={10}
								arrow
							>
								<ContactSupportIcon
									sx={{
										color: "#1b75b1",
										// opacity: lotto.in_dubbio ? 1 : 0,
										mr: "-0.125rem",
										flexGrow: 1,
									}}
								/>
							</Tooltip>
						</Box>
					)}
					{lotto.stato_lotto.stato == 4 && (
						<Box>
							<Tooltip
								title={lotto.stato_lotto.messaggio}
								sx={{ opacity: lotto.in_dubbio ? 1 : 0 }}
								placement="left-start"
								disableFocusListener
								enterTouchDelay={10}
								arrow
							>
								<ContactSupportIcon
									sx={{
										color: "red",
										borderColor: "white",
										// opacity: lotto.in_dubbio ? 1 : 0,
										mr: "-0.125rem",
										flexGrow: 1,
									}}
								/>
							</Tooltip>
						</Box>
					)}
				</Stack>
			</CardContent>

			<ConfermaAnnulla
				handleAnnulla={handleConfermaClose}
				handleConferma={handlerDeleteLotto}
				domanda={
					"Sicuro di voler cancellare il lotto " +
					(lotto.codice_tracciabilita || lotto.committente_desc)
				}
				open={openConferma}
			/>
		</Card>
	);
};

export default LottoCard;
