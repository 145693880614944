import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";

import { Lotto, ColoreTypes } from "./pianificazioneSlice";
import {
	TipoCapo,
	Committente,
	Produttore,
	Allevamento,
} from "../anagrafiche/anagraficheSlice";

import { useForm, SubmitHandler, Controller } from "react-hook-form";

import { ForiImpegnati } from "../../common/CalcoloCaricoAmmissibile";

import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import ReactHookFormSelect from "../../common/ReactHookFormSelect";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import Alert from "../../common/Alert";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

interface LottoFormProps {
	lottoEdited: Lotto;
	saveLotto: (lotto: Lotto, poiChiudi: boolean) => void;
	handleAnnulla: () => void;
	setStyleBkg: (in_dubbio: boolean) => void;
	committenti: Committente[];
	produttori: Produttore[];
	allevamenti: Allevamento[];
	tipiCapo: TipoCapo[];
}

const LottoForm = ({
	lottoEdited,
	saveLotto,
	handleAnnulla,
	setStyleBkg,
	committenti,
	produttori,
	allevamenti,
	tipiCapo,
}: LottoFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Lotto>();
	const listaCampi = Object.keys(lottoEdited) as Array<keyof Lotto>;

	// #############################################
	// Gestione calcolo numero fori
	// #############################################
	const [basatoSulNumFori, setBasatoSulNumFori] = useState(false);
	// const [inDubbio, setInDubbio] = useState(lottoEdited.in_dubbio);

	React.useEffect(() => {
		const numero_capi = getValues("numero_capi");
		if (numero_capi) {
			if (!basatoSulNumFori) {
				const numero_capi_foro = getValues("numero_capi_foro");
				numero_capi_foro &&
					numero_capi_foro > 0 &&
					setValue("numero_fori", Math.ceil(numero_capi / numero_capi_foro));
			} else {
				const numero_fori = getValues("numero_fori");
				numero_fori &&
					numero_fori > 0 &&
					setValue("numero_capi_foro", Math.ceil(numero_capi / numero_fori));
			}
		}
	}, [watch("numero_capi")]);

	const committenteCorrente: Committente | undefined = useSelector<
		RootState,
		Committente | undefined
	>((state) =>
		state.anagrafiche.committenti.results.find(
			(committente) => committente.id == getValues("committente")
		)
	);

	React.useEffect(() => {
		committenteCorrente &&
			!committenteCorrente.gestisce_colore &&
			setValue("colore", null);
	}, [committenteCorrente]);

	React.useEffect(() => {
		const numero_fori = getValues("numero_fori");
		const numero_capi = getValues("numero_capi");
		const numero_capi_foro = getValues("numero_capi_foro");
		if (numero_capi) {
			if (basatoSulNumFori) {
				numero_capi &&
					numero_fori &&
					setValue("numero_capi_foro", Math.ceil(numero_capi / numero_fori));
			} else {
				numero_capi_foro &&
					numero_capi_foro > 0 &&
					setValue("numero_fori", Math.ceil(numero_capi / numero_capi_foro));
			}
		}
	}, [watch("numero_fori"), watch("numero_capi_foro")]);

	React.useEffect(() => {
		const tipo_capo = getValues("tipo_capo");
		const numero_capi_foro = watch("numero_capi_foro");
		if (
			tipo_capo != lottoEdited.tipo_capo ||
			(tipo_capo == lottoEdited.tipo_capo &&
				numero_capi_foro != lottoEdited.numero_capi_foro)
		) {
			const numero_capi_foro: number =
				tipiCapo.find((tipoCapo: TipoCapo) => tipoCapo.id == tipo_capo)
					?.numero_capi_foro || 30;
			setValue("numero_capi_foro", numero_capi_foro);
		}
	}, [watch("tipo_capo")]);
	// #############################################

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, lottoEdited[field]);
		});
	}, [
		listaCampi.forEach((field) => {
			lottoEdited[field];
		}),
		lottoEdited.id,
	]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			lottoEdited?.errorsStack?.fieldsErrors &&
				lottoEdited.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: lottoEdited.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		lottoEdited?.errorsStack?.fieldsErrors,
		// lottoEdited.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const onSubmit: SubmitHandler<Lotto> = (lotto: Lotto) => {
		saveLotto(lotto, false);
	};
	const salvaChiudi: SubmitHandler<Lotto> = (lotto) => {
		saveLotto(lotto, true);
	};

	// useEffect(() => {
	// 	reset({}, { keepValues: true });
	// }, [lottoEdited, reset]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{lottoEdited?.errorsStack?.fieldsErrors?.non_field_errors && (
				<Alert severity="error">
					{lottoEdited?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
				</Alert>
			)}
			<Grid
				container
				sx={{ flexGrow: 1, mb: 1 }}
				rowSpacing={1}
				columnSpacing={{ xs: 1, sm: 2, md: 3 }}
				justifyContent="center"
				alignItems="stretch"
			>
				<Grid item xs={12} sm={10} md={6}>
					{getValues("in_dubbio") && (
						<TextField
							id="note_dubbio"
							label="note verifica"
							{...register("note_dubbio")}
							error={!!errors?.note_dubbio}
							helperText={errors?.note_dubbio?.message}
							InputLabelProps={{
								shrink: true,
							}}
							variant="outlined"
							// disabled={confermato}
							disabled={!getValues("in_dubbio")}
							autoComplete={"off"}
							sx={{ width: "100%", bgcolor: "white" }}
						/>
					)}
				</Grid>

				<Grid item xs={12} sm={2} md={2}>
					<FormControlLabel
						label={
							<Typography fontSize="0.8rem" marginLeft="0.1rem">
								{getValues("in_dubbio")
									? "lotto da verificare"
									: "lotto sicuro"}
							</Typography>
						}
						control={
							<Switch
								id="in_dubbio"
								// disabled={getValues("confermato")}
								checked={watch("in_dubbio") || false}
								onChange={(
									event: React.ChangeEvent<HTMLInputElement>,
									newValue: any
								) => {
									setValue("in_dubbio", newValue, {
										shouldDirty: true,
									});
									setStyleBkg(watch("in_dubbio"));
								}}
								color="error"
								size="small"
							/>
						}
					/>
				</Grid>
			</Grid>

			<Grid
				container
				sx={{ flexGrow: 1, mb: 1 }}
				rowSpacing={1}
				columnSpacing={{ xs: 1, sm: 2, md: 3 }}
				justifyContent="center"
				alignItems="flex-start"
			>
				<Grid item xs={12} sm={6} md={2}>
					<ReactHookFormSelect
						name="committente"
						label="committente"
						disabled={lottoEdited.stato_lotto.stato > 0}
						register={register}
						control={control}
						watch={watch}
						variant="outlined"
						required={true}
						error={errors?.committente?.message}
						sx={{ width: "100%" }}
					>
						{committenti.map((option) => {
							return (
								<MenuItem key={option.id} value={option.id}>
									{option.nome}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</Grid>

				<Grid item xs={12} sm={6} md={2}>
					<ReactHookFormSelect
						name="produttore"
						label="produttore"
						// disabled={getValues("confermato")}
						register={register}
						control={control}
						watch={watch}
						variant="outlined"
						error={errors?.produttore?.message}
						sx={{ width: "100%" }}
					>
						<MenuItem key={"nullo"} value={undefined} sx={{ p: 2 }} />
						{produttori.map((option) => {
							return (
								<MenuItem key={option.id} value={option.id}>
									{option.nome}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</Grid>
				<Grid item xs={12} sm={6} md={2}>
					<TextField
						id="ordine_cliente"
						label="n° ordine cliente"
						// disabled={getValues("confermato")}
						{...register("ordine_cliente")}
						error={!!errors?.ordine_cliente}
						helperText={errors?.ordine_cliente?.message}
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						autoComplete={"off"}
						sx={{ width: "100%" }}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={2}>
					<DesktopDatePicker
						label="data ordine cliente"
						value={watch("data_ordine_cliente") || null}
						renderInput={(params: any) => (
							<TextField
								{...params}
								error={!!errors?.data_ordine_cliente}
								helperText={errors?.data_ordine_cliente?.message}
								sx={{ width: "100%" }}
								autoComplete={"off"}
								InputLabelProps={{
									shrink: true,
								}}

								// required
								// {...register("data_ora_fine_turno")}
							/>
						)}
						onChange={(newValue: any) => {
							setValue(
								"data_ordine_cliente",
								newValue ? new Date(newValue.toDate()) : undefined,
								{
									shouldDirty: true,
								}
							);

							clearErrors("data_ordine_cliente");
						}}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				sx={{ flexGrow: 1, mb: 1 }}
				rowSpacing={1}
				columnSpacing={{ xs: 1, sm: 2, md: 3 }}
				justifyContent="center"
				alignItems="flex-start"
			>
				<Grid item xs={12} sm={6} md={2}>
					<ReactHookFormSelect
						name="tipo_capo"
						label="tipo capo"
						disabled={lottoEdited.stato_lotto.stato > 0}
						register={register}
						control={control}
						watch={watch}
						required={true}
						variant="outlined"
						error={errors?.tipo_capo?.message}
						sx={{ width: "100%" }}
					>
						{tipiCapo.map((option) => {
							return (
								<MenuItem key={option.id} value={option.id}>
									{option.nome}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</Grid>
				<Grid item xs={12} sm={6} md={2}>
					<ReactHookFormSelect
						name="colore"
						label="tipo colore"
						disabled={!committenteCorrente?.gestisce_colore}
						required={committenteCorrente?.gestisce_colore}
						register={register}
						control={control}
						watch={watch}
						variant="outlined"
						error={errors?.colore?.message}
						sx={{
							width: "100%",
							backgroundColor: !committenteCorrente?.gestisce_colore
								? "#ddd"
								: "inherit",
						}}
					>
						<MenuItem key={"nullo"} value={undefined} sx={{ p: 2 }} />
						{Object.entries(ColoreTypes).map(([key, value]) => {
							return (
								<MenuItem key={key} value={key}>
									{value}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<TextField
						label="Codice rintracciabilità"
						disabled={
							lottoEdited.calcolo_codice_tracciabilita_automatico
							//  || getValues("confermato")
						}
						variant="outlined"
						InputLabelProps={{
							shrink: true,
						}}
						{...register("codice_tracciabilita")}
						error={!!errors?.codice_tracciabilita}
						helperText={errors?.codice_tracciabilita?.message}
						autoComplete={"off"}
						sx={{ width: "100%" }}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				sx={{ flexGrow: 1, mb: 1 }}
				rowSpacing={1}
				columnSpacing={{ xs: 1, sm: 2, md: 3 }}
				justifyContent="center"
				alignItems="flex-start"
			>
				<Grid item xs={12} sm={6} md={4}>
					<TextField
						id="numero_capi"
						label="n capi"
						InputProps={{ inputProps: { min: 0, max: 100000 } }}
						disabled={lottoEdited.stato_lotto.stato > 1}
						required={true}
						{...register("numero_capi")}
						error={!!errors?.numero_capi}
						helperText={errors?.numero_capi?.message}
						type="number"
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						autoComplete={"off"}
						sx={{ width: "100%" }}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<Grid
						container
						sx={{ flexGrow: 1, mb: 1 }}
						rowSpacing={1}
						columnSpacing={1}
						justifyContent="center"
						alignItems="flex-start"
					>
						<Grid item xs={12} sm={5}>
							<TextField
								id="numero_capi_foro"
								label="capi x foro"
								{...register("numero_capi_foro")}
								error={!!errors?.numero_capi_foro}
								helperText={errors?.numero_capi_foro?.message}
								type="number"
								InputLabelProps={{
									shrink: true,
								}}
								variant="outlined"
								autoComplete={"off"}
								disabled={
									basatoSulNumFori
									//  || getValues("confermato")
								}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Switch
								checked={basatoSulNumFori}
								onChange={() => setBasatoSulNumFori(!basatoSulNumFori)}
								color="default"
								size="small"
							/>
						</Grid>
						<Grid item xs={12} sm={5}>
							<TextField
								id="numero_fori"
								label={"fori (" + ForiImpegnati(getValues("numero_fori")) + ")"}
								{...register("numero_fori")}
								error={!!errors?.numero_fori}
								helperText={errors?.numero_fori?.message}
								type="number"
								InputLabelProps={{
									shrink: true,
								}}
								variant="outlined"
								autoComplete={"off"}
								disabled={
									!basatoSulNumFori
									// || getValues("confermato")
								}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid container sx={{ flexGrow: 1, mb: 1 }} spacing={2}>
				<Grid item xs={12}>
					<Stack direction="row" spacing={2} justifyContent="center">
						<Button variant="outlined" onClick={handleAnnulla}>
							Annulla
						</Button>
						<Button disabled={!isDirty} variant="outlined" type="submit">
							{lottoEdited.id ? "Salva & Continua" : "Crea & Continua"}
						</Button>
						<Button
							disabled={!isDirty}
							variant="outlined"
							onClick={handleSubmit(salvaChiudi)}
						>
							{lottoEdited.id ? "Salva & Esci" : "Crea"}
						</Button>
					</Stack>
				</Grid>
			</Grid>
		</form>
	);
};

export default LottoForm;
