import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";

import { DATA_IOLEPEC_CORRENTE } from "../../../../api/apiUtils";
import {
	GraficoTipiCapoCommittenti,
	GraficiKpiStrutturaState,
	IndicatoriTotaliCommittente,
	fetchGraficoTipiCapoCommittenti,
	fetchIndicatoriTotaliCommittenti,
} from "../grafici/graficiKpiSlice";

import { TipoCapo, fetchTipiCapo } from "../../anagrafiche/anagraficheSlice";

import { ErrorStatusTypes } from "../../../common/errorsDeclarations";

import moment from "moment";
import ReportKgCommittentiMesePage from "./reportMensileKgCommittenti/ReportKgCommittentiMesePage";

import KgTipiCapoGrafico from "../grafici/KgTipiCapoGrafico";
import TipoCapoVelocitaProcessamentoGrafico from "./TipoCapoVelocitaProcessamentoGrafico";
import CircularProgress from "@mui/material/CircularProgress";
// import DateToday from "../../../common/dateUtils/DateToday";

import {
	RecuperaDataCorrente,
	FissaDataCorrente,
} from "../../../common/dateUtils/RecuperaDataCorrente";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const ReportisticaCommittentiPage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	const tipiCapo: TipoCapo[] = useSelector<RootState, TipoCapo[]>(
		(state) => state.anagrafiche.tipiCapo.results
	);

	const graficiKpiState: GraficiKpiStrutturaState = useSelector<
		RootState,
		GraficiKpiStrutturaState
	>((state) => state.graficiKpiState);

	const graficoTipiCapoCommittenti: GraficoTipiCapoCommittenti[] = useSelector<
		RootState,
		GraficoTipiCapoCommittenti[]
	>((state) => state.graficiKpiState.graficoTipiCapoCommittenti);

	const indicatoriTotaliCommittenti: IndicatoriTotaliCommittente[] =
		useSelector<RootState, IndicatoriTotaliCommittente[]>(
			(state) => state.graficiKpiState.indicatoriTotaliCommittenti
		);

	// salvataggio ultima data utilizzata
	type LocationState = {
		giornoMese: any;
	};
	const location = useLocation();
	const recupera_data_corrente = RecuperaDataCorrente(location);

	// const recupera_data_corrente = () => {
	// 	if (location.state) {
	// 		const { giornoMese } = location.state as LocationState;
	// 		if (giornoMese) {
	// 			return giornoMese;
	// 		}
	// 	}
	// 	const dataReportIolepecCorrente = localStorage.getItem(
	// 		DATA_IOLEPEC_CORRENTE
	// 	);
	// 	if (!!dataReportIolepecCorrente) {
	// 		return new Date(dataReportIolepecCorrente);
	// 	}
	// 	return DateToday();
	// };

	const [data, setData] = React.useState<Date | undefined>(
		// recupera_data_corrente
		// DateToday()
		new Date()
	);

	const [refresh, setRefresh] = React.useState<boolean>(false);

	useEffect(() => {
		data && FissaDataCorrente(data);

		// localStorage.setItem(
		// 	DATA_IOLEPEC_CORRENTE,
		// 	moment(data).format("YYYY-MM-DD")
		// );
		const data_da = moment(data).subtract(90, "days").toDate();
		const data_a = data;
		const tipiCapo_velocita_processamento = true;
		dispatch(fetchTipiCapo(tipiCapo_velocita_processamento));
		dispatch(fetchGraficoTipiCapoCommittenti({ data_da, data_a }));
		// dispatch(fetchIndicatoriTotaliCommittenti("3"));
	}, [data, refresh]);

	return (
		<Grid container spacing={1} sx={{ marginTop: 1 }}>
			<Grid item xs={12} sm={12} md={6} lg={5}>
				<ReportKgCommittentiMesePage />
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={7}>
				<Card sx={{ mb: 2 }}>
					<CardContent>
						<Box sx={{ height: "48vh", minHeight: 250, px: 3, pb: 4 }}>
							<Typography variant="h4">Ultimi 30 giorni</Typography>
							{graficiKpiState.errorsStack.status ==
							ErrorStatusTypes.PENDING ? (
								<Typography align="center" sx={{ mt: "15vh" }}>
									<CircularProgress color="inherit" />
								</Typography>
							) : (
								<KgTipiCapoGrafico
									graficoTipiCapoCommittenti={graficoTipiCapoCommittenti}
									tipiCapo={tipiCapo}
								/>
							)}
						</Box>
					</CardContent>
				</Card>
				<Card>
					<CardContent>
						<Box sx={{ height: "48vh", minHeight: 250, px: 3, pb: 4 }}>
							<Typography variant="h4">
								Velocità media ultimi 3 mesi (capi/minuto)
							</Typography>
							{/* {an.errorsStack.status ==
							ErrorStatusTypes.PENDING ? (
								<Typography align="center" sx={{ mt: "15vh" }}>
									<CircularProgress color="inherit" />
								</Typography>
							) : ( */}
							<TipoCapoVelocitaProcessamentoGrafico tipiCapo={tipiCapo} />
							{/* )} */}
						</Box>
					</CardContent>
				</Card>
				{/* <Card>
					<CardContent>
						<Box sx={{ height: "48vh", minHeight: 250, px: 3, pb: 4 }}>
							<Typography variant="h4">
								Lavorazioni medie Ultimi 3 mesi
							</Typography>
							{graficiKpiState.errorsStack.status ==
							ErrorStatusTypes.PENDING ? (
								<Typography align="center" sx={{ mt: "15vh" }}>
									<CircularProgress color="inherit" />
								</Typography>
							) : (
								<CommittenteLavorazioniGrafico
									indicatoriTotaliCommittenti={indicatoriTotaliCommittenti}
								/>
							)}
						</Box>
					</CardContent>
				</Card> */}
			</Grid>
			{/* <Grid item xs={12} sm={12} md={6} lg={6}>
				<Card>
					<CardContent>
						<Box sx={{ height: "82vh", minHeight: 400, px: 3, pb: 4 }}>
							<Typography variant="h4">Ultimi 3 mesi</Typography>
							{graficiKpiState.errorsStack.status ==
							ErrorStatusTypes.PENDING ? (
								<Typography align="center" sx={{ mt: "15vh" }}>
									<CircularProgress color="inherit" />
								</Typography>
							) : (
								<CommittenteKgNumCapiGrafico
									indicatoriTotaliCommittenti={indicatoriTotaliCommittenti}
								/>
							)}
						</Box>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={6}>
				<Card>
					<CardContent>
						<Box sx={{ height: "82vh", minHeight: 400, px: 3, pb: 4 }}>
							<Typography variant="h4">Calo Ultimi 3 mesi</Typography>
							{graficiKpiState.errorsStack.status ==
							ErrorStatusTypes.PENDING ? (
								<Typography align="center" sx={{ mt: "15vh" }}>
									<CircularProgress color="inherit" />
								</Typography>
							) : (
								<CommittenteCaloGrafico
									indicatoriTotaliCommittenti={indicatoriTotaliCommittenti}
								/>
							)}
						</Box>
					</CardContent>
				</Card>
			</Grid> */}
		</Grid>
	);
};

export default ReportisticaCommittentiPage;
