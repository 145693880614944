import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import storage from "redux-persist/lib/storage";
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";
import listenerMiddleware from "./listenerMiddleware";

import { userPreferenceReducer } from "../components/userPreference/userPreferenceSlice";
import { authenticationReducer } from "../components/authentication/authenticationSlice";
import { pianificazioneReducer } from "../components/main/pianificazione/pianificazioneSlice";
import { schedeCaricoSliceReducer } from "../components/main/schedeCarico/schedeCaricoSlice";
import { anagraficheReducer } from "../components/main/anagrafiche/anagraficheSlice";
import { allegatiSliceReducer } from "../components/main/allegati/allegatiSlice";
import { lavorazioniSliceReducer } from "components/main/lavorazioni/lavorazioniSlice";
import { consuntiviVivoSliceReducer } from "../components/main/schedeCarico/consuntiviVivo/consuntiviVivoSlice";
import { schedeMacelloSliceReducer } from "../components/main/schedeMacello/schedeMacelloSlice";
import { confezionamentiSliceReducer } from "../components/main/lavorazioni/lavorazioniConsuntivazione/confezionamentiSlice";
import { reseSliceReducer } from "../components/main/reportistica/rese/reseSlice";
import { veterinarioSliceReducer } from "../components/main/reportistica/veterinario/veterinarioSlice";
import { reportAutistiCamionSliceReducer } from "../components/main/reportistica/reportAutistiCamionSlice";
import { kgCommittentiSliceReducer } from "../components/main/reportistica/committenti/reportMensileKgCommittenti/kgCommittentiSlice";
import { bolleFattureSliceReducer } from "../components/main/bolle_fatture/bolleFattureSlice";
import { graficiKpiSliceReducer } from "../components/main/reportistica/grafici/graficiKpiSlice";
import { ospitiReducer } from "../components/main/ospiti/ospitiSlice";
import { palletsReducer } from "components/main/bolle_fatture/pallets/palletsSlice";
import { manutenzioneMezziReducer } from "components/main/manutenzione_mezzi/manutenzioneMezziSlice";

const persistConfig = {
	key: "root",
	storage,
	whitelist: [
		// "authentication",
		"userPreferenceState",
		// "comuniState",
	], // Elenco dei reducer persistenti
};

const rootReducer = combineReducers({
	userPreferenceState: userPreferenceReducer,
	authentication: authenticationReducer,
	pianificazione: pianificazioneReducer,
	schedeCaricoState: schedeCaricoSliceReducer,
	anagrafiche: anagraficheReducer,
	allegati: allegatiSliceReducer,
	lavorazioniState: lavorazioniSliceReducer,
	consuntiviVivoState: consuntiviVivoSliceReducer,
	schedeMacelloState: schedeMacelloSliceReducer,
	confezionamentiState: confezionamentiSliceReducer,
	reseState: reseSliceReducer,
	veterinarioState: veterinarioSliceReducer,
	reportAutistiCamionState: reportAutistiCamionSliceReducer,
	kgCommittentiState: kgCommittentiSliceReducer,
	bolleFattureState: bolleFattureSliceReducer,
	graficiKpiState: graficiKpiSliceReducer,
	ospitiState: ospitiReducer,
	palletsState: palletsReducer,
	manutenzioneMezziState: manutenzioneMezziReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== "production",
	// middleware: [thunk],
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).prepend(listenerMiddleware.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export default store;
export const persistor = persistStore(store);
