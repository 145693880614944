import React from "react";
import { useState } from "react";
import { LavorazioniNonCompletate } from "./bolleFattureSlice";

import CardMui from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { styled, Box } from "@mui/system";

import Button from "@mui/material/Button";

import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

import { Typography } from "@mui/material";
import { ErrorStatusTypes } from "../../common/errorsDeclarations";

import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Committente } from "../anagrafiche/anagraficheSlice";
import moment from "moment";

const Card = styled(CardMui)(({ theme }) => ({
	display: "flex:1",
	// "& > *": {
	// margin: theme.spacing(1),
	align: "center",
	marginBottom: theme.spacing(0.7),
	// },
}));

const listaLotti = (lavorazioniNonCompletate: LavorazioniNonCompletate) => {
	const listaLotti: { lotto: number; descrizione_lotto: string }[] = [];
	lavorazioniNonCompletate.results.map((lavorazione) => {
		const lottoObj: { lotto: number; descrizione_lotto: string } = {
			lotto: lavorazione.lotto,
			descrizione_lotto:
				"lotto " +
				lavorazione.lotto_codice +
				" " +
				lavorazione.tipo_capo +
				" del " +
				moment(lavorazione.lotto_data).format(" DD MMM YYYY"),
		};
		if (listaLotti.filter((l) => l.lotto == lavorazione.lotto).length == 0) {
			listaLotti.push(lottoObj);
		}
	});
	return listaLotti;
};

interface LavorazioniNonCompletateListProps {
	lavorazioniNonCompletate: LavorazioniNonCompletate;
	committente?: Committente;
	aggiungiLavorazioniDdt: (lavorazioni: number[]) => void;
}
const LavorazioniNonCompletateList = ({
	lavorazioniNonCompletate,
	committente,
	aggiungiLavorazioniDdt,
}: LavorazioniNonCompletateListProps) => {
	const [checked, setChecked] = useState<number[]>([]);

	const handleToggle = (value: number) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}
		setChecked(newChecked);
	};

	const selezionaTutti = () => {
		const newChecked = [...checked];
		lavorazioniNonCompletate.results.map((lavorazione) => {
			checked.indexOf(lavorazione.id) === -1 && newChecked.push(lavorazione.id);
		});
		setChecked(newChecked);
	};

	const handlerAggiungiLavorazioniDdt = () => {
		aggiungiLavorazioniDdt(checked);
		setChecked([]);
	};
	return (
		<Grid
			container
			rowSpacing={1}
			columnSpacing={{ xs: 1 }}
			justifyContent="center"
			alignItems="center"
		>
			<Grid item md={10}>
				<Box sx={{ paddingLeft: 2 }}>
					<Typography variant="h5" gutterBottom>
						{committente && committente.nome + " lavorazioni non consegnate"}
					</Typography>
				</Box>
				<Card
					sx={{
						border: 2,
						borderStyle: "solid",
						backgroundColor: "background.paper",
						borderColor: "#ffdc10",
						minHeight: "30vh",
						borderRadius: 4,
						boxShadow: 2,
					}}
				>
					<CardContent
						sx={{
							py: 0,
							"&:last-child": { pb: 1 },
						}}
					>
						<Button onClick={selezionaTutti}>Seleziona tutti</Button>
						{lavorazioniNonCompletate.errorsStack.status ==
						ErrorStatusTypes.PENDING ? (
							<CircularProgress color="inherit" />
						) : (
							<List sx={{ width: "100%", bgcolor: "background.paper" }}>
								{listaLotti(lavorazioniNonCompletate).map((lotto) => (
									<Box key={"lavorazioniNonCompletate_" + lotto.lotto}>
										<Divider textAlign="left">
											{lotto.descrizione_lotto}
										</Divider>
										{lavorazioniNonCompletate.results
											.filter((lavorazione) => lavorazione.lotto == lotto.lotto)
											.map((lavorazione) => {
												const labelId = `checkbox-list-label-${lavorazione.id}`;

												return (
													<ListItem key={lavorazione.id} disablePadding>
														<ListItemButton
															role={undefined}
															onClick={handleToggle(lavorazione.id)}
															dense
															sx={{
																backgroundColor:
																	lavorazione.codice_articolo_committente?.charAt(
																		0
																	) == "0"
																		? "#edd02b"
																		: lavorazione.descrizione
																				?.toLowerCase()
																				.includes("vendita")
																		? "#34519988"
																		: "inherit",
															}}
														>
															<ListItemIcon
																sx={{
																	minWidth: 0,
																}}
															>
																<Checkbox
																	edge="start"
																	checked={
																		checked.indexOf(lavorazione.id) !== -1
																	}
																	tabIndex={-1}
																	disableRipple
																	inputProps={{ "aria-labelledby": labelId }}
																/>
															</ListItemIcon>
															<ListItemText
																id={labelId}
																primary={
																	<Grid container>
																		<Grid item sm={4}>
																			{lavorazione.descrizione
																				? lavorazione.descrizione
																				: lavorazione.descrizione_articolo}{" "}
																			({lavorazione.codice_articolo_committente}
																			)
																		</Grid>
																		<Grid item sm={4}>
																			{lavorazione.note}
																		</Grid>
																		<Grid item sm={2}>
																			{lavorazione.numero_cassette_prodotte !=
																			lavorazione.numero_cassette_residue
																				? `${lavorazione.numero_cassette_residue} (${lavorazione.numero_cassette_prodotte})`
																				: lavorazione.numero_cassette_prodotte}{" "}
																			colli
																		</Grid>
																		<Grid item sm={2}>
																			<Stack
																				direction="row"
																				justifyContent="end"
																			>
																				{lavorazione.peso_cassette_prodotte !=
																				lavorazione.peso_cassette_residue
																					? `${Intl.NumberFormat("it-IT", {
																							maximumFractionDigits: 2,
																					  }).format(
																							lavorazione.peso_cassette_residue ||
																								0
																					  )} (${Intl.NumberFormat("it-IT", {
																							maximumFractionDigits: 2,
																					  }).format(
																							lavorazione.peso_cassette_prodotte ||
																								0
																					  )})`
																					: Intl.NumberFormat("it-IT", {
																							maximumFractionDigits: 2,
																					  }).format(
																							lavorazione.peso_cassette_prodotte ||
																								0
																					  )}{" "}
																				kg
																			</Stack>
																		</Grid>
																	</Grid>
																}
															/>
														</ListItemButton>
													</ListItem>
												);
											})}
									</Box>
								))}
							</List>
						)}
					</CardContent>
				</Card>
			</Grid>
			<Grid item md={2}>
				<Box>
					<Button
						sx={{ maxWidth: "95%", fontSize: "0.6vw" }}
						onClick={handlerAggiungiLavorazioniDdt}
						variant="contained"
						endIcon={<ArrowForwardIosIcon />}
						size="small"
					>
						Aggiungi selezionati
					</Button>
				</Box>
			</Grid>
		</Grid>
	);
};

export default LavorazioniNonCompletateList;
