import React from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";

import { UtenteLogged } from "../../authentication/authenticationSlice";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";
import SignIn from "../../authentication/SignIn";
import CircularProgress from "@mui/material/CircularProgress";

import scarso_img from "../../../../static/images/common/scarso_img.jpeg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";

import HomeMenu from "./HomeMenu";
const HomePage = () => {
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	return (
		<Grid
			container
			sx={{ flexGrow: 1, mb: 1 }}
			rowSpacing={1}
			columnSpacing={{ xs: 1, sm: 2, md: 3 }}
			justifyContent="center"
			alignItems="center"
		>
			<Grid item xs={12} sm={12} md={12} lg={6}>
				{utente.errorsStack.status == ErrorStatusTypes.PENDING ? (
					<Grid
						container
						direction="row"
						justifyContent="center"
						alignItems="center"
					>
						<Grid item>
							<CircularProgress size={100} color="info" />
						</Grid>
					</Grid>
				) : utente.isLogged ? (
					<HomeMenu />
				) : (
					<SignIn />
				)}
			</Grid>
			<Grid item xs={12} sm={12} md={3} lg={6}>
				<Box
					sx={{
						display: "flex",
						flexWrap: "wrap",
						"& > :not(style)": {
							// m: 1,
							marginTop: 5,
							maxWidth: "95%",
							height: "auto",
						},
						justifyContent: "center",
						border: 0,
						// elevation: 0,
					}}
				>
					<Card raised>
						<CardMedia
							component="img"
							image={scarso_img}
							height="430"
							sx={{
								objectFit: "cover",
								objectPosition: "50% 0%",
							}}
							alt="Azienda Scarso Romualdo"
						/>
						<CardContent>
							<Typography gutterBottom variant="h5" component="div">
								Scarso Romualdo e Figli
							</Typography>
							<Typography variant="body2" color="text.secondary">
								Azienda storica attiva nella macellazione di carni avicole,
								fondata nel 1975 dal Cav. Romualdo Scarso ed arrivata oggi alla
								quarta generazione, fornisce tutta Italia con la propria azienda
								di trasporti refrigerati per garantire la catena del freddo.
							</Typography>
						</CardContent>
					</Card>
				</Box>
			</Grid>
		</Grid>
	);
};

export default HomePage;
