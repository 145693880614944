import { handleResponse, handleError, headerset } from "../apiUtils";
import { Allegato } from "../../components/main/allegati/allegatiSlice";

const baseUrl = (
	scheda_carico_id?: number | null,
	eventoManutenzione_id?: number | null,
	tipo_documento_id?: number | null
) => {
	let baseUrl = process.env.API_URL + "/api/allegati/allegati_list/";
	if (scheda_carico_id) {
		baseUrl += "?scheda_carico=" + scheda_carico_id;
		if (tipo_documento_id) {
			baseUrl += "&tipo_documento_id=" + tipo_documento_id;
		}
	} else if (eventoManutenzione_id) {
		baseUrl += "?eventoManutenzione_id=" + eventoManutenzione_id;
		if (tipo_documento_id) {
			baseUrl += "&tipo_documento_id=" + tipo_documento_id;
		}
	} else {
		if (tipo_documento_id) {
			baseUrl += "?tipo_documento_id=" + tipo_documento_id;
		}
	}
	return baseUrl;
};

export function fetchAllegati(
	schedaCarico_id?: number | null,
	eventoManutenzione_id?: number | null,
	tipo_documento_id?: number
) {
	return fetch(
		baseUrl(schedaCarico_id, eventoManutenzione_id, tipo_documento_id),
		{
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: headerset().Authorization,
			},
		}
	)
		.then(handleResponse)
		.catch(handleError);
}

export function deleteAllegato(allegatoToDelete: Allegato) {
	const url =
		process.env.API_URL + "/api/allegati/allegato/" + allegatoToDelete.id + "/";
	return fetch(url, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

const uploadBaseUrl = (
	nomeFile: string,
	descrizione?: string,
	tipo_documento?: number | null,
	scheda_carico_id?: number | null,
	evento_manutenzione_id?: number | null
) => {
	let uploadBaseUrl = process.env.API_URL + "/api/allegati/allegati_list/";
	if (scheda_carico_id) {
		uploadBaseUrl =
			process.env.API_URL +
			"/api/allegati/upload/scheda_carico/" +
			scheda_carico_id +
			"/" +
			nomeFile;
		if (descrizione) {
			uploadBaseUrl += "?descrizione=" + descrizione;
			tipo_documento && (uploadBaseUrl += "&tipo_documento=" + tipo_documento);
		} else {
			tipo_documento && (uploadBaseUrl += "?tipo_documento=" + tipo_documento);
		}
	} else if (evento_manutenzione_id) {
		uploadBaseUrl =
			process.env.API_URL +
			"/api/allegati/upload/evento_manutenzione/" +
			evento_manutenzione_id +
			"/" +
			nomeFile;
		if (descrizione) {
			uploadBaseUrl += "?descrizione=" + descrizione;
			tipo_documento && (uploadBaseUrl += "&tipo_documento=" + tipo_documento);
		} else {
			tipo_documento && (uploadBaseUrl += "?tipo_documento=" + tipo_documento);
		}
	} else {
		return process.env.API_URL + "/api/allegati/allegati_list/";
	}
	return uploadBaseUrl;
};

export function uploadAllegato(
	allegato: Allegato,

	file: File
) {
	return fetch(
		uploadBaseUrl(
			file.name,
			allegato.descrizione,
			allegato.tipo_documento,
			allegato.scheda_carico,
			allegato.evento_manutenzione
		),
		{
			method: "PUT", // PUT to upload.
			headers: {
				"content-type": "application/json",
				Authorization: headerset().Authorization,
			},
			body: file,
		}
	)
		.then(handleResponse)
		.catch(handleError);
}

export function mandaDocumentiLotto(lotto_id: number, contatto_id: number) {
	const url =
		process.env.API_URL +
		"/api/allegati/manda_documenti_lotto/" +
		lotto_id +
		"/" +
		contatto_id +
		"/";

	return fetch(url, {
		method: "PUT", // PUT to upload.
		headers: {
			"content-type": "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// #######################################################################

export function avvertiUtenteDocumentiLotto(
	lotto_id: number,
	utenteEsterno_id: number
) {
	const url =
		process.env.API_URL +
		"/api/allegati/avverti_utente_documenti_lotto/" +
		lotto_id +
		"/" +
		utenteEsterno_id +
		"/";

	return fetch(url, {
		method: "PUT", // PUT to upload.
		headers: {
			"content-type": "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// #######################################################################

const tipiDocumentoUrl =
	process.env.API_URL + "/api/allegati/tipo_documento_list/";

export function fetchTipiDocumento() {
	return fetch(tipiDocumentoUrl, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
