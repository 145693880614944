import React from "react";

import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Header from "./Header";

import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
	drawerWidth: number;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open" && prop !== "drawerWidth",
})<AppBarProps>(({ theme, open, drawerWidth }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

interface TopBarProps {
	open?: boolean;
	drawerWidth: number;
	handleDrawerOpen: () => void;
}

export default function TopBar({
	open,
	drawerWidth,
	handleDrawerOpen,
}: TopBarProps) {
	return (
		<AppBar
			position="fixed"
			open={open}
			drawerWidth={drawerWidth}
			sx={{ background: "linear-gradient(60deg, #1b75b1,  #1d4763 )", pr: 0 }}
		>
			<Toolbar sx={{ pr: 0 }}>
				<IconButton
					// color="white"
					aria-label="open drawer"
					onClick={handleDrawerOpen}
					edge="start"
					sx={{
						marginRight: "36px",
						...(open && { display: "none" }),
						color: "white",
					}}
					size="large"
				>
					<MenuIcon />
				</IconButton>
				<Typography
					variant="h4"
					noWrap
					component="div"
					display="inline"
					color="white"
				>
					{"T&MA"}{" "}
					<Typography variant="body1" noWrap display="inline" color="white">
						{"Trasporto & Macellazione Avicola"}
					</Typography>
				</Typography>
				<Box
					sx={{
						display: "flex",
						flex: 1,
						justifyContent: "flex-end",
					}}
				>
					<Header />
				</Box>
			</Toolbar>
		</AppBar>
	);
}
