import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../../common/errorsDeclarations";

import { Lotto, getLotto } from "../../pianificazione/pianificazioneSlice";
import { Lavorazione, Lavorazioni } from "../../lavorazioni/lavorazioniSlice";
import { CostoTrasporto } from "../../bolle_fatture/bolleFattureSlice";
import * as reseApi from "../../../../api/reportistica/reportisticaApi";
import * as lavorazioniApi from "../../../../api/lavorazioni/lavorazioniApi";

import moment from "moment";
import { toast } from "react-toastify";
import { Contatto } from "components/main/anagrafiche/anagraficheSlice";

moment.locale("it");
// export interface StatoConsuntivoVivo {
// 	stato: number;
// 	descrizione: string;
// 	messaggio: string;
// }

export interface Resa extends Lotto {
	data_ora_arrivo_lotto: Date | string;
	sosta_macello: number;
	km_ar_lotto: number | null;
	data_ora_inizio_carico_lotto: Date | string;
	data_ora_fine_carico_lotto: Date | string;
	tempo_carico_lotto: number;
	data_ora_inizio_macello: Date | string;
	data_ora_fine_macello: Date | string;
	num_casse_busto_lotto?: number | null;
	num_busti_lotto?: number | null;
	peso_casse_busto_lotto?: number | null;
	num_casse_tz_lotto?: number | null;
	num_tz_lotto?: number | null;
	peso_casse_tz_lotto?: number | null;
	numero_capi_resa?: number | null;
	numero_capi_morti?: number | null;
	numero_scarti_1?: number | null;
	numero_scarti_2?: number | null;
	croste?: number;
	gozzi?: number;
	ali_rotte?: number;
	ematomi_cosce?: number;
	ematomi_petto?: number;
	fusi_verdi?: number;
	grasso?: number;
	peso_medio_resa?: number | null;
	dichiarazione_aggiustamento_resa: string | null;
	percentuale_resa?: number | null;
	percentuale_resa_manuale?: number | null;
	calcolo_percentuale_resa?: number | null;
	costi_di_trasporto_vivo_list: CostoTrasporto[];
	// percentuale_resa?: number | null;
	errorsStack: ErrorsStack;
}
export interface ReseState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: Resa[];
	errorsStack: ErrorsStack;
}
export interface ResaStrutturaState {
	rese: ReseState;
	lavorazioniLotto: Lavorazioni;
}

const initialState: ResaStrutturaState = {
	rese: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
	lavorazioniLotto: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchRese = createAsyncThunk(
	"rese/fetchRese",
	async (dataRange?: { data_da?: Date; data_a?: Date }) => {
		return await reseApi.fetchRese(dataRange?.data_da, dataRange?.data_a);
	}
);

export const getResa = createAsyncThunk(
	"resa/getResa",
	async (resaId: number) => {
		return await reseApi.getResa(resaId);
	}
);

export const saveResa = createAsyncThunk(
	"resa/saveResa",
	async (resa: Resa) => {
		return await reseApi.saveResa(resa);
	}
);

export const switchConfermaResa = createAsyncThunk(
	"resa/switchConfermaResa",
	async (resaId: number, thunkApi) => {
		return await reseApi.switchConfermaResa(resaId).then((response) => {
			thunkApi.dispatch(getLotto(resaId));
			return response;
		});
	}
);

export const fetchLavorazioniLotto = createAsyncThunk(
	"resa/fetchLavorazioniLotto",
	async (resaID: number) => {
		return await lavorazioniApi.fetchLavorazioni(resaID);
	}
);

export const mandaResaEmail = createAsyncThunk(
	"resa/mandaResaEmail",
	async (
		parametriMail: {
			resa_id: number;
			file: File;
			contatto: Contatto;
		},
		thunkApi
	) => {
		return await reseApi.mandaResaEmail(
			parametriMail.resa_id,
			parametriMail.file,
			parametriMail.contatto.id
		);
	}
);

export const reseSlice = createSlice({
	name: "reseState",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch rese
		builder.addCase(fetchRese.pending, (state, action) => {
			state.rese.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchRese.fulfilled, (state, action) => {
			state.rese = action.payload;

			state.rese.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchRese.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.rese.errorsStack = parseErrorMessage(action.error);
		});

		// get resa
		builder.addCase(getResa.pending, (state, action) => {
			state.rese.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(getResa.fulfilled, (state, action) => {
			state.rese.results = [
				...state.rese.results.filter((resa) => resa.id != action.payload.id),
				action.payload,
			];
			//  state.rese.results.map((resa) => {
			// 	if (resa.id == action.payload.id) {
			// 		return action.payload;
			// 	} else {
			// 		return resa;
			// 	}
			// });
			state.rese.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getResa.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.rese.errorsStack = parseErrorMessage(action.error);
		});

		// save resa
		builder.addCase(saveResa.pending, (state, action) => {
			state.rese.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(saveResa.fulfilled, (state, action) => {
			state.rese.results = [
				...state.rese.results.filter((resa) => resa.id != action.payload.id),
				action.payload,
			];
			state.rese.errorsStack = { status: ErrorStatusTypes.OK };
			toast.success("Aggiornamento riuscito.");
		});
		builder.addCase(saveResa.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.rese.errorsStack = parseErrorMessage(action.error);
		});

		// switchConfermaResa
		builder.addCase(switchConfermaResa.pending, (state, action) => {
			state.rese.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(switchConfermaResa.fulfilled, (state, action) => {
			state.rese.results = state.rese.results.map((resa) => {
				if (resa.id == action.payload.id) {
					return action.payload;
				} else {
					return resa;
				}
			});
			state.rese.errorsStack = { status: ErrorStatusTypes.OK };
		});

		builder.addCase(switchConfermaResa.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.rese.errorsStack = parseErrorMessage(action.error);
		});

		// get AggregatoLotto (alias veterinario)
		builder.addCase(fetchLavorazioniLotto.pending, (state, action) => {
			state.lavorazioniLotto.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchLavorazioniLotto.fulfilled, (state, action) => {
			state.lavorazioniLotto = action.payload;
			state.lavorazioniLotto.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchLavorazioniLotto.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.lavorazioniLotto.errorsStack = parseErrorMessage(action.error);
		});
		// carica Documento
		builder.addCase(mandaResaEmail.pending, (state, action) => {
			state.rese.errorsStack = { status: ErrorStatusTypes.PENDING };
		});
		builder.addCase(mandaResaEmail.fulfilled, (state, action) => {
			state.rese.errorsStack = { status: ErrorStatusTypes.OK };
			toast.success("Resa inviata.");
		});
		builder.addCase(mandaResaEmail.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");
			state.rese.errorsStack = parseErrorMessage(action.error);
		});
	},
});

// Action creators are generated for each case reducer function
export const {} = reseSlice.actions;

export const reseSliceReducer = reseSlice.reducer;
