import { handleResponse, handleError, headerset } from "../apiUtils";

const baseUrl = (articolo_id?: number) => {
  if (articolo_id) {
    return (
      process.env.API_URL + "/api/anagrafiche/articoli" + articolo_id + "/"
    );
  } else {
    return process.env.API_URL + "/api/anagrafiche/articoli";
  }
};

export function fetchArticoli() {
  return fetch(baseUrl(), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: headerset().Authorization,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}
