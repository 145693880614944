import * as React from "react";
import { Routes_path } from "../../routerApp";
import { useNavigate, Path, Link } from "react-router-dom";
import { EventoManutenzione } from "./manutenzioneMezziSlice";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import IconButton from "@mui/material/IconButton";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import BuildIcon from "@mui/icons-material/Build";

interface EventiManutenzioneBreadcrumbsProps {
	eventoManutenzione?: EventoManutenzione;
}

const EventiManutenzioneBreadcrumbs = ({
	eventoManutenzione,
}: EventiManutenzioneBreadcrumbsProps) => {
	return (
		<Breadcrumbs aria-label="breadcrumb">
			<IconButton
				key={"home"}
				aria-label="home"
				size="small"
				component={Link}
				to={{
					pathname: Routes_path.HOME,
				}}
				// state={{
				// 	lavorazine_id: params.value,
				// }}
			>
				<HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
				Home
			</IconButton>
			<IconButton
				key={"home"}
				aria-label="modifica lavorazione"
				size="small"
				component={Link}
				to={{
					pathname: Routes_path.MANUTENZIONE_MEZZI,
				}}
				// state={{
				// 	lavorazine_id: params.value,
				// }}
			>
				<BuildCircleIcon sx={{ mr: 0.5 }} fontSize="inherit" />
				Eventi Manutenzione
			</IconButton>
			<Typography
				sx={{ display: "flex", alignItems: "center" }}
				color="text.primary"
			>
				<BuildIcon sx={{ mr: 0.5 }} fontSize="inherit" />
				{!eventoManutenzione?.id
					? "Nuovo Evento"
					: `Evento n° ${eventoManutenzione.id}`}
			</Typography>
		</Breadcrumbs>
	);
};

export default EventiManutenzioneBreadcrumbs;
