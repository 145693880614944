import React from "react";

import { Utente } from "../../authentication/authenticationSlice";

import { styled, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Routes_path } from "../../routerApp";
import { PermissionTypes } from "../../authentication/permissionsGroups";

import List from "@mui/material/List";
// import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import Grid from "@mui/material/Grid";
import { utenteAutorizzato } from "../../authentication/UtenteAutorizzato";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const PREFIX = "HomeMenu";

const classes = {
	iconSelected: `${PREFIX}-iconSelected`,
};

const StyledList = styled(List)(() => ({
	[`& .${classes.iconSelected}`]: {
		color: "#1b75b1",
	},
}));

const styleListItem = { justifyContent: "center" };
interface HomeMenuButtonProps {
	nome: string;
	utente: Utente;
	gruppiAutorizzati: PermissionTypes[];
	path: Routes_path;
	Icon: any;
}
const HomeMenuButton = ({
	nome,
	utente,
	gruppiAutorizzati,
	path,
	Icon,
}: HomeMenuButtonProps) => {
	return (
		<>
			{utenteAutorizzato(utente, gruppiAutorizzati) && (
				// <Grid item sm={6} md={4} xs={6}>
				<Grid item xs={6} sm={6} md={4} lg={4}>
					<Button
						sx={{
							background: "linear-gradient(to right bottom, #1b75b1, #1d4763)",
							"&:hover": {
								background: "linear-gradient(to right bottom, #aaa, #1b75b1)",
							},

							color: "#ebd663",
							width: "100%",
							minHeight: 90,
							elevation: 3,
							alignContent: "center",
							fontSize: { lg: "1vw", md: 20, sm: 14, xs: 14 },
						}}
						// startIcon={<Icon sx={{ height: 50, mx: 3 }} />}
						variant="contained"
						component={Link}
						to={path}
					>
						<Icon
							sx={{
								fontSize: { lg: "3vw", md: 50, sm: 25, xs: 22 },
								mr: { md: 3, sm: 2, xs: 2 },
							}}
						/>
						{nome}
					</Button>
				</Grid>
			)}
		</>
	);
};

export default HomeMenuButton;
