import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import * as palletsApi from "../../../../api/bolle_fatture/palletsApi";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../common/errorsDeclarations";

export interface PalletCommittenteMovimento {
	id_bolla: number;
	data_documento: Date | string;
	pallet_ingresso: number;
	pallet_uscita: number;
}

export interface SaldoPalletCommittente {
	id_committente: number;
	committente: string;
	saldo_pallet: number;
	ultimi_movimenti: PalletCommittenteMovimento[];
	errorsStack: ErrorsStack;
}

export interface SaldiPalletCommittenti {
	results: SaldoPalletCommittente[];
	errorsStack: ErrorsStack;
}

const initialState: SaldiPalletCommittenti = {
	results: [],
	errorsStack: { status: ErrorStatusTypes.OK, saving: false },
};

export const fetchSaldoPalletCommittenti = createAsyncThunk(
	"bolle_fatture/fetchSaldoPalletCommittenti",
	async (parametri?: {
		committente_id?: number | null;
		data_da?: Date;
		data_a?: Date;
	}) => {
		return await palletsApi.fetchSaldoPalletCommittenti(
			parametri?.committente_id,
			parametri?.data_da,
			parametri?.data_a
		);
	}
);

export const palletsSlice = createSlice({
	name: "pallets",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// fetch saldi pallet
		builder.addCase(fetchSaldoPalletCommittenti.pending, (state, action) => {
			state.errorsStack.status = ErrorStatusTypes.PENDING;
		});
		builder.addCase(fetchSaldoPalletCommittenti.fulfilled, (state, action) => {
			state.results = [
				...action.payload,
				...state.results.filter((saldo) => {
					if (
						action.payload.find(
							(saldoNew: SaldoPalletCommittente) =>
								saldoNew.id_committente == saldo.id_committente
						)
					) {
						return false;
					} else {
						return true;
					}
				}),
			];

			state.errorsStack = {
				status: ErrorStatusTypes.OK,
			};
		});
		builder.addCase(fetchSaldoPalletCommittenti.rejected, (state, action) => {
			state.errorsStack.status = ErrorStatusTypes.ERROR;
		});
	},
});

export const palletsReducer = palletsSlice.reducer;
