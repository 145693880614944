import React from "react";
import { Link } from "react-router-dom";
import { Routes_path } from "../../../routerApp";

import { Lotto } from "../../pianificazione/pianificazioneSlice";
import { Parametri } from "../../anagrafiche/anagraficheSlice";
import { AllevamentoCommittente } from "../ospitiSlice";

import Avatar from "@mui/material/Avatar";
import { theme } from "../../../../theme";
import moment from "moment";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

import Chip from "@mui/material/Chip";
interface AllevamentiCommittenteCardProps {
	allevamento: AllevamentoCommittente;
	setAllevamentoCorrente: () => void;
}

const AllevamentiCommittenteCard = ({
	allevamento,
	setAllevamentoCorrente,
}: AllevamentiCommittenteCardProps) => {
	return (
		<>
			{/* <ListItem
				key={"Box_allevamentoCommittente_page_" + allevamento.id}
				disablePadding
			> */}
			{/* <ListItemButton component={Link} to={"/ospiti/allevamento/" + allevamento.id}> */}
			<ListItemButton
				key={"Box_allevamentoCommittente_page_" + allevamento.id}
				onClick={setAllevamentoCorrente}
				// component={Link}
				// to={Routes_path.LOTTO_COMMITTENTE + allevamento.id}
			>
				<Grid container spacing={{ xs: 0.5, md: 1, lg: 2 }}>
					<Grid item xs={5} sm={4} md={3} lg={3}>
						<Typography variant="subtitle1" component="div">
							{allevamento.nome} ({allevamento.codice_asl})
						</Typography>
					</Grid>
					<Grid item xs={4} sm={4} md={3} lg={4}>
						<Stack
							direction="column"
							justifyContent="flex-start"
							alignItems="flex-start"
							spacing={1}
						>
							<Typography variant="body1" component="div">
								{allevamento.indirizzo}
							</Typography>
							<Typography variant="body1" component="div">
								{allevamento.comune} ({allevamento.provincia_desc})
							</Typography>
						</Stack>
					</Grid>
					<Grid item xs={3} sm={3} md={3} lg={3}>
						<Typography variant="body1" component="div">
							{allevamento.distanza || "-"} km
						</Typography>
						{!!allevamento.distanza_concordata && (
							<Typography variant="body1" component="div">
								(concordati {allevamento.distanza_concordata || "-"} km)
							</Typography>
						)}
					</Grid>
					<Grid item xs={3} sm={3} md={3} lg={2}>
						<Typography variant="body1" component="div">
							{Intl.NumberFormat("it-IT", {
								maximumFractionDigits: 2,
								minimumFractionDigits: 2,
							}).format((allevamento.resa_media || 0) * 100) + "%"}{" "}
							({allevamento.numero_lotti} lotti)
						</Typography>
					</Grid>
				</Grid>
			</ListItemButton>
			{/* </ListItem> */}
			<Divider />
		</>
	);
};

export default AllevamentiCommittenteCard;
