function hexColour(c: number) {
	if (c < 256) {
		return Math.abs(c).toString(16);
	}
	return 0;
}

export const stringToColour = (str: string) => {
	var hash = 0;
	for (var i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << (i * 2)) - hash);
	}
	var colour = "#";
	for (var i = 0; i < 3; i++) {
		var value = (hash >> (i * 8)) & 0xff;
		colour += value.toString(16);
	}
	return colour;
};
