import { handleResponse, handleError, headerset } from "../apiUtils";
import {
	DocumentoDiTrasporto,
	DettaglioDDT,
} from "components/main/bolle_fatture/bolleFattureSlice";
import moment from "moment";

const baseUrl = (documento_id?: number | null, azione?: string) => {
	let url: string =
		process.env.API_URL + "/api/bolle_fatture/documenti_di_trasporto/";

	if (documento_id) {
		url += +documento_id + "/";
	}

	if (!!azione) {
		url = url + `?azione=${azione}`;
	}

	return url;
};

export function fetchDocumentiDiTrasporto(data_da?: Date, data_a?: Date) {
	let url = baseUrl();
	if (data_da && data_a) {
		url += `?data_da=${moment(data_da).format("YYYY-MM-DD")}&data_a=${moment(
			data_a
		).format("YYYY-MM-DD")}`;
	} else if (data_da) {
		url += `?data_da=${moment(data_da).format("YYYY-MM-DD")}`;
	} else if (data_a) {
		url += `?data_a=${moment(data_a).format("YYYY-MM-DD")}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getDocumentoDiTrasporto(documento_id: number) {
	let url = baseUrl(documento_id);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveDocumentoDiTrasporto(
	documentoDiTrasportoToSave: DocumentoDiTrasporto,
	azione?: string
) {
	const documentoDiTrasporto = {
		...documentoDiTrasportoToSave,
		data_documento: moment(documentoDiTrasportoToSave.data_documento).format(
			"YYYY-MM-DD"
		),
		data_ora_trasporto: moment(
			documentoDiTrasportoToSave.data_ora_trasporto
		).toISOString(),
		pallet_uscita:
			documentoDiTrasportoToSave.pallet_uscita != ""
				? documentoDiTrasportoToSave.pallet_uscita
				: null,
		pallet_ingresso:
			documentoDiTrasportoToSave.pallet_ingresso != ""
				? documentoDiTrasportoToSave.pallet_ingresso
				: null,
	};

	return fetch(baseUrl(documentoDiTrasportoToSave.id, azione), {
		method: documentoDiTrasportoToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...documentoDiTrasporto,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteDocumentoDiTrasporto(
	documentoDiTrasportoToDelete: DocumentoDiTrasporto
) {
	return fetch(baseUrl(documentoDiTrasportoToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// ###########################

export function fetchLavorazioniNonCompletate(committente_id: number) {
	let url: string =
		process.env.API_URL +
		"/api/bolle_fatture/lavorazioni_non_completate/" +
		committente_id +
		"/";

	// if (data_da && data_a) {
	// 	url += `?data_da=${moment(data_da).format("YYYY-MM-DD")}&data_a=${moment(
	// 		data_a
	// 	).format("YYYY-MM-DD")}`;
	// } else if (data_da) {
	// 	url += `?data_da=${moment(data_da).format("YYYY-MM-DD")}`;
	// } else if (data_a) {
	// 	url += `?data_a=${moment(data_a).format("YYYY-MM-DD")}`;
	// }
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// ###########################

export function aggiungiLavorazioniDdt(
	ddt: DocumentoDiTrasporto,
	lavorazioni: number[]
) {
	let url: string =
		process.env.API_URL +
		"/api/bolle_fatture/aggiungi_lavorazioni_ddt/" +
		ddt.id +
		"/";
	return fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			lavorazioni,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

// ###########################

export function aggiungiDettagliDdt(
	ddt: DocumentoDiTrasporto,
	dettaglioDdt: DettaglioDDT
) {
	let url: string =
		process.env.API_URL +
		"/api/bolle_fatture/aggiungi_dettagli_ddt/" +
		ddt.id +
		"/";
	return fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...dettaglioDdt,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

// ########  MODIFICA dettaglio DDT###################

export function saveDettaglioDocumentoDiTrasporto(dettaglioDdt: DettaglioDDT) {
	let url: string =
		process.env.API_URL +
		"/api/bolle_fatture/riga_dettagli_ddt/" +
		dettaglioDdt.id +
		"/";

	return fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...dettaglioDdt,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}
// ########  Cancella dettaglio DDT###################

export function deleteDettaglioDocumentoDiTrasporto(rddt_id: number) {
	let url: string =
		process.env.API_URL +
		"/api/bolle_fatture/riga_dettagli_ddt/" +
		rddt_id +
		"/";

	return fetch(url, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// ############################################
// ############################################

const uploadBaseUrl = (
	nomeFile: string,
	ddt_id: number,
	contatto_id: number
) => {
	let uploadBaseUrl =
		process.env.API_URL +
		"/api/bolle_fatture/send_mail_ddt/" +
		ddt_id +
		"/" +
		nomeFile;
	uploadBaseUrl += "?contatto_id=" + contatto_id;
	return uploadBaseUrl;
};

export function mandaDdtEmail(ddt_id: number, file: File, contatto_id: number) {
	return fetch(uploadBaseUrl(file.name, ddt_id, contatto_id), {
		method: "PUT", // PUT to upload.
		headers: {
			"content-type": "application/json",
			Authorization: headerset().Authorization,
		},
		body: file,
	})
		.then(handleResponse)
		.catch(handleError);
}
