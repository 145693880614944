import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver"; // v2.0.5

interface stampaConNomeProps {
	Documento: JSX.Element;
	nome: string;
}

const stampaConNome = ({ Documento, nome }: stampaConNomeProps) => {
	pdf(Documento)
		.toBlob()
		.then((blob) => saveAs(blob, nome));
};

export default stampaConNome;
