import React, { useEffect } from "react";

import { Lotto } from "../../pianificazione/pianificazioneSlice";
import {
	Camion,
	Autista,
	Allevamento,
	TipoContattoTypes,
} from "../../anagrafiche/anagraficheSlice";

import SchedeCaricoLottoList from "../SchedeCaricoLottoList";
import SchedeCaricoLottoCardIntestazione from "./SchedeCaricoLottoCardIntestazione";

import AllevamentoPage from "../../anagrafiche/allevamento/AllevamentoPage";
import ConfermaAnnulla from "../../../common/ConfermaAnnulla";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

interface SchedeCaricoLottoCardProps {
	lotto: Lotto;
	allevamento?: Allevamento;
	camions: Camion[];
	autisti: Autista[];
	// deleteSchedaCarico: () => void;
	generaSchedeCarico: (lotto: Lotto, viaggioLungo: boolean) => void;
	mandaM4: (lotto: Lotto, contatto_id: number) => void;
}

const SchedeCaricoLottoCard = ({
	lotto,
	allevamento,
	camions,
	autisti,
	// deleteSchedaCarico,
	generaSchedeCarico,
	mandaM4,
}: SchedeCaricoLottoCardProps) => {
	const [scegliAllevamento, setScegliAllevamento] =
		React.useState<boolean>(false);

	const [editaAllevamento, setEditaAllevamento] =
		React.useState<boolean>(false);

	useEffect(() => {
		setScegliAllevamento(!lotto.allevamento);
	}, [lotto.allevamento]);

	const [openConferma, setOpenConferma] = React.useState(false);

	const handlerGeneraSchedeCarico = () => {
		!!lotto.allevamento
			? generaSchedeCarico(lotto, false)
			: setOpenConferma(true);
	};
	const handleConfermaClose = () => setOpenConferma(false);

	return editaAllevamento ? (
		<Card key={"Box_schedaCarico_page_" + lotto.id} sx={{ my: 2 }}>
			<CardContent>
				<AllevamentoPage
					allevamento_id={lotto.allevamento}
					lotto={lotto}
					annulla={() => setEditaAllevamento(false)}
				/>
			</CardContent>
		</Card>
	) : (
		<Card
			key={"Box_schedaCarico_page_" + lotto.id}
			//  sx={{ my: 2 }}
			sx={{
				my: 2,
				border: "4px",
				borderStyle: "solid",
				backgroundColor: lotto.in_dubbio
					? "rgba(250, 250, 250, 0.6)"
					: "background.paper",
				borderColor: lotto.in_dubbio
					? "rgba(250, 250, 250, 0)"
					: lotto.stato_lotto.stato == 4
					? "red"
					: lotto.stato_lotto.stato == 3
					? "lightgreen"
					: lotto.stato_lotto.stato == 2
					? "rgba(91, 188, 247, 1)"
					: lotto.stato_lotto.stato == 1
					? "#ffdc10"
					: "background.paper",
			}}
		>
			<CardContent>
				<SchedeCaricoLottoCardIntestazione
					lotto={lotto}
					allevamento={allevamento}
					mandaM4={mandaM4}
					setEditaAllevamento={setEditaAllevamento}
				/>
			</CardContent>
			<CardContent sx={{ py: 1 }}>
				<SchedeCaricoLottoList
					lotto={lotto}
					camions={camions}
					autisti={autisti}
				/>
			</CardContent>
			{lotto.stato_lotto.schede_carico_mancanti > 0 ? (
				<CardActions sx={{ px: 2 }}>
					<Button
						variant="outlined"
						size="small"
						onClick={handlerGeneraSchedeCarico}
					>
						{/* {lotto.stato_lotto.stato < 1
							? "Per Generare le schede di carico devi prima selezionare un allevamento"
							: "Genera Schede di carico mancanti"} */}
						Genera {lotto.stato_lotto.schede_carico_mancanti} Schede di carico
					</Button>
					<ConfermaAnnulla
						handleAnnulla={handleConfermaClose}
						handleConferma={() => {
							generaSchedeCarico(lotto, true);
							handleConfermaClose();
						}}
						testoConferma={"Si"}
						handleAzioneAlternativa={() => {
							generaSchedeCarico(lotto, false);
							handleConfermaClose();
						}}
						testoAzioneAlternativa={"No"}
						domanda={"E' un viaggio LUNGO che prevede più autisti"}
						open={openConferma}
					/>
				</CardActions>
			) : (
				<CardActions sx={{ p: 3 }}>
					<Typography variant="caption">
						Per generare ulteriori schede di carico rivedi il numero dei capi o
						dei capi per foro
					</Typography>
				</CardActions>
			)}
		</Card>
	);
};

export default SchedeCaricoLottoCard;
