import React from "react";
import { useSelector } from "react-redux";
import { UtenteLogged } from "./authenticationSlice";
import { PermissionTypes } from "./permissionsGroups";
import { RootState } from "../../redux/store";

import { Route, Navigate, RouteProps, useLocation } from "react-router-dom";
import { utenteAutorizzato } from "../authentication/UtenteAutorizzato";

import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

interface PrivateRouteProps {
	gruppiAutorizzati: PermissionTypes[];
	element: JSX.Element;
}

const PrivateRoute = ({ gruppiAutorizzati, element }: PrivateRouteProps) => {
	const location = useLocation();
	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	if (utente.isLogged) {
		if (utenteAutorizzato(utente, gruppiAutorizzati)) {
			return element;
		} else {
			return (
				<Navigate
					replace
					to={{
						pathname: "/notPermitted",
						// state: { from: routeProps.location },
					}}
				/>
				// <Redirect
				//   to={{ pathname: "/notPermitted", state: { from: routeProps.location } }}
				// />
			);
		}
	} else {
		return (
			<Navigate
				to="/signin"
				state={{ from: { pathname: location.pathname } }}
			/>
		);
	}
};

export default PrivateRoute;
