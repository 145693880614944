import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { theme } from "../../../../theme";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";
import NavigazioneMesiToolbar from "../../../common/dateUtils/NavigazioneMesiToolbar";

// import { DATA_AUTISTI_CORRENTE } from "../../../../api/apiUtils";
import {
	ReportAutistiCamionState,
	fetchReportAutisti,
	ReportAutisti,
} from "../reportAutistiCamionSlice";

import { Autista, fetchAutisti } from "../../anagrafiche/anagraficheSlice";
import { ErrorStatusTypes } from "../../../common/errorsDeclarations";

import moment from "moment";

import ReportAutistiMeseTable from "./ReportAutistiMeseTable";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// import DateToday from "../../../common/dateUtils/DateToday";

import {
	RecuperaDataCorrente,
	FissaDataCorrente,
} from "../../../common/dateUtils/RecuperaDataCorrente";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const ReportAutistiMesePage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	const ToggleButton = styled(MuiToggleButton)({
		"&.Mui-selected, &.Mui-selected:hover": {
			color: "white",
			backgroundColor: theme.palette.primary.main,
		},
	});

	const autisti: Autista[] = useSelector<RootState, Autista[]>(
		(state) => state.anagrafiche.autisti.results
	);

	const reportAutistiCamionState: ReportAutistiCamionState = useSelector<
		RootState,
		ReportAutistiCamionState
	>((state) => state.reportAutistiCamionState.reportAutistiCamion);

	const reportAutisti: ReportAutisti[] = useSelector<
		RootState,
		ReportAutisti[]
	>((state) => state.reportAutistiCamionState.reportAutisti);

	type LocationState = {
		giornoMese: any;
	};
	const location = useLocation();
	const recupera_data_corrente = RecuperaDataCorrente(location);

	// const recupera_data_corrente = () => {
	// 	if (location.state) {
	// 		const { giornoMese } = location.state as LocationState;
	// 		if (giornoMese) {
	// 			return giornoMese;
	// 		}
	// 	}
	// 	const dataReportAutistiCorrente = localStorage.getItem(
	// 		DATA_AUTISTI_CORRENTE
	// 	);
	// 	if (!!dataReportAutistiCorrente) {
	// 		return new Date(dataReportAutistiCorrente);
	// 	}
	// 	return DateToday();
	// };

	const [data, setData] = React.useState<Date | undefined>(
		recupera_data_corrente
	);
	const [refresh, setRefresh] = React.useState<boolean>(false);

	const [autistaSelezionato, setAutistaSelezionato] = React.useState<number>(1);

	const handleChange = (
		event: React.MouseEvent<HTMLElement>,
		newAutistaSelezionato: number
	) => {
		setAutistaSelezionato(newAutistaSelezionato);
	};

	useEffect(() => {
		data && FissaDataCorrente(data);

		// localStorage.setItem(
		// 	DATA_AUTISTI_CORRENTE,
		// 	moment(data).format("YYYY-MM-DD")
		// );
		const data_da = moment(data).startOf("month").toDate();
		const data_a = moment(data).endOf("month").toDate();
		// const data_da = moment(data).startOf("isoWeek").toDate();
		// const data_a = moment(data).endOf("isoWeek").toDate();
		dispatch(fetchReportAutisti({ data_da, data_a }));
		dispatch(fetchAutisti(data_da));
	}, [data, refresh]);

	return (
		<>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					reportAutistiCamionState.errorsStack.status ==
					ErrorStatusTypes.PENDING
					// ||
					// lavorazioni.errorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<NavigazioneMesiToolbar
				titolo={"Report Mensile Autista"}
				setData={setData}
				data={data}
				refresh={() => {
					setRefresh(true);
				}}
			/>

			<Grid container spacing={1} sx={{ marginTop: 1 }}>
				<Grid item xs={12} sm={3} md={2} sx={{ flexGrow: 1 }}>
					<ToggleButtonGroup
						color="primary"
						orientation="vertical"
						value={autistaSelezionato}
						exclusive
						onChange={handleChange}
						sx={{ width: "90%" }}
					>
						{autisti
							.filter((autista) => autista.dipendente == true)
							.map((autista) => (
								<ToggleButton
									key={autista.id}
									value={autista.id}
									sx={{
										backgroundColor: "white",
										border: 0,
										textTransform: "uppercase",
									}}
								>
									{autista.cognome} {autista.nome}
								</ToggleButton>
							))}
					</ToggleButtonGroup>
				</Grid>
				<Grid item xs={12} sm={9} md={10}>
					{reportAutisti && (
						<ReportAutistiMeseTable
							reportAutistiMese={reportAutisti.filter(
								(reportAutista) =>
									reportAutista.autista_id == autistaSelezionato
							)}
							nome={
								autisti.find((autista) => autista.id == autistaSelezionato)
									?.nome
							}
							cognome={
								autisti.find((autista) => autista.id == autistaSelezionato)
									?.cognome
							}
						/>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default ReportAutistiMesePage;
