import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";
import moment from "moment";

import AllevamentoForm from "./AllevamentoForm";

import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../common/errorsDeclarations";

import { Lotto } from "../../pianificazione/pianificazioneSlice";
import {
	Allevamento,
	Provincia,
	Autista,
	getAllevamento,
	saveAllevamento,
} from "../anagraficheSlice";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

interface AllevamentoPageProps {
	allevamento_id?: number | null;
	lotto?: Lotto;
	annulla?: () => void;
}

const AllevamentoPage = ({
	allevamento_id,
	lotto,
	annulla,
	...props
}: AllevamentoPageProps) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (allevamento_id != null || allevamento_id != undefined) {
			dispatch(getAllevamento(allevamento_id));
		}
	}, [allevamento_id]);

	const allevamento: Allevamento | undefined = useSelector<
		RootState,
		Allevamento | undefined
	>((state) =>
		state.anagrafiche.allevamenti.results.find(
			(allevamento) => allevamento.id == allevamento_id
		)
	);

	const errorsStack = useSelector<RootState, ErrorsStack>(
		(state) => state.anagrafiche.allevamenti.errorsStack
	);

	const province: Provincia[] = useSelector<RootState, Provincia[]>(
		(state) => state.anagrafiche.province.province
	);

	const [poiChiudi, setPoiChiudi] = useState(false);

	useEffect(() => {
		if (
			errorsStack.status != ErrorStatusTypes.ERROR &&
			errorsStack.saving == false &&
			annulla &&
			poiChiudi
		) {
			setPoiChiudi(false);
			annulla();
		}
	}, [errorsStack.status]);

	const handleSaveAllevamento = (allevamentoToSave: Allevamento) => {
		dispatch(saveAllevamento({ allevamento: allevamentoToSave, lotto: lotto }));
		setPoiChiudi(true);
	};

	const handleAnnulla = () => {
		annulla && annulla();
		// handleClose();
	};

	return (
		<Box>
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={
					// allevamento.errorsStack.status == ErrorStatusTypes.PENDING ||
					errorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			{allevamento && (
				<AllevamentoForm
					key={"form_allevamento_" + allevamento.id}
					allevamento={allevamento}
					province={province}
					saveAllevamento={handleSaveAllevamento}
					handleAnnulla={handleAnnulla}
				/>
			)}
		</Box>
	);
};

export default AllevamentoPage;
