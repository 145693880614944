import { handleResponse, handleError, headerset } from "../apiUtils";

const baseUrl = (vettore_id?: number) => {
	let url: string =
		process.env.API_URL + "/api/bolle_fatture/vettori_trasporto/";

	if (vettore_id) {
		url += +vettore_id + "/";
	}

	return url;
};

export function fetchVettoriTrasporto() {
	return fetch(baseUrl(), {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getVettoreTrasporto(vettore_id: number) {
	let url = baseUrl(vettore_id);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
