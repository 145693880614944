import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

import { Lotto } from "../../../pianificazione/pianificazioneSlice";
import { getColore } from "../../../pianificazione/utility/lottoUtility";

// Create styles
const styles = StyleSheet.create({
	textIntestazione: {
		fontSize: 7,
		borderBottom: "0.5px",
		fontStyle: "normal",

		paddingLeft: 1,
		paddingRight: 1,
		paddingTop: 5,
		paddingBottom: 5,
		marginBottomp: 1,
	},
	textDati: {
		fontSize: 11,
		fontStyle: "normal",
		// fontWeight: 100,
		whiteSpace: "wrap",
		fontWeight: "bold",
		paddingLeft: 1,
		paddingRight: 1,
		paddingTop: 5,
		paddingBottom: 5,
		marginBottomp: 3,
	},
	sectionTableIntestazione: {
		fontSize: 9,
		fontStyle: "normal",
		flexDirection: "column",
		marginHorizontal: 2,
		marginTop: 2,
		marginLeft: 5,
		// marginBottom: 10,
		// borderTop: "1px",
		// borderBottom: "0.5px",
	},
	sectionTableDati: {
		flexDirection: "row",
		// marginHorizontal: 20,
	},
});

interface LottoLavorazioniIntestazionePDFProps {
	lotto: Lotto;
}

const LottoLavorazioniIntestazionePDF = ({
	lotto,
}: // giornoSettimana,
LottoLavorazioniIntestazionePDFProps) => (
	<View
		style={{
			flexDirection: "column",
			justifyContent: "space-between",
			// height: 410,
		}}
	>
		<View style={{ flexDirection: "row", margin: "20px" }}>
			<View style={[styles.sectionTableIntestazione, { width: "25%" }]}>
				<Text style={[styles.textIntestazione]}>Committente</Text>
				<Text style={styles.textDati}>{lotto.committente_desc}</Text>
			</View>
			<View style={[styles.sectionTableIntestazione, { width: "20%" }]}>
				<Text style={[styles.textIntestazione]}>Cod. Rintrac.</Text>
				<Text style={styles.textDati}>{lotto.codice_tracciabilita}</Text>
			</View>
			<View style={[styles.sectionTableIntestazione, { width: "30%" }]}>
				<Text style={styles.textIntestazione}>Capi Ordinati</Text>
				<Text style={[styles.textDati, { textTransform: "uppercase" }]}>
					{Intl.NumberFormat("it-IT").format(lotto.numero_capi || 0)}{" "}
					{lotto.tipo_capo_desc} {getColore(lotto)}
				</Text>
			</View>
			<View style={[styles.sectionTableIntestazione, { width: "25%" }]}>
				<Text style={styles.textIntestazione}>
					Allevatore (Cod. ASL: {lotto.allevamento_pack?.codice_asl})
				</Text>
				<Text style={[styles.textDati]}>
					{lotto.allevamento_pack?.nome &&
					lotto.allevamento_pack?.nome.length > 33
						? lotto.allevamento_pack?.nome.substr(0, 32) + "..."
						: lotto.allevamento_pack?.nome}
				</Text>
			</View>
		</View>
	</View>
);

export default LottoLavorazioniIntestazionePDF;
