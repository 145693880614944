import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { Routes_path } from "../../../routerApp";
import {
	Lavorazione,
	Lavorazioni,
	LavorazioneCommittente,
} from "./../lavorazioniSlice";

import {
	Utente,
	UtenteLogged,
} from "../../../authentication/authenticationSlice";
import { PermissionTypes } from "../../../authentication/permissionsGroups";

import { utenteAutorizzato } from "../../../authentication/UtenteAutorizzato";

import Grid from "@mui/material/Grid";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Grid4x4Icon from "@mui/icons-material/Grid4x4";
import GridOnIcon from "@mui/icons-material/GridOn";
import WidthWideIcon from "@mui/icons-material/WidthWide";
import { toast } from "react-toastify";
import AllInboxTwoToneIcon from "@mui/icons-material/AllInboxTwoTone";
import BalanceIcon from "@mui/icons-material/Balance";
import { Stack, Typography } from "@mui/material";
import LavorazionePianoForm from "./LavorazionePianoForm";
import Tooltip from "@mui/material/Tooltip";

interface LavorazioniGridProps {
	utente: Utente;
	lavorazioni: Lavorazioni;
	saveLavorazione: (lottoId: number, lavorazioneToSave: Lavorazione) => void;
	gridAutoHeight: boolean;
	setLavorazioneCommittenteCorrente: (
		lavorazioneCommittente_id: number
	) => void;
	cosuntivazione?: boolean;
}

const LavorazioniGrid = ({
	utente,
	lavorazioni,
	saveLavorazione,
	gridAutoHeight,
	setLavorazioneCommittenteCorrente,
	cosuntivazione,
}: LavorazioniGridProps) => {
	return (
		<Box
			sx={{
				"& .campo-editabile": {
					backgroundColor: "#bbbbbb11",
					// background: "linear-gradient(to right bottom, #f5f7f6, #dce3e0)",
					// borderWidth: "5px",
					borderColor: "#ff91003d",
					// borderColor: "#0000003d",
					// borderStyle: "outset",
					borderWidth: 3,
					borderStyle: "ridge",
					color: "#1a3e72",
					fontWeight: "600",
				},
				"& .campo-errore": {
					backgroundColor: "rgba(124, 3, 0, 0.3)",
					color: "#ffffff",
					fontWeight: "600",
				},
				"& .super-app-theme--evidenaziato": {
					bgcolor: "blanchedalmond",
					border: "1px solid #333",
					"&:hover": {
						bgcolor: "blanchedalmond",
					},
				},
			}}
		>
			<Box sx={{ border: "solid", borderColor: "orange", borderRadius: 1 }}>
				<Grid
					container
					sx={{
						backgroundColor: "#21877343",
						borderBottom: "0.02rem solid #aaaaaa",
						p: 1,
					}}
				>
					<Grid item xs={5}></Grid>
					<Grid item xs={7}>
						<Grid
							container
							sx={{ flexGrow: 1, mb: 1 }}
							rowSpacing={1}
							columnSpacing={1}
							justifyContent="center"
							alignItems="flex-start"
						>
							<Grid item xs={6} sm={4} md={4}>
								Note specifiche lotto
							</Grid>
							<Grid item xs={3} sm={2} md={2}>
								cassette richieste
							</Grid>
							<Grid item xs={3} sm={2} md={2}>
								cassette x pallet
							</Grid>
							<Grid item xs={3} sm={2} md={2}>
								<Typography variant="body2" align="right">
									peso casse prodotte
								</Typography>
							</Grid>
							<Grid item xs={3} sm={2} md={2}>
								<Typography variant="body2" align="right">
									casse prodotte
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{lavorazioni.results.map((lavorazione, index) => (
					<Grid
						container
						key={`righa_lavorazione_${lavorazione.id}`}
						sx={{
							backgroundColor: index % 2 === 0 ? "#eeeeee99" : "inherit",
							borderBottom: "0.02rem solid #aaaaaa",
							p: 0.4,
						}}
					>
						<Grid item xs={5}>
							<Grid container>
								<Grid item xs={2}>
									<Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
										{lavorazione.articolo?.descrizione}
									</Typography>
								</Grid>

								<Grid item xs={1}>
									<Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
										{lavorazione.codice_articolo_committente}
									</Typography>
								</Grid>

								<Grid item xs={2}>
									<Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
										{lavorazione.descrizione}
									</Typography>
								</Grid>
								<Grid item xs={2}>
									<Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
										{lavorazione.destinazione}
									</Typography>
								</Grid>

								<Grid item xs={5}>
									{utenteAutorizzato(utente, [
										PermissionTypes.PIANIFICATORI,
										PermissionTypes.PIANIFICATORI_LETTURA,
										PermissionTypes.OPERATORI_LOGISTICA,
										PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
										PermissionTypes.SCHEDULATORI_LAVORAZIONI,
									]) && (
										<Stack direction="row" spacing={1} alignItems="flex-end">
											<Tooltip title="Etichetta Grande Imballo">
												<Button
													component={Link}
													to={{
														pathname: Routes_path.STAMPA_GRANDE_IMBALLO,
													}}
													state={{
														lavorazine_id: lavorazione.id,
													}}
													variant="outlined"
													size="small"
													startIcon={<AllInboxTwoToneIcon />}
													color="info"
												/>
											</Tooltip>
											<Tooltip title="Modifica Generale">
												<Button
													onClick={() =>
														setLavorazioneCommittenteCorrente(
															lavorazione.lavorazione_committente || 0
														)
													}
													variant="outlined"
													size="small"
													startIcon={<EditIcon />}
													color="warning"
													disabled={
														!utenteAutorizzato(utente, [
															PermissionTypes.PIANIFICATORI,
															PermissionTypes.OPERATORI_LOGISTICA,
															PermissionTypes.OPERATORI_LOGISTICA_LETTURA,
															PermissionTypes.SCHEDULATORI_LAVORAZIONI,
														])
													}
												/>
											</Tooltip>

											<Tooltip
												title={
													<Stack direction="column" spacing={1}>
														<Stack
															direction="row"
															spacing={1}
															alignItems="flex-start"
														>
															<Typography
																variant="body2"
																sx={{ fontSize: "0.8rem" }}
															>
																{lavorazione.materiale_cassetta}
															</Typography>
															<Typography
																variant="body2"
																sx={{ fontSize: "0.8rem" }}
															>
																{lavorazione.dimensione_cassetta}
															</Typography>
														</Stack>
														<Typography
															variant="body2"
															sx={{ fontSize: "0.8rem" }}
														>
															{lavorazione.etichetta_su_cassetta
																? "Con Etichetta cassetta"
																: "Senza Etichetta cassetta"}
														</Typography>
														<Typography
															variant="body2"
															sx={{ fontSize: "0.8rem" }}
														>
															{lavorazione.etichetta_su_pallet
																? "Con Etichetta Pallet"
																: "Senza Etichetta Pallet"}
														</Typography>

														{lavorazione.pettorina_desc && (
															<Typography
																variant="body2"
																sx={{ fontSize: "0.8rem" }}
															>
																{lavorazione.pettorina_desc}
															</Typography>
														)}
														{lavorazione.materiale_sotto && (
															<Typography
																variant="body2"
																sx={{ fontSize: "0.8rem" }}
															>
																mat.sotto:{lavorazione.materiale_sotto}
															</Typography>
														)}
														{lavorazione.materiale_sopra && (
															<Typography
																variant="body2"
																sx={{ fontSize: "0.8rem" }}
															>
																mat. sopra: {lavorazione.materiale_sopra}
															</Typography>
														)}
													</Stack>
												}
											>
												{lavorazione.materiale_cassetta == "Plastica" ? (
													<GridOnIcon />
												) : (
													<WidthWideIcon />
												)}
											</Tooltip>
											<IconButton
												key={`displosizione_bilancia_lavorazione${lavorazione.id}`}
												aria-label="modifica lavorazione"
												size="small"
											>
												<BalanceIcon
													fontSize="inherit"
													sx={{
														color:
															lavorazione.disposizione_bilancia == "SP"
																? "green"
																: lavorazione.disposizione_bilancia == "CO"
																? "#000"
																: "transparent",
													}}
												/>
											</IconButton>
											<IconButton
												key={`lavorazione_da_pallettiz${lavorazione.id}`}
												aria-label="da pallettizzare"
												size="small"
											>
												<PrecisionManufacturingIcon
													fontSize="inherit"
													sx={{
														color:
															lavorazione.da_pallettizzare == true
																? "green"
																: "transparent",
													}}
												/>
											</IconButton>
										</Stack>
									)}
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={7}>
							<LavorazionePianoForm
								lavorazione={lavorazione}
								annulla={() => {}}
								lotto_id={lavorazione.lotto}
								saveLavorazione={saveLavorazione}
								cosuntivazione={cosuntivazione}
							/>
						</Grid>
					</Grid>
				))}
			</Box>
		</Box>
	);
};

export default LavorazioniGrid;
