import React from "react";
import { useEffect } from "react";

import { useForm, SubmitHandler, Controller } from "react-hook-form";

import TextField from "@mui/material/TextField";
import { NumericFormat, NumericFormatProps } from "react-number-format";

import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";

import ButtonGroup from "@mui/material/ButtonGroup";

interface ResaManualeFormProps {
	percentuale_resa?: number | null;
	calcolo_percentuale_resa?: number | null;
	savePercentualeResaManuale: (percentuale_resa?: number | null) => void;
	ripristinaPercentualeResaCalcolata: () => void;
}

const ResaManualeForm = ({
	percentuale_resa,
	calcolo_percentuale_resa,
	savePercentualeResaManuale,
	ripristinaPercentualeResaCalcolata,
}: ResaManualeFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<{ percentuale_resa?: number | null }>();

	const listaCampi = ["percentuale_resa"];

	useEffect(() => {
		setValue("percentuale_resa", percentuale_resa);
	}, [percentuale_resa]);

	const onSubmit: SubmitHandler<{ percentuale_resa?: number | null }> = (
		payload
	) => {
		savePercentualeResaManuale(payload.percentuale_resa);
	};

	// useEffect(() => {
	// 	reset({}, { keepValues: true });
	// }, [percentuale_resa, reset]);

	//  ######################################################

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack
				direction="column"
				justifyContent="flex-start"
				alignItems="flex-start"
				spacing={2}
				sx={{ mt: 1 }}
			>
				<Controller
					name="percentuale_resa"
					control={control}
					render={({ field }) => (
						<NumericFormat
							id="percentuale_resa"
							customInput={TextField}
							variant="outlined"
							label="percentuale resa manuale"
							InputLabelProps={{
								shrink: true,
							}}
							// error={!!errors?.peso_medio}
							// helperText={errors?.peso_medio?.message}
							// {...register("percentuale_resa")}
							value={field.value}
							autoComplete="off"
							prefix="% "
							thousandSeparator="."
							decimalSeparator=","
							valueIsNumericString={true}
							onValueChange={(v) => {
								setValue("percentuale_resa", Number(v.value));
							}}
							sx={{ width: "100%" }}
						/>
					)}
				/>
				<ButtonGroup variant="outlined" size="small" aria-label="gestione resa">
					<Button type="submit">salva</Button>
					<Button
						onClick={ripristinaPercentualeResaCalcolata}
						disabled={
							calcolo_percentuale_resa ==
							(percentuale_resa && percentuale_resa / 100)
						}
					>
						ripristina{" "}
						{Intl.NumberFormat("it-IT", {
							maximumSignificantDigits: 4,
						}).format(
							(calcolo_percentuale_resa && calcolo_percentuale_resa * 100) || 0
						)}{" "}
						%
					</Button>
				</ButtonGroup>
			</Stack>
		</form>
	);
};

export default ResaManualeForm;
