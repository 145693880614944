import { Utente } from "./authenticationSlice";
import { PermissionTypes } from "./permissionsGroups";

export const utenteAutorizzato = (
	utente: Utente,
	gruppiAutorizzati: PermissionTypes[]
): boolean => {
	if (utente.is_superuser) {
		return true;
	}
	if (utente.isLogged && gruppiAutorizzati.indexOf(PermissionTypes.ALL) > -1) {
		return true;
	}
	const utenteAutorizzato =
		gruppiAutorizzati &&
		gruppiAutorizzati.find(
			(gruppo) => utente.gruppi && utente.gruppi.indexOf(gruppo) > -1
		);

	return !!utenteAutorizzato;
};
