import { handleResponse, handleError, headerset } from "../apiUtils";
import moment from "moment";
import {
  Lavorazione,
  LavorazioneCommittente,
} from "../../components/main/lavorazioni/lavorazioniSlice";

const baseUrl = (lotto_id: number | null, lavorazione_id?: number | null) => {
  if (lavorazione_id) {
    return (
      process.env.API_URL +
      "/api/lavorazioni/lavorazioni/" +
      lotto_id +
      "/" +
      lavorazione_id +
      "/"
    );
  } else {
    return (
      process.env.API_URL + "/api/lavorazioni/lavorazioni/" + lotto_id + "/"
    );
  }
};

export function fetchLavorazioni(lottoId: number) {
  let url = baseUrl(lottoId);

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: headerset().Authorization,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getLavorazione(lottoId: number, lavorazioneId: number) {
  let url = baseUrl(lottoId, lavorazioneId);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: headerset().Authorization,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveLavorazione(
  lottoId: number,
  lavorazioneToSave: Lavorazione
) {
  const lavorazione = {
    ...lavorazioneToSave,
  };

  return fetch(baseUrl(lottoId, lavorazioneToSave.id), {
    method: lavorazioneToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: headerset().Authorization,
    },
    body: JSON.stringify({
      ...lavorazione,
      // Parse authorId to a number (in case it was sent as a string).
      // id: parseInt(progetto.id, 10),
    }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteLavorazione(
  lottoId: number,
  lavorazioneToDelete: Lavorazione
) {
  return fetch(baseUrl(lottoId, lavorazioneToDelete.id), {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: headerset().Authorization,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

// #############################################################################
// gestione lavorazioni Committente
// #############################################################################

const baseUrlLavorazioniCommittenti = (
  lotto_id: number | null,
  lavorazioneCommittente_id?: number | null
) => {
  if (lavorazioneCommittente_id) {
    return (
      process.env.API_URL +
      "/api/lavorazioni/lavorazioni_committente/" +
      lotto_id +
      "/" +
      lavorazioneCommittente_id +
      "/"
    );
  } else {
    return (
      process.env.API_URL +
      "/api/lavorazioni/lavorazioni_committente/" +
      lotto_id +
      "/"
    );
  }
};

export function getLavorazioneCommittente(
  lottoId: number,
  lavorazioneCommittente_id: number
) {
  let url = baseUrlLavorazioniCommittenti(lottoId, lavorazioneCommittente_id);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: headerset().Authorization,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveLavorazioneCommittente(
  lottoId: number,
  lavorazioneCommittenteToSave: LavorazioneCommittente
) {
  const lavorazioneCommittente = {
    ...lavorazioneCommittenteToSave,
  };

  return fetch(
    baseUrlLavorazioniCommittenti(lottoId, lavorazioneCommittenteToSave.id),
    {
      method: lavorazioneCommittenteToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: headerset().Authorization,
      },
      body: JSON.stringify({
        ...lavorazioneCommittente,
        // Parse authorId to a number (in case it was sent as a string).
        // id: parseInt(progetto.id, 10),
      }),
    }
  )
    .then(handleResponse)
    .catch(handleError);
}

export function deleteLavorazioneCommittente(
  lottoId: number,
  lavorazioneCommittenteToDelete: LavorazioneCommittente
) {
  return fetch(
    baseUrlLavorazioniCommittenti(lottoId, lavorazioneCommittenteToDelete.id),
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: headerset().Authorization,
      },
    }
  )
    .then(handleResponse)
    .catch(handleError);
}
