import { toast } from "react-toastify";

export enum ErrorStatusTypes {
	OK = "ok",
	ERROR = "error",
	PENDING = "pending",
	SUCCESS = "success",
}
export interface ErrorsStack {
	status: ErrorStatusTypes;
	saving?: boolean;
	data?: [] | {};
	messages?: string[];
	errors?: [];
	fieldsErrors?: { [key: string]: string[] };
}

export function parseErrorMessage(error: any): ErrorsStack {
	try {
		// Tentativo di rimuovere il prefisso "Error: " se presente
		const message = error.message.replace(/^Error:\s*/, "");
		// Verifica se il messaggio sembra essere un JSON
		if (message.startsWith("{")) {
			const parsedMessage = JSON.parse(message);
			// Assumi che il messaggio JSON abbia una struttura compatibile con ErrorsStack
			return {
				status: ErrorStatusTypes.ERROR,
				fieldsErrors: parsedMessage,
			};
		} else {
			// Il messaggio di errore è una stringa semplice, non JSON
			toast.error(message, {
				autoClose: 7000,
			});
			return {
				status: ErrorStatusTypes.ERROR,
				messages: [message], // Usa un array per mantenere la compatibilità con l'interfaccia
			};
		}
	} catch (e) {
		// Asserzione di tipo per l'errore
		const error = e as Error;
		toast.error(error.message, {
			autoClose: 7000,
		});
		// Il parsing JSON ha fallito, trattalo come un errore generico
		return {
			status: ErrorStatusTypes.ERROR,
			messages: [`Errore di parsing: ${error.message}`],
		};
	}
}
