import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Committente, Articolo } from "../anagrafiche/anagraficheSlice";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { NumericFormat, NumericFormatProps } from "react-number-format";

import {
	DocumentoDiTrasporto,
	DettaglioDDT,
	ListinoVenditaArticoli,
} from "./bolleFattureSlice";
import FormControl from "@mui/material/FormControl";
import InputLabel, { InputLabelProps } from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { RootState } from "../../../redux/store";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { Button } from "@mui/material";

interface DdtDettaglioFormProps {
	articoli: Articolo[];

	dettaglioDdt: DettaglioDDT;
	aggiungiDettagliDdt: (dettaglioDdt: DettaglioDDT) => void;
	documentoDiTrasporto: DocumentoDiTrasporto;
}

const withValueInteger = (numberFormatValues: NumericFormatProps) => {
	return Number.isInteger(numberFormatValues["aria-flowto"]);
};

const DdtDettaglioForm = ({
	articoli,
	dettaglioDdt,
	aggiungiDettagliDdt,
	documentoDiTrasporto,
}: DdtDettaglioFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<DettaglioDDT>();

	const listiniVenditaArticoli = useSelector<
		RootState,
		ListinoVenditaArticoli[]
	>((state) => state.bolleFattureState.listiniVenditaArticoli.results);
	const committente = useSelector<RootState, Committente | undefined>((state) =>
		state.anagrafiche.committenti.results.find(
			(committente) => committente.id == documentoDiTrasporto.destinatario
		)
	);
	const [articoloScelto, setArticoloScelto] = useState<string | null>(null);

	const trovaPrezzo = (articoloScelto: string) => {
		const listino = listiniVenditaArticoli.find(
			(listino) => listino.id == committente?.listino_vendita_articoli
		);
		const prezzo = listino?.prezzi.find(
			(prezzo) => prezzo.articolo.toString() == articoloScelto
		);
		return prezzo?.prezzo || "";
	};

	const listaCampi = Object.keys(dettaglioDdt) as Array<keyof DettaglioDDT>;

	useEffect(() => {
		listaCampi.map((field) => {
			setValue(field, dettaglioDdt[field]);
		});
	}, []);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			dettaglioDdt?.errorsStack?.fieldsErrors &&
				dettaglioDdt.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: dettaglioDdt.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [dettaglioDdt?.errorsStack?.fieldsErrors]);

	const onSubmit: SubmitHandler<DettaglioDDT> = (dettaglioDdt) => {
		aggiungiDettagliDdt(dettaglioDdt);
	};
	const [articolo, setArticolo] = useState("");
	const handllerSelectArticolo = (event: SelectChangeEvent) => {
		setArticolo(event.target.value as string);
		const descrizione_articolo = articoli.find(
			(articolo) => articolo.id.toString() == event.target.value
		);
		descrizione_articolo?.descrizione &&
			setValue("storico_descrizione", descrizione_articolo?.descrizione);
		descrizione_articolo?.codice_articolo &&
			setValue("storico_articolo", descrizione_articolo?.codice_articolo);
		setArticoloScelto(event.target.value);
	};

	useEffect(() => {
		if (articoloScelto) {
			const nuovoPrezzo = trovaPrezzo(articoloScelto);
			nuovoPrezzo && articoloScelto && setValue("storico_prezzo", nuovoPrezzo);
		}
	}, [articoloScelto]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack direction="row" spacing={2} justifyContent="center">
				<FormControl sx={{ width: "100%" }}>
					<InputLabel id="articolo_label" shrink={true}>
						articolo
					</InputLabel>
					<Select
						key="articolo"
						id="articolo"
						label="articolo"
						variant="outlined"
						disabled={!!dettaglioDdt.id}
						// notched={true}
						value={articolo}
						sx={{ width: "100%" }}
						onChange={handllerSelectArticolo}
					>
						{articoli.map((option) => {
							return (
								<MenuItem key={option.id} value={option.id}>
									{option.descrizione}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
				<TextField
					id="storico_codice_tracciabilita"
					label="codice tracciabilita"
					{...register("storico_codice_tracciabilita")}
					error={!!errors?.storico_codice_tracciabilita}
					helperText={errors?.storico_codice_tracciabilita?.message}
					disabled={!!dettaglioDdt.id}
					InputLabelProps={{
						shrink: true,
					}}
					variant="outlined"
					autoComplete={"off"}
					sx={{ width: "100%", bgcolor: "white" }}
				/>
				<TextField
					id="storico_descrizione"
					label="descrizione"
					required={true}
					{...register("storico_descrizione")}
					error={!!errors?.storico_descrizione}
					helperText={errors?.storico_descrizione?.message}
					InputLabelProps={{
						shrink: true,
					}}
					variant="outlined"
					autoComplete={"off"}
					sx={{ width: "100%", bgcolor: "white" }}
				/>
				<Controller
					name="colli"
					control={control}
					render={({ field }) => (
						<NumericFormat
							id="colli"
							customInput={TextField}
							variant="outlined"
							label="colli"
							InputLabelProps={{
								shrink: true,
							}}
							// isAllowed={withValueInteger}
							error={!!errors?.colli}
							helperText={errors?.colli?.message}
							// {...register("colli")}
							value={field.value}
							autoComplete="off"
							thousandSeparator="."
							decimalSeparator=","
							valueIsNumericString={true}
							onValueChange={(v) => {
								setValue("colli", Number(v.value), { shouldDirty: true });
							}}
							sx={{ width: "100%" }}
						/>
					)}
				/>
				<Controller
					name="quantita"
					control={control}
					render={({ field }) => (
						<NumericFormat
							id="quantita"
							customInput={TextField}
							variant="outlined"
							label="quantita"
							InputLabelProps={{
								shrink: true,
							}}
							error={!!errors?.quantita}
							helperText={errors?.quantita?.message}
							// {...register("quantita")}
							value={field.value}
							autoComplete="off"
							prefix="kg "
							thousandSeparator="."
							decimalSeparator=","
							valueIsNumericString={true}
							onValueChange={(v) => {
								setValue("quantita", Number(v.value), { shouldDirty: true });
							}}
							sx={{ width: "100%" }}
						/>
					)}
				/>
				<Controller
					name="storico_prezzo"
					control={control}
					render={({ field }) => (
						<NumericFormat
							id="storico_prezzo"
							customInput={TextField}
							variant="outlined"
							label="prezzo al kg"
							InputLabelProps={{
								shrink: true,
							}}
							error={!!errors?.quantita}
							helperText={errors?.quantita?.message}
							// {...register("storico_prezzo")}
							value={field.value}
							autoComplete="off"
							prefix="€ "
							thousandSeparator="."
							decimalSeparator=","
							valueIsNumericString={true}
							onValueChange={(v) => {
								setValue("storico_prezzo", Number(v.value), {
									shouldDirty: true,
								});
							}}
							sx={{ width: "100%" }}
						/>
					)}
				/>
				<Stack direction="row" spacing={2} justifyContent="center">
					<Button disabled={!isDirty} variant="contained" type="submit">
						Salva
					</Button>
				</Stack>
			</Stack>
		</form>
	);
};

export default DdtDettaglioForm;
