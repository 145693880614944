import { handleResponse, handleError, headerset } from "../apiUtils";

// export interface Progetto {
//   id: number;
//   nome: string;
//   descrizione: string;
//   data_inizio: Date;
//   deadline: Date;
//   data_fine: Date;
//   owner: number;
//   boards: [];
//   deleted: boolean;
// }

const baseUrl = (provincia_id?: number) => {
  if (provincia_id) {
    return (
      process.env.API_URL + "/api/anagrafiche/tipi_capo" + provincia_id + "/"
    );
  } else {
    return process.env.API_URL + "/api/comuni_italiani/elenco/province/";
  }
};

export function fetchProvince() {
  return fetch(baseUrl(), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: headerset().Authorization,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}
