import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { useState } from "react";

import {
	ColoreTypes,
	Lotto,
} from "../../../pianificazione/pianificazioneSlice";
import { getColore } from "../../../pianificazione/utility/lottoUtility";
import LavorazioneCommittenteFooter from "../../LavorazioneCommittenteFooter";
import { Lavorazione } from "../../lavorazioniSlice";
import moment from "moment";
const logoScarso = require("../../../../../../static/images/logo/logo-Scarso_nuovo_solo.png");

// Create styles
const styles = StyleSheet.create({
	page: {
		backgroundColor: "#fff",
		paddingTop: 20,
	},
	section: {
		marginTop: 10,
		marginLeft: 10,
		marginRight: 10,
		padding: 10,
	},
	sectionIntestazione: {
		// width: "100%",
		marginTop: 10,
		marginHorizontal: 20,
		padding: 5,
		// paddingTop: 10,
		// paddingBottom: 7,
		// backgroundColor: "#777777",
		// color: "#ffffff",
		borderWidth: 1,
		borderStyle: "solid",
		borderColor: "#000",
	},
	text: {
		fontSize: 15,
		fontStyle: "medium",
		// marginVertical: 20,
		textTransform: "uppercase",
	},
	sectionTable: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginLeft: 0,
		borderBottomWidth: 1,
	},
	sectionFrame: {
		flexDirection: "row",
		paddingHorizontal: 60,
		paddingVertical: 40,
		fontSize: 180,
		fontStyle: "medium",
		borderWidth: 1,
	},
});

interface GrandeImballoPdfProps {
	lotto: Lotto;
	lavorazione: Lavorazione;
}

const GrandeImballoPdf = ({ lotto, lavorazione }: GrandeImballoPdfProps) => {
	const colore = getColore(lotto) == ColoreTypes.G ? "#fcdb03" : "white";

	return (
		<>
			<View
				key={"etichetta_schedaCarico_" + lotto.id}
				style={[
					styles.sectionIntestazione,
					{
						// borderWidth: 1,
						flexDirection: "row",
						justifyContent: "center",
					},
				]}
			>
				<Text style={[styles.text, { fontSize: 24, width: "95%" }]}>
					identificazione grande imballo
				</Text>
				<Image
					src={logoScarso}
					style={{
						width: 30,
						height: 30,
						// margin: 0,
						padding: 0,
						marginLeft: "3px",
					}}
				/>
			</View>
			<View
				key={"Codice_" + lotto.id}
				style={{
					borderWidth: 0,
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "flex-end",
					marginTop: 3,
					marginHorizontal: 20,
					padding: 2,
				}}
			>
				<Text
					style={[
						// styles.text,
						{
							width: "15%",
							fontSize: 18,
							paddingBottom: 1,
							textAlign: "right",
						},
					]}
				>
					Codice:
				</Text>
				<Text
					style={[
						styles.text,
						{ width: "85%", fontSize: 20, paddingBottom: 1, marginLeft: 5 },
					]}
				>
					{lavorazione.codice_articolo_committente}
				</Text>
			</View>
			<View
				key={"Prodotto_" + lotto.id}
				style={{
					borderWidth: 0,
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "flex-end",
					marginTop: 3,
					marginHorizontal: 20,
					padding: 2,
				}}
			>
				<Text
					style={[
						// styles.text,
						{
							width: "15%",
							fontSize: 18,
							paddingBottom: 1,
							textAlign: "right",
						},
					]}
				>
					Prodotto:
				</Text>
				<Text
					style={[
						styles.text,
						{ width: "85%", fontSize: 20, paddingBottom: 1, marginLeft: 5 },
					]}
				>
					{lavorazione.descrizione || lavorazione.descrizione_articolo}
				</Text>
			</View>
			<View
				style={{
					flexDirection: "column",
					marginTop: 10,
					borderWidth: 1,
					marginHorizontal: 20,
				}}
			>
				<View style={{ flexDirection: "row", alignItems: "flex-start" }}>
					<View style={{ borderRightWidth: 1, width: "35%" }}>
						<Text style={(styles.text, { fontSize: 22, padding: 5 })}>
							DATA
						</Text>
					</View>
					<View>
						<Text style={(styles.text, { fontSize: 22, padding: 5 })}>
							{moment(lotto.data_lavorazione).format("dddd DD MMM YYYY")}
						</Text>
					</View>
				</View>
			</View>
			<View
				style={{
					flexDirection: "row",
					borderWidth: 1,
					marginHorizontal: 20,
					justifyContent: "center",
					backgroundColor: colore,
				}}
			>
				<Text
					style={[
						styles.text,
						{
							margin: 5,
							fontSize: 22,
						},
					]}
				>
					Lotto
				</Text>
			</View>
			<View
				style={{
					flexDirection: "row",
					borderWidth: 1,
					marginHorizontal: 20,
					justifyContent: "center",
				}}
			>
				<Text
					style={[
						styles.text,
						{
							margin: 10,
							fontSize: 66,
							letterSpacing: 12,
						},
					]}
				>
					{lotto.codice_tracciabilita}
				</Text>
			</View>
			<View
				style={{
					flexDirection: "column",
					borderWidth: 1,
					marginHorizontal: 20,
					justifyContent: "space-between",
					marginTop: 0,
					paddingBottom: 4,
				}}
			>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
					}}
				>
					<Text
						style={[
							styles.text,
							{
								margin: 10,
								fontSize: 25,
							},
						]}
					>
						Carne Avicola: ITALIA
					</Text>
					<Text
						style={[
							styles.text,
							{
								margin: 10,
								fontSize: 25,
							},
						]}
					>
						{lotto?.allevamento_pack?.codice_asl}
					</Text>
				</View>

				<Text
					style={{
						marginLeft: 10,
						fontSize: 15,
					}}
				>
					conservare da 0 C° a +4 C° - da consumarsi previo cottura
				</Text>
				<Text
					style={{
						marginLeft: 10,
						fontSize: 15,
					}}
				>
					prodotto non destinato a consumatore finale
				</Text>
			</View>
			{/* <View
				style={{
					flexDirection: "row",
					borderWidth: 1,
					marginHorizontal: 20,
					justifyContent: "space-between",
					marginTop: 3,
				}}
			>
				<View
					style={{
						flexDirection: "column",
						justifyContent: "space-between",
						width: "33%",
						margin: 0,
					}}
				>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							borderWidth: 1,
							// height: 15,
						}}
					>
						<Text
							style={{
								marginLeft: 10,
								fontSize: 15,
							}}
						>
							{" "}
						</Text>
					</View>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							borderWidth: 1,
							height: 100,
						}}
					></View>
				</View>
				<View
					style={{
						flexDirection: "column",
						justifyContent: "space-between",
						width: "33%",
						margin: 0,
					}}
				>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							borderWidth: 1,
							// height: 15,
						}}
					>
						<Text
							style={{
								marginLeft: 10,
								fontSize: 15,
							}}
						>
							Q.tà Kg (vedi etichetta)
						</Text>
					</View>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							borderWidth: 1,
							height: 100,
						}}
					></View>
				</View>
				<View
					style={{
						flexDirection: "column",
						justifyContent: "space-between",
						width: "33%",
						margin: 0,
					}}
				>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							borderWidth: 1,
							// height: 15,
						}}
					>
						<Text
							style={{
								marginLeft: 10,
								fontSize: 15,
							}}
						>
							Casse N°
						</Text>
					</View>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							borderWidth: 1,
							height: 100,
						}}
					></View>
				</View>
			</View>

			<View
				style={{
					flexDirection: "row",
					marginHorizontal: 20,
					justifyContent: "space-between",
					marginTop: 3,
				}}
			>
				<Text
					style={{
						marginLeft: 10,
						fontSize: 20,
					}}
				>
					Destinazione: {lavorazione.destinazione || lotto.committente_desc}
				</Text>
			</View> */}

			<View
				style={{
					flexDirection: "row",
					borderWidth: 1,
					marginHorizontal: 20,
					justifyContent: "center",
					marginTop: 0,
					height: 420,
				}}
			>
				<Text
					style={{
						marginTop: 100,
						fontSize: 12,
						color: "#bbb",
					}}
				>
					Spazio per etichetta totale pallet
				</Text>
			</View>
		</>
	);
};

export default GrandeImballoPdf;
