import React from "react";
import { useEffect } from "react";
import { Lotto, saveLotto } from "../../pianificazione/pianificazioneSlice";
import { useAppDispatch } from "../../../../redux/store";

import { useForm, SubmitHandler, Controller } from "react-hook-form";

import TextField from "@mui/material/TextField";
import Alert from "../../../common/Alert";
import {
	NumericFormat,
	NumericFormatProps,
	PatternFormat,
} from "react-number-format";

interface SchedaCaricoLottoPesoMedioFormProps {
	lotto: Lotto;
}

const SchedaCaricoLottoPesoMedioForm = ({
	lotto,
}: SchedaCaricoLottoPesoMedioFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty, isValid },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<Lotto>();

	const listaCampi = Object.keys(lotto) as Array<keyof Lotto>;

	const dispatch = useAppDispatch();

	const handlerSaveLotto = (lotto: Lotto) => {
		dispatch(saveLotto(lotto));
	};

	useEffect(() => {
		reset();
		listaCampi.map((field) => {
			setValue(field, lotto[field]);
		});
	}, [
		listaCampi.forEach((field) => {
			lotto[field];
		}),
		lotto.id,
		lotto.allevamento,
	]);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			lotto?.errorsStack?.fieldsErrors &&
				lotto.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message: lotto.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [
		lotto?.errorsStack?.fieldsErrors,
		// lotto.allevamento_pack?.errorsStack?.fieldsErrors,
	]);

	const handlerDetailChaingSubmit = () => {
		if (isValid && isDirty) {
			const formValues = getValues();
			handlerSaveLotto(formValues);
		}
	};
	const onSubmit: SubmitHandler<Lotto> = (lotto) => {
		handlerSaveLotto(lotto);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{lotto?.errorsStack?.fieldsErrors?.non_field_errors && (
				<Alert severity="error">
					{lotto?.errorsStack?.fieldsErrors?.non_field_errors.toString()}
				</Alert>
			)}
			<Controller
				name="peso_medio"
				control={control}
				render={({ field }) => (
					<NumericFormat
						id="peso_medio"
						customInput={TextField}
						variant="outlined"
						size="small"
						label="peso medio"
						InputLabelProps={{
							shrink: true,
						}}
						error={!!errors?.peso_medio}
						helperText={errors?.peso_medio?.message}
						{...register("peso_medio")}
						// value={Intl.NumberFormat("it-IT").format(field.value || 0)}
						// value={Intl.NumberFormat("it-IT").format(getValues("peso_medio") || 0)}
						value={field.value}
						autoComplete="off"
						prefix="kg "
						thousandSeparator="."
						decimalSeparator=","
						valueIsNumericString={true}
						onValueChange={(v) => {
							setValue("peso_medio", Number(v.value));
						}}
						sx={{ width: "100%" }}
						onBlur={handlerDetailChaingSubmit}
					/>
				)}
			/>
		</form>
	);
};

export default SchedaCaricoLottoPesoMedioForm;
