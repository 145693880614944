import React from "react";
import moment from "moment";

import {
	PDFViewer,
	Page,
	Document,
	StyleSheet,
	Text,
	View,
	Image,
} from "@react-pdf/renderer";

import { Lotto } from "../../../pianificazione/pianificazioneSlice";
import { getColore } from "../../../pianificazione/utility/lottoUtility";
const logoScarso = require("../../../../../../static/images/logo/logo-Scarso_nuovo_solo.png");

// Create styles
const styles = StyleSheet.create({
	page: {
		backgroundColor: "#fff",
		paddingTop: 20,
	},
	tableContainer: {
		flexDirection: "column",
		flexWrap: "wrap",
	},
	sectionOrdine: {
		flexDirection: "row",
		justifyContent: "flex-start",
		paddingVertical: 8,
		paddingHorizontal: 3,
		width: "3%",
		borderLeftWidth: 1,
		borderBottomWidth: 1,
	},
	sectionAllevatore: {
		flexDirection: "row",
		justifyContent: "flex-start",
		paddingVertical: 8,
		paddingHorizontal: 3,
		width: "22%",
		borderLeftWidth: 1,
		borderBottomWidth: 1,
	},
	sectionConformita: {
		flexDirection: "row",
		justifyContent: "flex-start",
		paddingVertical: 10,
		width: "4%",
		borderLeftWidth: 1,
		borderBottomWidth: 1,
	},
	sectionNote: {
		flexDirection: "row",
		justifyContent: "flex-start",
		paddingVertical: 10,
		width: "35%",
		borderLeftWidth: 1,
		borderRightWidth: 1,
		borderBottomWidth: 1,
	},
	text: {
		fontSize: 12,
		fontStyle: "medium",
	},
	intestazioneSezioni: {
		borderTopWidth: 1,
		borderLeftWidth: 1,
		borderBottomWidth: 1,
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		width: "8%",
	},
	intestazioneCNC: {
		borderLeftWidth: 1,
		borderBottomWidth: 1,
		flexDirection: "row",
		flexWrap: "nowrap",
		justifyContent: "center",
		alignItems: "center",
		width: "4%",
	},
	textCNC: {
		fontSize: 12,
		fontStyle: "medium",
		textAlign: "center",
		paddingVertical: 2,
	},
});

interface SchedaHaccpPdfProps {
	lotti: Lotto[];
}

const SchedaHaccpPdf = ({ lotti }: SchedaHaccpPdfProps) => {
	const totaleCapi = lotti.reduce(function (previousValue, currentValue) {
		return previousValue + (currentValue.numero_capi || 0);
	}, 0);
	return (
		<PDFViewer width={"100%"} height={"100%"}>
			<Document>
				<Page
					size="A4"
					style={styles.page}
					orientation="landscape"
					key={"scheda_haccp"}
				>
					<View
						key={"scheda_haccp_intestazione_1riga"}
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							marginHorizontal: 10,
							marginTop: 5,
						}}
					>
						<View
							key={"scheda_haccp_intestazione_1riga_sinistra"}
							style={{
								borderTopWidth: 1,
								borderLeftWidth: 1,
								borderBottomWidth: 1,
								flexDirection: "column",
								justifyContent: "center",
								width: "25%",
							}}
						>
							<View style={{ paddingHorizontal: 20, alignItems: "center" }}>
								<Text
									style={[
										styles.text,
										{
											fontSize: 10,
											paddingTop: 8,
										},
									]}
								>
									SCARSO Romualdo e FIGLI s.r.l.
								</Text>
								<Text
									style={[
										styles.text,
										{
											fontSize: 10,
											paddingBottom: 8,
										},
									]}
								>
									Ponte San Nicolò (PD)
								</Text>
							</View>
						</View>
						<View
							key={"scheda_haccp_intestazione_1riga_centro"}
							style={{
								borderTopWidth: 1,
								borderLeftWidth: 1,
								borderRightWidth: 1,
								borderBottomWidth: 1,
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								width: "50%",
							}}
						>
							<Text
								style={[
									styles.text,
									{
										fontSize: 18,
									},
								]}
							>
								MODULO
							</Text>
						</View>
						<View
							key={"scheda_haccp_intestazione_1riga_destra"}
							style={{
								borderTopWidth: 1,
								borderRightWidth: 1,
								borderBottomWidth: 1,
								flexDirection: "column",
								justifyContent: "center",
								width: "25%",
							}}
						>
							<View
								style={{
									paddingRight: 5,
									alignItems: "flex-end",
								}}
							>
								<Text
									style={[
										styles.text,
										{
											fontSize: 10,
											paddingTop: 4,
										},
									]}
								>
									MOD HACCP 01
								</Text>
								<Text
									style={[
										styles.text,
										{
											fontSize: 10,
										},
									]}
								>
									Data: 30.08.2019
								</Text>
								<Text
									style={[
										styles.text,
										{
											fontSize: 10,
											paddingBottom: 4,
										},
									]}
								>
									Rev 01
								</Text>
							</View>
						</View>
					</View>

					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							marginHorizontal: 10,
						}}
					>
						<View
							key={"scheda_haccp_intestazione_2riga"}
							style={{
								borderRightWidth: 1,
								borderLeftWidth: 1,
								borderBottomWidth: 1,
								flexDirection: "row",
								justifyContent: "center",
								width: "100%",
							}}
						>
							<Text
								style={[
									styles.text,
									{
										fontSize: 14,
										paddingVertical: 6,
									},
								]}
							>
								CONTROLLO DOCUMENTI ACCETTAZIONE ANIMALI VIVI
							</Text>
						</View>
					</View>

					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							marginTop: 30,
							marginLeft: 15,
						}}
					>
						<View
							key={"scheda_haccp_data"}
							style={{
								flexDirection: "row",
								justifyContent: "flex-start",
								width: "65%",
							}}
						>
							<Text
								style={[
									styles.text,
									{
										paddingBottom: 4,
										textTransform: "uppercase",
									},
								]}
							>
								Data:{"   "}
								{moment(lotti[0]?.data_lavorazione).format("dddd DD/MM/YYYY")}
							</Text>
						</View>
						<View
							key={"scheda_haccp_firma"}
							style={{
								flexDirection: "row",
								justifyContent: "flex-start",
								width: "35%",
							}}
						>
							<Text
								style={[
									styles.text,
									{
										paddingBottom: 4,
									},
								]}
							>
								FIRMA Responsabile Carico Animali
							</Text>
						</View>
					</View>
					{/* <View
						style={{
							flexDirection: "row",
							justifyContent: "flex-start",
							// marginTop: 20,
							marginLeft: 15,
						}}
					>
						<Text
							style={[
								styles.text,
								{
									paddingBottom: 4,
								},
							]}
						>
							vivi:
							...............................................................
						</Text>
					</View> */}

					<View
						key={"scheda_haccp_tabella_titolo_1riga"}
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							marginHorizontal: 10,
							marginTop: 30,
						}}
					>
						<View
							key={"scheda_haccp_tabella_titolo_lotto_allevatore"}
							style={{
								borderTopWidth: 1,
								borderLeftWidth: 1,
								flexDirection: "column",
								justifyContent: "center",
								width: "25%",
							}}
						>
							<View style={{ paddingHorizontal: 5, alignItems: "flex-start" }}>
								<Text
									style={[
										styles.text,
										{
											paddingTop: 8,
										},
									]}
								>
									ALLEVATORE
								</Text>
								<Text
									style={[
										styles.text,
										{
											paddingBottom: 8,
										},
									]}
								>
									N° ordine/Allevatore
								</Text>
							</View>
						</View>
						<View
							key={"scheda_haccp_tabella_titolo_arrivo"}
							style={styles.intestazioneSezioni}
						>
							<Text
								style={[
									styles.text,
									{
										textAlign: "center",
									},
								]}
							>
								Conferma arrivo
							</Text>
						</View>
						<View
							key={"scheda_haccp_tabella_titolo_documentazione"}
							style={styles.intestazioneSezioni}
						>
							<Text
								style={[
									styles.text,
									{
										textAlign: "center",
									},
								]}
							>
								Documentazione
							</Text>
						</View>
						<View
							key={"scheda_haccp_tabella_titolo_sospensione"}
							style={styles.intestazioneSezioni}
						>
							<Text
								style={[
									styles.text,
									{
										textAlign: "center",
									},
								]}
							>
								Rispetto tempi di sospensione
							</Text>
						</View>
						<View
							key={"scheda_haccp_tabella_titolo_salmonella"}
							style={styles.intestazioneSezioni}
						>
							<Text
								style={[
									styles.text,
									{
										textAlign: "center",
									},
								]}
							>
								Rispetto tempi per controllo salmonella
							</Text>
						</View>
						<View
							key={"scheda_haccp_tabella_titolo_disinfezione"}
							style={styles.intestazioneSezioni}
						>
							<Text
								style={[
									styles.text,
									{
										textAlign: "center",
									},
								]}
							>
								Avvenuta disinfezione
							</Text>
						</View>
						<View
							key={"scheda_haccp_tabella_note"}
							style={{
								borderTopWidth: 1,
								borderLeftWidth: 1,
								borderRightWidth: 1,
								flexDirection: "row",
								justifyContent: "center",
								width: "35%",
							}}
						>
							<Text
							// style={[
							// 	styles.text,
							// 	{
							// 		textAlign: "center",
							// 		paddingTop: 8,
							// 	},
							// ]}
							>
								{/* Note */}
							</Text>
						</View>
					</View>

					<View
						key={"scheda_haccp_tabella_titolo_2riga"}
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							marginHorizontal: 10,
						}}
					>
						<View
							style={{
								borderLeftWidth: 1,
								borderBottomWidth: 1,
								flexDirection: "column",
								justifyContent: "center",
								width: "25%",
							}}
						>
							<Text></Text>
						</View>
						{[...Array(5)].map((i) => {
							return (
								<>
									<View key={"campo_C_" + i} style={styles.intestazioneCNC}>
										<Text style={styles.textCNC}>C</Text>
									</View>
									<View key={"campo_NC_" + i} style={styles.intestazioneCNC}>
										<Text style={styles.textCNC}>NC</Text>
									</View>
								</>
							);
						})}

						<View
							style={{
								borderLeftWidth: 1,
								borderRightWidth: 1,
								borderBottomWidth: 1,
								flexDirection: "row",
								justifyContent: "center",
								width: "35%",
							}}
						>
							<Text></Text>
						</View>
					</View>

					<View
						style={{
							flexDirection: "column",
							justifyContent: "center",
							marginHorizontal: 10,
						}}
					>
						<View style={styles.tableContainer}>
							{lotti.map((lotto) => {
								return (
									<View style={{ flexDirection: "row" }}>
										<View
											key={"ordine_lotto_" + lotto.id}
											style={styles.sectionOrdine}
										>
											<Text style={{ fontSize: 12 }}>
												{lotto.ordinamento_lotto}
											</Text>
										</View>
										<View
											key={"allevatore_lotto_" + lotto.id}
											style={styles.sectionAllevatore}
										>
											<Text style={{ fontSize: 10 }}>
												{lotto.allevamento_pack?.nome}
											</Text>
										</View>
										{[...Array(5)].map((i) => {
											return (
												<>
													<View
														key={"campo_C_" + i + "_lotto_" + lotto.id}
														style={styles.sectionConformita}
													>
														<Text style={{ fontSize: 10 }}></Text>
													</View>
													<View
														key={"campo_NC_" + i + "_lotto_" + lotto.id}
														style={styles.sectionConformita}
													>
														<Text style={{ fontSize: 10 }}></Text>
													</View>
												</>
											);
										})}
										<View
											key={"note_lotto_" + lotto.id}
											style={styles.sectionNote}
										>
											<Text style={{ fontSize: 10 }}></Text>
										</View>
									</View>
								);
							})}
						</View>
					</View>
				</Page>
			</Document>
		</PDFViewer>
	);
};

export default SchedaHaccpPdf;
