import React from "react";
import Box from "@mui/material/Box";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

function Alert(props: AlertProps) {
	return (
		<Box m={3}>
			<MuiAlert elevation={6} variant="filled" {...props} />
		</Box>
	);
}

export default Alert;
