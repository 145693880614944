import React from "react";
import { useEffect, useState } from "react";

import { Committente } from "../anagrafiche/anagraficheSlice";

import { useForm, SubmitHandler } from "react-hook-form";
import ReactHookFormSelect from "../../common/ReactHookFormSelect";

import {
	DocumentoDiTrasporto,
	DestinazioneTrasporto,
	VettoreTrasporto,
	CausaleTrasportoTypes,
	AspettoEsterioreBeniTypes,
} from "./bolleFattureSlice";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Fingerprint from "@mui/icons-material/Fingerprint";

import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";

interface DdtTestataFormProps {
	documentoDiTrasporto: DocumentoDiTrasporto;
	committenti: Committente[];
	destinazioniTrasporto: DestinazioneTrasporto[];
	vettoriTrasporto: VettoreTrasporto[];

	saveDocumentoDiTrasporto: (
		documentoDiTrasporto: DocumentoDiTrasporto
	) => void;
}

const DdtTestataForm = ({
	documentoDiTrasporto,
	committenti,
	destinazioniTrasporto,
	vettoriTrasporto,
	saveDocumentoDiTrasporto,
}: DdtTestataFormProps) => {
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
		setValue,
		watch,
		getValues,
		control,
		setError,
		clearErrors,
		reset,
	} = useForm<DocumentoDiTrasporto>();

	const listaCampi = Object.keys(documentoDiTrasporto) as Array<
		keyof DocumentoDiTrasporto
	>;

	useEffect(() => {
		listaCampi.map((field) => {
			setValue(field, documentoDiTrasporto[field]);
		});
	}, []);

	useEffect(() => {
		clearErrors();
		listaCampi.forEach((field) => {
			documentoDiTrasporto?.errorsStack?.fieldsErrors &&
				documentoDiTrasporto.errorsStack.fieldsErrors[field] &&
				setError(field, {
					type: "server",
					message:
						documentoDiTrasporto.errorsStack.fieldsErrors[field].toString(),
				});
		});
	}, [documentoDiTrasporto?.errorsStack?.fieldsErrors]);

	useEffect(() => {
		if (
			getValues("destinatario") &&
			getValues("destinatario") != documentoDiTrasporto.destinatario
		) {
			setValue(
				"causale_trasporto",
				committenti.find(
					(committente) => committente.id == getValues("destinatario")
				)?.causale_trasporto_default || "L"
			);
		}
	}, [watch("destinatario")]);

	const onSubmit: SubmitHandler<DocumentoDiTrasporto> = (
		documentoDiTrasporto
	) => {
		saveDocumentoDiTrasporto(documentoDiTrasporto);
	};

	const [
		destinazioniTrasportoCompatibili,
		setDestinazioniTrasportoCompatibili,
	] = useState<DestinazioneTrasporto[]>(
		destinazioniTrasporto.filter(
			(dest) => dest.destinatario == documentoDiTrasporto.destinatario
		)
	);
	useEffect(() => {
		setDestinazioniTrasportoCompatibili(
			destinazioniTrasporto.filter(
				(dest) => dest.destinatario == watch("destinatario")
			)
		);
	}, [watch("destinatario")]);

	const testiStandard: string[] = [
		"MERCE CONSEGNATA A POLLO DEL'AVVENIRE, PER O/C CLAI",
		"MERCE CONSEGNATA A GESCO SOC.COOP. AGRICOLA, IN C/CONFERIMENTO, PER O/C AVI.COOP SOC COOP. AGRICOLA",
	];
	const [testiStandardOpen, setTestiStandardOpen] = useState(false);

	const handleSwitchTestiStandardOpen = () => {
		setTestiStandardOpen(!testiStandardOpen);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid
				container
				rowSpacing={1}
				columnSpacing={{ xs: 1 }}
				justifyContent="center"
				alignItems="flex-start"
			>
				<Grid item xs={6} sm={4} md={3}>
					<ReactHookFormSelect
						name="destinatario"
						label="destinatario"
						register={register}
						control={control}
						disabled={documentoDiTrasporto.righe_dettaglio.length > 0}
						watch={watch}
						variant="outlined"
						required={true}
						error={errors?.destinatario?.message}
						sx={{ width: "100%" }}
					>
						{committenti.map((option) => {
							return (
								<MenuItem key={option.id} value={option.id}>
									{option.nome}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</Grid>
				<Grid item xs={6} sm={4} md={3}>
					<ReactHookFormSelect
						name="destinazione"
						label="destinazione"
						register={register}
						control={control}
						watch={watch}
						variant="outlined"
						// required={true}
						error={errors?.destinazione?.message}
						sx={{ width: "100%" }}
					>
						<MenuItem key={-1} value={""}>
							= destinatario (vuoto)
						</MenuItem>
						{destinazioniTrasportoCompatibili.map((option) => {
							return (
								<MenuItem key={option.id} value={option.id}>
									{option.ragione_sociale}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</Grid>
				<Grid item xs={6} sm={4} md={3}>
					<ReactHookFormSelect
						name="vettore"
						label="vettore"
						register={register}
						control={control}
						watch={watch}
						variant="outlined"
						// required={true}
						error={errors?.vettore?.message}
						sx={{ width: "100%" }}
					>
						<MenuItem key={0} value={""}>
							"a cura del destinatario"
						</MenuItem>
						{vettoriTrasporto.map((option) => {
							return (
								<MenuItem key={option.id} value={option.id}>
									{option.ragione_sociale}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</Grid>
				<Grid item xs={6} sm={4} md={3}>
					<ReactHookFormSelect
						name="causale_trasporto"
						label="causale trasporto"
						register={register}
						control={control}
						watch={watch}
						required={true}
						variant="outlined"
						error={errors?.causale_trasporto?.message}
						sx={{ width: "100%" }}
					>
						{/* <MenuItem key={"nullo"} value={undefined} sx={{ p: 2 }} /> */}
						{Object.entries(CausaleTrasportoTypes).map(([key, value]) => {
							return (
								<MenuItem key={key} value={key}>
									{value}
								</MenuItem>
							);
						})}
					</ReactHookFormSelect>
				</Grid>

				<Grid item sm={12} md={6}>
					<Grid
						container
						sx={{ flexGrow: 1, mb: 1 }}
						rowSpacing={1}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						justifyContent="center"
						alignItems="flex-start"
					>
						<Grid item sm={12} md={4}>
							<DesktopDatePicker
								label="data documento"
								value={watch("data_documento")}
								renderInput={(params) => (
									<TextField
										{...params}
										error={!!errors?.data_documento}
										helperText={errors?.data_documento?.message}
										sx={{ width: "100%" }}
										autoComplete={"off"}
									/>
								)}
								onChange={(newValue: any) => {
									setValue(
										"data_documento",
										newValue ? new Date(newValue) : "",
										{
											shouldDirty: true,
										}
									);
									clearErrors("data_documento");
								}}
							/>
						</Grid>
						<Grid item sm={12} md={4}>
							<DateTimePicker
								label="ora carico"
								value={watch("data_ora_trasporto")}
								renderInput={(params) => (
									<TextField
										{...params}
										error={!!errors?.data_ora_trasporto}
										helperText={errors?.data_ora_trasporto?.message}
										sx={{ width: "100%" }}
										autoComplete={"off"}
									/>
								)}
								onChange={(newValue: any) => {
									setValue(
										"data_ora_trasporto",
										newValue ? new Date(newValue) : "",
										{
											shouldDirty: true,
										}
									);
									clearErrors("data_ora_trasporto");
								}}
							/>
						</Grid>

						<Grid item sm={12} md={4}>
							<ReactHookFormSelect
								name="aspetto_esteriore_beni"
								label="aspetto esteriore beni"
								register={register}
								control={control}
								watch={watch}
								variant="outlined"
								error={errors?.aspetto_esteriore_beni?.message}
								sx={{ width: "100%" }}
							>
								<MenuItem key={"nullo"} value={""} sx={{ p: 2 }} />
								{Object.entries(AspettoEsterioreBeniTypes).map(
									([key, value]) => {
										return (
											<MenuItem key={key} value={key}>
												{value}
											</MenuItem>
										);
									}
								)}
							</ReactHookFormSelect>
						</Grid>

						<Grid
							item
							sm={12}
							md={4}
							sx={{
								pt: "16px !important",
								mt: 2,
								borderTop: "solid",
								borderTopWidth: 1,
							}}
						>
							<TextField
								id="pallet_uscita"
								label="n. pallet uscita"
								// disabled={getValues("confermato")}
								{...register("pallet_uscita")}
								InputLabelProps={{
									shrink: true,
								}}
								variant="outlined"
								fullWidth
								type="number"
								autoComplete={"off"}
								error={!!errors?.pallet_uscita}
								helperText={errors?.pallet_uscita?.message}
							/>
						</Grid>
						<Grid
							item
							sm={12}
							md={4}
							sx={{
								pt: "16px !important",
								mt: 2,
								borderTop: "solid",
								borderTopWidth: 1,
							}}
						>
							<TextField
								id="pallet_ingresso"
								label="n. pallet ingresso"
								// disabled={getValues("confermato")}
								{...register("pallet_ingresso")}
								InputLabelProps={{
									shrink: true,
								}}
								variant="outlined"
								fullWidth
								type="number"
								autoComplete={"off"}
								error={!!errors?.pallet_ingresso?.message}
								helperText={errors?.pallet_ingresso?.message}
							/>
						</Grid>
						<Grid
							item
							sm={12}
							md={4}
							sx={{
								pt: "16px !important",
								mt: 2,
								borderTop: "solid",
								borderTopWidth: 1,
							}}
						></Grid>
					</Grid>
				</Grid>

				<Grid item xs={12} sm={10} md={6}>
					<TextField
						id="annotazioni"
						label="annotazioni"
						{...register("annotazioni")}
						multiline={true}
						rows={3}
						error={!!errors?.annotazioni}
						helperText={errors?.annotazioni?.message}
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						autoComplete={"off"}
						sx={{ width: "100%", bgcolor: "white", mb: 0, pb: 0 }}
					/>

					<List
						// sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
						sx={{ fontSize: 7, color: "#aaa", mt: 0, p: 0 }}
						component="nav"
						aria-labelledby="nested-list-subheader"
					>
						<ListItemButton
							onClick={handleSwitchTestiStandardOpen}
							sx={{ mt: 0, p: 0 }}
						>
							<ListItemText primary="Testi standard" />
							{testiStandardOpen ? <ExpandLess /> : <ExpandMore />}
						</ListItemButton>
						<Collapse in={testiStandardOpen} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								{testiStandard.map((testo, i) => {
									return (
										<ListItemButton
											key={"key_" + i}
											sx={{ pl: 4 }}
											onClick={() => {
												setValue(
													"annotazioni",
													getValues("annotazioni") + "\n" + testo
												);
											}}
										>
											<ListItemText primary={testo} />
										</ListItemButton>
									);
								})}
							</List>
						</Collapse>
					</List>

					<Typography sx={{ whiteSpace: "pre-line" }}>
						{documentoDiTrasporto.annotazione_automatica}
					</Typography>
				</Grid>

				<Grid item xs={12} sm={6} md={3}>
					<Stack direction="row" spacing={2} justifyContent="center">
						<Button disabled={!isDirty} variant="contained" type="submit">
							Salva
						</Button>
					</Stack>
				</Grid>
			</Grid>
		</form>
	);
};

export default DdtTestataForm;
