const DateToday = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  const todayStr = yyyy + "-" + mm + "-" + dd;
  return today;
};

export default DateToday;
