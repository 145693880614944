import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { RootState, useAppDispatch } from "../../../../redux/store";

import { Routes_path } from "../../../routerApp";
import {
	Committente,
	Produttore,
	Autista,
} from "../../anagrafiche/anagraficheSlice";
import { UtentiEsterni } from "../ospitiSlice";

import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { theme } from "theme";
import Typography from "@mui/material/Typography";

const utentiFiltrati = (
	utenti: UtentiEsterni,
	committenteId: number | null | undefined,
	produttoreId: number | null | undefined,
	autistaId: number | null | undefined
) => {
	if (committenteId || produttoreId || autistaId) {
		return utenti.results.filter((utente) => {
			if (committenteId && utente.committente != committenteId) {
				return false;
			}
			if (produttoreId && utente.produttore != produttoreId) {
				return false;
			}
			if (autistaId && utente.autista != autistaId) {
				return false;
			}
			return true;
		});
	}
	return utenti.results;
};

interface UtentiEsterniListProps {
	utentiEsterni: UtentiEsterni;
	committenti: Committente[];
	produttori: Produttore[];
	autisti: Autista[];
}

const UtentiEsterniList = ({
	utentiEsterni,
	committenti,
	produttori,
	autisti,
}: UtentiEsterniListProps) => {
	const dispatch = useAppDispatch();

	const [anchorElCommittenteFilter, setAnchorElCommittenteFilter] =
		useState(null);
	const [anchorElProduttoreFilter, setAnchorElProduttoreFilter] =
		useState(null);
	const [anchorElAutistaFilter, setAnchorElAutistaFilter] = useState(null);

	const handleStatoFilterClose = () => {
		setAnchorElCommittenteFilter(null);
		setAnchorElProduttoreFilter(null);
		setAnchorElAutistaFilter(null);
		// onClose(selectedValue);
	};

	const [committenteId, setCommittenteId] = useState<number | null | undefined>(
		null
	);
	const [produttoreId, setProduttoreId] = useState<number | null | undefined>(
		null
	);

	const [autistaId, setAutistaId] = useState<number | null | undefined>(null);

	return (
		<List>
			<ListItem sx={{ backgroundColor: theme.palette.primary.main }}>
				<Grid container>
					<Grid item xs={4} md={2} lg={1}>
						<Typography variant="h6" color="secondary" gutterBottom>
							n*
						</Typography>
					</Grid>
					<Grid item xs={4} md={2} lg={2}>
						<Typography variant="h6" color="secondary" gutterBottom>
							Utente
						</Typography>
					</Grid>
					<Grid item xs={4} md={2} lg={2}>
						<Typography variant="h6" color="secondary" gutterBottom>
							Nome
						</Typography>
					</Grid>
					<Grid item xs={4} md={2} lg={2}>
						<Typography variant="h6" color="secondary" gutterBottom>
							Email
						</Typography>
					</Grid>
					<Grid item xs={4} md={2} lg={2}>
						<Typography variant="h6" color="secondary" gutterBottom>
							Ultimo acesso
						</Typography>
					</Grid>
					<Grid item xs={4} md={2} lg={1}>
						<Stack direction="row" spacing={1}>
							<Typography variant="h6" color="secondary" gutterBottom>
								Committente
							</Typography>
							<IconButton
								color={committenteId ? "error" : "inherit"}
								onClick={(event: any) =>
									setAnchorElCommittenteFilter(event.currentTarget)
								}
							>
								<FilterAltIcon sx={{ mt: -0.5 }} />
							</IconButton>
						</Stack>

						<Box style={{ display: "flex", justifyContent: "space-between" }}>
							<Menu
								id="simple-menu"
								anchorEl={anchorElCommittenteFilter}
								keepMounted
								open={Boolean(anchorElCommittenteFilter)}
								onClose={handleStatoFilterClose}
							>
								{committenti.map((committente) => (
									<MenuItem
										key={committente.id}
										onClick={() => {
											setCommittenteId(committente.id);
											setAnchorElCommittenteFilter(null);
										}}
										sx={{
											backgroundColor:
												committente.id == committenteId ? "#aaa" : "inherit",
										}}
									>
										{committente.ragione_sociale}
									</MenuItem>
								))}
								<MenuItem
									onClick={() => {
										setCommittenteId(undefined);
										setAnchorElCommittenteFilter(null);
									}}
									sx={{
										backgroundColor: !committenteId ? "#aaa" : "inherit",
									}}
								>
									tutti
								</MenuItem>
							</Menu>
						</Box>
					</Grid>
					<Grid item xs={4} md={2} lg={1}>
						<Stack direction="row" spacing={1}>
							<Typography variant="h6" color="secondary" gutterBottom>
								Produttore
							</Typography>
							<IconButton
								color={produttoreId ? "error" : "inherit"}
								onClick={(event: any) =>
									setAnchorElProduttoreFilter(event.currentTarget)
								}
							>
								<FilterAltIcon sx={{ mt: -0.5 }} />
							</IconButton>
						</Stack>

						<Box style={{ display: "flex", justifyContent: "space-between" }}>
							<Menu
								id="simple-menu"
								anchorEl={anchorElProduttoreFilter}
								keepMounted
								open={Boolean(anchorElProduttoreFilter)}
								onClose={handleStatoFilterClose}
							>
								{produttori.map((produttore) => (
									<MenuItem
										key={produttore.id}
										onClick={() => {
											setProduttoreId(produttore.id);
											setAnchorElProduttoreFilter(null);
										}}
										sx={{
											backgroundColor:
												produttore.id == produttoreId ? "#aaa" : "inherit",
										}}
									>
										{produttore.nome}
									</MenuItem>
								))}
								<MenuItem
									onClick={() => {
										setProduttoreId(undefined);
										setAnchorElProduttoreFilter(null);
									}}
									sx={{
										backgroundColor: !produttoreId ? "#aaa" : "inherit",
									}}
								>
									tutti
								</MenuItem>
							</Menu>
						</Box>
					</Grid>
					<Grid item xs={4} md={2} lg={1}>
						<Stack direction="row" spacing={1}>
							<Typography variant="h6" color="secondary" gutterBottom>
								Autista
							</Typography>
							<IconButton
								color={autistaId ? "error" : "inherit"}
								onClick={(event: any) =>
									setAnchorElAutistaFilter(event.currentTarget)
								}
							>
								<FilterAltIcon sx={{ mt: -0.5 }} />
							</IconButton>
						</Stack>

						<Box style={{ display: "flex", justifyContent: "space-between" }}>
							<Menu
								id="simple-menu"
								anchorEl={anchorElAutistaFilter}
								keepMounted
								open={Boolean(anchorElAutistaFilter)}
								onClose={handleStatoFilterClose}
							>
								{autisti.map((autista) => (
									<MenuItem
										key={autista.id}
										onClick={() => {
											setAutistaId(autista.id);
											setAnchorElAutistaFilter(null);
										}}
										sx={{
											backgroundColor:
												autista.id == autistaId ? "#aaa" : "inherit",
										}}
									>
										{`${autista.nome} ${autista.cognome}`}
									</MenuItem>
								))}
								<MenuItem
									onClick={() => {
										setAutistaId(undefined);
										setAnchorElAutistaFilter(null);
									}}
									sx={{
										backgroundColor: !autistaId ? "#aaa" : "inherit",
									}}
								>
									tutti
								</MenuItem>
							</Menu>
						</Box>
					</Grid>
				</Grid>
			</ListItem>
			{utentiFiltrati(
				utentiEsterni,
				committenteId,
				produttoreId,
				autistaId
			).map((utente, index) => (
				<ListItem
					key={`utenteEsterno_${utente.id}`}
					component={Link}
					to={{
						pathname: Routes_path.UTENTE_ESTERNO,
					}}
					state={{
						utente_id: utente.id,
					}}
					sx={{
						backgroundColor: index % 2 == 0 ? "#aaaaaa55" : "inherit",
						color: "#112211dd",
					}}
				>
					<Grid container>
						<Grid item xs={4} md={2} lg={1}>
							{utente.id}
						</Grid>
						<Grid item xs={4} md={2} lg={2}>
							{utente.user?.username}
						</Grid>
						<Grid item xs={4} md={2} lg={2}>
							{`${utente.user?.first_name} ${utente.user?.last_name}`}
						</Grid>
						<Grid item xs={4} md={2} lg={2}>
							{utente.user?.email}
						</Grid>
						<Grid item xs={4} md={2} lg={2}>
							{moment(utente.user?.last_login).format("ddd DD yyyy hh:mm")}
						</Grid>
						<Grid item xs={4} md={2} lg={1}>
							{
								committenti.find(
									(committente) => committente.id == utente.committente
								)?.nome
							}
						</Grid>
						<Grid item xs={4} md={2} lg={1}>
							{
								produttori.find(
									(produttore) => produttore.id == utente.produttore
								)?.nome
							}
						</Grid>
						<Grid item xs={4} md={2} lg={1}>
							{autisti.find((autista) => autista.id == utente.autista)?.cognome}
						</Grid>
					</Grid>
				</ListItem>
			))}
		</List>
	);
};

export default UtentiEsterniList;
