import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import {
	Camion,
	Autista,
	fetchCamions,
	fetchAutisti,
} from "../anagrafiche/anagraficheSlice";
import {
	Lotto,
	getLotto,
	PianificazioneStrutturaState,
} from "../pianificazione/pianificazioneSlice";
import * as eventiManutenzioneApi from "../../../api/eventiManutenzione/eventiManutenzioneApi";
import moment from "moment";
import { toast } from "react-toastify";
// import { useDispatch } from "react-redux";

moment.locale("it");

export enum StatoEventoManutenzioneTypes {
	P = "Pianificato",
	E = "in Esecuzione",
	T = "Terminato",
}

export interface EventoManutenzione {
	id?: number | null;
	stato: "P" | "E" | "T";

	camion?: number;
	descrizione?: string;
	data_inizio_pianificata?: Date | string;
	data_fine_pianificata?: Date | string;
	data_inizio_manutenzione?: Date | string;
	data_fine_manutenzione?: Date | string;
	km_evento?: number;
	costo?: number;
	tipo_manutentore?: "I" | "E";
	manutentore?: string;
	note?: string;
	errorsStack?: ErrorsStack;
}

export interface EventiManutenzioneState {
	count: number;
	page: number;
	num_pages: number;
	next?: URL;
	previous?: URL;
	results: EventoManutenzione[];
	errorsStack: ErrorsStack;
}

// export interface LottoCorrente extends Lotto {
//   errorsStack: ErrorsStack;
// }

export interface ManutenzioneMezziStrutturaState {
	eventiManutenzione: EventiManutenzioneState;
	nuovoEventoManutenzioneId?: number;
}

const initialState: ManutenzioneMezziStrutturaState = {
	eventiManutenzione: {
		count: 0,
		page: 0,
		num_pages: 0,
		next: undefined,
		previous: undefined,
		results: [],
		errorsStack: { status: ErrorStatusTypes.OK },
	},
};

export const fetchEventiManutenzione = createAsyncThunk(
	"eventoManutenzione/fetchEventiManutenzione",
	async (parametri: {
		stato?: string;
		camion_id?: number;
		data_da?: Date;
		data_a?: Date;
		numeroRecord?: number;
		page?: number;
		search?: string;
	}) => {
		return await eventiManutenzioneApi.fetchEventiManutenzione(
			parametri.stato,
			parametri.camion_id,
			parametri.data_da,
			parametri.data_a,
			parametri.numeroRecord,
			parametri.page,
			parametri.search
		);
	}
);

export const getEventoManutenzione = createAsyncThunk(
	"eventoManutenzione/getEventoManutenzione",
	async (eventoManutenzioneId: number) => {
		return await eventiManutenzioneApi.getEventoManutenzione(
			eventoManutenzioneId
		);
	}
);

export const saveEventoManutenzione = createAsyncThunk(
	"eventoManutenzione/saveEventoManutenzione",
	async (eventoManutenzioneToSave: EventoManutenzione, thunkApi) => {
		return await eventiManutenzioneApi.saveEventoManutenzione(
			eventoManutenzioneToSave
		);
	}
);

export const deleteEventoManutenzione = createAsyncThunk(
	"eventoManutenzione/deleteEventoManutenzione",
	async (eventoManutenzioneToDelete: EventoManutenzione, thunkApi) => {
		return await eventiManutenzioneApi.deleteEventoManutenzione(
			eventoManutenzioneToDelete
		);
	}
);

export const manutenzioneMezziSlice = createSlice({
	name: "manutenzioneMezziState",
	initialState,
	reducers: {
		resetEventoManutenzioneId: (state) => {
			state.nuovoEventoManutenzioneId = undefined;
		},
	},
	extraReducers: (builder) => {
		// fetch Schede Macelo
		builder.addCase(fetchEventiManutenzione.pending, (state, action) => {
			state.eventiManutenzione.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(fetchEventiManutenzione.fulfilled, (state, action) => {
			state.eventiManutenzione = action.payload;

			// riotorna l'array ordinato per id
			state.eventiManutenzione.results.sort((a, b) => {
				if (a.id && b.id) {
					return b.id - a.id;
				} else {
					return 0;
				}
			});

			state.eventiManutenzione.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(fetchEventiManutenzione.rejected, (state, action) => {
			state.eventiManutenzione.errorsStack = parseErrorMessage(action.error);
		});

		// get
		builder.addCase(getEventoManutenzione.pending, (state, action) => {
			state.eventiManutenzione.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(getEventoManutenzione.fulfilled, (state, action) => {
			state.eventiManutenzione.results =
				state.eventiManutenzione.results.filter((eventoManutenzione) => {
					eventoManutenzione.id != action.payload.id;
				});
			state.eventiManutenzione.results.push(action.payload);
			// riotorna l'array ordinato per id
			state.eventiManutenzione.results.sort((a, b) => {
				if (a.id && b.id) {
					return b.id - a.id;
				} else {
					return 0;
				}
			});
			state.eventiManutenzione.errorsStack = { status: ErrorStatusTypes.OK };
		});
		builder.addCase(getEventoManutenzione.rejected, (state, action) => {
			state.eventiManutenzione.errorsStack = parseErrorMessage(action.error);
		});

		// save save EventoManutenzione
		builder.addCase(saveEventoManutenzione.pending, (state, action) => {
			state.eventiManutenzione.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(saveEventoManutenzione.fulfilled, (state, action) => {
			state.eventiManutenzione.results = state.eventiManutenzione.results.map(
				(eventoManutenzione) => {
					if (eventoManutenzione.id == action.payload.id) {
						return action.payload;
					} else {
						return eventoManutenzione;
					}
				}
			);
			state.eventiManutenzione.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};

			// Solo se è nuova creazione ********************
			if (!action.meta.arg.id) {
				state.nuovoEventoManutenzioneId = action.payload.id;
			}

			toast.success("Scheda macello salvata.");
		});
		builder.addCase(saveEventoManutenzione.rejected, (state, action) => {
			toast.error("Errore:" + action?.error?.message || "");

			state.eventiManutenzione.results = state.eventiManutenzione.results.map(
				(eventoManutenzione) => {
					if (eventoManutenzione.id == action.meta.arg.id) {
						return {
							...eventoManutenzione,
							errorsStack: parseErrorMessage(action.error),
						};
					} else {
						return eventoManutenzione;
					}
				}
			);
			state.eventiManutenzione.errorsStack = parseErrorMessage(action.error);
		});

		// cancella EventoManutenzione
		builder.addCase(deleteEventoManutenzione.pending, (state, action) => {
			state.eventiManutenzione.errorsStack = {
				status: ErrorStatusTypes.PENDING,
			};
		});
		builder.addCase(deleteEventoManutenzione.fulfilled, (state, action) => {
			state.eventiManutenzione.errorsStack = {
				status: ErrorStatusTypes.SUCCESS,
			};
			state.eventiManutenzione.results =
				state.eventiManutenzione.results.filter(
					(eventoManutenzione) => eventoManutenzione.id != action.meta.arg.id
				);
			toast.success(action.payload.message || "Scheda macello cancellata.");
		});
		builder.addCase(deleteEventoManutenzione.rejected, (state, action) => {
			state.eventiManutenzione.errorsStack = parseErrorMessage(action.error);
		});
	},
});

// Action creators are generated for each case reducer function
export const { resetEventoManutenzioneId } = manutenzioneMezziSlice.actions;

export const manutenzioneMezziReducer = manutenzioneMezziSlice.reducer;
