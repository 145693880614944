import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../redux/store";

import {
	DocumentiDiTrasporto,
	fetchDocumentiDiTrasporto,
	fetchDestinazioniTrasporto,
	fetchVettoriTrasporto,
	CausaleTrasportoTypes,
} from "./bolleFattureSlice";
import {
	Committente,
	fetchCommittenti,
	fetchArticoli,
} from "../anagrafiche/anagraficheSlice";
import NavigazioneGiorniToolbar from "../../common/dateUtils/NavigazioneGiorniToolbar";

import moment from "moment";

import { ErrorStatusTypes } from "../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

// import DateToday from "../../common/dateUtils/DateToday";

import {
	RecuperaDataCorrente,
	FissaDataCorrente,
} from "../../common/dateUtils/RecuperaDataCorrente";

import Button from "@mui/material/Button";

// import { DATA_DDT_LIST_CORRENTE } from "../../../api/apiUtils";

import { styled, Box } from "@mui/system";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));

const DocumentiDiTrasportoPage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const documentiDiTrasporto: DocumentiDiTrasporto = useSelector<
		RootState,
		DocumentiDiTrasporto
	>((state) => state.bolleFattureState.documentiDiTrasporto);

	const committenti = useSelector<RootState, Committente[]>(
		(state) => state.anagrafiche.committenti.results
	);

	type LocationState = {
		giornoSettimana: any;
	};

	const location = useLocation();
	const recupera_data_corrente = RecuperaDataCorrente(location);

	// const recupera_data_corrente = () => {
	// 	if (location.state) {
	// 		const { giornoSettimana } = location.state as LocationState;
	// 		if (giornoSettimana) {
	// 			return giornoSettimana;
	// 		}
	// 	}
	// 	const dataDdtListaCorrente = localStorage.getItem(DATA_DDT_LIST_CORRENTE);
	// 	if (!!dataDdtListaCorrente) {
	// 		return new Date(dataDdtListaCorrente);
	// 	}
	// 	return DateToday();
	// };

	const [data, setData] = React.useState<Date | undefined>(
		recupera_data_corrente
	);

	const [refresh, setRefresh] = React.useState<boolean>(false);

	useEffect(() => {
		data && FissaDataCorrente(data);

		// localStorage.setItem(
		// 	DATA_DDT_LIST_CORRENTE,
		// 	moment(data).format("YYYY-MM-DD")
		// );

		const data_da = data;
		const data_a = data;
		dispatch(fetchDestinazioniTrasporto());
		dispatch(fetchVettoriTrasporto());
		dispatch(fetchDocumentiDiTrasporto({ data_da, data_a }));
		dispatch(fetchCommittenti());
		dispatch(fetchArticoli());

		setRefresh(false);
	}, [data, refresh]);

	return (
		<>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					documentiDiTrasporto.errorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<NavigazioneGiorniToolbar
				titolo={"Documenti di Trasporto"}
				// dataPresente={false}
				setData={setData}
				data={data}
				refresh={() => {
					setRefresh(true);
				}}
				Toolbar={() => {
					return (
						<Stack
							direction="row"
							justifyContent="flex-end"
							alignItems="center"
							sx={{ ml: 2 }}
							spacing={1}
						>
							<Button
								component={Link}
								variant="contained"
								to={{
									pathname: "/documento_di_trasporto",
								}}
								state={{
									documentoDiTrasporto_id: null,
								}}
							>
								crea DDT
							</Button>
						</Stack>
					);
				}}
			/>

			<Box
				sx={{
					p: 1,
					// elevation: 0,
				}}
			>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 700 }} aria-label="customized table">
						<TableHead>
							<TableRow>
								<StyledTableCell>n° ddt</StyledTableCell>
								<StyledTableCell align="right">Data</StyledTableCell>
								<StyledTableCell align="right">Destinatario</StyledTableCell>
								<StyledTableCell align="right">Tipo</StyledTableCell>
								{/* <StyledTableCell align="right">n° colli</StyledTableCell> */}
								<StyledTableCell align="right">tipo colli</StyledTableCell>
								<StyledTableCell align="right">kg</StyledTableCell>
								{/* <StyledTableCell align="right">annotazioni</StyledTableCell> */}
							</TableRow>
						</TableHead>
						<TableBody>
							{documentiDiTrasporto.results.map((documentoDiTrasporto) => (
								<StyledTableRow key={documentoDiTrasporto.id}>
									<StyledTableCell component="th" scope="row">
										<Link
											to={{
												pathname: "/documento_di_trasporto",
											}}
											state={{
												documentoDiTrasporto_id: documentoDiTrasporto.id,
											}}
										>
											{documentoDiTrasporto.progressivo ||
												documentoDiTrasporto.id}
										</Link>
									</StyledTableCell>
									<StyledTableCell align="right">
										{moment(documentoDiTrasporto.data_documento).format(
											"ddd DD MMM"
										)}
									</StyledTableCell>
									<StyledTableCell align="right">
										{
											committenti.find(
												(committente) =>
													committente.id == documentoDiTrasporto.destinatario
											)?.nome
										}
									</StyledTableCell>
									<StyledTableCell align="right">
										{
											Object.entries(CausaleTrasportoTypes).find(
												([key, value]) =>
													key == documentoDiTrasporto.causale_trasporto
											)?.[1]
										}
									</StyledTableCell>
									{/* <StyledTableCell align="right">
										{documentoDiTrasporto.colli_consegna}
									</StyledTableCell> */}
									<StyledTableCell align="right">
										{documentoDiTrasporto.aspetto_esteriore_beni_desc}
									</StyledTableCell>
									<StyledTableCell align="right">
										{documentoDiTrasporto.kg_consegna} kg
									</StyledTableCell>
									{/* <StyledTableCell align="right">
										{documentoDiTrasporto.annotazioni}
									</StyledTableCell> */}
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</>
	);
};

export default DocumentiDiTrasportoPage;
