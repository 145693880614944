import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";

import { Route, useLocation, Link } from "react-router-dom";
import { Lotto } from "./pianificazioneSlice";
import { TipoCapo, Parametri } from "../anagrafiche/anagraficheSlice";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";

import moment from "moment";
import { toast } from "react-toastify";

import {
	CalcoloCaricoAmmissibile,
	CalcoloForiPrevisti,
	// TotaleKg,
} from "../../common/CalcoloCaricoAmmissibile";

import { Routes_path } from "../../routerApp";

import DnDBox from "../../common/DnDBox";
import DnDDroppableBox from "../../common/DnDDroppableBox";

import LottoCard from "./LottoCard";

import { styled, Box } from "@mui/system";
import PaperMui from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PlusOneIcon from "@mui/icons-material/PlusOne";
import TransitionsModal from "../../common/TransitionsModal";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import GiornoLottiHistory from "./GiornoLottiHistory";

const Paper = styled(PaperMui)(({ theme }) => ({
	color: theme.palette.primary.contrastText,
	backgroundColor: "#b0b0b0",
	padding: theme.spacing(1),
	borderRadius: theme.shape.borderRadius,
}));

interface GiornoColumnProps {
	giornoSettimana: Date;
	lotti: Lotto[];
	tipiCapo: TipoCapo[];
	// parametri: Parametri;
	handleOpen: any;
	moveLotto: (dragId: number, hoverId: number) => void;
	deleteLotto: (lottoToDelete: Lotto) => void;
	cambiaGiornoLotto: (dragId: number, hoverValue: any) => void;
	consolidaOrdinamento: (dragId: number) => void;
}

const GiornoColumn = ({
	giornoSettimana,
	lotti,
	tipiCapo,
	// parametri,
	handleOpen,
	moveLotto,
	deleteLotto,
	cambiaGiornoLotto,
	consolidaOrdinamento,
}: GiornoColumnProps) => {
	moment.locale("it");

	const moveBox = (dragId: number, hoverId: number) => {
		moveLotto(dragId, hoverId);
	};

	const cambiaGiorno = (dragId: number, hoverValue: any) => {
		hoverValue != undefined
			? cambiaGiornoLotto(dragId, hoverValue)
			: consolidaOrdinamento(dragId);
	};

	const lottiGiorno = lotti
		.filter(
			(lotto) =>
				moment(lotto.data_lavorazione).format("YYYY-MM-DD") ==
				moment(giornoSettimana).format("YYYY-MM-DD")
		)
		.sort((a, b) => {
			return a.ordinamento_lotto - b.ordinamento_lotto;
		});

	const giornataChiusa: boolean = !lottiGiorno.find(
		(lotto) => lotto.resa_confermata == false
	);

	const parametri = useSelector<RootState, Parametri | undefined>((state) =>
		state.anagrafiche.storicoParametri.results.find(
			(p) =>
				new Date(p.data_validta_da) <=
					new Date(new Date(giornoSettimana).getTime() + 6 * 60 * 60 * 1000) &&
				new Date(new Date(giornoSettimana).getTime() - 6 * 60 * 60 * 1000) <=
					new Date(p.data_validta_a)
		)
	);

	!parametri &&
		toast.error(
			`Non ci sono parametri validi per la giornata ${moment(
				giornoSettimana
			).format("LL")}`,
			{ autoClose: 7000 }
		);
	const {
		minutiPrevisti,
		capiPrevisti,
		minutiEffettivi,
		capiEffettivi,
		eccedeTempo,
	} = CalcoloCaricoAmmissibile(
		lottiGiorno,
		tipiCapo,
		parametri,
		giornoSettimana
	);
	const m: number = minutiPrevisti % 60;
	const tempoPrevisto =
		"tempo previsto: " + (minutiPrevisti - m) / 60 + "h " + Math.round(m) + "'";

	const me: number = minutiEffettivi % 60;
	const tempoEffettivo =
		minutiEffettivi > 0
			? "tempo effettivo: " +
			  (minutiEffettivi - me) / 60 +
			  "h " +
			  Math.round(me) +
			  "'"
			: "tempo effettivo: ";

	const { foriUtilizzati, foriImpegnati, massimoForiGiornata, eccedeFori } =
		CalcoloForiPrevisti(lottiGiorno, tipiCapo, parametri);
	const foriPrevisti =
		"fori: " +
		foriUtilizzati +
		"/" +
		foriImpegnati +
		"(" +
		massimoForiGiornata +
		")";

	const arrayGiornidiersiDaOggi = [
		"day_1",
		"day_2",
		"day_3",
		"day_4",
		"day_5",
		"day_6",
	].filter((giorno) => giorno != "day_" + moment(giornoSettimana).isoWeekday());

	const location = useLocation();

	const [openLottoHistory, setopenLottoHistory] = React.useState(false);

	return (
		<Grid item xs>
			<DnDDroppableBox
				key={moment(giornoSettimana).format("YYYY-MM-DD")}
				value={moment(giornoSettimana).format("YYYY-MM-DD")}
				accept={arrayGiornidiersiDaOggi}
				// disabled={giornataChiusa}
			>
				<Paper elevation={2}>
					<Box
						sx={{
							px: 1,
							mb: 2,
						}}
					>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								flexDirection: "row",
							}}
						>
							<Typography
								variant="h6"
								component="div"
								color="theme.text.primary"
								sx={{ flexGrow: 2 }}
							>
								{/* color="#5d4037" */}
								{moment(giornoSettimana).format("ddd DD MMM")}
							</Typography>

							<IconButton
								size="small"
								color="secondary"
								aria-label="schede carico"
								component={Link}
								to={{
									pathname: Routes_path.SCHEDE_CARICO,
								}}
								state={{
									giornoSettimana,
								}}
							>
								<Tooltip title="Schede carico">
									<LocalShippingIcon />
								</Tooltip>
							</IconButton>
							<IconButton
								color="secondary"
								size="small"
								aria-label="Piano lavorazioni"
								component={Link}
								to={{
									pathname: Routes_path.PIANO_LAVORAZIONI,
								}}
								state={{
									giornoSettimana,
								}}
							>
								<Tooltip title="Piano lavorazioni">
									<FormatListNumberedIcon />
								</Tooltip>
							</IconButton>

							<IconButton
								color="secondary"
								aria-label="Consuntivi Vivo"
								size="small"
								component={Link}
								to={{
									pathname: Routes_path.CONSUNTIVO_VIVO,
								}}
								state={{
									giornoSettimana,
								}}
							>
								<Tooltip title="Consuntivi Vivo">
									<AppRegistrationIcon />
								</Tooltip>
							</IconButton>
							{/* <Link to={{ pathname: "/lavorazioni_giorno" }}>LG</Link> */}
						</Box>
						<Typography variant="subtitle1" component="div" color="#ffffff">
							{!!capiEffettivi
								? `tot. capi: ${Intl.NumberFormat("it-IT").format(
										capiEffettivi
								  )} vs ${Intl.NumberFormat("it-IT").format(capiPrevisti)}`
								: `tot. capi: ${Intl.NumberFormat("it-IT").format(
										capiPrevisti
								  )}`}
						</Typography>
						<Typography
							// variant="subtitle1"
							component="div"
							color="#ffffff"
							sx={{
								backgroundColor: eccedeTempo ? "#fcf223" : "inherit",
								color: eccedeTempo ? "#991111" : "inherit",
							}}
						>
							{tempoPrevisto}
						</Typography>
						<Typography
							// variant="subtitle1"
							component="div"
							color="#ffffff"
							// sx={{
							// 	backgroundColor: eccedeTempo ? "#fcf223" : "inherit",
							// 	color: eccedeTempo ? "#991111" : "inherit",
							// }}
						>
							{!!minutiEffettivi && tempoEffettivo}
							{!!minutiEffettivi ? (
								<IconButton
									color="inherit"
									size="small"
									onClick={() => setopenLottoHistory(true)}
								>
									<HistoryToggleOffIcon />
								</IconButton>
							) : (
								<IconButton sx={{ color: "#00000000" }} size="small">
									<HistoryToggleOffIcon />
								</IconButton>
							)}
						</Typography>
						<Typography
							variant="body2"
							component="div"
							color="#ffffff"
							sx={{
								backgroundColor: eccedeTempo ? "#fcf223" : "inherit",
								color: eccedeTempo ? "#991111" : "inherit",
							}}
						>
							{foriPrevisti}
						</Typography>
					</Box>
					{lottiGiorno.map((lotto, index) => (
						<DnDBox
							key={lotto.id}
							id={lotto.id || 9999}
							index={index}
							typeBox={"day_" + moment(giornoSettimana).isoWeekday()}
							accept={"day_" + moment(giornoSettimana).isoWeekday()}
							moveDnDBox={moveBox}
							dragDnDBox={cambiaGiorno}
							disabled={lotto.resa_confermata}
						>
							<LottoCard
								lotto={lotto}
								deleteLotto={deleteLotto}
								handleOpen={handleOpen}
							/>
						</DnDBox>
					))}
					<Stack
						sx={{ mt: 3 }}
						spacing={2}
						direction="row"
						justifyContent="center"
					>
						<Button
							variant="contained"
							startIcon={<PlusOneIcon />}
							onClick={handleOpen(null, giornoSettimana)}
						>
							Lotto
						</Button>
					</Stack>
				</Paper>
			</DnDDroppableBox>

			<TransitionsModal
				open={openLottoHistory}
				handleClose={() => setopenLottoHistory(false)}
			>
				<GiornoLottiHistory
					lotti={lottiGiorno}
					giornoSettimana={giornoSettimana}
					tipiCapo={tipiCapo}
					// parametri={parametri}
				/>
			</TransitionsModal>
		</Grid>
	);
};

export default GiornoColumn;
