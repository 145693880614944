import React from "react";
import moment from "moment";

import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";

import { Resa } from "../reseSlice";
import { getColore } from "../../../pianificazione/utility/lottoUtility";

// Create styles
const styles = StyleSheet.create({
	textIntestazione: {
		fontSize: 7,
		borderBottom: "0.5px",
		fontStyle: "normal",

		paddingLeft: 1,
		paddingRight: 1,
		paddingTop: 5,
		paddingBottom: 5,
		marginBottomp: 1,
	},
	textDati: {
		fontSize: 11,
		fontStyle: "normal",
		// fontWeight: 100,
		whiteSpace: "wrap",
		fontWeight: "bold",
		paddingLeft: 1,
		paddingRight: 1,
		paddingTop: 5,
		paddingBottom: 5,
		marginBottomp: 3,
	},
	sectionTableIntestazione: {
		fontSize: 9,
		fontStyle: "normal",
		flexDirection: "column",
		marginHorizontal: 2,
		marginTop: 2,
		marginLeft: 5,
		// marginBottom: 10,
		// borderTop: "1px",
		// borderBottom: "0.5px",
	},
	sectionTableDati: {
		flexDirection: "row",
		// marginHorizontal: 20,
	},
});

interface ResaIntestazionePDFProps {
	resa: Resa;
}

const ResaIntestazionePDF = ({
	resa,
}: // giornoSettimana,
ResaIntestazionePDFProps) => {
	// const km_percorsi = resa.allevamento_pack?.distanza ? resa.allevamento_pack.distanza * 2 : 0
	return (
		<View
			style={{
				flexDirection: "column",
				justifyContent: "space-between",
				// height: 410,
			}}
			// key={"disinfezione_schedaCarico_" + schedaCarico.id}
		>
			<View style={{ flexDirection: "row", margin: "20px" }}>
				<View style={[styles.sectionTableIntestazione, { width: "25%" }]}>
					<Text style={[styles.textIntestazione]}>Cod. Rintrac.</Text>
					<Text style={styles.textDati}>{resa.codice_tracciabilita}</Text>
					<Text style={styles.textIntestazione}>
						Allevatore (Cod. ASL: {resa.allevamento_pack?.codice_asl})
					</Text>
					<Text style={[styles.textDati]}>
						{resa.allevamento_pack?.nome &&
						resa.allevamento_pack?.nome.length > 33
							? resa.allevamento_pack?.nome.substr(0, 32) + "..."
							: resa.allevamento_pack?.nome}
					</Text>
					<View style={styles.sectionTableDati}>
						<View style={{ flexDirection: "column", width: "28%" }}>
							<Text style={[styles.textIntestazione]}>Km percorsi</Text>
							<Text style={[styles.textDati]}>
								{/* {Intl.NumberFormat("it-IT").format(resa.km_ar_lotto || 0)} */}
								{Intl.NumberFormat("it-IT").format(
									resa.allevamento_pack?.distanza
										? resa.allevamento_pack.distanza * 2
										: 0 || 0
								)}
								<Text style={{ fontSize: 8 }}>
									{" "}
									km
									{resa.schede_carico.length > 1
										? "x" + resa.schede_carico.length
										: ""}
								</Text>
							</Text>
						</View>

						<View style={{ flexDirection: "column", width: "24%" }}>
							<Text style={[styles.textIntestazione]}>Inizio carico </Text>
							<Text style={[styles.textDati]}>
								{resa.data_ora_inizio_carico_lotto &&
									moment(resa.data_ora_inizio_carico_lotto).format("HH:mm")}
							</Text>
						</View>
						<View style={{ flexDirection: "column", width: "24%" }}>
							<Text style={[styles.textIntestazione]}>Fine carico</Text>
							<Text style={[styles.textDati]}>
								{resa.data_ora_fine_carico_lotto &&
									moment(resa.data_ora_fine_carico_lotto).format("HH:mm")}
							</Text>
						</View>
						<View style={{ flexDirection: "column", width: "24%" }}>
							<Text style={[styles.textIntestazione]}>Tempo carico</Text>
							<Text style={[styles.textDati]}>
								{Math.floor(
									moment
										.duration(Number(resa.tempo_carico_lotto || 0), "seconds")
										.asHours()
								)}
								:
								{moment
									.utc(
										moment
											.duration(Number(resa.tempo_carico_lotto || 0), "seconds")
											.as("milliseconds")
									)
									.format("mm")}
							</Text>
						</View>
					</View>
					<Text style={styles.textIntestazione}>Capi Ordinati</Text>
					<Text style={[styles.textDati, { textTransform: "uppercase" }]}>
						{Intl.NumberFormat("it-IT").format(resa.numero_capi || 0)}{" "}
						{resa.tipo_capo_desc} {getColore(resa)}
					</Text>
				</View>
				<View style={[styles.sectionTableIntestazione, { width: "20%" }]}>
					<View style={styles.sectionTableDati}>
						<View style={{ flexDirection: "column", width: "50%" }}>
							<Text style={[styles.textIntestazione]}>Peso partenza</Text>
							<Text style={[styles.textDati]}>
								{Intl.NumberFormat("it-IT").format(
									resa.peso_lotto_partenza || 0
								)}
								kg
							</Text>
						</View>

						<View style={{ flexDirection: "column", width: "50%" }}>
							<Text style={[styles.textIntestazione]}>Peso arrivo</Text>
							<Text style={[styles.textDati]}>
								{Intl.NumberFormat("it-IT").format(resa.peso_lotto_arrivo || 0)}
								kg
							</Text>
						</View>
					</View>
					<View style={styles.sectionTableDati}>
						<View style={{ flexDirection: "column", width: "50%" }}>
							<Text style={[styles.textIntestazione]}>Arrivo macello</Text>
							<Text style={[styles.textDati]}>
								{resa.data_ora_arrivo_lotto &&
									moment(resa.data_ora_arrivo_lotto).format("HH:mm")}
							</Text>
						</View>

						<View style={{ flexDirection: "column", width: "50%" }}>
							<Text style={[styles.textIntestazione]}>Sosta macello</Text>
							<Text style={[styles.textDati]}>
								{Math.floor(
									moment
										.duration(Number(resa.sosta_macello || 0), "seconds")
										.asHours()
								)}
								:
								{moment
									.utc(
										moment
											.duration(Number(resa.sosta_macello || 0), "seconds")
											.as("milliseconds")
									)
									.format("mm")}
							</Text>
						</View>
					</View>
					<View style={styles.sectionTableDati}>
						<View style={{ flexDirection: "column", width: "50%" }}>
							<Text style={[styles.textIntestazione]}>Inizio macellazione</Text>
							<Text style={[styles.textDati]}>
								{resa.data_ora_inizio_macello &&
									moment(resa.data_ora_inizio_macello).format("HH:mm")}
							</Text>
						</View>
						<View style={{ flexDirection: "column", width: "50%" }}>
							<Text style={[styles.textIntestazione]}>Fine macellazione</Text>
							<Text style={[styles.textDati]}>
								{resa.data_ora_fine_macello &&
									moment(resa.data_ora_fine_macello).format("HH:mm")}
							</Text>
						</View>
					</View>
					<View style={styles.sectionTableDati}>
						<View style={{ flexDirection: "column", width: "50%" }}>
							<Text style={[styles.textIntestazione]}>peso Macellato</Text>
							<Text style={[styles.textDati]}>
								{Intl.NumberFormat("it-IT", {
									maximumFractionDigits: 0,
								}).format(
									(resa.peso_casse_busto_lotto || 0) +
										(resa.peso_casse_tz_lotto || 0)
								)}{" "}
								kg
							</Text>
						</View>

						<View style={{ flexDirection: "column", width: "50%" }}>
							<Text style={[styles.textIntestazione]}>Conta polli</Text>
							<Text style={[styles.textDati]}>
								{Intl.NumberFormat("it-IT").format(
									(resa.num_busti_lotto || 0) +
										(resa.num_tz_lotto || 0) +
										(resa.numero_scarti_2 || 0)
								)}
							</Text>
						</View>
					</View>
				</View>
				<View style={[styles.sectionTableIntestazione, { width: "45%" }]}>
					<View style={styles.sectionTableDati}>
						<View style={{ flexDirection: "column", width: "25%" }}>
							<Text style={[styles.textIntestazione]}>M.TI</Text>
							<Text style={[styles.textDati]}>
								{Intl.NumberFormat("it-IT").format(resa.numero_capi_morti || 0)}
							</Text>
						</View>

						<View style={{ flexDirection: "column", width: "25%" }}>
							<Text style={[styles.textIntestazione]}>SC1</Text>
							<Text style={[styles.textDati]}>
								{Intl.NumberFormat("it-IT").format(resa.numero_scarti_1 || 0)}
							</Text>
						</View>
						<View style={{ flexDirection: "column", width: "25%" }}>
							<Text style={[styles.textIntestazione]}>SC2</Text>
							<Text style={[styles.textDati]}>
								{Intl.NumberFormat("it-IT").format(resa.numero_scarti_2 || 0)}
							</Text>
						</View>
					</View>
					<View style={styles.sectionTableDati}>
						<View style={{ flexDirection: "column", width: "25%" }}>
							<Text style={[styles.textIntestazione]}>peso BST</Text>
							<Text style={[styles.textDati]}>
								{Intl.NumberFormat("it-IT", {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								}).format(resa.peso_casse_busto_lotto || 0)}
							</Text>
						</View>

						<View style={{ flexDirection: "column", width: "25%" }}>
							<Text style={[styles.textIntestazione]}>N° BST</Text>
							<Text style={[styles.textDati]}>
								{Intl.NumberFormat("it-IT").format(resa.num_busti_lotto || 0)}
							</Text>
						</View>
						<View style={{ flexDirection: "column", width: "25%" }}>
							<Text style={[styles.textIntestazione]}>N° casse BST</Text>
							<Text style={[styles.textDati]}>
								{Intl.NumberFormat("it-IT", {
									maximumFractionDigits: 0,
								}).format(resa.num_casse_busto_lotto || 0)}
							</Text>
						</View>
						<View style={{ flexDirection: "column", width: "25%" }}>
							<Text style={[styles.textIntestazione]}>p.medio BST</Text>
							<Text style={[styles.textDati]}>
								{Intl.NumberFormat("it-IT", {
									maximumFractionDigits: 2,
								}).format(
									(resa.peso_casse_busto_lotto || 0) /
										(resa.num_busti_lotto || 1)
								)}
							</Text>
						</View>
					</View>
					<View style={styles.sectionTableDati}>
						<View style={{ flexDirection: "column", width: "25%" }}>
							<Text style={[styles.textIntestazione]}>peso TZ</Text>
							<Text style={[styles.textDati]}>
								{Intl.NumberFormat("it-IT", {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								}).format(resa.peso_casse_tz_lotto || 0)}
							</Text>
						</View>

						<View style={{ flexDirection: "column", width: "25%" }}>
							<Text style={[styles.textIntestazione]}>N° TZ</Text>
							<Text style={[styles.textDati]}>
								{Intl.NumberFormat("it-IT").format(resa.num_tz_lotto || 0)}
							</Text>
						</View>
						<View style={{ flexDirection: "column", width: "25%" }}>
							<Text style={[styles.textIntestazione]}>N° casse TZ</Text>
							<Text style={[styles.textDati]}>
								{Intl.NumberFormat("it-IT", {
									maximumFractionDigits: 0,
								}).format(resa.num_casse_tz_lotto || 0)}
							</Text>
						</View>
						<View style={{ flexDirection: "column", width: "25%" }}>
							<Text style={[styles.textIntestazione]}>p.medio TZ</Text>
							<Text style={[styles.textDati]}>
								{Intl.NumberFormat("it-IT", {
									maximumFractionDigits: 2,
								}).format(
									(resa.peso_casse_tz_lotto || 0) / (resa.num_tz_lotto || 1)
								)}
							</Text>
						</View>
					</View>
					{resa.costi_di_trasporto_vivo_list &&
						resa.costi_di_trasporto_vivo_list.length > 0 && (
							<View style={styles.sectionTableDati}>
								<View style={{ flexDirection: "column", width: "70%" }}>
									<Text style={[styles.textIntestazione]}>
										Fascia trasporto
									</Text>
								</View>

								<View style={{ flexDirection: "column", width: "15%" }}>
									<Text style={[styles.textIntestazione]}>rimorchio</Text>
								</View>
								<View style={{ flexDirection: "column", width: "15%" }}>
									<Text style={[styles.textIntestazione]}>costo</Text>
								</View>
							</View>
						)}
					{resa.costi_di_trasporto_vivo_list.map((costoTrasporto) => {
						return (
							<View style={styles.sectionTableDati} key={costoTrasporto.id}>
								<View style={{ flexDirection: "column", width: "70%" }}>
									<Text style={[styles.textDati, { fontSize: "8px" }]}>
										{costoTrasporto.fascia_trasporto}
										{costoTrasporto.lotti_per_viaggio > 1 &&
											" (viaggio condiviso in " +
												costoTrasporto.lotti_per_viaggio +
												")"}
									</Text>
								</View>

								<View style={{ flexDirection: "column", width: "15%" }}>
									<Text
										style={[
											styles.textDati,
											{ textAlign: "center", fontSize: "8px" },
										]}
									>
										{costoTrasporto.rimorchio ? "si" : "no"}
									</Text>
								</View>
								<View style={{ flexDirection: "column", width: "15%" }}>
									<Text style={[styles.textDati, { textAlign: "right" }]}>
										{Intl.NumberFormat("it-IT", {
											maximumFractionDigits: 0,
										}).format(costoTrasporto.costo_trasporto || 0)}{" "}
										€
									</Text>
								</View>
							</View>
						);
					})}
				</View>
				<View
					style={[
						styles.sectionTableIntestazione,
						{
							width: "10%",
							textAlign: "right",
							backgroundColor: "#E5E4E2",
							paddingHorizontal: 4,
							color: "#1c325c",
						},
					]}
				>
					<View style={styles.sectionTableDati}>
						<View style={{ flexDirection: "column", width: "100%" }}>
							<Text style={[styles.textIntestazione]}>Totale capi</Text>
							<Text style={[styles.textDati]}>
								{Intl.NumberFormat("it-IT").format(resa.numero_capi_resa || 0)}
							</Text>
							{/* </View>

					<View style={{ flexDirection: "column", width: "50%" }}> */}
							<Text style={[styles.textIntestazione]}>Peso medio resa</Text>
							<Text style={[styles.textDati]}>
								{Intl.NumberFormat("it-IT", {
									maximumFractionDigits: 2,
								}).format(resa.peso_medio_resa || 0)}
								kg
							</Text>
						</View>
					</View>
					<View style={[styles.sectionTableDati]}>
						<View style={{ flexDirection: "column", width: "100%" }}>
							<Text style={[styles.textIntestazione]}>Resa</Text>
							<Text style={[styles.textDati, { fontSize: 20 }]}>
								{resa.percentuale_resa &&
									Intl.NumberFormat("it-IT", {
										maximumFractionDigits: 2,
										minimumFractionDigits: 2,
									}).format(resa.percentuale_resa * 100) + "%"}
							</Text>
						</View>
					</View>
				</View>
			</View>
		</View>
	);
};

export default ResaIntestazionePDF;
