import { ReportAutisti } from "../reportAutistiCamionSlice";
import moment from "moment";

const getDaysInMonth = (date: Date | string | undefined) => {
	let year, month;
	if (date instanceof Date) {
		year = date.getFullYear();
		month = date.getMonth();
	} else if (typeof date === "string") {
		const dateObj = new Date(date);
		year = dateObj.getFullYear();
		month = dateObj.getMonth();
	} else if (date === undefined) {
		const today = new Date();
		year = today.getFullYear();
		month = today.getMonth();
	} else {
		throw new Error("Invalid date format");
	}

	const daysInMonth = new Date(year, month + 1, 0).getDate(); // restituisce l'ultimo giorno del mese
	const daysArray = [];

	for (let i = 0; i <= daysInMonth + 1; i++) {
		daysArray.push(new Date(year, month, i));
	}

	return daysArray;
};

export const tabellaOrariAutistaMesePayroll = (
	reportAutistiMese: ReportAutisti[]
): string[][] => {
	const mese = getDaysInMonth(reportAutistiMese[0].data_ora_partenza_sede);

	const csvData: string[][] = [];
	const intestazione = mese[0];
	csvData.push(["mese: ", moment(mese[0]).format("MMMM YYYY")]);
	csvData.push([
		"autista: ",
		`${reportAutistiMese[0].cognome} ${reportAutistiMese[0].nome}`,
	]);
	csvData.push([
		"",
		"1° VIAGGIO",
		"1° VIAGGIO",
		"",
		"Cavallo",
		"Cavallo",
		"",
		"2° VIAGGIO",
		"2° VIAGGIO",
	]);
	csvData.push([
		"GIORNO",
		"Ora part.",
		"Ora arr.",
		"Tot. Imp.",
		"Ora part.",
		"Ora arr.",
		"Tot. Cavallo",
		"Ora part.",
		"Ora arr.",
	]);

	let oraArrCavalloPrec = "";

	mese.map((giorno) => {
		let oraPart1 = "";
		let oraArr1 = "";
		let oraPartCavallo = "";
		let oraArrCavallo = oraArrCavalloPrec;
		let oraPart2 = "";
		let oraArr2 = "";

		const viaggi_giorno = reportAutistiMese.filter(
			(viaggio) =>
				moment(viaggio.data_ora_partenza_sede).format("DD MMMM YYYY") ==
				moment(giorno).format("DD MMMM YYYY")
		);

		if (viaggi_giorno.length > 0) {
			const data_fine = new Date(
				viaggi_giorno[0].data_ora_fine_turno?.toString() || ""
			);

			if (data_fine.setHours(0, 0, 0, 0) == giorno.setHours(0, 0, 0, 0)) {
				oraPart1 = moment(viaggi_giorno[0].data_ora_partenza_sede).format(
					"HH:mm"
				);
				oraArr1 = moment(viaggi_giorno[0].data_ora_fine_turno).format("HH:mm");
				oraArrCavalloPrec = "";
			} else {
				oraPartCavallo = moment(viaggi_giorno[0].data_ora_partenza_sede).format(
					"HH:mm"
				);
				oraArrCavalloPrec = moment(viaggi_giorno[0].data_ora_fine_turno).format(
					"HH:mm"
				);
			}

			if (viaggi_giorno.length == 2) {
				const data_fine = new Date(
					viaggi_giorno[1].data_ora_fine_turno?.toString() || ""
				);

				if (data_fine.setHours(0, 0, 0, 0) == giorno.setHours(0, 0, 0, 0)) {
					oraPart2 = moment(viaggi_giorno[1].data_ora_partenza_sede).format(
						"HH:mm"
					);
					oraArr2 = moment(viaggi_giorno[1].data_ora_fine_turno).format(
						"HH:mm"
					);
					oraArrCavalloPrec = "";
				} else {
					oraPartCavallo = moment(
						viaggi_giorno[1].data_ora_partenza_sede
					).format("HH:mm");
					oraArrCavalloPrec = moment(
						viaggi_giorno[1].data_ora_fine_turno
					).format("HH:mm");
				}
			}
		} else {
			oraArrCavalloPrec = "";
		}

		csvData.push([
			moment(giorno).format("DD"),
			oraPart1,
			oraArr1,
			"",
			oraPartCavallo,
			oraArrCavallo,
			"",
			oraPart2,
			oraArr2,
		]);
	});

	return csvData;
};
