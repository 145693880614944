import { handleResponse, handleError, headerset } from "../apiUtils";
import { CorrispettiviLotto } from "components/main/bolle_fatture/bolleFattureSlice";

export function fetchListiniMacellazione() {
	const url = process.env.API_URL + "/api/bolle_fatture/listini_macellazione/";
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
// ****************************************************************

const baseUrl = (lotto_id?: number | null) => {
	let url: string =
		process.env.API_URL + "/api/bolle_fatture/corrispettivi/lotto/";

	if (lotto_id) {
		url += +lotto_id + "/";
	}

	return url;
};

export function getCorrispettiviLotto(lotto_id: number) {
	let url = baseUrl(lotto_id);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function modificaCorrispettiviLotto(
	corrispettiviLotto: CorrispettiviLotto
) {
	const corrispettiviLottoToSave = {
		...corrispettiviLotto,
	};

	return fetch(baseUrl(corrispettiviLotto.lotto_id), {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...corrispettiviLottoToSave,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

// ****************************************************************
// ****************************************************************

export function fetchListiniTrasportoVivo() {
	const url =
		process.env.API_URL + "/api/bolle_fatture/listini_trasporto_vivo/";
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
// ****************************************************************

// ****************************************************************

export function fetchListiniVenditaArticoli() {
	const url =
		process.env.API_URL + "/api/bolle_fatture/listini_vendita_articoli/";
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
// ****************************************************************
