import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { Lavorazione } from "../../../lavorazioni/lavorazioniSlice";

// Create styles
const styles = StyleSheet.create({
	textIntestazione: {
		fontSize: 9,
		borderBottom: "0.5px",
		fontStyle: "normal",
		width: "20%",
		paddingLeft: 1,
		paddingRight: 1,
		paddingTop: 5,
		paddingBottom: 5,
		marginBottom: 1,
	},
	textDati: {
		fontSize: 8,
		fontStyle: "normal",
		// fontWeight: 100,
		whiteSpace: "wrap",
		width: "20%",
		paddingLeft: 1,
		paddingRight: 1,
		paddingTop: 5,
		paddingBottom: 5,
		marginBottom: 1,
	},
	sectionTableIntestazione: {
		fontSize: 9,
		fontStyle: "normal",
		flexDirection: "column",
		// marginHorizontal: 2,
		marginTop: 2,
		// marginLeft: 1,
		backgroundColor: "#3b7d9c",
		color: "#FFF",
		// marginBottom: 10,
		// borderTop: "1px",
		// borderBottom: "0.5px",
	},
	sectionTableDati: {
		flexDirection: "row",
		borderStyle: "solid",
		borderWidth: "0.1px",
		// marginHorizontal: 20,
	},
});

interface ResaLavorazioniPDFProps {
	lavorazioni: Lavorazione[];
}

const ResaLavorazioniPDF = ({
	lavorazioni,
}: // giornoSettimana,
ResaLavorazioniPDFProps) => (
	<View
		style={{
			flexDirection: "column",
			margin: "25px",
			marginTop: "10px",
		}}
		// key={"disinfezione_schedaCarico_" + schedaCarico.id}
	>
		{lavorazioni.length > 0 ? (
			<View
				style={[
					styles.sectionTableIntestazione,
					{
						flexDirection: "row",
					},
				]}
			>
				<Text style={[styles.textIntestazione, { width: "10%" }]}>
					Articolo
				</Text>
				<Text style={[styles.textIntestazione, { width: "28%" }]}>
					Descrizione
				</Text>
				<Text
					style={[
						styles.textIntestazione,
						{ textAlign: "center", width: "10%" },
					]}
				>
					Capi x cassa
				</Text>
				<Text style={[styles.textIntestazione, { width: "16%" }]}>
					Destinazione
				</Text>
				<Text
					style={[
						styles.textIntestazione,
						{ textAlign: "right", width: "10%" },
					]}
				>
					N° casse prod.
				</Text>
				<Text
					style={[
						styles.textIntestazione,
						{ textAlign: "right", width: "10%" },
					]}
				>
					N° capi
				</Text>
				<Text
					style={[
						styles.textIntestazione,
						{ textAlign: "right", width: "10%" },
					]}
				>
					Peso medio
				</Text>
				<Text
					style={[
						styles.textIntestazione,
						{ textAlign: "right", width: "10%" },
					]}
				>
					Peso Totale
				</Text>
			</View>
		) : (
			<View
				style={[
					styles.sectionTableIntestazione,
					{
						flexDirection: "row",
					},
				]}
			>
				<Text style={[styles.textIntestazione, { width: "100%" }]}>
					Nessuna lavorazione registrata
				</Text>
			</View>
		)}
		{lavorazioni
			.filter((l) => l.numero_cassette_prodotte > 0)
			.map((lavorazione) => (
				<View
					style={styles.sectionTableDati}
					key={"lavorazione_resa_" + lavorazione.id}
				>
					<Text style={[styles.textDati, { width: "10%" }]}>
						{lavorazione.codice_articolo_committente
							? lavorazione.codice_articolo_committente
							: lavorazione.articolo?.codice_articolo}
					</Text>
					<Text style={[styles.textDati, { width: "28%" }]}>
						{lavorazione.descrizione}
					</Text>
					<Text
						style={[styles.textDati, { textAlign: "center", width: "10%" }]}
					>
						{lavorazione.articolo?.numero_capi_cassa}
					</Text>
					<Text style={[styles.textDati, { width: "16%" }]}>
						{(lavorazione.destinazione || "") +
							" " +
							(lavorazione.note == null ? "" : lavorazione.note)}
					</Text>
					<Text style={[styles.textDati, { textAlign: "right", width: "10%" }]}>
						{Intl.NumberFormat("it-IT").format(
							lavorazione.numero_cassette_prodotte || 0
						)}
					</Text>
					<Text style={[styles.textDati, { textAlign: "right", width: "10%" }]}>
						{lavorazione.articolo?.numero_capi_cassa &&
							Intl.NumberFormat("it-IT").format(
								lavorazione.numero_cassette_prodotte *
									(lavorazione.articolo?.numero_capi_cassa || 0)
							)}
					</Text>
					<Text style={[styles.textDati, { textAlign: "right", width: "10%" }]}>
						{lavorazione.articolo?.numero_capi_cassa &&
							Intl.NumberFormat("it-IT").format(
								lavorazione.peso_cassette_prodotte /
									(lavorazione.numero_cassette_prodotte *
										(lavorazione.articolo?.numero_capi_cassa || 0) || 1)
							) + " kg"}
					</Text>
					<Text style={[styles.textDati, { textAlign: "right", width: "10%" }]}>
						{Intl.NumberFormat("it-IT").format(
							lavorazione.peso_cassette_prodotte || 0
						)}
						kg
					</Text>
				</View>
			))}
	</View>
);

export default ResaLavorazioniPDF;
