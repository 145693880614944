import React from "react";
import { useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";

import MuiDrawer, { DrawerProps as MuiDrawerProps } from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import SideMenu from "./SideMenu";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronRight";

// import logowebylia from "../../../../static/images/logo/logo-biglia_contornaW.png";

const logoScarso = require("../../../../static/images/logo/logo-Scarso-Nuovo.png");

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	// ...theme.mixins.toolbar,
}));

interface DrawerProps extends MuiDrawerProps {
	theme?: any;
	drawerWidth: number | string;
	openedMixin: any;
	closedMixin: any;
}

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) =>
		prop !== "open" &&
		prop !== "drawerWidth" &&
		prop !== "openedMixin" &&
		prop !== "closedMixin",
})(({ theme, drawerWidth, openedMixin, closedMixin, open }: DrawerProps) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

interface SideBarProps extends MuiDrawerProps {
	open: boolean;
	drawerWidth: number | string;
	handleDrawerClose: () => void;
	openedMixin: any;
	closedMixin: any;
	window?: () => Window;
}

export default function SideBar({
	open,
	drawerWidth,
	handleDrawerClose,
	openedMixin,
	closedMixin,

	...props
}: SideBarProps) {
	const { window } = props;
	const theme = useTheme();
	const container =
		window !== undefined ? () => window().document.body : undefined;

	const [mobileOpen, setMobileOpen] = React.useState(false);
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const drawerContent = (
		<>
			<DrawerHeader>
				<img
					src={logoScarso}
					alt="Scarso & figli - consulenza aziendale"
					style={{ width: 150 }}
				/>
				<IconButton onClick={handleDrawerClose} size="large">
					{/* <ChevronRightIcon /> */}
					<ChevronLeftIcon />
				</IconButton>
			</DrawerHeader>
			<Divider />
			<SideMenu open={open} handleDrawerClose={handleDrawerClose} />
		</>
	);
	return (
		<>
			<Drawer
				variant="permanent"
				open={open}
				drawerWidth={drawerWidth}
				openedMixin={openedMixin}
				closedMixin={closedMixin}
				sx={{
					display: { xs: "none", sm: "block" },
				}}
			>
				{drawerContent}
			</Drawer>
			<MuiDrawer
				container={container}
				// variant="temporary"
				variant="persistent"
				open={open}
				onClose={handleDrawerClose}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				sx={{
					display: { xs: "block", sm: "none" },
					"& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
				}}
			>
				{drawerContent}
			</MuiDrawer>
		</>
	);
}
