export const DATA_CORRENTE = "dataCorrente_" + process.env.APP_NAME;
export const DATA_CORRENTE_DATA = "dataCorrente_data_" + process.env.APP_NAME;
export const DATA_PIANIFICAZIONE_CORRENTE =
	"dataPianificazioneCorrente_" + process.env.APP_NAME;
export const DATA_SCHEDULAZIONE_CORRENTE =
	"dataSchedulazioneCorrente_" + process.env.APP_NAME;
export const DATA_PIANIFICAZIONE_LAVORAZIONI_CORRENTE =
	"dataLavorazioniCorrente_" + process.env.APP_NAME;
export const DATA_PIANO_VIAGGI_CORRENTE =
	"dataPianoViaggiCorrente_" + process.env.APP_NAME;
export const DATA_PROGRAMMA_DI_INCASSETTAMENTO_CORRENTE =
	"dataProgammaIncassettamentoCorrente_" + process.env.APP_NAME;
export const DATA_CONSUNTIVAZIONE_LAVORAZIONI_CORRENTE =
	"dataLavorazioniCorrente_" + process.env.APP_NAME;
export const DATA_CONSUNTIVAZIONE_CORRENTE =
	"dataLavorazioniCorrente_" + process.env.APP_NAME;
export const DATA_MACELLAZIONE_CORRENTE =
	"dataMacellazioneCorrente_" + process.env.APP_NAME;
export const DATA_VETERINARIO_CORRENTE =
	"dataVeterinarioCorrente_" + process.env.APP_NAME;
export const DATA_AUTISTI_CORRENTE =
	"dataAutistiCorrente_" + process.env.APP_NAME;
export const DATA_KG_COMMITTENTE_CORRENTE =
	"dataKgCommittenteCorrente_" + process.env.APP_NAME;
export const DATA_IOLEPEC_CORRENTE =
	"dataIolepecCorrente_" + process.env.APP_NAME;
export const DATA_DDT_LIST_CORRENTE =
	"dataDdtListCorrente_" + process.env.APP_NAME;

export async function handleResponse(response: any) {
	if (response.ok) return response.json();
	if (response.status >= 400 && response.status < 500) {
		if (response.status == 401) {
			const messaggioErrore =
				"API call server error: status:" +
				response.status +
				" " +
				response.statusText +
				" at: " +
				response.url;

			// alert(messaggioErrore);

			// localStorage.removeItem(ACCESS_TOKEN);
			// localStorage.removeItem(REFRESH_TOKEN);
			// localStorage.removeItem("timeRefresh");
		}
		if (response.status == 403) {
			// accesso negato
			const messaggioErrore =
				"API call server error: status:" +
				response.status +
				" " +
				response.statusText +
				" at: " +
				response.url;

			// alert(messaggioErrore);
		}
		// So, a server-side validation error occurred.
		// Server side validation returns a string error message, so parse as text instead of json.
		// const error = await response.text();
		const error = await response.text();
		throw new Error(error);
	}

	const messaggioErrore =
		"API call server error: status:" +
		response.status +
		" " +
		response.statusText +
		" at: " +
		response.url;

	// alert(messaggioErrore);

	throw new Error(messaggioErrore);
}

// In a real app, would likely call an error logging service.
export function handleError(error: any) {
	// eslint-disable-next-line no-console
	console.error("Errore Server: ", error);
	// alert(error);
	throw new Error(error);
	// throw error;
}

import store from "../redux/store";

function getAccessToken() {
	const state = store.getState(); // Ottieni lo stato corrente
	return state.userPreferenceState.userStored?.access;
}

// In a real app, would likely call an error logging service.
export function headerset() {
	const token_access = getAccessToken();

	!token_access && window.location.replace("/signin");
	const Authorization = "Bearer " + token_access;
	const headers = {
		Authorization: Authorization,
	};
	return headers;
}
