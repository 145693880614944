import {
	DocumentoDiTrasporto,
	CausaleTrasportoTypes,
	AspettoEsterioreBeniTypes,
} from "../bolleFattureSlice";

export const getCausaleDdt = (ddt: DocumentoDiTrasporto) => {
	const causaleType = Object.entries(CausaleTrasportoTypes).find(
		([key, value]) => key == ddt.causale_trasporto
	);
	if (causaleType != undefined) {
		return causaleType[1];
	} else {
		return null;
	}
};

export const gettipoDdt = (ddt: DocumentoDiTrasporto) => {
	const aspettoType = Object.entries(AspettoEsterioreBeniTypes).find(
		([key, value]) => key == ddt.aspetto_esteriore_beni
	);
	if (aspettoType != undefined) {
		return aspettoType[1];
	} else {
		return null;
	}
};
