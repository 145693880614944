import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../redux/store";
import { useLocation } from "react-router-dom";
import { UtenteLogged } from "../../../authentication/authenticationSlice";
import { PermissionTypes } from "../../../authentication/permissionsGroups";

import {
	Autista,
	Autisti,
	fetchAutisti,
} from "../../anagrafiche/anagraficheSlice";
import { AutistaSelect } from "./AutistaSelect";

import NavigazioneGiorniToolbar from "../../../common/dateUtils/NavigazioneGiorniToolbar";

import moment from "moment";

import { PianiViaggioState, fetchPianiViaggio } from "../schedeCaricoSlice";
import ViaggioAutistaCard from "./ViaggioAutistaCard";
import ViaggiAutistiList from "./ViaggiAutistiList";

import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

import DateToday from "../../../common/dateUtils/DateToday";
import {
	RecuperaDataCorrente,
	FissaDataCorrente,
} from "../../../common/dateUtils/RecuperaDataCorrente";

import { Box } from "@mui/system";

import { DATA_PIANO_VIAGGI_CORRENTE } from "./../../../../api/apiUtils";

const PianiViaggioPage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	const utente = useSelector<RootState, UtenteLogged>(
		(state) => state.authentication.utenteLogged
	);
	const autisti: Autisti = useSelector<RootState, Autisti>(
		(state) => state.anagrafiche.autisti
	);

	const [autistaCorrente, setAutistaCorrente] = useState<Autista | null>(null);
	const [utenteGestore, setUtenteGestore] = useState<boolean>(false);

	useEffect(() => {
		if (
			utente.is_staff ||
			(utente?.gruppi &&
				utente.gruppi.indexOf(PermissionTypes.PIANIFICATORI) > -1) ||
			(utente?.gruppi &&
				utente.gruppi.indexOf(PermissionTypes.OPERATORI_LOGISTICA) > -1) ||
			(utente?.gruppi &&
				utente.gruppi.indexOf(PermissionTypes.OPERATORI_LOGISTICA_LETTURA) > -1)
		) {
			setUtenteGestore(true);
		}
	}, [utente]);
	useEffect(() => {
		if (utente.autista) {
			const autistaUtente = autisti.results.find(
				(autista) => autista.id == utente.autista
			);
			autistaUtente && setAutistaCorrente(autistaUtente);
		} else {
			!autistaCorrente && setAutistaCorrente(autisti.results[0]);
		}
	}, [autisti.results]);

	type LocationState = {
		giornoSettimana: Date;
	};
	const location = useLocation();
	const recupera_data_corrente = RecuperaDataCorrente(location);
	// const recupera_data_corrente = () => {
	// 	if (location.state) {
	// 		const { giornoSettimana } = location.state as LocationState;
	// 		if (giornoSettimana) {
	// 			return giornoSettimana;
	// 		}
	// 	}
	// 	const dataLavorazioniCorrente = localStorage.getItem(
	// 		DATA_PIANO_VIAGGI_CORRENTE
	// 	);
	// 	if (!!dataLavorazioniCorrente) {
	// 		return new Date(dataLavorazioniCorrente);
	// 	}
	// 	return DateToday();
	// };

	const [data, setData] = useState<Date | undefined>(recupera_data_corrente);

	const pianiViaggio: PianiViaggioState = useSelector<
		RootState,
		PianiViaggioState
	>((state) => state.schedeCaricoState.pianiViaggio);

	const anagraficheErrorsStack: ErrorsStack = useSelector<
		RootState,
		ErrorsStack
	>((state) => state.anagrafiche.errorsStack);

	const [refresh, setRefresh] = useState<boolean>(false);

	useEffect(() => {
		data && FissaDataCorrente(data);
		// localStorage.setItem(
		// 	DATA_PIANO_VIAGGI_CORRENTE,
		// 	moment(data).format("YYYY-MM-DD")
		// );
		const data_da = data;
		const data_a = data;
		dispatch(fetchPianiViaggio({ data_da, data_a }));

		// dispatch(fetchAutisti(data));
		setRefresh(false);
	}, [data, autistaCorrente, refresh]);

	return (
		<Box>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					// autisti.errorsStack.status == ErrorStatusTypes.PENDING
					pianiViaggio.errorsStack.status == ErrorStatusTypes.PENDING ||
					anagraficheErrorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<NavigazioneGiorniToolbar
				titolo={
					"Piani Viaggio di " +
					autistaCorrente?.nome +
					" " +
					autistaCorrente?.cognome
				}
				dataPresente={true}
				data={data}
				setData={setData}
				refresh={() => {
					setRefresh(true);
				}}
				Toolbar={() => {
					return utenteGestore ? (
						<Stack
							direction="row"
							justifyContent="flex-end"
							alignItems="center"
							sx={{ ml: 2 }}
							spacing={1}
						>
							{autisti.results && (
								<AutistaSelect
									autistaCorrente={autistaCorrente}
									autisti={autisti.results}
									setAutistaCorrente={setAutistaCorrente}
								/>
							)}
						</Stack>
					) : (
						<></>
					);
				}}
			/>
			<Box
				sx={{
					p: 1,
				}}
			>
				{pianiViaggio.results
					.filter((piani) =>
						piani.autisti.includes(autistaCorrente?.id || 999999999)
					)
					.map((viaggioAutista) => {
						return (
							<ViaggioAutistaCard
								viaggioAutista={viaggioAutista}
								utenteGestore={utenteGestore}
								key={"viaggioAutista_" + viaggioAutista.id}
							/>
						);
					})}
				<Box sx={{ width: "100%", bgcolor: "#aaa", p: 2 }}>
					<ViaggiAutistiList pianiViaggio={pianiViaggio.results} />
				</Box>
			</Box>
		</Box>
	);
};

export default PianiViaggioPage;
