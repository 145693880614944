import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { DettaglioDDT } from "../bolleFattureSlice";

// Create styles
const styles = StyleSheet.create({
	textIntestazione: {
		fontSize: 9,
		borderBottom: "0.5px",
		fontStyle: "normal",
		width: "20%",
		paddingLeft: 1,
		paddingRight: 1,
		paddingTop: 5,
		paddingBottom: 5,
		marginBottom: 1,
	},
	textDati: {
		fontSize: 8,
		fontStyle: "normal",
		// fontWeight: 100,
		whiteSpace: "wrap",
		width: "20%",
		paddingLeft: 1,
		paddingRight: 1,
		paddingTop: 5,
		paddingBottom: 5,
		marginBottom: 1,
	},
	sectionTableIntestazione: {
		fontSize: 9,
		fontStyle: "normal",
		flexDirection: "column",
		// marginHorizontal: 2,
		marginTop: 2,
		// marginLeft: 1,
		backgroundColor: "#adcbd9",
		color: "#0a2d3d",
		// marginBottom: 10,
		// borderTop: "1px",
		// borderBottom: "0.5px",
	},
	sectionTableDati: {
		flexDirection: "row",
		borderStyle: "solid",
		borderWidth: "0.1px",
		// marginHorizontal: 20,
	},
});

interface DettaglioDdtPDFProps {
	dettagliDdt: DettaglioDDT[];
}

const DettaglioDdtPDF = ({ dettagliDdt }: DettaglioDdtPDFProps) => (
	<View
		style={{
			flexDirection: "column",
			marginTop: "10px",
		}}
		key="DettaglioDdtPDF"
	>
		{dettagliDdt.length > 0 && (
			<View
				style={[
					styles.sectionTableIntestazione,
					{
						flexDirection: "row",
					},
				]}
			>
				<Text style={[styles.textIntestazione, { width: "2%" }]}></Text>
				<Text style={[styles.textIntestazione, { width: "15%" }]}>
					Articolo
				</Text>
				<Text style={[styles.textIntestazione, { width: "25%" }]}>
					Descrizione
				</Text>
				<Text style={[styles.textIntestazione, { width: "20%" }]}>Lotto</Text>

				<Text
					style={[
						styles.textIntestazione,
						{ textAlign: "right", width: "20%" },
					]}
				>
					Colli
				</Text>
				<Text
					style={[
						styles.textIntestazione,
						{ textAlign: "right", width: "20%" },
					]}
				>
					Quantità
					<Text style={{ width: "2%" }}></Text>
				</Text>
				<Text style={[styles.textIntestazione, { width: "2%" }]}></Text>
			</View>
		)}
		{dettagliDdt.map((dettaglioDdt) => (
			<View
				style={styles.sectionTableDati}
				key={"dettaglioDdt_" + dettaglioDdt.id}
			>
				<Text style={{ width: "2%" }}></Text>
				<Text style={[styles.textDati, { width: "15%" }]}>
					{dettaglioDdt.storico_articolo}
				</Text>
				<Text style={[styles.textDati, { width: "25%" }]}>
					{dettaglioDdt.storico_descrizione}
				</Text>
				<Text style={[styles.textDati, { width: "20%" }]}>
					{dettaglioDdt.storico_codice_tracciabilita}
				</Text>

				<Text style={[styles.textDati, { textAlign: "right", width: "20%" }]}>
					{Intl.NumberFormat("it-IT").format(dettaglioDdt.colli)}
				</Text>

				<Text style={[styles.textDati, { textAlign: "right", width: "20%" }]}>
					{Intl.NumberFormat("it-IT").format(dettaglioDdt.quantita) + " kg"}
				</Text>
				<Text style={{ width: "2%" }}></Text>
			</View>
		))}
	</View>
);

export default DettaglioDdtPDF;
