import React from "react";
import ReactPDF from "@react-pdf/renderer";
import moment from "moment";

import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";

import { Lotto } from "../../pianificazione/pianificazioneSlice";
import { SchedaCarico } from "../schedeCaricoSlice";
import { Parametri } from "../../anagrafiche/anagraficheSlice";
const logoIolepec = require("../../../../../static/images/logo/logo-Iolepec-trasp.png");

// Create styles
const styles = StyleSheet.create({
	page: {
		backgroundColor: "#fff",
		paddingTop: 20,
	},
	section: {
		marginTop: 5,
		marginLeft: 25,
		marginRight: 25,
		padding: 5,
	},
	sectionIntestazione: {
		padding: 5,
		margin: 10,
		border: "2px",
		borderColor: "#777777",
		color: "#000000",
	},
	text: {
		fontSize: 12,
		fontStyle: "normal",
	},
	sectionTable: {
		flexDirection: "row",
		marginHorizontal: 40,
		borderTopWidth: 0.5,
		borderLeftWidth: 0.5,
		borderRightWidth: 0.5,
	},
});

interface FogliModuliDisinfezionePdfProps {
	lotto: Lotto;
	schedaCarico: SchedaCarico;
	parametri: Parametri;
}

const FogliModuliDisinfezionePdf = ({
	lotto,
	schedaCarico,
	parametri,
}: FogliModuliDisinfezionePdfProps) => (
	<View
		style={{
			flexDirection: "column",
			justifyContent: "space-between",
			height: 820,
		}}
		key={"disinfezione_schedaCarico_" + schedaCarico.id}
	>
		<View>
			<View
				style={[
					styles.sectionIntestazione,
					{
						flexDirection: "row",
						justifyContent: "space-between",
						// marginTop: 0,
						// paddingTop: 5,
					},
				]}
			>
				<Text style={styles.text}>
					Allegato all'ordine di carico n°
					<Text style={{ fontSize: 18, fontStyle: "bold" }}>
						{schedaCarico.numero_ordine_giorno}
					</Text>
					{" del " + moment(lotto.data_lavorazione).format("DD/MM/YYYY")}
				</Text>
			</View>

			<View
				style={{
					flexDirection: "row",
					justifyContent: "flex-start",
					marginLeft: 25,
					marginBottom: 25,
				}}
			>
				<View style={(styles.section, { margin: "0px" })}>
					<View>
						<Text
							style={[styles.text, { marginLeft: "16px", marginTop: "15px" }]}
						>
							<Image
								src={logoIolepec}
								style={{
									width: 189,
									height: 45,
									padding: 10,
								}}
							/>
						</Text>
					</View>
				</View>
				<View style={(styles.section, { marginLeft: 8 })}>
					<Text style={(styles.text, { fontSize: 14 })}>Iolepec s.r.l.</Text>
					<Text style={(styles.text, { fontSize: 10 })}>
						Sede legale: via G. Rossa, 7
					</Text>
					<Text style={(styles.text, { fontSize: 10 })}>
						35020 Ponte San Nicolò (PD)
					</Text>
					<Text style={(styles.text, { fontSize: 10 })}>
						Sede operativa: via Garibaldi, 43
					</Text>
					<Text style={(styles.text, { fontSize: 10 })}>
						35020 Ponte San Nicolò (PD)
					</Text>
					<Text style={(styles.text, { fontSize: 10 })}>
						Cap.Soc.: € 20.000
					</Text>
					<Text style={(styles.text, { fontSize: 10 })}>
						Cod.Fisc. - P.Iva - Reg.Imprese 03770910283
					</Text>
				</View>
			</View>
			<View>
				<View
					style={[
						styles.section,
						{
							flexDirection: "row",
							justifyContent: "center",
							margin: 0,
							padding: 0,
							// paddingLeft: 0,
						},
					]}
				>
					<Text
						style={{
							color: "#000000",
							fontSize: 16,
						}}
					>
						ATTESTAZIONE DI PULIZIA E DISINFEZIONE DEGLI AUTOMEZZI
					</Text>
				</View>
				<View
					style={[
						styles.section,
						{
							flexDirection: "row",
							justifyContent: "center",
							marginBottom: 15,
							paddingBottom: 0,
							paddingLeft: 0,
						},
					]}
				>
					<Text
						style={{
							color: "#000000",
							fontSize: 16,
						}}
					>
						ADIBITI AL TRASPORTO DI VOLATILI
					</Text>
				</View>
			</View>
			<View style={styles.section}>
				<Text style={styles.text}>
					Dichiarazione dell'operatore/conducente del mezzo di trasporto di:
					VOLATILI
				</Text>
			</View>
			<View style={styles.section}>
				<Text style={styles.text}>
					Il sottoscritto MARIANO SCARSO nato a Ponte San Nicolò (PD) il
					19/03/1954
				</Text>
				<Text style={[styles.text, { marginTop: 5 }]}>
					c.f. SCRMRN54C19G855M
				</Text>
				<Text style={[styles.text, { marginTop: 5 }]}>
					Operatore/conducente del veicolo (tipo e targa):{" "}
					<Text style={styles.text}>
						<Text
							style={[
								styles.text,
								{
									fontWeight: "bold",
									paddingBottom: 0,
									fontSize: 16,
									color: "#052282",
								},
							]}
						>
							{schedaCarico.camion_pack?.nome +
								" " +
								schedaCarico.camion_pack?.targa}
						</Text>
						{schedaCarico.rimorchio ? " con rimorchio" : " senza rimorchio"}
					</Text>
				</Text>
				<Text style={[styles.text, { marginTop: 5, lineHeight: 1.5 }]}>
					di proprietà della Ditta IOLEPEC s.r.l. dichiara che il veicolo è
					stato sottoposto a pulizia e disinfezione, secondo la normativa
					vigente.
				</Text>
				<Text style={[styles.text, { marginTop: 10, marginBottom: 5 }]}>
					La pulizia e disinfezione è avvenuta prima dell'ingresso nello
					stabilimento:
				</Text>
				<View>
					<Text
						style={{ fontSize: 14, color: "#052282", fontWeight: "medium" }}
					>
						{lotto.allevamento_pack?.nome +
							" (" +
							lotto.allevamento_pack?.codice_asl +
							")"}{" "}
						- {lotto.allevamento_pack?.indirizzo}, {lotto.allevamento_pack?.cap}{" "}
						{lotto.allevamento_pack?.comune} -
						{lotto.allevamento_pack?.provincia_desc}
					</Text>
				</View>
				<Text style={[styles.text, { marginTop: 15, marginBottom: 10 }]}>
					La pulizia e la disinfezione sono state effettuate presso:
				</Text>
				<View style={styles.sectionTable}>
					<Text
						style={[
							styles.text,
							{ marginLeft: 5, marginRight: 80, marginVertical: 10 },
						]}
					>
						Macello:
					</Text>
					<View>
						<Text
							style={{ fontSize: 14, fontWeight: "medium", marginVertical: 10 }}
						>
							Scarso Romualdo e figli s.r.l. CE IT 0307 M
						</Text>
					</View>
				</View>
				<View style={styles.sectionTable}>
					<Text
						style={[
							styles.text,
							{ marginLeft: 5, marginRight: 77, marginVertical: 10 },
						]}
					>
						Indirizzo:
					</Text>
					<View>
						<Text
							style={{ fontSize: 14, fontWeight: "medium", marginVertical: 10 }}
						>
							Via Garibaldi, 43 - 35020 Ponte San Nicolò (PD)
						</Text>
					</View>
				</View>
				<View style={styles.sectionTable}>
					<Text
						style={[
							styles.text,
							{ marginLeft: 5, marginRight: 95, marginVertical: 10 },
						]}
					>
						Data:
					</Text>
					<View>
						<Text
							style={{
								fontSize: 14,
								color: "#052282",
								fontWeight: "medium",
								marginVertical: 10,
							}}
						>
							{moment(lotto.data_lavorazione)
								.subtract(1, "days")
								.format("D MMMM YYYY")}
						</Text>
					</View>
				</View>
				<View style={styles.sectionTable}>
					<Text
						style={[
							styles.text,
							{ marginLeft: 5, marginRight: 100, marginVertical: 10 },
						]}
					>
						Ora:
					</Text>
					<View>
						<Text
							style={{
								fontSize: 14,
								color: "#052282",
								fontWeight: "medium",
								marginVertical: 10,
							}}
						>
							12:00
						</Text>
					</View>
				</View>
				<View style={[styles.sectionTable, { borderBottomWidth: 0.5 }]}>
					<Text
						style={[
							styles.text,
							{ marginLeft: 5, marginRight: 20, marginVertical: 10 },
						]}
					>
						Disinfettante usato:
					</Text>
					<View>
						<Text
							style={{ fontSize: 14, fontWeight: "medium", marginVertical: 10 }}
						>
							{parametri.disinfettante}
						</Text>
					</View>
				</View>
			</View>
			<View
				style={{
					flexDirection: "row",
					justifyContent: "space-between",
					marginTop: 10,
					marginBottom: 0,
				}}
			>
				<View style={styles.section}>
					<Text style={styles.text}>
						Ponte San Nicolò,{" "}
						<Text>
							{moment(lotto.data_lavorazione)
								.subtract(1, "days")
								.format("DD/MM/YYYY")}
						</Text>
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.text}>
						Firma ......................................
					</Text>
				</View>
			</View>
		</View>
		<View
			style={{
				flexDirection: "column",
				marginTop: 0,
			}}
		>
			<View style={styles.section}>
				<Text style={{ fontSize: 10 }}>
					Timbro del macello ove sono avvenute le operazioni di pulizia e
					disinfezione:
				</Text>
			</View>
			<View
				style={[styles.section, { borderWidth: 0.5, marginHorizontal: 180 }]}
			>
				<Text
					style={[
						styles.text,
						{
							marginTop: 10,
							paddingHorizontal: 50,
							paddingVertical: 30,
						},
					]}
				></Text>
			</View>
		</View>
		<Text break />
	</View>
);

export default FogliModuliDisinfezionePdf;
