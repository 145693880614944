import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const style = {
	position: "absolute" as "absolute",
	top: "10%",
	left: "50%",
	transform: "translate(-50%, 0%)",
	width: "95%",
	bgcolor: "background.paper",
	border: "2px solid #00838f",
	boxShadow: 24,
	borderRadius: 3,
	p: 4,
};
interface TransitionsModalProps {
	open: boolean;
	handleClose: () => void;
	children?: React.ReactNode;
}

export default function TransitionsModal({
	open,
	handleClose,
	children,
}: TransitionsModalProps) {
	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={open}
			sx={{ overflow: "scroll" }}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<Box>
					{/* <Button onClick={handleClose}>annulla</Button> */}
					<Box sx={style}>{children}</Box>
				</Box>
			</Fade>
		</Modal>
	);
}
