import { handleResponse, handleError, headerset } from "../apiUtils";
import moment from "moment";

const baseUrl = (destinatario_id?: number) => {
	let url: string =
		process.env.API_URL + "/api/bolle_fatture/destinatari_trasporto/";

	if (destinatario_id) {
		url += +destinatario_id + "/";
	}

	return url;
};

export function fetchDestinazioniTrasporto() {
	return fetch(baseUrl(), {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getDestinazioneTrasporto(destinatario_id: number) {
	let url = baseUrl(destinatario_id);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
