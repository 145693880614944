import React from "react";
import { Lavorazione } from "../../lavorazioni/lavorazioniSlice";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import MuiTableCell, { tableCellClasses } from "@mui/material/TableCell";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/system";
// Create styles

const TableCell = styled(MuiTableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#2368a1",
		color: "#fff",
		fontSize: 16,
		fontWeight: "bold",
		padding: 8,
		paddingBottom: 2,
		borderBottom: 0,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

interface LottoCommittenteLavorazioniTableProps {
	lavorazioni: Lavorazione[];
}

const LottoCommittenteLavorazioniTable = ({
	lavorazioni,
}: // giornoSettimana,
LottoCommittenteLavorazioniTableProps) => (
	<>
		{lavorazioni.filter(
			(l) =>
				l.numero_cassette_prodotte > 0 ||
				(l.numero_cassette_richieste && l.numero_cassette_richieste > 0)
		).length > 0 ? (
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 700 }} aria-label="customized table">
					<TableHead>
						<TableRow sx={{ pb: 0, mb: 0, borderBottom: 0 }}>
							<TableCell>Articolo</TableCell>
							<TableCell>Descrizione</TableCell>
							<TableCell>Capi x cassa</TableCell>
							<TableCell>Destinazione</TableCell>
							<TableCell colSpan={2} align="center">
								N° casse
							</TableCell>
							<TableCell>N° capi</TableCell>
							<TableCell>Peso medio</TableCell>
							<TableCell>Peso Totale</TableCell>
						</TableRow>
						<TableRow sx={{ pt: 0, mt: 0 }}>
							<TableCell colSpan={4} />
							<TableCell>richieste</TableCell>
							<TableCell>prodotto</TableCell>
							<TableCell colSpan={3} />
						</TableRow>
					</TableHead>

					<TableBody>
						{lavorazioni
							.filter(
								(l) =>
									l.numero_cassette_prodotte > 0 ||
									(l.numero_cassette_richieste &&
										l.numero_cassette_richieste > 0)
							)
							.map((lavorazione) => (
								<TableRow key={"lavorazione_" + lavorazione.id}>
									<TableCell>
										{lavorazione.codice_articolo_committente
											? lavorazione.codice_articolo_committente
											: lavorazione.articolo?.codice_articolo}
									</TableCell>
									<TableCell>{lavorazione.descrizione}</TableCell>
									<TableCell align="center">
										{lavorazione.articolo?.numero_capi_cassa}
									</TableCell>
									<TableCell>
										{(lavorazione.destinazione || "") +
											" " +
											(lavorazione.note == null ? "" : lavorazione.note)}
									</TableCell>
									<TableCell align="center">
										{Intl.NumberFormat("it-IT").format(
											lavorazione.numero_cassette_richieste || 0
										)}
									</TableCell>
									<TableCell align="center">
										{Intl.NumberFormat("it-IT").format(
											lavorazione.numero_cassette_prodotte || 0
										)}
									</TableCell>
									<TableCell align="center">
										{lavorazione.articolo?.numero_capi_cassa &&
											Intl.NumberFormat("it-IT").format(
												lavorazione.numero_cassette_prodotte *
													(lavorazione.articolo?.numero_capi_cassa || 0)
											)}
									</TableCell>
									<TableCell align="right">
										{lavorazione.articolo?.numero_capi_cassa &&
											Intl.NumberFormat("it-IT").format(
												lavorazione.peso_cassette_prodotte /
													(lavorazione.numero_cassette_prodotte *
														(lavorazione.articolo?.numero_capi_cassa || 0) || 1)
											) + " kg"}
									</TableCell>
									<TableCell align="right">
										{Intl.NumberFormat("it-IT").format(
											lavorazione.peso_cassette_prodotte || 0
										)}{" "}
										kg
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		) : (
			<Card raised sx={{ backgroundColor: "#2368a1", color: "#fff", p: 1 }}>
				<CardContent sx={{ mb: 3 }}>
					<Typography variant="h4">
						Non ci sono lavorazioni disponibili al momento
					</Typography>
				</CardContent>
			</Card>
		)}
	</>
);

export default LottoCommittenteLavorazioniTable;
