import React from "react";
import {
	DocumentoDiTrasporto,
	DestinazioneTrasporto,
	VettoreTrasporto,
} from "../bolleFattureSlice";
import { Committente } from "components/main/anagrafiche/anagraficheSlice";
import DdtPdf from "./DdtPdf";
// import ResaLavorazioniPDF from "./ResaLavorazioniPDF";
import { Page, Document } from "@react-pdf/renderer";

interface DocumentoDdtProps {
	documentoDiTrasporto: DocumentoDiTrasporto;
	destinatario: Committente;
	destinazione?: DestinazioneTrasporto;
	vettore?: VettoreTrasporto;
}

const DocumentoDdt = ({
	documentoDiTrasporto,
	destinatario,
	destinazione,
	vettore,
}: DocumentoDdtProps) => {
	return (
		<Document>
			<Page
				size="A4"
				orientation="portrait"
				style={{ backgroundColor: "#fff", paddingTop: 20 }}
				key={"documentoDiTrasporto_" + documentoDiTrasporto?.id}
			>
				{documentoDiTrasporto && (
					<DdtPdf
						documentoDiTrasporto={documentoDiTrasporto}
						destinatario={destinatario}
						destinazione={destinazione}
						vettore={vettore}
					/>
				)}
			</Page>
		</Document>
	);
};

export default DocumentoDdt;
