import { handleResponse, handleError, headerset } from "../apiUtils";

// export interface Progetto {
//   id: number;
//   nome: string;
//   descrizione: string;
//   data_inizio: Date;
//   deadline: Date;
//   data_fine: Date;
//   owner: number;
//   boards: [];
//   deleted: boolean;
// }

const baseUrl = (tipiCapo_velocita_processamento?: boolean) => {
	if (tipiCapo_velocita_processamento) {
		return (
			process.env.API_URL +
			"/api/anagrafiche/tipi_capo/?tipiCapo_velocita_processamento=true"
		);
	} else {
		return process.env.API_URL + "/api/anagrafiche/tipi_capo";
	}
};

export function fetchTipiCapo(tipiCapo_velocita_processamento?: boolean) {
	return fetch(baseUrl(tipiCapo_velocita_processamento), {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
