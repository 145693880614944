import React from "react";
import { Allevamento } from "../../anagrafiche/anagraficheSlice";

import moment from "moment";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import PaperMui from "@mui/material/Paper";

import { styled, Box } from "@mui/system";
import { PianiViaggio } from "../schedeCaricoSlice";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import RvHookupIcon from "@mui/icons-material/RvHookup";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

const vistaAllevamento = (allevamentoPack?: Allevamento) => {
	return (
		allevamentoPack && (
			<>
				<Typography variant="subtitle1">{allevamentoPack.nome}</Typography>
				<Typography variant="subtitle1">
					{allevamentoPack.comune} {allevamentoPack.distanza}km
				</Typography>
			</>
		)
	);
};

interface ViaggiAutistiListProps {
	pianiViaggio: PianiViaggio[];
}

const ViaggiAutistiList = ({ pianiViaggio }: ViaggiAutistiListProps) => {
	return (
		<Box>
			{pianiViaggio.map((pianoViaggio) => {
				return (
					<Card key={"pianoViaggi_" + pianoViaggio.id} sx={{ mb: 1 }}>
						<CardContent>
							<Grid container spacing={1}>
								<Grid item xs={5} sm={3} md={1}>
									<Stack direction="row" spacing={0.4} justifyContent="center">
										{pianoViaggio.rimorchio && (
											<Avatar sx={{ bgcolor: "#ddd" }}>
												<RvHookupIcon color="info" />
											</Avatar>
										)}
										<Avatar sx={{ bgcolor: "#ddd" }}>
											<LocalShippingIcon color="info" />
										</Avatar>
									</Stack>
									<Typography variant="subtitle1" align="center">
										{pianoViaggio.camion_pack?.nome}
									</Typography>
								</Grid>
								<Grid item xs={7} sm={5} md={2}>
									{pianoViaggio.autisti_pack.map((autista) => {
										return (
											<Typography
												variant="h6"
												key={"autista_piani_viaggio_" + autista.id}
											>
												{autista.cognome + " " + autista.nome}
											</Typography>
										);
									})}
								</Grid>
								<Grid item xs={12} sm={4} md={2}>
									<Typography variant="subtitle1">
										{moment(pianoViaggio.data_ora_carico_pianificata).format(
											"dddd MMM YYYY hh:mm"
										)}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={3}>
									<Typography variant="subtitle1">
										{Intl.NumberFormat("it-IT").format(
											pianoViaggio.numero_capi_pianificati || 0
										)}{" "}
										{pianoViaggio.lotto_pack.tipo_capo_desc}
									</Typography>
									<Typography variant="subtitle1">
										gabbie:{" "}
										{Intl.NumberFormat("it-IT").format(
											pianoViaggio.numero_fori || 0
										)}{" "}
										animali x foro:{" "}
										{Intl.NumberFormat("it-IT").format(
											pianoViaggio.numero_capi_foro || 0
										)}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={3}>
									{vistaAllevamento(pianoViaggio.lotto_pack.allevamento_pack)}
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				);
			})}
		</Box>
	);
};

export default ViaggiAutistiList;
