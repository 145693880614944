import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../redux/store";
import { Lotto } from "../../../pianificazione/pianificazioneSlice";
import {
	Lavorazioni,
	Lavorazione,
	fetchLavorazioni,
} from "../../../lavorazioni/lavorazioniSlice";
// import { DATA_PIANIFICAZIONE_LAVORAZIONI_CORRENTE } from "../../../../../api/apiUtils";

import GrandeImballoPdf from "./GrandeImballoPdf";
import NavigazioneGiorniToolbar from "../../../../common/dateUtils/NavigazioneGiorniToolbar";
import { PDFViewer, Page, Document, StyleSheet } from "@react-pdf/renderer";
import { Text, View } from "@react-pdf/renderer";

import moment from "moment";

import {
	RecuperaDataCorrente,
	FissaDataCorrente,
} from "../../../../common/dateUtils/RecuperaDataCorrente";

import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// import DateToday from "../../../../common/dateUtils/DateToday";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

// Create styles
interface GrandeImballoStampaPageProps {}

const GrandeImballoStampaPage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();
	type LocationState = {
		lavorazine_id: number;
	};
	const location = useLocation();

	const { lavorazine_id } = location.state as LocationState;
	const lavorazione: Lavorazione | undefined = useSelector<
		RootState,
		Lavorazione | undefined
	>((state) =>
		state.lavorazioniState.lavorazioni.results.find(
			(lavorazione) => lavorazione.id == lavorazine_id
		)
	);

	const lotto: Lotto | undefined = useSelector<RootState, Lotto | undefined>(
		(state) =>
			state.pianificazione.lotti.results.find(
				(lotto) => lotto.id == lavorazione?.lotto
			)
	);

	const anagraficheErrorsStack: ErrorsStack = useSelector<
		RootState,
		ErrorsStack
	>((state) => state.anagrafiche.errorsStack);

	// const lavorazione = lavorazioni.results.find(
	// 	(lavorazione) => lavorazione.id == lavorazine_id
	// );

	return (
		<Box>
			{/* <Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={
					lotto.errorsStack.status == ErrorStatusTypes.PENDING ||
					anagraficheErrorsStack.status == ErrorStatusTypes.PENDING
				}
			>
				<CircularProgress color="inherit" />
			</Backdrop> */}

			<Box sx={{ backgroundColor: "#999", height: "80vh" }}>
				{!!(lavorazione && lotto) ? (
					<PDFViewer width={"100%"} height={"100%"}>
						<Document>
							<Page
								size="A4"
								orientation="portrait"
								style={{
									backgroundColor: "#fff",
									paddingTop: 20,
								}}
								key={"grande_imballo" + lavorazine_id}
							>
								{/* <View
								style={{
									flexDirection: "row",
									justifyContent: "center",
								}}
							>
								eeeeeeeeee
							</View> */}
								<GrandeImballoPdf lotto={lotto} lavorazione={lavorazione} />
							</Page>
						</Document>
					</PDFViewer>
				) : (
					<Typography variant="h4" gutterBottom sx={{ p: 30 }} align="center">
						Nessuna lavorazione trovata.
					</Typography>
				)}
			</Box>
		</Box>
	);
};

export default GrandeImballoStampaPage;
