import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";
import moment from "moment";

import SchedeCaricoLottoCard from "../schedeCarico/schedeCaricoLottoCard/SchedeCaricoLottoCard";

import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { theme } from "../../../theme";

import {
	Lotto,
	getLotto,
	saveLotto,
	// setNuovoLotto,
} from "./pianificazioneSlice";

import {
	ErrorStatusTypes,
	ErrorsStack,
	parseErrorMessage,
} from "../../common/errorsDeclarations";

import {
	Committente,
	Produttore,
	Allevamento,
	fetchCamions,
	fetchAutisti,
	Camion,
	Autista,
	Parametri,
} from "../anagrafiche/anagraficheSlice";
import {
	fetchschedeCarico,
	generaSchedeCarico,
	mandaM4,
} from "../schedeCarico/schedeCaricoSlice";
// import { DndProvider } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
import CamionsBox from "../schedeCarico/CamionsBox";
import AutistiBox from "../schedeCarico/AutistiBox";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

interface SchedeCaricoLottoTabProps {
	lotto: Lotto;
}

const SchedeCaricoLottoTab = ({
	lotto,
	...props
}: SchedeCaricoLottoTabProps) => {
	const dispatch = useAppDispatch();
	const [currentTab, setCurrentTab] = useState("1");

	useEffect(() => {
		if (lotto.data_lavorazione) {
			const data_da = new Date(lotto.data_lavorazione);
			const data_a = new Date(lotto.data_lavorazione);
			dispatch(fetchschedeCarico({ data_da, data_a }));
			dispatch(fetchCamions(lotto.data_lavorazione));
			dispatch(fetchAutisti(lotto.data_lavorazione));
		}
	}, [lotto]);

	const allevamenti = useSelector<RootState, Allevamento[]>(
		(state) => state.anagrafiche.allevamenti.results
	);

	const camions: Camion[] = useSelector<RootState, Camion[]>((state) =>
		state.anagrafiche.camions.results.filter((c) => c.tipo_trasporto == "V")
	);
	const autisti: Autista[] = useSelector<RootState, Autista[]>(
		(state) => state.anagrafiche.autisti.results
	);

	const handleTabChange = (event: any, newValue: string) => {
		setCurrentTab(newValue);
	};

	const handlerGeneraSchedeCarico = (lotto: Lotto, viaggioLungo: boolean) => {
		dispatch(generaSchedeCarico({ lotto, viaggioLungo }));
	};

	const handlerMandaM4 = (lotto: Lotto, contatto_id: number) => {
		dispatch(mandaM4({ lotto, contatto_id }));
	};

	return (
		// <DndProvider backend={HTML5Backend}>
		<Grid container>
			<Grid item sm={6} md={10}>
				<SchedeCaricoLottoCard
					lotto={lotto}
					allevamento={allevamenti.find(
						(allevamento) => allevamento.id == lotto.allevamento
					)}
					camions={camions}
					autisti={autisti}
					// deleteSchedaCarico,
					generaSchedeCarico={handlerGeneraSchedeCarico}
					mandaM4={handlerMandaM4}
				/>
			</Grid>
			<Grid item sm={6} md={2}>
				<Paper
					elevation={2}
					sx={{
						m: 1,
						mt: 2,
						backgroundColor: "#dfdfdf",
					}}
				>
					<TabContext value={currentTab}>
						<Box
							sx={{
								borderBottom: 1,
								borderColor: "divider",
							}}
						>
							<TabList
								onChange={handleTabChange}
								aria-label="lab API tabs example"
								variant="scrollable"
								scrollButtons="auto"
							>
								<Tab label="Autisti" value="1" />
								<Tab
									label="Camion"
									value="2"
									disabled={lotto.id == undefined}
								/>
							</TabList>
						</Box>

						<TabPanel value="1" sx={{ p: 1 }}>
							<AutistiBox autisti={autisti} />
						</TabPanel>
						<TabPanel value="2" sx={{ p: 1 }}>
							<CamionsBox camions={camions} />
						</TabPanel>
					</TabContext>
				</Paper>
				{/* </Paper> */}
			</Grid>
		</Grid>
		// </DndProvider>
	);
};

export default SchedeCaricoLottoTab;
