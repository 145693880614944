import React, { useEffect } from "react";

import { ReportAutisti } from "../reportAutistiCamionSlice";

import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";

import { tabellaOrariAutistaMesePayroll } from "./tabellaOrariAutistaMesePayroll";
import { Box } from "@mui/system";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

interface ReportAutistiMeseTableProps {
	reportAutistiMese: ReportAutisti[];
	nome: string | undefined;
	cognome: string | undefined;
}

const calcoloTotaleOre = (reportAutistiMese: ReportAutisti[]) => {
	const totaleOre = reportAutistiMese.reduce(function (
		previousValue: number,
		currentValue: ReportAutisti
	) {
		return previousValue + Number(currentValue.delta_turno_per_autista || 0);
	},
	0);
	return totaleOre;
};

const ReportAutistiMeseTable = ({
	reportAutistiMese,
	nome,
	cognome,
}: ReportAutistiMeseTableProps) => {
	const [totaleOre, setTotaleOre] = React.useState(0);

	useEffect(() => {
		setTotaleOre(calcoloTotaleOre(reportAutistiMese));
	}, [reportAutistiMese]);

	return (
		<React.Fragment>
			<TableContainer component={Paper} sx={{ width: "100%" }}>
				<Box>
					<Table size="small" aria-label="report mensile autisti">
						<TableHead>
							<TableRow>
								<TableCell
									align="center"
									colSpan={4}
									sx={{
										paddingTop: 3,
										paddingBottom: 3,
										fontWeight: "bold",
										fontSize: 18,
										textTransform: "uppercase",
									}}
								>
									{cognome} {nome}
									{" - "}
									<span style={{ textTransform: "none" }}>Ore totali</span>{" "}
									{Math.floor(
										moment.duration(Number(totaleOre || 0), "seconds").asHours()
									)}
									:
									{moment
										.utc(
											moment
												.duration(Number(totaleOre || 0), "seconds")
												.as("milliseconds")
										)
										.format("mm")}
								</TableCell>
								<TableCell align="center" colSpan={1}>
									{reportAutistiMese.length > 0 && (
										<CSVLink
											data={tabellaOrariAutistaMesePayroll(reportAutistiMese)}
										>
											Dati x payroll
										</CSVLink>
									)}
								</TableCell>
							</TableRow>
							{/* <TableRow>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell
									align="left"
									sx={{
										// paddingBottom: 3,
										fontWeight: "bold",
										fontSize: 18,
										textTransform: "uppercase",
									}}
								>
									Ore totali{" "}
									{moment.duration(Number(totaleOre || 0), "seconds").hours()}:
									{moment.duration(Number(totaleOre || 0), "seconds").minutes()}
								</TableCell>
							</TableRow> */}
							<TableRow>
								<TableCell sx={{ fontWeight: "bold" }}>
									Data inizio turno
								</TableCell>
								<TableCell sx={{ fontWeight: "bold" }} align="center">
									Ora inizio turno
								</TableCell>
								<TableCell sx={{ fontWeight: "bold" }}>
									Data fine turno
								</TableCell>
								<TableCell sx={{ fontWeight: "bold" }} align="center">
									Ora fine turno
								</TableCell>
								<TableCell sx={{ fontWeight: "bold" }} align="center">
									Ore lavorate
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{reportAutistiMese.length > 0 &&
								reportAutistiMese.map((dettaglioAutista) => (
									<TableRow key={dettaglioAutista.id}>
										<TableCell>
											{dettaglioAutista.data_ora_partenza_sede &&
												moment(dettaglioAutista.data_ora_partenza_sede).format(
													"DD MMMM YYYY"
												)}
										</TableCell>
										<TableCell align="center">
											{dettaglioAutista.data_ora_partenza_sede &&
												moment(dettaglioAutista.data_ora_partenza_sede).format(
													"HH:mm"
												)}
										</TableCell>
										<TableCell>
											{dettaglioAutista.data_ora_fine_turno &&
												moment(dettaglioAutista.data_ora_fine_turno).format(
													"DD MMMM YYYY"
												)}
										</TableCell>
										<TableCell align="center">
											{dettaglioAutista.data_ora_fine_turno &&
												moment(dettaglioAutista.data_ora_fine_turno).format(
													"HH:mm"
												)}
										</TableCell>
										<TableCell align="center">
											{Math.floor(
												moment
													.duration(
														Number(
															dettaglioAutista.delta_turno_per_autista || 0
														),
														"seconds"
													)
													.asHours()
											)}
											:
											{moment
												.utc(
													moment
														.duration(
															Number(
																dettaglioAutista.delta_turno_per_autista || 0
															),
															"seconds"
														)
														.as("milliseconds")
												)
												.format("mm")}
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</Box>
			</TableContainer>
		</React.Fragment>
	);
};

export default ReportAutistiMeseTable;
