import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../../redux/store";
import NavigazioneMesiToolbar from "../../../../common/dateUtils/NavigazioneMesiToolbar";

// import { DATA_KG_COMMITTENTE_CORRENTE } from "../../../../../api/apiUtils";
import { fetchKgCommittenti, KgCommittentiState } from "./kgCommittentiSlice";

import {
	Committente,
	fetchCommittenti,
} from "../../../anagrafiche/anagraficheSlice";
import { ErrorStatusTypes } from "../../../../common/errorsDeclarations";

import moment from "moment";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import KgCommittentiMeseTable from "./KgCommittentiMeseTable";
import KgCommittentiMeseGrafico from "./KgCommittentiMeseGrafico";
import CircularProgress from "@mui/material/CircularProgress";
// import DateToday from "../../../../common/dateUtils/DateToday";

import {
	RecuperaDataCorrente,
	FissaDataCorrente,
} from "../../../../common/dateUtils/RecuperaDataCorrente";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";

const ReportKgCommittentiMesePage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	const committenti: Committente[] = useSelector<RootState, Committente[]>(
		(state) => state.anagrafiche.committenti.results
	);

	const kgCommittentiState: KgCommittentiState = useSelector<
		RootState,
		KgCommittentiState
	>((state) => state.kgCommittentiState.kgCommittenti);

	type LocationState = {
		giornoMese: any;
	};
	const location = useLocation();
	const recupera_data_corrente = RecuperaDataCorrente(location);

	// const recupera_data_corrente = () => {
	// 	if (location.state) {
	// 		const { giornoMese } = location.state as LocationState;
	// 		if (giornoMese) {
	// 			return giornoMese;
	// 		}
	// 	}
	// 	const dataReportKgCommittenteCorrente = localStorage.getItem(
	// 		DATA_KG_COMMITTENTE_CORRENTE
	// 	);
	// 	if (!!dataReportKgCommittenteCorrente) {
	// 		return new Date(dataReportKgCommittenteCorrente);
	// 	}
	// 	return DateToday();
	// };

	const [data, setData] = React.useState<Date | undefined>(
		recupera_data_corrente
	);
	const [refresh, setRefresh] = React.useState<boolean>(false);

	useEffect(() => {
		data && FissaDataCorrente(data);

		// localStorage.setItem(
		// 	DATA_KG_COMMITTENTE_CORRENTE,
		// 	moment(data).format("YYYY-MM-DD")
		// );
		const data_da = moment(data).startOf("month").toDate();
		const data_a = moment(data).endOf("month").toDate();
		// const data_da = moment(data).startOf("isoWeek").toDate();
		// const data_a = moment(data).endOf("isoWeek").toDate();
		dispatch(fetchKgCommittenti({ data_da, data_a }));
		dispatch(fetchCommittenti());
	}, [data, refresh]);

	return (
		<Box>
			{kgCommittentiState.errorsStack.status == ErrorStatusTypes.PENDING ? (
				<Stack justifyContent="center" sx={{ p: "20vw" }}>
					<CircularProgress size={50} color="info" />
				</Stack>
			) : (
				kgCommittentiState.results && (
					<Card>
						<NavigazioneMesiToolbar
							titolo={"Kg per Committente"}
							setData={setData}
							data={data}
							refresh={() => {
								setRefresh(true);
							}}
						/>
						<CardContent>
							<KgCommittentiMeseTable
								committenti={committenti}
								kgCommittentiMese={kgCommittentiState.results}
							/>
						</CardContent>

						<CardContent>
							<Box sx={{ height: "43vh", minHeight: 200, px: 3, pb: 4 }}>
								<Typography variant="h4">Calo percentuale medio</Typography>

								<KgCommittentiMeseGrafico
									committenti={committenti}
									kgCommittentiMese={kgCommittentiState.results}
								/>
							</Box>
						</CardContent>
					</Card>
				)
			)}
		</Box>
	);
};

export default ReportKgCommittentiMesePage;
