import { handleResponse, handleError, headerset } from "../apiUtils";
import { UtenteEsterno } from "components/main/ospiti/ospitiSlice";

const baseUrl = (documento_id?: number | null) => {
	let url: string = process.env.API_URL + "/api/ospiti/utenti_esterni/";

	if (documento_id) {
		url += +documento_id + "/";
	}

	return url;
};

export function fetchUtentiEsterni() {
	let url = baseUrl();
	// moment(data_da).format("YYYY-MM-DD");
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getUtenteEsterno(utenteEsterno_id: number) {
	let url = baseUrl(utenteEsterno_id);
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function saveUtenteEsterno(utenteEsternoToSave: UtenteEsterno) {
	return fetch(baseUrl(utenteEsternoToSave.id), {
		method: utenteEsternoToSave.id ? "PUT" : "POST", // PUT to update when id already exists.
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
		body: JSON.stringify({
			...utenteEsternoToSave,
			// Parse authorId to a number (in case it was sent as a string).
			// id: parseInt(progetto.id, 10),
		}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function deleteUtenteEsterno(utenteEsternoToDelete: UtenteEsterno) {
	return fetch(baseUrl(utenteEsternoToDelete.id), {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// ###########################

export function invitaUtenteEsterno(utenteEsterno: UtenteEsterno) {
	const url: string =
		process.env.API_URL +
		"/api/ospiti/utenti_esterni/invia_invito/" +
		utenteEsterno.id +
		"/";
	return fetch(url, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// ###########################

export function fetchLottiCommittente(
	committente_id: number,
	numeroRecord?: number,
	page?: number
) {
	let url: string =
		process.env.API_URL +
		"/api/ospiti/utenti_esterni/lotti_committente/" +
		committente_id +
		"/";

	if (numeroRecord != undefined || page != undefined) {
		url += "?";
	}
	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// ###########################

export function fetchAllevamentiCommittente(
	committente_id: number,
	numeroRecord?: number,
	page?: number
) {
	let url: string =
		process.env.API_URL +
		"/api/ospiti/utenti_esterni/allevamenti_committente/" +
		committente_id +
		"/";
	if (numeroRecord != undefined) {
		url += "?numero_record=" + numeroRecord;
	} else if (page != undefined) {
		url += "?page=" + page;
	}

	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

// ###########################

export function fetchLottiProduttore(
	produttore_id: number,
	numeroRecord?: number,
	page?: number
) {
	let url: string =
		process.env.API_URL +
		"/api/ospiti/utenti_esterni/lotti_produttore/" +
		produttore_id +
		"/";

	if (numeroRecord != undefined || page != undefined) {
		url += "?";
	}
	if (numeroRecord != undefined) {
		url += `numero_record=${numeroRecord}&`;
	}
	if (page != undefined) {
		url += `page=${page}`;
	}
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
