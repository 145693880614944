import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";

import { RootState, useAppDispatch } from "../../../../redux/store";

import {
	setUtenteCorrente,
	getUtenteEsterno,
	UtenteEsterno,
	UtentiEsterni,
	saveUtenteEsterno,
	deleteUtenteEsterno,
	invitaUtenteEsterno,
} from "../ospitiSlice";
import {
	Committente,
	Produttore,
	Autista,
	fetchCommittenti,
	fetchProduttori,
	fetchArticoli,
	Autisti,
} from "../../anagrafiche/anagraficheSlice";
import NavigazioneGiorniToolbar from "../../../common/dateUtils/NavigazioneGiorniToolbar";

import moment from "moment";
import UtenteEsternoForm from "./UtenteEsternoForm";
import {
	ErrorStatusTypes,
	ErrorsStack,
} from "../../../common/errorsDeclarations";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

import Button from "@mui/material/Button";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Grid from "@mui/material/Grid";
import PrintIcon from "@mui/icons-material/Print";
import SendIcon from "@mui/icons-material/Send";

import { Box } from "@mui/system";
import { Routes_path } from "components/routerApp";

const UtenteEsternoPage = () => {
	moment.locale("it");
	const dispatch = useAppDispatch();

	const utenteEsterno_id = useSelector<RootState, number | null>(
		(state) => state.ospitiState.utenteEsterno_id
	);

	type LocationState = {
		utente_id?: number | null;
	};
	const location = useLocation();

	const { utente_id } = (location.state as LocationState) || {
		utente_id: null,
	};

	const [refresh, setRefresh] = React.useState<boolean>(false);

	// useEffect(() => {
	// 	dispatch(fetchCommittenti());
	// 	dispatch(fetchProduttori());

	// 	setRefresh(false);
	// }, [refresh]);

	useEffect(() => {
		dispatch(setUtenteCorrente({ utenteEsterno_id: utente_id || null }));
	}, []);

	// ############# Per resettare all'uscita ###############################
	useEffect(
		() => () => {
			dispatch(setUtenteCorrente({ utenteEsterno_id: null }));
		},
		[]
	);
	// #######################################################################

	useEffect(() => {
		if (utenteEsterno_id != null || utenteEsterno_id != undefined) {
			dispatch(getUtenteEsterno(utenteEsterno_id));
		}
	}, [utenteEsterno_id, refresh]);

	const utentiEsterni: UtentiEsterni = useSelector<RootState, UtentiEsterni>(
		(state) => state.ospitiState.utentiEsterni
	);
	const committenti = useSelector<RootState, Committente[]>(
		(state) => state.anagrafiche.committenti.results
	);

	const produttori: Produttore[] = useSelector<RootState, Produttore[]>(
		(state) => state.anagrafiche.produttori.results
	);
	const autisti: Autista[] = useSelector<RootState, Autista[]>(
		(state) => state.anagrafiche.autisti.results
	);

	const utenteNuovoFieldsErrors: ErrorsStack = useSelector<
		RootState,
		ErrorsStack
	>((state) => state.ospitiState.utentiEsterni.errorsStack);

	const [utenteEsterno, setUtenteEsterno] = useState<UtenteEsterno>({
		id: null,
		user: null,
		committente: null,
		produttore: null,
		solo_lettura: true,
		errorsStack: utenteNuovoFieldsErrors,
	});

	useEffect(() => {
		setUtenteEsterno({
			...utenteEsterno,
			errorsStack: utenteNuovoFieldsErrors,
		});
	}, [utenteNuovoFieldsErrors]);

	const utenteEsternoEsistente: UtenteEsterno | undefined = useSelector<
		RootState,
		UtenteEsterno | undefined
	>((state) =>
		state.ospitiState.utentiEsterni.results.find(
			(utenteEsterno) => utenteEsterno.id == utenteEsterno_id
		)
	);

	useEffect(() => {
		utenteEsternoEsistente && setUtenteEsterno(utenteEsternoEsistente);
	}, [utenteEsterno_id, utenteEsternoEsistente]);

	const handlerSaveUtenteEsterno = (utenteEsternoToSave: UtenteEsterno) => {
		dispatch(saveUtenteEsterno(utenteEsternoToSave));
	};

	const handlerDeleteUtente = (documentoDiTrasportoToDelete: UtenteEsterno) => {
		dispatch(deleteUtenteEsterno(documentoDiTrasportoToDelete));
	};

	const handlerInvitaUtenteEsterno = () => {
		utenteEsterno?.user?.email && dispatch(invitaUtenteEsterno(utenteEsterno));
	};

	return (
		<>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={utentiEsterni.errorsStack.status == ErrorStatusTypes.PENDING}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			<NavigazioneGiorniToolbar
				titolo={
					utenteEsterno.id ? "Utente n° " + utenteEsterno.id : "Nuovo utente"
				}
				dataPresente={false}
				setData={() => {}}
				refresh={() => {
					setRefresh(true);
				}}
			/>

			<Box
				sx={{
					p: 1,
					// elevation: 0,
					margin: 5,
					mx: 1,
					// marginBottom: 5,
					padding: 5,
					paddingBottom: 3,
					backgroundColor: "#f5f5f5",
					border: 0,
					// borderStyle: "solid",
					borderRadius: 4,
					boxShadow: 2,
				}}
			>
				{utenteEsterno && (
					<Grid container justifyContent="center">
						<Grid item xs={10} sm={7} md={5}>
							<Card raised>
								<CardContent sx={{ p: 2 }}>
									<UtenteEsternoForm
										key={utenteEsterno.id || 0}
										utenteEsterno={utenteEsterno}
										committenti={committenti}
										produttori={produttori}
										autisti={autisti}
										saveUtenteEsterno={handlerSaveUtenteEsterno}
									/>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				)}

				<Stack direction="row" spacing={2} sx={{ mt: 5 }}>
					<Button
						variant="contained"
						component={Link}
						to={{
							pathname: Routes_path.UTENTI_ESTERNI,
						}}
						endIcon={<KeyboardReturnIcon />}
					>
						Torna alla lista Utenti
					</Button>

					<Button
						variant="contained"
						onClick={handlerInvitaUtenteEsterno}
						endIcon={<SendIcon />}
						disabled={!utenteEsterno.user?.email}
					>
						Manda invito a {utenteEsterno.user?.email || "..."}
					</Button>
				</Stack>
			</Box>
		</>
	);
};

export default UtenteEsternoPage;
