import { handleResponse, handleError, headerset } from "../apiUtils";

export function fetchEtichette() {
	const url: string = process.env.API_URL + "/api/anagrafiche/etichette/";
	return fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: headerset().Authorization,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
