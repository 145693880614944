import React, { useState, useEffect } from "react";
import { SchedaMacello } from "./schedeMacelloSlice";
import { Lotto } from "../pianificazione/pianificazioneSlice";
import { theme } from "../../../theme";

import SchedaMacelloForm from "./SchedaMacelloForm";
import SchedaMacelloInfoLotto from "./SchedaMacelloInfoLotto";

import Avatar from "@mui/material/Avatar";
import { Box } from "@mui/system";
import { getColore } from "../pianificazione/utility/lottoUtility";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

interface SchedaMacelloCardProps {
	lotto: Lotto;
	schedaMacello?: SchedaMacello;
	saveSchedaMacello: (schedaMacello: SchedaMacello) => void;
}
const SchedaMacelloCard = ({
	lotto,
	schedaMacello,
	saveSchedaMacello,
}: SchedaMacelloCardProps) => {
	const [dettagliOn, setDettagliOn] = useState<boolean>(false);

	const subheader = (
		<div>
			{lotto.codice_tracciabilita}
			<br />
			<em>{lotto.produttore_pack?.nome}</em>
		</div>
	);
	return (
		<Card
			key={"lotto_card_scheda_macello" + lotto.id}
			sx={{ my: 2 }}
			// expanded={expanded === "lotto_" + lotto.id}
			// onChange={handleChangeLottoPanel(lotto)}
		>
			<Grid container spacing={1} justifyContent="flex-start">
				<Grid item sm={1}>
					<CardHeader
						sx={{ pb: 1, pt: 1 }}
						avatar={
							<IconButton
								sx={{ ml: -1 }}
								aria-label="delete"
								onClick={() => setDettagliOn(!dettagliOn)}
							>
								{/* <Badge
									badgeContent={"?"}
									color="info"
									sx={{
										"& .MuiBadge-badge": {
											// color: "lightgreen",
											// backgroundColor: "green",
											border: `2px solid ${theme.palette.background.paper}`,
										},
									}}
								> */}
								<Avatar
									sx={{
										width: "1.5rem",
										height: "1.5rem",
										bgcolor: theme.palette.primary.main,
										color: "white",
									}}
									aria-label="recipe"
								>
									{lotto.ordinamento_lotto}
								</Avatar>
								{/* </Badge> */}
							</IconButton>
						}
						title={
							<Stack
								direction="row"
								justifyContent="space-between"
								alignItems="center"
								mr={2}
								spacing={2}
							>
								<Box>
									<Typography variant="h6">{lotto.committente_desc}</Typography>
									<Typography variant="body1">
										{Intl.NumberFormat("it-IT").format(lotto.numero_capi || 0) +
											" " +
											lotto.tipo_capo_desc +
											" " +
											(lotto.colore ? " " + getColore(lotto) : "")}
									</Typography>
								</Box>
								<Box>
									{lotto.num_tz_eviscerati_pianificati_lotto != 0 && (
										<Typography variant="body1">
											{"TZ eviscer.: " +
												lotto.num_tz_eviscerati_pianificati_lotto}
										</Typography>
									)}
									{lotto.num_tz_pianificati_lotto != 0 && (
										<Typography variant="body1">
											{"TZ: " + lotto.num_tz_pianificati_lotto}
										</Typography>
									)}
								</Box>
							</Stack>
						}
						subheader={subheader}
					/>
				</Grid>

				<Grid item sm={11}>
					<CardContent>
						{schedaMacello && (
							<SchedaMacelloForm
								schedaMacello={schedaMacello}
								saveSchedaMacello={saveSchedaMacello}
							/>
						)}
					</CardContent>
				</Grid>
			</Grid>
			{dettagliOn && (
				<CardContent
					sx={{
						border: 1,
						borderColor: theme.palette.primary.main,
						margin: 3,
					}}
				>
					<SchedaMacelloInfoLotto lotto={lotto} />
				</CardContent>
			)}
		</Card>
	);
};

export default SchedaMacelloCard;
