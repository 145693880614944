import { handleResponse, handleError, headerset } from "../apiUtils";
import { Allevamento } from "../../components/main/anagrafiche/anagraficheSlice";

const baseUrl = (allevamento_id?: number) => {
  if (allevamento_id) {
    return (
      process.env.API_URL +
      "/api/anagrafiche/allevamenti/" +
      allevamento_id +
      "/"
    );
  } else {
    return process.env.API_URL + "/api/anagrafiche/allevamenti?page=all";
  }
};

export function fetchAllevamenti() {
  return fetch(baseUrl(), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: headerset().Authorization,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAllevamento(allevamento_id: number) {
  let url = baseUrl(allevamento_id);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: headerset().Authorization,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveAllevamento(allevamento: Allevamento) {
  return fetch(baseUrl(allevamento.id), {
    method: allevamento.id ? "PUT" : "POST", // PUT to update when id already exists.
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: headerset().Authorization,
    },
    body: JSON.stringify({
      ...allevamento,
      // Parse authorId to a number (in case it was sent as a string).
      // id: parseInt(progetto.id, 10),
    }),
  })
    .then(handleResponse)
    .catch(handleError);
}
