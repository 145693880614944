import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel, { InputLabelProps } from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";

// import { Theme } from '../styles';
import { SxProps } from "@mui/system";
import Select from "@mui/material/Select";

import { Controller } from "react-hook-form";

interface ReactHookFormSelectProps {
	name: string;
	label: string;
	disabled?: boolean;
	notched?: boolean;
	register: any;
	control: any;
	watch: any;
	defaultValue?: any;
	required?: boolean;
	multiple?: boolean;
	children: any;
	variant?: "standard" | "outlined" | "filled";
	error?: string;
	sx?: SxProps;
	displayEmpty?: boolean;
}

const ReactHookFormSelect = ({
	name,
	label,
	disabled = false,
	notched = true,
	register,
	control,
	watch,
	defaultValue,
	required = false,
	multiple = false,
	children,
	variant,
	error,
	sx,
	...props
}: ReactHookFormSelectProps) => {
	const labelId = `${name}-label`;
	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<FormControl sx={sx}>
					<InputLabel required={required} id={name + "_label"} shrink={true}>
						{label}
					</InputLabel>
					<Select
						key={watch(name)}
						required={required}
						id={name}
						label={label}
						disabled={disabled}
						notched={notched}
						multiple={multiple}
						defaultValue={defaultValue}
						value={field.value || (multiple ? [] : "")}
						{...register(name)}
						error={!!error}
						variant={variant}
						sx={sx}
					>
						{children}
					</Select>
					{error && <FormHelperText error>{error}</FormHelperText>}
				</FormControl>
			)}
		/>
	);
};
export default ReactHookFormSelect;
